import React from "react";
import { useNavigate } from "react-router-dom";
import Datalayer from "../../services/datalayer";

import { CheckoutFooter, CheckoutPageTitle, CTAButton } from "../../components";

import PartialFail from "../../assets/icons/status-partial-fail.png";
import * as S from "./styles";

const GiftCheckoutFailed = () => {
  const navigate = useNavigate();

  return (
    <S.Wrapper className="wrapper">
      <S.EmptyHeader />

      <S.CheckoutContainer>
        <S.Image>
          <img src={PartialFail} alt={"status-partial-fail"} />
        </S.Image>

        <div>
          <CheckoutPageTitle title={"OPS!"} />

          <S.Description>
            Ocorreu um erro ao processar a sua compra. Verifique os dados
            inseridos ou tente novamente com outro cartão.
          </S.Description>
        </div>

        <CTAButton
          id={"btn-gift_fail-tente_novamente"}
          name={"btn-gift_fail-tente_novamente"}
          onClick={() => {
            Datalayer.onClick(
              "checkout",
              "botao:presente:falha-na-compra",
              "tente_novamente"
            );
            navigate("/presente/pagamento", { replace: true });
          }}
          text="Tente novamente"
        />

        <S.Description>
          <strong>SE O ERRO PERSISTIR</strong>
          <br />
          <span className="lighter">
            Entre em contato com o nosso suporte para receber ajuda ou solicite
            um boleto pelo WhatsApp:{" "}
            <a
              href="https://wa.me/5511993425668"
              target="_blank"
              rel="noopener noreferrer"
            >
              (11) 99342-5668
            </a>
            , de segunda à sexta, das 10h às 19h.
          </span>
        </S.Description>
      </S.CheckoutContainer>
      <CheckoutFooter />
    </S.Wrapper>
  );
};

export default GiftCheckoutFailed;
