import React from "react";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";

import { LoadingSpinner } from "../../components";
import { parseLink } from "../../util/mobile-redirect";

const Redirect = () => {
  const [searchParams] = useSearchParams();

  const redirectToAppMobile = async () => {
    if (isMobile) {
      const deeplink = await parseLink(searchParams.get("route"));
      window.location.replace(deeplink);
    }
  };

  const redirectToAppDesktop = () => {
    window.location.href = searchParams.get("route");
  };

  const continueToRoute = () => {
    try {
      isMobile ? redirectToAppMobile() : redirectToAppDesktop();

      // if redirect to app fails without crash, redirect to web
      setTimeout(() => {
        redirectToAppDesktop();
      }, 5000);
    } catch (error) {
      // If there is an error, continue in the web app as a fallback
      redirectToAppDesktop();
    }
  };

  useEffect(() => {
    continueToRoute();
  }, []);

  return <LoadingSpinner color="light" />;
};

export default Redirect;
