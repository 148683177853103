import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Auth } from "@aws-amplify/auth";
import { useAuthContext } from "../../context/AuthContext";
import { translateAmplifyErrorMessage } from "../../util/messages";

import {
  Button,
  PasswordInput,
  LoginPageTitle,
  LoginContainer,
  Alert,
} from "../../components";

import * as S from "./styles";

const schema = yup
  .object({
    password: yup
      .string()
      // eslint-disable-next-line no-template-curly-in-string
      .min(6, "A senha deve conter no mínimo ${min} caracteres")
      .trim(),
    passwordConfirmation: yup
      .string()
      // eslint-disable-next-line no-template-curly-in-string
      .min(6, "A senha deve conter no mínimo ${min} caracteres")
      .oneOf([yup.ref("password"), null], "As senhas não conferem")
      .trim(),
  })
  .required("Por favor, preencha a nova senha");

const NewPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { signIn, setIsLoggedIn } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const { email, oldPassword } = location.state;

      const user = await Auth.signIn(email, oldPassword);
      await Auth.completeNewPassword(user, data.password, {
        email,
      });
      await signIn(email, data.password);

      setIsLoggedIn(true);
      navigate("/", { replace: true });
    } catch (error) {
      const { message } = translateAmplifyErrorMessage(error?.message);

      setAlert({
        message,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state?.passwordUpdated) {
      setAlert({ message: location.state.message, type: "success" });
      return window.history.replaceState({}, document.title);
    }
  }, []);

  return (
    <LoginContainer>
      <S.LoginWrapper>
        {!!alert.message && <Alert variant={alert.type} text={alert.message} />}

        <LoginPageTitle title={"Nova senha"} />
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <PasswordInput
            label={"Nova senha"}
            inputName={"password"}
            registerFunction={register}
            errors={errors}
          />

          <PasswordInput
            label={"Confirmar nova senha"}
            inputName={"passwordConfirmation"}
            registerFunction={register}
            errors={errors}
          />

          <Button
            variant={"white"}
            size={"full"}
            type="submit"
            disabled={loading}
            loading={loading}
          >
            Alterar senha
          </Button>
        </S.Form>
      </S.LoginWrapper>
    </LoginContainer>
  );
};

export default NewPassword;
