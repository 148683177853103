import styled from "styled-components";

export const Container = styled.div`
  margin: 0 0.5rem;
`;

export const Wrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    margin: 0 2rem;
    .block1 {
      width: 50%;
    }
    .block2 {
      padding: 1rem;
      width: 50%;
    }
  }
`;

export const TeacherPicture = styled.div`
  position: relative;
  width: 100%;
  margin: 1rem auto;
  img {
    width: 100%;
    height: 235px;
    object-fit: contain;
  }
  .overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 3rem;
    background: linear-gradient(180deg, rgba(32, 30, 32, 0) 0%, #201e20 100%);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #fff;
  }
  @media (min-width: 768px) {
    img {
      object-fit: contain;
      /* width: 50%; */
    }
  }
`;

export const SubTitle = styled.div`
  font-size: ${(props) => (props.bold ? "16px" : "20px")};
  font-weight: ${(props) => (props.bold ? "700" : "300")};
  margin: 1rem 0;
  @media (min-width: 768px) {
    font-size: ${(props) => (props.bold ? "20px" : "30px")};
  }
`;

export const Categories = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  div {
    background-color: #100f10;
    padding: 5px;
    margin: 0 14px 14px 0;
    height: 26px;
    line-height: 1;
    text-align: center;
    overflow-y: hidden;
    a {
      color: #aca9b3;
      font-size: 12px;
    }
    a:visited {
      text-decoration: none;
    }
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-top: 0.5rem;
    div a {
      font-size: 16px;
    }
  }
`;

export const TeacherInfo = styled.div`
  margin: 1rem 0;
  font-size: 14px;
  line-height: 22px;
`;

export const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.5rem 0;

  // TODO: refactor after cds-api implementation
  a,
  div {
    background-color: #100f10;
    color: #aca9b3;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 3px 10px 3px 0;
    transition: all 0.1s ease-in;
  }
  a:hover {
    text-decoration: none;
    color: #cff16b;
  }
`;
