import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    a:hover {
      text-decoration: none;
    }
  `}
`;

export const CourseDescription = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family.maisonNeue};
    font-size: ${theme.font.size.smaller};
    font-weight: ${theme.font.weight.light};
    letter-spacing: 0em;
    text-align: left;

    p {
      line-height: 160%;
    }

    @media (${device.smallPhone}) {
      font-size: ${theme.font.size.small};
    }

    @media (${device.desktop}) {
      font-size: ${theme.font.size.default};
    }
  `}
`;

export const Teacher = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.small};
    font-family: ${theme.font.family.maisonNeue};
    font-size: ${theme.font.size.smaller};
    font-weight: ${theme.font.weight.light};
    letter-spacing: 0em;
    text-align: left;
    margin: ${theme.spacings.small} 0;

    img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      object-fit: cover;
    }

    p {
      margin: 0;
    }

    @media (${device.smallPhone}) {
      font-size: ${theme.font.size.small};
    }
  `}
`;
