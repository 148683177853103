import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCategories, useCategoryCourses, useSentry } from "../../hooks";
import {
  CardsGrid,
  Container,
  LoadingSpinner,
  NoContentLoaded,
} from "../../components";

const Category = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { logException } = useSentry(false);

  const { data: categories, isFetching: isCategoriesFetching } =
    useCategories();

  const categoryId = useMemo(
    () => categories?.find((category) => slug === category.slug)?.id,
    [categories, slug]
  );

  const { data: categoryData, isFetching: isCoursesFetching } =
    useCategoryCourses(
      {
        categoryId,
      },
      { enabled: !!categoryId }
    );

  if (categories?.length > 0 && !categoryId) {
    const exceptionParams = {
      error: new Error("Category id not found"),
      transactionName: "Category page",
      origin: "error redirect",
      extras: [
        { label: "categories", value: categories },
        { label: "slug", value: slug },
        { label: "categoryId", value: categoryId },
      ],
    };
    logException(exceptionParams);

    return navigate("/error");
  }

  if (isCoursesFetching || isCategoriesFetching) {
    return <LoadingSpinner color={"light"} />;
  }

  const { title, courses } = !!categoryData && categoryData;

  if (!courses) {
    const exceptionParams = {
      error: new Error("Category without courses"),
      transactionName: "Category page",
      origin: "no content error",
      extras: [
        { label: "categories", value: categories },
        { label: "slug", value: slug },
        { label: "categoryId", value: categoryId },
        { label: "categoryData", value: categoryData },
      ],
    };
    logException(exceptionParams);

    return (
      <Container type={"clean"}>
        <NoContentLoaded />
      </Container>
    );
  }

  return (
    <Container type={"clean"}>
      <CardsGrid content={courses} pageName={title} />
    </Container>
  );
};

export default Category;
