import React, { useEffect, useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useCheckoutContext } from "../../context/CheckoutContext";
import { requests } from "../../services/requests";
import { useDatalayer, useQuery, useUser } from "../../hooks";
import Datalayer from "../../services/datalayer";
import { secureStorage } from "../../util/SecureStorage";

import {
  Alert,
  Button,
  CheckoutPageTitle,
  Input,
  PhoneNumberInput,
  SignupHeader,
  StepperCustom,
} from "../../components";

import strings from "../../config/strings.json";
import * as S from "./styles";

const schema = yup.object({
  giverEmail: yup
    .string()
    .required("Preencha um email válido")
    .email("Preencha um email válido")
    .lowercase()
    .trim(),
  recipientEmail: yup
    .string()
    .required("Preencha um email válido")
    .email("Preencha um email válido")
    .lowercase()
    .trim(),
  giverName: yup
    .string()
    .required("Por favor, preencha o nome de quem presenteia")
    .trim(),
  recipientName: yup
    .string()
    .required("Por favor, preencha o nome da pessoa presenteada")
    .trim(),
});

const GiftCheckout = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const { currentStep, giftSteps, changeStep } = useCheckoutContext();
  const {
    watch,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { handleBlur } = useDatalayer();

  const [alert, setAlert] = useState({
    type: "generic",
    message: "⚠️ Válido apenas para novas assinaturas",
  });
  const [phoneError, setPhoneError] = useState({});
  const [loading, setLoading] = useState(false);

  const watchedPhoneNumber = watch("giverPhoneNumber");

  const { data: userResponse } = useUser();

  useEffect(() => {
    const { userData } = userResponse;
    if (userData) {
      setValue("giverPhoneNumber", `+${userData.phoneNumber}`);
      setValue("giverName", userData.name);
      setValue("giverEmail", userData.email);
    }
  }, [userResponse]);

  useEffect(() => {
    // watch input typing to clear error when a correct phone number is typed
    if (
      watchedPhoneNumber &&
      isPossiblePhoneNumber(watchedPhoneNumber) &&
      !!phoneError.giverPhoneNumber
    ) {
      return setPhoneError({});
    }
  }, [watchedPhoneNumber]);

  const onSubmit = async (data) => {
    try {
      // phoneNumber input is not validated by Yup
      if (
        !data.giverPhoneNumber ||
        !isPossiblePhoneNumber(data.giverPhoneNumber)
      ) {
        throw new Error("PhoneNumberInvalid");
      }
      setPhoneError({});

      setLoading(true);
      const {
        data: { message },
      } = await requests.verifyGiftSubscription(
        data.giverEmail,
        data.recipientEmail
      );

      if (message === "success") {
        const storageKey = data.giverEmail + data.recipientEmail;
        secureStorage.setItem(
          storageKey,
          JSON.stringify({
            giverName: data.giverName,
            giverEmail: data.giverEmail,
            giverPhoneNumber: data.giverPhoneNumber,
            recipientName: data.recipientName,
            recipientEmail: data.recipientEmail,
          })
        );

        navigate(`/presente/planos?${query.toString()}`, {
          state: {
            giverEmail: data.giverEmail,
            recipientEmail: data.recipientEmail,
          },
        });
      }
    } catch (error) {
      if (error.message === "PhoneNumberInvalid") {
        // this object follows Yup errors structure
        return setPhoneError({
          giverPhoneNumber: {
            message: "Por favor, preencha um telefone válido",
          },
        });
      }

      if (error.response) {
        const { status } = error.response.data;

        if (status === 409) {
          return setAlert({
            type: "error",
            message: (
              <>
                <span style={{ display: "block" }}>
                  <strong>Já existe uma assinatura com esse e-mail.</strong>
                </span>
                <span>
                  O vale presente é válido apenas para novas assinaturas.
                </span>
              </>
            ),
          });
        }
      }

      return setAlert({
        type: "error",
        message:
          "Houve um problema, por favor, verifique os dados e tente novamente ou entre em contato com o suporte",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    changeStep("cadastro");
  }, []);

  return (
    <S.MainWrapper>
      <SignupHeader />
      <StepperCustom steps={giftSteps} currentStep={currentStep} />
      <S.ContentWrapper>
        <CheckoutPageTitle
          title="Assinatura para presente"
          subtitle="Após concluir o pagamento, escolha como enviar o presente para que seu beneficiário possa utilizá-lo."
        />

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <b>Quem vai dar o presente:</b>
          <Input
            variant="light"
            label={"Seu nome"}
            inputName={"giverName"}
            registerFunction={register}
            errors={errors}
            onBlur={(e) => handleBlur(e, "checkout", "presente:1", "giverName")}
          />
          <Input
            variant="light"
            label={"Seu e-mail"}
            inputName={"giverEmail"}
            registerFunction={register}
            errors={errors}
            style={{ textTransform: "lowercase" }}
            onBlur={(e) =>
              handleBlur(e, "checkout", "presente:1", "giverEmail")
            }
          />
          <PhoneNumberInput
            variant="light"
            label={"Seu telefone"}
            inputName={"giverPhoneNumber"}
            control={control}
            errors={phoneError}
            onBlur={(e) =>
              handleBlur(e, "checkout", "presente:1", "giverPhoneNumber")
            }
          />

          <div>
            <b>Quem vai receber o presente:</b>

            {!!alert.message && (
              <Alert variant={alert.type} text={alert.message} />
            )}

            <Input
              variant="light"
              label={"Nome"}
              inputName={"recipientName"}
              registerFunction={register}
              errors={errors}
              onBlur={(e) =>
                handleBlur(e, "checkout", "presente:1", "recipientName")
              }
            />
          </div>
          <Input
            variant="light"
            label={"E-mail"}
            inputName={"recipientEmail"}
            registerFunction={register}
            errors={errors}
            style={{ textTransform: "lowercase" }}
            onBlur={(e) =>
              handleBlur(e, "checkout", "presente:1", "recipientEmail")
            }
          />
          <S.PrivacyPolicy>
            <p>
              Ao criar uma conta você confirma que concorda com os{" "}
              <a
                id={"btn-gift_checkout-termos_de_uso"}
                name={"btn-gift_checkout-termos_de_uso"}
                target="_blank"
                rel="noopener noreferrer"
                href={strings.url.termsOfUse}
                onClick={() =>
                  Datalayer.onClickLink("checkout", "termos-de-uso")
                }
              >
                Termos de Uso
              </a>
              ,{" "}
              <a
                id={"btn-gift_checkout-politica_de_privacidade"}
                name={"btn-gift_checkout-politica_de_privacidade"}
                target="_blank"
                rel="noopener noreferrer"
                href={strings.url.privacyPolicy}
                onClick={() =>
                  Datalayer.onClickLink("checkout", "politica-privacidade")
                }
              >
                Política de Privacidade
              </a>{" "}
              e aceita receber publicidade e promoções da Casa do Saber.
            </p>

            <Button
              variant={"green"}
              size={"full"}
              type="submit"
              onClick={() => {
                if (!loading) {
                  Datalayer.onClick("checkout", "botao:presente:1", "próximo");
                }

                if (
                  !isPossiblePhoneNumber(getValues("giverPhoneNumber") || "")
                ) {
                  return setPhoneError({
                    giverPhoneNumber: {
                      message: "Por favor, preencha um telefone válido",
                    },
                  });
                }
              }}
              disabled={loading}
              loading={loading}
            >
              Próximo
            </Button>
          </S.PrivacyPolicy>
        </S.Form>
      </S.ContentWrapper>
    </S.MainWrapper>
  );
};

export default GiftCheckout;
