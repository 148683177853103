import styled, { css } from "styled-components";

export const CourseTagsContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    color: ${theme.font.color.lightGrey};
    margin: ${theme.spacings.small} 0;

    @media (min-width: ${theme.breakPoint.regularPhone}) {
      margin: ${theme.spacings.medium} 0;
    }

    @media (min-width: ${theme.breakPoint.laptop}) {
      cursor: pointer;
    }

    a,
    span {
      background-color: #100f10;
      font-size: 12px;
      color: ${theme.font.color.white};
      padding: 5px 10px;
      border-radius: 5px;
      margin: 3px 10px 3px 0;
      transition: all 0.1s ease-in;
      text-decoration: none !important;

      @media (min-width: ${theme.breakPoint.laptop}) {
        cursor: pointer;
      }
    }

    a:hover {
      text-decoration: none !important;
      color: ${theme.font.color.white};
    }
  `}
`;
