import React from "react";
import * as S from "./styles";

const Button = ({
  children,
  id = undefined,
  name = undefined,
  onClick = undefined,
  disabled = false,
  variant,
  size,
  loading,
  type,
  ...props
}) => {
  const buttonColor = {
    green: "green",
    white: "white",
    black: "black",
    outlined: "outlined",
    transparent: "transparent",
    default: "transparent",
  };

  const buttonSize = {
    small: "small",
    full: "full",
    default: "full",
  };

  return (
    <S.Wrapper
      id={id}
      name={name}
      onClick={onClick}
      disabled={disabled}
      variant={buttonColor[variant] || buttonColor["default"]}
      size={buttonSize[size] || buttonSize["default"]}
      type={type}
      {...props}
    >
      {!!loading ? <div className="spinner black" /> : children}
    </S.Wrapper>
  );
};

export default Button;
