import React from "react";
import * as S from "./styles";

const Title = ({ children, htmlTag = "default", ...props }) => {
  const render = {
    h1: <S.H1 {...props}>{children}</S.H1>,
    h3: <S.H3 {...props}>{children}</S.H3>,
    h2: <S.H2 {...props}>{children}</S.H2>,
    h4: <S.H4 {...props}>{children}</S.H4>,
    h5: <S.H5 {...props}>{children}</S.H5>,
    h6: <S.H6 {...props}>{children}</S.H6>,
    default: null,
  };

  return <>{render[htmlTag] || render["default"]}</>;
};

export default Title;
