import React from "react";
import * as S from "./styles";

const Checkbox = ({ label, value, onChange, id }) => {
  return (
    <S.Label>
      <input
        type="checkbox"
        checked={value}
        onChange={onChange}
        id={id}
        name={id}
      />
      {label}
    </S.Label>
  );
};

export default Checkbox;
