import styled, { css } from "styled-components";
import { device, size } from "../../styles/mediaQueries";
import { Link } from "react-router-dom";

const MobileHeaderModifiers = {
  full: (theme) => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin: 0;
  `,
  clean: (theme) => css`
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;

    .logo {
      justify-self: center;
    }
  `,
  transparent: (theme) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    position: relative;
    height: 60px;
    margin-bottom: -60px;
    z-index: 99;
  `,
  minimal: (theme) => css`
    height: 60px;
  `,
};

export const Wrapper = styled.div`
  ${({ theme, variant }) => css`
    .logo {
      cursor: pointer;
    }
    @media (${device.laptop}) {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      min-height: 80px;
      .logo img {
        height: 60px;
      }
      .formSearch {
        width: 65%;
        max-width: 450px;
        position: relative;
      }
    }

    ${variant && MobileHeaderModifiers[variant](theme)}
  `}
`;
export const Icons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
  font-size: 1rem;
  padding: 0 10px;

  svg,
  span,
  .search-control {
    cursor: pointer;
  }

  .search-control {
    background: none;
    border: none;
    color: #fff;
  }

  .search-control:hover,
  svg:hover,
  span:hover {
    transition: all 0.2s ease-out;
    color: #cff16b;
  }

  .search-control span {
    display: none;
    font-size: 14px;
    margin: 0 3px;
  }

  a {
    line-height: normal;
    font-size: initial;
  }
  button {
    font-size: 10px;
    line-height: normal;
    color: #cccccc;
    background-color: #201e20;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px;
    transition: all 0.2s ease-out;

    :hover {
      color: #cff16b;
      border: 1px solid #cff16b;
    }
  }

  svg {
    font-size: 1.5rem;
  }

  @media (min-width: 414px) {
    button {
      font-size: 12px;
      padding: 5px 10px;
    }
  }

  @media (min-width: 993px) {
    .search-control span {
      display: inline;
    }
  }
`;
export const Text = styled.a`
  text-transform: uppercase;
  color: #cff16b !important;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;
export const TooltipText = styled.div`
  cursor: pointer;
`;
export const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + 7px);
  right: 0px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 150px;
  padding: 5px 5px;
  border-radius: 4px;
  padding: 0.5s ease-in-out;

  &:before {
    content: "";
    width: 0;
    height: 0;
    right: 2%;
    top: -8px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
  }
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #100f10;
    margin-bottom: 0;
  }

  @media (${device.laptop}) {
    left: -92px;
    &:before {
      content: "";
      left: 45%;
    }
  }
`;
export const TooltipCard = styled.div`
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    background-color: #ffffff;
    width: 200px;
    padding: 8px 8px;
    height: 55px;
    z-index: 10;

    &:before {
      border-color: transparent transparent #fff #fff;
    }
  }
`;

export const ProfileLink = styled(Link)`
  ${({ theme }) => css`
    background-color: ${theme.darkTheme.colors.white};
    border: 1px solid ${theme.darkTheme.colors.white};
    color: ${theme.darkTheme.colors.darkBlack};
    border-radius: 5px;
    padding: 5px 10px;
    font-family: "Maison Neue", sans-serif;
    font-size: 12px !important;
    letter-spacing: 0em;

    transition: all 0.1s ease-in;

    :hover {
      color: ${theme.darkTheme.colors.darkBlack};
      text-decoration: none;
    }
  `}
`;

export const CertificateLink = styled(Link)`
  display: block;
  @media (max-width: ${size.regularPhone}px) {
    display: none;
  }
`;
