import React, { useEffect, useCallback, useRef } from "react";
import _ from "lodash";
import { Spinner } from "reactstrap";
import * as S from "./styles";
import SearchInput from "../SearchInput";
import { useSearchContext } from "../../../context/SearchContext";
import SearchResultsModule from "../SearchResultsModule";
import SearchEmptyResults from "../SearchEmptyResults";

const FloatingSearch = ({ close }) => {
  const {
    loading,
    userQuery,
    foldersFound,
    categoriesFound,
    updateQuery,
    resetSearch,
  } = useSearchContext();
  const searchRef = useRef(null);
  const delayedQuery = useCallback(_.debounce(updateQuery, 800), [userQuery]);

  useEffect(() => {
    delayedQuery();
    return delayedQuery.cancel;
  }, [userQuery, delayedQuery]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        close();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      resetSearch();
    };
  }, [searchRef]);

  const hasCoursesFound = foldersFound && foldersFound.length > 0;
  const hasCategoriesFound = categoriesFound && categoriesFound.length > 0;
  const insufficientInput = userQuery.length >= 0 && userQuery.length <= 2;

  useEffect(() => {
    document.addEventListener("keydown", handleHitEnter, true);
    return () => {
      document.removeEventListener("keydown", handleHitEnter, true);
    };
  }, []);

  const handleHitEnter = (e) => {
    const ENTER_KEY_CODE = 13;

    if (
      e.target.name === "userQuery" &&
      (e.key === "Enter" || e.keyCode === ENTER_KEY_CODE)
    ) {
      e.stopPropagation();
    }
  };

  return (
    <S.Wrapper ref={searchRef}>
      <div>
        <SearchInput />
      </div>

      {!insufficientInput && (
        <S.ResultsContainer>
          {loading ? (
            <div className="text-center mt-5">
              <Spinner color="dark" />
            </div>
          ) : (
            <>
              {hasCoursesFound && (
                <SearchResultsModule
                  title={"CURSOS ENCONTRADOS"}
                  type={"course"}
                  renderArray={foldersFound}
                />
              )}

              {hasCategoriesFound && (
                <SearchResultsModule
                  title={"CATEGORIAS ENCONTRADAS"}
                  type={"category"}
                  renderArray={categoriesFound}
                />
              )}

              {!hasCategoriesFound && !hasCoursesFound && (
                <SearchEmptyResults />
              )}
            </>
          )}
        </S.ResultsContainer>
      )}
    </S.Wrapper>
  );
};

export default FloatingSearch;
