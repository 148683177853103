import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 414px;
  margin: 5rem auto 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Form = styled.form`
  min-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Error = styled.div`
  color: #ff7651;
  font-weight: lighter;
  font-size: 12px;
`;
