import styled from "styled-components";

export const Wrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: ${(props) => props.theme.lightTheme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 86px;
  button {
    background-color: ${(props) =>
      props.theme.lightTheme.colors.buttonBackground};
  }
  button:hover {
    color: ${(props) => props.theme.lightTheme.colors.text};
  }
`;

export const CheckoutContainer = styled.div`
  max-width: 414px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
`;

export const BulletsContainer = styled.ul`
  list-style-image: ${(props) => `url(${props.marker})`};
  padding-top: 16px;
  padding-left: 25px;

  li {
    color: ${(props) => props.theme.lightTheme.colors.text};
    line-height: 160%;
    margin: 5px 0;
  }
`;
