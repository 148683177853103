import React from 'react';
import PropTypes from 'prop-types';

import Error401 from "./Error401";
import Error403 from "./Error403";
import Error404 from "./Error404";
import Error500 from "./Error500";

const Errors = ({ status, message, ...props }) => {


  const showError = () => {
    switch (status) {
      case 401:
        return <Error401 message={message || ""} />;
      case 403:
        return <Error403 message={message || ""} />;
      case 404:
        return <Error404 message={message || ""} />;
      default:
        return <Error500 message={message || ""} />
    }
  };

  return (
    <div className="error-fragment mt-5 mb-5">
      {showError()}
    </div>
  );
};

Errors.propTypes = {
  status: PropTypes.number.isRequired,
  message: PropTypes.string
};

export default Errors;
