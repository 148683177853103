import React from "react";
import * as S from "./styles";
import ArrowDown from "../../../assets/icons/arrow-down.svg";

const FAQ = ({ faq, index, toggleFAQ }) => {
  return (
    <S.Wrapper
      className={"faq"}
      open={faq.open}
      onClick={() => toggleFAQ(index)}
      id={"btn-component-accordion"}
      name={"btn-component-accordion"}
    >
      <S.FaqQuestionContainer className="faq-question" icon={ArrowDown}>
        {faq.question}
      </S.FaqQuestionContainer>

      <S.FaqAnswerContainer
        className="faq-answer"
        dangerouslySetInnerHTML={{ __html: `${faq.answer}` }}
      />
    </S.Wrapper>
  );
};

export default FAQ;
