import styled, { css } from "styled-components";

export const H1 = styled.h1`
  ${({ theme }) => css`
    margin: 0; //reset
    font-size: ${theme.font.size.h1};
    font-weight: ${theme.font.weight.h1};
  `}
`;
export const H2 = styled.h2`
  ${({ theme }) => css`
    margin: 0; //reset
    font-size: ${theme.font.size.h2};
    font-weight: ${theme.font.weight.h2};
  `}
`;
export const H3 = styled.h3`
  ${({ theme }) => css`
    margin: 0; //reset
    font-size: ${theme.font.size.h3};
    font-weight: ${theme.font.weight.h3};
  `}
`;
export const H4 = styled.h4`
  ${({ theme }) => css`
    margin: 0; //reset
    font-size: ${theme.font.size.h4};
    font-weight: ${theme.font.weight.h4};
  `}
`;
export const H5 = styled.h5`
  ${({ theme }) => css`
    margin: 0; //reset
    font-size: ${theme.font.size.h5};
    font-weight: ${theme.font.weight.h5};
  `}
`;
export const H6 = styled.h6`
  ${({ theme }) => css`
    margin: 0; //reset
    font-size: ${theme.font.size.h6};
    font-weight: ${theme.font.weight.h6};
  `}
`;
