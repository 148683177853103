import React from "react";
import * as S from "./styles";

const Textarea = ({
  label,
  inputName,
  registerFunction,
  errors,
  variant = "dark",
  onChange,
  ...props
}) => {
  if (!label || !inputName || !registerFunction) return null;

  return (
    <div className="input-container">
      <S.InputContainer>
        <S.TextareaContainer invalid={!!errors?.[inputName]} variant={variant}>
          <label htmlFor={inputName}>{label}</label>
          <textarea
            {...props}
            {...registerFunction(inputName)}
            aria-invalid={errors?.[inputName] ? "true" : "false"}
            type="text"
            onChange={(e) => {
              if (onChange) onChange(e);
            }}
          />
        </S.TextareaContainer>
      </S.InputContainer>

      {errors?.[inputName] && (
        <S.ErrorMessage variant={variant}>
          {errors[inputName].message}
        </S.ErrorMessage>
      )}
    </div>
  );
};

export default Textarea;
