import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { LoadingSpinner } from "./components";

const root = createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={<LoadingSpinner color="light" />}>
    <App />
  </Suspense>
);
