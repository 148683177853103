import { useQuery } from "react-query";
import { requests } from "../services/requests";

const queryKeys = {
  channels: "channels",
};

export const useChannels = (options = {}) => {
  return useQuery(queryKeys.channels, () => requests.getChannels(), {
    refetchOnWindowFocus: false,
    initialData: [],
    ...options,
  });
};
