import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Wrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: ${({ theme }) => theme.darkTheme.colors.background};
`;

export const Container = styled.div`
  margin: 15px auto 0 auto;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  row-gap: 25px;

  ${({ theme }) => css`
    max-width: ${theme.breakPoint.regularPhone};
    @media (max-width: ${theme.breakPoint.regularPhone}) {
      margin: 15px;
    }
  `}

  h1 {
    font-weight: 700;
    font-size: 24px;
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

export const LinkStyled = styled(Link)`
  color: #ccc;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0;
  :hover {
    text-decoration: none;
  }
`;

export const CategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  label {
    display: block;
    position: relative;
    margin: 0;
    padding: 12px 10px 12px 40px;
    line-height: 25px;
    background-color: #323132;
    border-radius: 5px;
    :hover {
      cursor: pointer;
    }
  }
`;

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked ~ span:after {
    display: block;
  }
`;

export const CheckboxMark = styled.span`
  position: absolute;
  top: 14px;
  left: 10px;
  height: 20px;
  width: 20px;
  background-color: #323132;
  border: 1px solid #5f5d5f;
  border-radius: 5px;
  ::after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 3px;
    width: 6px;
    height: 9px;
    border: solid #cff16b;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
