import { useQuery } from "react-query";
import { requests } from "../services/requests";
import {
  setInitialData,
  setFreshTime,
} from "../constants/reactQueryDefaultOption";

const queryKeys = {
  authors: "authors",
  authorDetails: "author-details",
};

export const useAuthors = (
  props = { showHome: undefined, limit: undefined },
  options = {}
) => {
  return useQuery(
    [queryKeys.authors, props],
    ({ queryKey }) => {
      const [, params] = queryKey;
      return requests.getAuthors(params);
    },
    {
      refetchOnWindowFocus: false,
      ...setInitialData([]),
      ...setFreshTime(10),
      ...options,
    }
  );
};

export const useAuthorDetails = (authorId, options = {}) => {
  return useQuery(
    [queryKeys.authorDetails, authorId],
    () => {
      return requests.getAuthorDetails(authorId);
    },
    {
      refetchOnWindowFocus: false,
      initialData: [],
      ...options,
    }
  );
};
