import React from "react";
import * as S from "./styles";

const ProgressBar = ({ progress }) => {
  if (!progress || typeof progress !== "number") return <></>;

  return (
    <S.Wrapper>
      <S.Track>
        <S.Thumb progress={progress} />
      </S.Track>
    </S.Wrapper>
  );
};

export default ProgressBar;
