import React from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LoadingSpinner } from "../../components";
import { useAuthContext } from "../../context/AuthContext";
import { useActiveCampaignTag } from "../../hooks";
import activeCampaignTags from "../../constants/active-campaign-tags.json";

const LoginIntegration = () => {
  const { setIsLoggedIn, answerCustomChallenge, setPathToRedirect } =
    useAuthContext();
  const { mutate: sendActiveCampaignTag } = useActiveCampaignTag();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [emailBase64, challengeParam, redirectParam] = [
    searchParams.get("email"),
    searchParams.get("challenge"),
    searchParams.get("redirect"),
  ];

  const emailParam = !!emailBase64 ? atob(emailBase64) : "";

  useEffect(
    function loginIntegration() {
      async function answerChallenge() {
        try {
          const response = await answerCustomChallenge(
            emailParam,
            challengeParam
          );

          if (response && !response.signInUserSession)
            throw Error("Code expired");

          setIsLoggedIn(true);
          sendActiveCampaignTag({
            email: emailParam,
            tagId: activeCampaignTags.sucessologin,
          });
          if (!redirectParam) {
            navigate("/");
          }
        } catch (error) {
          sendActiveCampaignTag({
            email: emailParam,
            tagId: activeCampaignTags.falhalogin,
          });
          navigate("/");
        }
      }

      // limpa localstorage para deslogar o usuário pois os dados do cognito ficam
      // não foi utilizado o signOut do auth context pois
      // as lógicas na função fazem redirecionamentos para o login,
      // deixando a experiência do usuário muito ruim
      // localStorage.clear();
      localStorage.clear();

      setPathToRedirect(redirectParam ?? "");

      answerChallenge();
    },
    [emailParam]
  );

  return <LoadingSpinner color="light" />;
};

export default LoginIntegration;
