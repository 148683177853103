import styled, { css } from "styled-components";

const wrapperModifiers = {
  success: (theme) => css`
    border: 1.5px solid ${theme.darkTheme.colors.success};
    svg {
      color: ${theme.darkTheme.colors.success};
    }
  `,
  error: (theme) => css`
    border: 1.5px solid ${theme.darkTheme.colors.danger};
    svg {
      color: ${theme.darkTheme.colors.danger};
    }
  `,
  warning: (theme) => css`
    border: 1.5px solid ${theme.darkTheme.colors.warning};
    svg {
      color: ${theme.darkTheme.colors.warning};
    }
  `,
  generic: (theme) => css`
    border: 1.5px solid ${theme.darkTheme.colors.gray};
  `,
};

export const Wrapper = styled.div`
  ${({ theme, variant }) => css`
    width: 100%;
    max-width: 600px;
    padding: ${theme.spacings.medium};
    border-radius: 5px;
    margin: 1rem auto;
    display: flex;
    align-items: center;

    span {
      color: ${theme.darkTheme.colors.text};
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 130%;
      display: inline;
      align-items: center;
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 25px;
      margin-right: 0.5rem;
    }
    a {
      color: ${theme.darkTheme.colors.text};
      text-decoration: underline;
    }

    ${variant && wrapperModifiers[variant](theme)}
  `}
`;
