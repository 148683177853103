import React from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { ReactComponent as FailIcon } from "../../assets/icons/fail-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon-certificate.svg";
import { support } from "../../data/support";
import CopyButton from "../CopyButton";

import * as S from "./styles";

const CertificateModal = ({
  type,
  courseName,
  authorName,
  setShowModal,
  certificateUrl,
  changeType,
  tryAgain,
}) => {
  const navigate = useNavigate();

  if (!type) {
    return <></>;
  }

  const texts = {
    congratulations: {
      message: (
        <>
          Parabéns! Você finalizou o curso: <strong>{courseName}</strong>
          <p>Você pode:</p>
        </>
      ),
    },
    download: {
      message: <>Seu certificado foi gerado com sucesso! Você pode:</>,
    },
    loading: {
      message: (
        <>
          <span>Algo deu errado :(</span>
          <span>Por algum motivo não foi possível baixar seu certificado.</span>
        </>
      ),
      details: `Se o problema persistir, entre em contato com nosso suporte pelo e-mail ${support.mail.text} ou pelo WhatsApp ${support.whatsapp.text}, de segunda à sexta-feira, das 10h às 19h.`,
    },
    downloadError: {
      message: (
        <>
          <span>Algo deu errado :(</span>
          <span>Por algum motivo não foi possível baixar seu certificado.</span>
        </>
      ),
      details: `Se o problema persistir, entre em contato com nosso suporte pelo e-mail ${support.mail.text} ou pelo WhatsApp ${support.whatsapp.text}, de segunda à sexta-feira, das 10h às 19h.`,
    },
    share: {
      message: <>Compartilhar com:</>,
    },
    shareError: {
      message: (
        <>
          <span>Algo deu errado :(</span>
          <span>
            Por algum motivo não foi possível compartilhar seu certificado.
          </span>
        </>
      ),
      details: `Se o problema persistir, entre em contato com nosso suporte pelo e-mail ${support.mail.text} ou pelo WhatsApp ${support.whatsapp.text}, de segunda à sexta-feira, das 10h às 19h.`,
    },
    incompleteRegister: {
      message: (
        <span>
          Ops, seu cadastro ainda está incompleto. Para emitir o certificado é
          necessário preencher todos os seus dados pessoais.
        </span>
      ),
    },
    default: {
      text: "",
    },
  };

  const modalTypes = {
    congratulations: (type, texts) => (
      <>
        <S.TextBox>{texts[type].message}</S.TextBox>
        <S.Button
          type={"share"}
          onClick={() => changeType("share")}
          id={"btn-congratulations_certificate_modal-compartilhar_certificado"}
          name={
            "btn-congratulations_certificate_modal-compartilhar_certificado"
          }
        >
          Compartilhar Certificado
        </S.Button>
        <a
          href={certificateUrl}
          target={"_blank"}
          rel={"noopener, noreferrer"}
          download
          id={"btn-congratulations_certificate_modal-baixar_certificado"}
          name={"btn-congratulations_certificate_modal-baixar_certificado"}
        >
          <S.Button
            type={"download"}
            onClick={() =>
              !certificateUrl
                ? changeType("downloadError")
                : setShowModal(false)
            }
          >
            Baixar Certificado
          </S.Button>
        </a>
      </>
    ),
    download: (type, texts) => (
      <>
        <S.TextBox>{texts[type].message}</S.TextBox>
        <S.Button
          type={"share"}
          onClick={() => changeType("share")}
          id={"btn-congratulations_certificate_modal-compartilhar_certificado"}
          name={
            "btn-congratulations_certificate_modal-compartilhar_certificado"
          }
        >
          Compartilhar Certificado
        </S.Button>
        <a
          href={certificateUrl}
          target={"_blank"}
          rel={"noopener, noreferrer"}
          download
          id={"btn-download_certificate_modal-download"}
          name={"btn-download_certificate_modal-download"}
        >
          <S.Button
            type={"download"}
            onClick={() =>
              !certificateUrl
                ? changeType("downloadError")
                : setShowModal(false)
            }
          >
            Baixar Certificado
          </S.Button>
        </a>
      </>
    ),
    downloadError: (type, texts) => (
      <>
        <S.WarningIcon>
          <FailIcon alt={`Ícone de aviso de falha`} />
        </S.WarningIcon>
        <S.TextBox>{texts[type].message}</S.TextBox>
        <S.Button
          type={type}
          onClick={() => {
            changeType("loading");
            tryAgain();
          }}
          id={"btn-download_error_certificate_modal-tente-novamente"}
          name={"btn-download_error_certificate_modal-tente-novamente"}
        >
          Tente Novamente
        </S.Button>

        <S.DetailsBox>{texts[type].details}</S.DetailsBox>
      </>
    ),
    share: (type, texts) => (
      <>
        <S.TextBox>{texts[type].message}</S.TextBox>
        <S.ShareBox>
          <WhatsappShareButton
            id={"btn-certificate_modal-whatsapp_share"}
            name={"btn-certificate_modal-whatsapp_share"}
            windowWidth={900}
            windowHeight={725}
            separator={"\n\n"}
            url={certificateUrl}
            title={`Olha só: terminei o curso *${courseName}* na Casa do Saber. Aqui está o link para o certificado: `}
          >
            <WhatsappIcon round={true} size={32} />
          </WhatsappShareButton>

          <FacebookShareButton
            id={"btn-certificate_modal-facebook_share"}
            name={"btn-certificate_modal-facebook_share"}
            url={certificateUrl}
            quote={`Finalizei o curso ${courseName}, de ${authorName} na plataforma da Casa do Saber.`}
            hashtag={"#casadosaber"}
          >
            <FacebookIcon round={true} size={32} />
          </FacebookShareButton>

          <TwitterShareButton
            id={"btn-certificate_modal-twitter_share"}
            name={"btn-certificate_modal-twitter_share"}
            url={certificateUrl}
            title={`Finalizei o curso ${courseName}, de ${authorName} na plataforma da Casa do Saber.`}
            hashtags={["casadosaber", authorName.replaceAll(" ", "")]}
          >
            <TwitterIcon round={true} size={32} />
          </TwitterShareButton>

          <EmailShareButton
            id={"btn-certificate_modal-facebook_share"}
            name={"btn-certificate_modal-facebook_share"}
            url={certificateUrl}
            subject={`Finalizei o curso ${courseName}, de ${authorName} na plataforma da Casa do Saber.`}
            body={`Olá! Aqui está o certificado do curso ${courseName}, que eu terminei na Casa do Saber. Aqui está o link do certificado: `}
            separator={"\n\n"}
          >
            <EmailIcon round={true} size={32} />
          </EmailShareButton>

          <CopyButton
            round={true}
            size={32}
            textToCopy={certificateUrl}
            id={"btn-certificate_modal-copy_link"}
            name={"btn-certificate_modal-copy_link"}
          />
        </S.ShareBox>
      </>
    ),
    incompleteRegister: (type, texts) => (
      <>
        <S.WarningIcon>
          <FailIcon alt={`Ícone de aviso de falha`} />
        </S.WarningIcon>
        <S.TextBox>{texts[type].message}</S.TextBox>
        <S.Button
          type={type}
          id={"btn-share_error_certificate_modal-completar_cadastro"}
          name={"btn-share_error_certificate_modal-completar_cadastro"}
          onClick={() => {
            navigate("/minha-conta/dados-pessoais");
          }}
        >
          Completar cadastro
        </S.Button>
      </>
    ),
    shareError: (type, texts) => (
      <>
        <S.WarningIcon>
          <FailIcon alt={`Ícone de aviso de falha`} />
        </S.WarningIcon>
        <S.TextBox>{texts[type].message}</S.TextBox>
        <S.Button
          type={type}
          id={"btn-share_error_certificate_modal-tente_novamente"}
          name={"btn-share_error_certificate_modal-tente_novamente"}
        >
          Tente Novamente
        </S.Button>
        <S.DetailsBox>{texts[type].details}</S.DetailsBox>
      </>
    ),
    loading: (type, texts) => (
      <>
        <S.WarningIcon>
          <FailIcon alt={`Ícone de aviso de falha`} />
        </S.WarningIcon>
        <S.TextBox>{texts[type].message}</S.TextBox>
        <S.Button type={type} disabled>
          <Spinner />
        </S.Button>

        <S.DetailsBox>{texts[type].details}</S.DetailsBox>
      </>
    ),
    default: () => null,
  };

  return (
    <S.Wrapper type={type}>
      <S.CloseButton
        onClick={() => setShowModal(false)}
        id={"btn-certificate_modal-close"}
        name={"btn-certificate_modal-close"}
      >
        <CloseIcon alt="copiar link" />
      </S.CloseButton>
      {modalTypes[type](type, texts) || modalTypes["default"]}
    </S.Wrapper>
  );
};

export default CertificateModal;
