import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  margin: 15px 0;
  padding: 15px;
  background-color: #F5F5F5;

  ${props => props.open && css`
      .faq-question {
        margin-bottom: 15px;
      }
      .faq-question::after {
        transform: translateY(-50%) rotate(180deg );
      }
      .faq-answer {
        max-height: 1000px;
        opacity: 1;
      }
  `}
`

export const FaqQuestionContainer = styled.div`
  position: relative;
  font-size: 16px;
  color: ${props => props.theme.lightTheme.colors.text};
  font-weight: 800;
  padding-right: 80px;

  transition: all 0.4s ease;

  ::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 12px;
    height: 8px;

    background-image: ${props => `url(${props.icon})`};
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    transition: all 0.4s ease-out;
  }
`

export const FaqAnswerContainer = styled.div`
  font-size: 14px;
  color: ${props => props.theme.lightTheme.colors.text};

  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease;

  p.disclaimer {
    font-family: "MaisonNeueBook", sans-serif;
    font-size: 12px;
  }
`