import React from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { getProgress } from "../../../util/course";
import { normalizeString } from "../../../util/Util";
import { PlayButton, ProgressBar } from "../../index";
import * as S from "./styles";
import Datalayer from "../../../services/datalayer";

export default function CourseCard({
  id = "",
  image = "",
  title = "",
  slug = "",
  classes = undefined,
  classesFinished = undefined,
  bigCard,
  showItemTitle = "",
  link = "",
}) {
  const isAuthor = link === "/professores";
  const suffix = Number(classes) > 1 ? "s" : "";
  const navigate = useNavigate();
  const { status, percentage } = getProgress(classesFinished, classes);

  const renderProgressContainer = {
    inProgress: (
      <S.InfoContainer variant={"progress"}>
        <ProgressBar progress={percentage} />
        Aula{" "}
        <strong>
          {classesFinished}/{classes}
        </strong>
      </S.InfoContainer>
    ),
    concluded: (
      <S.InfoContainer variant={"progress"}>
        <ProgressBar progress={percentage} />
        Aula{" "}
        <strong>
          {classesFinished}/{classes}
        </strong>
      </S.InfoContainer>
    ),
    notStarted: (
      <S.InfoContainer variant={"notStarted"}>
        {classes} aula{suffix}
      </S.InfoContainer>
    ),
    default: <></>,
  };

  const renderCard = (
    <>
      <S.ThumbContainer variant={bigCard ? "bigCard" : "smallCard"}>
        {!!percentage && <PlayButton link={slug} id={id} />}
        <LazyLoadComponent threshold="50" delayTime="0">
          <S.ThumbImage
            onClick={() => {
              if (link !== "") {
                Datalayer.onClick(
                  "home",
                  "card",
                  `${link.replace("/", "")}:${slug}`
                );
              }
              navigate(isAuthor ? `../${slug}` : `/curso/${id}/${slug}`);
            }}
            title={title}
            id={`btn-carousel-${normalizeString(title)}`}
            name={`btn-carousel-${normalizeString(title)}`}
            src={image}
            alt={title}
            className={`${isAuthor && "fit-cover"}`}
            role="link"
          />
        </LazyLoadComponent>
        {!!showItemTitle && (
          <S.ThumbCaption>
            <p>{title}</p>
          </S.ThumbCaption>
        )}
      </S.ThumbContainer>

      {renderProgressContainer[status] || <></>}
    </>
  );

  return <S.Wrapper>{renderCard}</S.Wrapper>;
}
