import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { useUserSubscriptionContext } from "../../context/userSubscriptionContext";
import { CertificateButton, CertificateModal } from "../../components";
import { useCertificate } from "../../hooks/useCertificateQuery";
import * as S from "./styles";

const CertificateCard = ({
  userCourseId,
  thumb,
  title,
  slug,
  author,
  finishedAt,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [generateCertificate, setGenerateCertificate] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);

  const { hasIncompleteRegister } = useUserSubscriptionContext();

  const {
    data: certificateUrl,
    isError: certificateError,
    isLoading: isCertificateLoading,
    refetch: refetchCertificateData,
  } = useCertificate(userCourseId, {
    enabled: !!userCourseId && !!generateCertificate,
  });

  const handleModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  async function handleShareButton() {
    return setShowShareOptions(!showShareOptions);
  }

  useEffect(() => {
    if (certificateError && generateCertificate) handleModal("downloadError");
  }, [certificateError]);
  useEffect(() => {
    if (certificateUrl && openFile) window.open(certificateUrl, "_blank");
    setGenerateCertificate(false);
    setOpenFile(false);
  }, [certificateUrl]);

  useEffect(() => {
    //cleanup funcion
    return () => {
      setGenerateCertificate(false);
      setShowModal(false);
      setModalType("");
    };
  }, []);

  const renderButtons = () => {
    if (isCertificateLoading) return <Spinner />;

    if (hasIncompleteRegister)
      return (
        <>
          <CertificateButton
            id={"btn-certificate_card-download"}
            name={"btn-certificate_card-download"}
            type={"download"}
            onClick={() => handleModal("incompleteRegister")}
          />
          <CertificateButton
            id={"btn-certificate_card-share"}
            name={"btn-certificate_card-share"}
            type="share"
            role="button"
            onClick={() => handleModal("incompleteRegister")}
          />
        </>
      );

    if (!certificateUrl && generateCertificate)
      return (
        <>
          <CertificateButton
            id={"btn-certificate_card-download"}
            name={"btn-certificate_card-download"}
            type={"download"}
            onClick={() => {
              handleModal("downloadError");
            }}
          />
          <CertificateButton
            id={"btn-certificate_card-share"}
            name={"btn-certificate_card-share"}
            type="share"
            role="button"
            onClick={() => {
              handleModal("downloadError");
            }}
          />
        </>
      );

    return (
      <>
        <CertificateButton
          id={"btn-certificate_card-download"}
          name={"btn-certificate_card-download"}
          type={"download"}
          fileName={slug}
          certificateUrl={certificateUrl}
          onClick={() => {
            if (!certificateUrl) {
              setGenerateCertificate(true);
              setOpenFile(true);
            }
          }}
        />
        <CertificateButton
          id={"btn-certificate_card-share"}
          name={"btn-certificate_card-share"}
          type="share"
          role="button"
          certificateUrl={certificateUrl}
          onClick={() => {
            if (!certificateUrl) {
              setGenerateCertificate(true);
            }
            handleShareButton();
          }}
          showShareOptions={showShareOptions}
        />
      </>
    );
  };

  return (
    <S.Wrapper>
      <S.CertificateDiv1>
        <img alt={`Capa do curso ${title}`} src={thumb} />
      </S.CertificateDiv1>
      <S.CertificateDiv2>
        <S.Title>{title}</S.Title>
        <S.Professor>por {author}</S.Professor>
      </S.CertificateDiv2>
      <S.CertificateDiv3>
        <S.FinishDate>Concluído - {finishedAt}</S.FinishDate>
      </S.CertificateDiv3>
      <S.CertificateDiv4>{renderButtons()}</S.CertificateDiv4>

      {!!showModal && !!modalType && (
        <CertificateModal
          type={modalType}
          setShowModal={setShowModal}
          tryAgain={refetchCertificateData}
          changeType={setModalType}
        ></CertificateModal>
      )}
    </S.Wrapper>
  );
};

export default CertificateCard;
