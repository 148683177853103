import styled, { css } from "styled-components";
import { Modal } from "reactstrap";

export const FormWrapper = styled.div`
  margin: 0 auto;
  max-width: 420px;
  padding-bottom: 64px; //zendesk button spacing

  button {
    margin: 5px auto;
  }
`;

export const CardContainer = styled.div`
  margin-bottom: 1rem;
`;

export const Errors = styled.div`
  padding: 15px;
  border-radius: 5px;
  margin-top: -25px;
  border: 1.5px solid #ff7651;
  margin-bottom: 0.7rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  span {
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    display: inline;
    align-items: center;
  }
  svg {
    color: #ff7651;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
`;

export const FormInputWithLabel = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
    margin-bottom: 10px;

    label {
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
      line-height: 130%;
      color: ${theme.darkTheme.colors.gray};
      display: flex;
      align-items: center;
      position: relative;
      top: 14px;
      left: 8px;
      height: 0px;
      width: 100%;
    }

    .form-control {
      width: 100%;
      height: 50px;
      font-size: 14px;
      background: ${theme.darkTheme.colors.inputBackground};
      color: ${theme.darkTheme.colors.white};
      border-radius: 5px;
      padding: 0 10px;
      outline: none !important;
      border: none;
      padding-top: 15px;

      transition: all 300ms linear;

      &:disabled {
        background-color: ${theme.darkTheme.colors.coal};
        border-color: ${theme.darkTheme.colors.coal};
        color: ${theme.darkTheme.colors.darkBlack};
      }

      &:focus {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
      }
    }
  `}
`;

export const Disclaimer = styled.p`
  color: #aca9b3;
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 2px;
  padding: 15px;
`;

export const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    .modal-dialog-centered {
      justify-content: center;
    }
    .content-modal-change-card {
      font-size: 14px;
      line-height: 160%;
      color: rgba(0, 0, 0, 0.6);
      max-width: 400px;
      padding: 24px 18px 8px 18px;
      margin: 0 20px;
      border-radius: 10px;
      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
        0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
    }

    .modal-footer {
      border-top: none;
    }

    button {
      padding: 10px 8px;
      background: transparent;
      border: 1px solid transparent;
      border-radius: 5px;
      transition: all 0.2s ease-in;
      text-transform: uppercase;
      font-weight: bold;

      &:hover {
        border: 1px solid ${theme.darkTheme.colors.darkBlack};
        background-color: ${theme.darkTheme.colors.lightGray};
      }
    }

    button.confirm-change {
      color: ${theme.darkTheme.colors.darkBlack};
    }

    button.cancel-change {
      color: ${theme.darkTheme.colors.gray};
    }
  `}
`;
