import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 420px;
  margin: 4em auto 0;

  display: flex;
  flex-direction: column;
  gap: 25px;

  svg {
    font-size: 1.7em;
  }
  //footer spacing
  min-height: calc(100vh - 62px - 4em); //header height - Wrapper margin-top
  position: relative;
  padding-bottom: 180px;

  @media (min-width: 900px) {
    min-height: calc(100vh - 151px - 4em); //header height - Wrapper margin-top
  }
`;
export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 0; //reset h2 margin
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: normal;
  margin: 0; //reset p margin
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0; //reset margin
`;

export const StepsItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin: 0; //reset margin
`;

export const ImageNumber = styled.img`
  margin: 0; //reset margin
`;
