import React from "react";
import * as S from "./styles";

const CheckoutPageTitle = ({ children, title, subtitle }) => {
  return (
    <S.Wrapper>
      <h1>{title}</h1>
      <p>{subtitle}</p>
      {children}
    </S.Wrapper>
  );
};

export default CheckoutPageTitle;
