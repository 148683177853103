import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    #play {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    .stroke-solid {
      stroke: ${theme.darkTheme.colors.white};
      stroke-dashoffset: 0;
      stroke-dasharray: 300;
      stroke-width: 4px;
      fill: rgba(0, 0, 0, 0.3);
      transition: fill 1s ease-out, stroke-dashoffset 1s ease, opacity 1s ease;
    }

    .icon {
      fill: ${theme.darkTheme.colors.white};
      transform: scale(0.8);
      transform-origin: 50% 50%;
      transition: transform 200ms ease-out, fill 200ms ease-out;
    }

    // Hover
    #play:hover {
      .stroke-solid {
        fill: ${theme.darkTheme.colors.green};
        opacity: 1;
        stroke-dashoffset: 300;
      }
      .icon {
        fill: ${theme.darkTheme.colors.darkBlack};
        transform: scale(0.9);
      }
    }
  `}
`;
