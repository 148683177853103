import React, { useMemo, useState } from "react";
import ReactElasticCarousel, { consts } from "react-elastic-carousel";
import CourseCard from "./CourseCard";
import prevIcon from "../../assets/images/prevIcon.svg";
import nextIcon from "../../assets/images/nextIcon.svg";
import * as S from "./styles";
import { normalizeString } from "../../util/Util";

export default function Carousel({
  title = "",
  items,
  link,
  bigCard,
  showItemTitle,
  quantity = undefined,
  subtitle,
  ...props
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const totalCourses = quantity || items.length;
  const hasItems = totalCourses > 0;
  const showAll = bigCard ? items.length > 4 : items.length > 5;

  const isAuthor = link === "/professores";
  const quantityText =
    totalCourses > 1
      ? isAuthor
        ? "professores"
        : "cursos"
      : isAuthor
      ? "professor(a)"
      : "curso";

  const calculateCarouselItems = () => {
    const windowWidth = window.innerWidth;
    const maxWidth = props.maxWidth ? props.maxWidth : 1080;
    const bigCardWidth = windowWidth <= 768 ? 144 : 202;
    const smallCardWidth = windowWidth <= 768 ? 101 : 126;
    const itemPadding = 25;

    if (bigCard) {
      if (windowWidth > maxWidth)
        return Number(maxWidth / (bigCardWidth + itemPadding)).toFixed(1);
      return Number(windowWidth / (bigCardWidth + itemPadding)).toFixed(1);
    } else {
      if (windowWidth > maxWidth)
        return Number(maxWidth / (smallCardWidth + itemPadding)).toFixed(1);
      return Number(windowWidth / (smallCardWidth + itemPadding)).toFixed(1);
    }
  };

  const breakPoints = [
    {
      width: windowWidth,
      itemsToShow: Number(calculateCarouselItems()),
      itemsToScroll: bigCard ? 1.5 : 3,
    },
  ];

  const calculateWindowWidth = () => {
    const innerWidth = window.innerWidth;
    setWindowWidth(innerWidth);
  };

  window.addEventListener("resize", function () {
    calculateWindowWidth();
  });

  const arrowButton = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? prevIcon : nextIcon;
    const hidden = isEdge ? "hidden" : "";

    return (
      <S.ArrowButton
        onClick={onClick}
        disabled={isEdge}
        hidden={hidden}
        id={`btn-carousel-${
          type === consts.PREV ? "previous" : "next"
        }_${normalizeString(title)}`}
        name={`btn-carousel-${
          type === consts.PREV ? "previous" : "next"
        }_${normalizeString(title)}`}
      >
        <img src={pointer} alt={"seta do carrossel"} />
      </S.ArrowButton>
    );
  };

  const showPagination = useMemo(() => {
    const coursesOnScreen = calculateCarouselItems();
    if (items.length >= coursesOnScreen) return true;
    return false;
  }, [window.innerWidth]);

  const renderCards = () => {
    const cardsList = items.map((item) => {
      return (
        <CourseCard
          key={item.slug}
          id={item.id}
          image={item.thumb || item.images.thumbUrl} // TODO: remove old link after refactor
          title={item.title}
          slug={item.slug}
          bigCard={bigCard}
          showItemTitle={showItemTitle}
          link={link}
          classes={item.classes}
          classesFinished={item.classesFinished}
        />
      );
    });

    return cardsList;
  };

  return (
    <>
      {hasItems && (
        <S.CarouselContainer>
          <S.HeaderCarousel>
            <div>
              {title && <S.TitleCarousel>{title} </S.TitleCarousel>}(
              {link ? (
                <S.ShowAllLink
                  to={link}
                  id={`btn-contador-cursos-${title
                    .toLowerCase()
                    .replace(" ", "-")}`}
                  name={`btn-contador-cursos-${title
                    .toLowerCase()
                    .replace(" ", "-")}`}
                >
                  {totalCourses} {quantityText}
                </S.ShowAllLink>
              ) : (
                <>
                  {totalCourses} {quantityText}
                </>
              )}
              )
            </div>
            {link && showAll && (
              <S.ShowAllLink
                to={link || "/#"}
                id={`btn-ver-todos-${title.toLowerCase().replace(" ", "-")}`}
                name={`btn-ver-todos-${title.toLowerCase().replace(" ", "-")}`}
              >
                ver todos
              </S.ShowAllLink>
            )}
          </S.HeaderCarousel>
          <S.Subtitle>{subtitle}</S.Subtitle>
          <ReactElasticCarousel
            breakPoints={breakPoints}
            pagination={showPagination}
            showArrows={windowWidth >= 768 ? true : false}
            renderArrow={arrowButton}
            className="carousel"
            enableSwipe={true}
            enableMouseSwipe={windowWidth >= 768 ? false : true}
            enableTilt={false}
            itemPosition={consts.CENTER}
            itemPadding={[0, 0, 0, 10]}
          >
            {renderCards()}
          </ReactElasticCarousel>
        </S.CarouselContainer>
      )}
    </>
  );
}
