export const size = {
  smallPhone: 340,
  regularPhone: 480,
  tablet: 768,
  laptop: 1280,
  desktop: 1536,
  tv: 1919,
};

export const device = {
  smallPhone: `min-width: ${size.smallPhone}px`,
  regularPhone: `min-width: ${size.regularPhone}px`,
  tablet: `min-width: ${size.tablet}px`,
  laptop: `min-width: ${size.laptop}px`,
  desktop: `min-width: ${size.desktop}px`,
  tv: `min-width: ${size.tv}px`,
};
