import React from "react";
import { normalizeString } from "../../util/Util";
import CourseCard from "../Carousel/CourseCard";
import PageTitle from "../PageTitle";
import { NoCategoryCourses } from "../../components";
import * as S from "./styles";

const CardsGrid = ({
  content = [],
  link = "",
  showItemTitle = false,
  pageName = "",
}) => {
  return (
    <>
      <PageTitle>{pageName}</PageTitle>
      {content?.length > 0 ? (
        <S.Wrapper>
          {content.map((folder) => (
            <CourseCard
              key={normalizeString(folder.slug)}
              id={folder.id}
              slug={folder.slug}
              image={folder.thumb || folder.images.thumbUrl} // TODO: remove old link after refactor
              title={folder.title || folder.name}
              classes={folder.classes}
              classesFinished={folder.classesFinished}
              bigCard
              showItemTitle={showItemTitle}
              link={link}
            />
          ))}
        </S.Wrapper>
      ) : (
        <NoCategoryCourses />
      )}
    </>
  );
};

export default CardsGrid;
