import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme, background }) => css`
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: ${background === "light"
      ? theme.lightTheme.colors.background
      : background === "dark"
      ? theme.darkTheme.colors.background
      : "transparent"};
  `}
`;
