import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { usePromotionalCampaign, useUser } from "../../../hooks";
import {
  addOneYearAndFormat,
  formatDiscountString,
  formattedDate,
} from "../../../util/Util";
import { support } from "../../../data/support";
import { Button, Container, LoadingSpinner } from "../../../components";

import * as S from "./styles";

const SubscriptionExtensionDetails = () => {
  const navigate = useNavigate();

  const {
    data: { subscriptionDetails },
    isLoading: isSubscriptionLoading,
  } = useUser();

  const { data: campaign, isLoading: isCampaignLoading } =
    usePromotionalCampaign();

  useEffect(() => {
    if (!campaign && !isCampaignLoading)
      navigate("/minha-conta/assinatura", { replace: true });
  }, [campaign]);

  useEffect(() => {
    const extensionRequestedIsPending =
      subscriptionDetails?.extensionStatus === "processing";

    const extensionSuccess = subscriptionDetails?.extensionStatus === "paid";

    if (extensionRequestedIsPending)
      navigate("/minha-conta", {
        state: { processingExtensionPayment: true },
        replace: true,
      });
    if (extensionSuccess) navigate("/minha-conta", { replace: true });
  }, [subscriptionDetails]);

  if (!subscriptionDetails || isSubscriptionLoading || isCampaignLoading) {
    return <LoadingSpinner color="light" />;
  }

  const phone = (
    <a
      href={support.whatsapp.href}
      target={support.whatsapp.target}
      rel={support.whatsapp.rel}
      id={"btn-account_footer-phone"}
      name={"btn-account_footer-phone"}
    >
      {support.whatsapp.text}
    </a>
  );

  const { endAt: remainingAccess } = subscriptionDetails;

  const discountString = formatDiscountString(
    campaign.coupon.discountValue,
    campaign.coupon.discountType
  );

  const formattedExtendedDate = addOneYearAndFormat(remainingAccess);

  return (
    <Container type={"minimal"} pageName="Entenda a promoção">
      <S.Wrapper className="wrapper">
        <S.Text>
          Pague agora com <strong>{discountString} de desconto</strong> e
          estenda sua assinatura por mais um ano, contando com a vigência atual.
        </S.Text>
        <S.Text>
          Como seu plano é válido até {formattedDate(remainingAccess)}, ele será
          prolongado até {formattedExtendedDate}.
        </S.Text>

        <S.Text>
          <strong>Não perca essa chance de economizar!</strong>
        </S.Text>

        <Button
          variant={"white"}
          size={"full"}
          id={"btn-subscription_extension_details-quero_o_desconto"}
          name={"btn-subscription_extension_details-quero_o_desconto"}
          onClick={() => navigate(-1)}
        >
          QUERO O DESCONTO
        </Button>

        <S.Support>
          <S.Text>
            <strong>Ainda está com dúvidas?</strong>
          </S.Text>
          <S.Text>Fale com a gente no WhatsApp:</S.Text>
          <S.Text>{phone}</S.Text>
        </S.Support>
      </S.Wrapper>
    </Container>
  );
};

export default SubscriptionExtensionDetails;
