import styled, { css } from "styled-components";

export const SwitchContainer = styled.div`
  position: relative;
`;
export const ToolTip = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;

  .switch.blocked-change {
    pointer-events: none;
    opacity: 0.4 !important;
  }

  span {
    margin-right: 8px;
    pointer-events: none;
  }

  span.blocked-change {
    opacity: 0.4;
  }

  :hover > span {
    visibility: visible;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > div {
      margin-left: 10px;
    }
  }
`;
export const TooltipText = styled.span`
  ${({ theme }) => css`
    visibility: hidden;
    width: auto;
    background-color: #cff16b;
    color: #201e20;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 120%;
    left: -5%;
    text-align: left;
    font-size: ${theme.font.size.smaller};

    @media (min-width: ${theme.breakPoint.tablet}) {
      width: 200px;
      font-size: ${theme.font.size.medium};
    }

    :after {
      content: " ";
      position: absolute;
      bottom: 100%;
      left: 40%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #cff16b transparent;
    }
  `}
`;
