import React from "react";
import Text from "../Styles/Text";
import { useTranslation } from "react-i18next";
import * as S from "./styles";

const Error500 = ({ message, ...props }) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.Title>{t("errors.500.title")}</S.Title>
      <Text
        className="error-description"
        html={message || t("errors.500.message")}
      />
    </S.Wrapper>
  );
};

export default Error500;
