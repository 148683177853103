import React, { useEffect, useState } from "react";

import { useFavoriteCourses, useMyList, useUser } from "../../hooks";

import {
  Alert,
  Container,
  LoadingSpinner,
  MyListCard,
  NoCourseContent,
} from "../../components";

import { message } from "../../data/favorite";
import strings from "../../config/strings.json";
import * as S from "./styles";

const MyList = () => {
  const {
    data: { userData },
  } = useUser();

  const { data: favoriteCourses } = useFavoriteCourses();
  const { favoriteActionStatus } = useMyList();
  const [loading, setLoading] = useState(true);

  const hasCourses = favoriteCourses && favoriteCourses.length > 0;

  const alert = {
    removed: <Alert variant={"success"} text={message.removed} />,
    error: <Alert variant={"error"} text={message.error} />,
    added: <></>,
  };

  useEffect(() => {
    if (userData) {
      setLoading(false);
    }
  }, [userData]);

  if (loading) return <LoadingSpinner color="light" />;

  return (
    <>
      <Container type={"minimal"} pageName={strings.categories.MyList}>
        <S.Wrapper>
          {hasCourses ? (
            <>
              <S.Description>
                Aqui você encontra os cursos que salvou para assistir quando
                quiser. Que tal começar agora?
              </S.Description>

              {favoriteActionStatus && alert[favoriteActionStatus]}

              {favoriteCourses.map((folder) => {
                return (
                  <MyListCard
                    key={folder.slug}
                    id={folder.id}
                    title={folder.title}
                    thumb={folder.images?.thumbUrl}
                    author={folder.author.name}
                    slug={folder.slug}
                    classes={folder.classes}
                    finishedClasses={folder.classesFinished}
                    courseId={folder.id}
                    userId={userData.id}
                  />
                );
              })}
            </>
          ) : (
            <NoCourseContent />
          )}
        </S.Wrapper>
      </Container>
    </>
  );
};

export default MyList;
