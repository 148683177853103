export const CDStheme = {
  layer: {
    fifth: 50,
    sixth: 60,
  },
  breakPoint: {
    smallPhone: "340px",
    regularPhone: "480px",
    tablet: "768px",
    laptop: "1280px",
    desktop: "1536px",
    tv: "1919px",
  },
  font: {
    size: {
      smallest: "12px",
      smaller: "13px",
      small: "14px",
      default: "16px",
      medium: "20px",
      large: "24px",
      larger: "32px",
      largest: "40px",
      h1: "1.5em", //24px
      h2: "1.25em", //20px
      h3: "1.25em", //20px
      h4: "1em", //16px
      h5: "1em", //16px
      h6: "0.875em", //14px
    },
    color: {
      lightBlack: "#5f5d5f",
      lightGrey: "#ACA9B3",
      white: "#ffff",
    },
    weight: {
      lightest: "100",
      light: "300",
      regular: "400",
      bold: "700",
      extraBold: "900",
      h1: "700",
      h2: "700",
      h3: "300",
      h4: "700",
      h5: "300",
      h6: "700",
    },
    family: {
      maisonNeue: "MaisonNeue",
    },
    lineHeight: {
      medium: "26px",
    },
  },
  spacings: {
    smallest: "8px",
    smaller: "10px",
    small: "14px",
    medium: "16px",
    large: "24px",
    larger: "32px",
    largest: "40px",
  },
  gap: {
    smallest: "0.3em",
    smaller: "0.5em",
    small: "0.7em",
    medium: "1em",
    large: "1.3em",
    larger: "1.5em",
    largest: "1.7em",
  },
  darkTheme: {
    colors: {
      background: "#201E20",
      inputBackground: "#100f10",
      danger: "#FF7651",
      warning: "#f7ce6b",
      success: "#CFF16B",
      text: "#FFFFFF",
      white: "#FFFFFF",
      lightGray: "#F0F0F0",
      gray: "#ACA9B3",
      darkGray: "#5F5D5F",
      coal: "#323132",
      mediumBlack: "#171414",
      darkBlack: "#100f10",
      absoluteBlack: "#000",
      green: "#CFF16B",
      purpleRed: "#9f3552",
      yellow: "#f7ce6b",
    },
  },
  lightTheme: {
    colors: {
      background: "#FFFF",
      inputBackground: "#F0F0F0",
      buttonBackground: "#CFF16B",
      disabledBackground: "#cacaca",
      disabledText: "#fafafa",
      danger: "#FF7651",
      warning: "#f7ce6b",
      success: "#CFF16B",
      text: "#201E20",
      white: "#FFF",
      lightGray: "#F0F0F0",
      gray: "#ACA9B3",
      darkGray: "#201E20",
      coal: "#323132",
      mediumBlack: "#171414",
      darkBlack: "#100f10",
      absoluteBlack: "#000",
      green: "#CFF16B",
      purpleRed: "#9f3552",
      yellow: "#f7ce6b",
    },
  },
};
