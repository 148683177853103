import styled, { css } from "styled-components";

export const Disabled = styled.button`
  ${({ theme, type }) => css`
    text-transform: uppercase;
    border-radius: 5px;
    background: none;
    padding: 0;
    outline: inherit;
    font-size: 10px;
    min-width: 75px;
    color: #ffffff;
    border: 1px solid #5f5d5f;
    height: 9vw;
    width: 100%;
    @media (min-width: ${theme.breakPoint.smallPhone}) {
      font-size: ${theme.font.size.smallest};
      height: 25px;
    }
    @media (min-width: ${theme.breakPoint.regularPhone}) {
      font-size: ${theme.font.size.smaller};
      height: 30px;
    }
    @media (min-width: ${theme.breakPoint.laptop}) {
      cursor: pointer;
    }
  `}
`;
export const ShareButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 5px;
    background: none;
    padding: 0;
    outline: inherit;
    font-size: 10px;
    min-width: 75px;
    color: #ffffff;
    border: 1px solid #5f5d5f;
    height: 9vw;
    width: 24vw;
    @media (min-width: ${theme.breakPoint.smallPhone}) {
      font-size: ${theme.font.size.smallest};
      height: 25px;
      width: 26vw;
      max-width: 110px;
    }
    @media (min-width: ${theme.breakPoint.regularPhone}) {
      font-size: ${theme.font.size.smaller};
      height: 30px;
      width: 110px;
    }
    @media (min-width: ${theme.breakPoint.laptop}) {
      cursor: pointer;
    }
  `}
`;

export const DownloadAnchor = styled.a`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-family: ${theme.font.family.maisonNeue};
    font-weight: ${theme.font.weight.regular};
    height: 9vw;
    width: 24vw;
    border-radius: 5px;
    border: none;
    padding: 0;
    font-size: 10px;
    min-width: 70px;
    text-decoration: none;
    text-align: center;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    * {
      color: ${theme.darkTheme.colors.mediumBlack};
    }
    :hover {
      color: ${theme.lightTheme.colors.text};
      text-decoration: none;
      cursor: pointer;
    }

    @media (min-width: ${theme.breakPoint.smallPhone}) {
      font-size: ${theme.font.size.smallest};
      height: 25px;
      max-width: 110px;
    }
    @media (min-width: ${theme.breakPoint.regularPhone}) {
      font-size: ${theme.font.size.smaller};
      height: 30px;
      width: 110px;
    }
    @media (min-width: ${theme.breakPoint.laptop}) {
      cursor: pointer;
    }
  `}
`;
