import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WhatsappShareButton } from "react-share";

import { useCheckoutContext } from "../../context/CheckoutContext";
import { useGiftSubscriptionStatus } from "../../hooks";
import exportAsImage from "../../util/exportAsImage";
import { formattedDate } from "../../util/Util";

import {
  CheckoutFooter,
  CheckoutPageTitle,
  StepperCustom,
  LoadingSpinner,
  AlertV2,
} from "../../components";

import { ReactComponent as LogoCasa } from "../../assets/images/simbolo-cds.svg";
import emailIcon from "../../assets/icons/email-icon-3.svg";
import whatsIcon from "../../assets/icons/whats-icon-3.svg";
import downloadIcon from "../../assets/icons/download-icon-3.svg";
import statue from "../../assets/images/giftcard-image.png";
import * as S from "./styles";

const GiftCheckoutSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { giftCode } = Object.fromEntries([...searchParams]);
  const [alertContent, setAlertContent] = useState({
    message:
      "Este vale-presente ainda não foi utilizado. É necessário enviá-lo para que o beneficiário possa aproveitá-lo.",
    type: "warning",
    giftVerificationSuccess: true,
  });

  const { currentStep, giftSteps, changeStep } = useCheckoutContext();
  const { data: giftData, isLoading } = useGiftSubscriptionStatus(giftCode, {
    onError: (error) => {
      const { status, message } = error.response.data;
      if (message) {
        setAlertContent({
          message,
          type: status === 409 ? "success" : "error",
          giftVerificationSuccess: false,
        });
      } else {
        setAlertContent({
          message:
            "Houve um erro ao verficar o vale-presente. Por favor, verifique sua caixa de e-mail ou entre em contato com o suporte",
          type: "error",
          giftVerificationSuccess: false,
        });
      }
    },
  });

  const exportRef = useRef();
  const canvasOptions = {
    backgroundColor: "#201E20",
    scale: 1.5,
  };

  useEffect(() => {
    changeStep("sucesso");
  }, []);

  useEffect(() => {
    if (!giftCode) {
      return navigate(`/presente`, { replace: true });
    }
  }, [giftCode]);

  if (isLoading)
    return (
      <div>
        <S.EmptyHeader />
        <LoadingSpinner background={"light"} />
      </div>
    );

  const renderPage = () => {
    if (alertContent?.giftVerificationSuccess) {
      return (
        <S.GiftCheckoutContainer>
          <CheckoutPageTitle
            title={"Seu vale-presente está pronto. Agora é só compartilhar."}
          />

          <S.GiftCard bgImage={statue} id="capture" ref={exportRef}>
            <div className="title-container">
              <div>
                <p className="title">Vale Presente</p>
                <p className="subtitle">Casa do Saber</p>
              </div>
              <LogoCasa
                alt="Casa do Saber +"
                id={"btn-header-logo"}
                name={"btn-header-logo"}
              />
            </div>
            <div>
              <p className="code-label">Código:</p>
              <p className="code-value">{giftCode}</p>
            </div>
          </S.GiftCard>

          <AlertV2 variant={alertContent.type}>{alertContent.message}</AlertV2>

          <S.Description>
            Escolha como enviar o presente e as instruções de uso para a pessoa
            que deseja presentear.
          </S.Description>

          <S.ButtonsContainer>
            <button
              id={"btn-gift_success-enviar_por_email"}
              name={"btn-gift_success-enviar_por_email"}
              onClick={() =>
                navigate(`/presente/enviar-email?giftCode=${giftCode}`, {
                  state: giftData,
                })
              }
            >
              <img src={emailIcon} alt="ícone de email" />
              Enviar por email
            </button>

            <WhatsappShareButton
              separator={"\n"}
              url={` `}
              title={`Oi! Eu tenho um presente para você: uma assinatura da plataforma de cursos da Casa do Saber. \nCom ela, você libera seu acesso a todos os cursos da Casa do Saber. \n\nPara começar agora a usar o seu presente, é só seguir os passos: \n1) Clique no link: https://ondemand.casadosaber.com.br/cadastro?giftCode=${giftCode} \n2) Insira o seu e-mail e escolha uma senha para criar sua conta de acesso. Estas serão suas informações de login. \n\nAgora é só começar seu primeiro curso. Se precisar de ajuda, a equipe da Casa do Saber pode te atender. É só enviar uma mensagem para suporte@casadosaber.com.br ou para o WhatsApp +55 11 96609-2057.`}
              windowWidth={"3000px"}
              windowHeight={"800px"}
              resetButtonStyle={false}
              id={"btn-gift_checkout-whatsapp_share"}
              name={"btn-gift_checkout-whatsapp_share"}
            >
              <img src={whatsIcon} alt="ícone de whatsapp" />
              Enviar por Whatsapp
            </WhatsappShareButton>

            <button
              id={"btn-gift_success-enviar_por_email"}
              name={"btn-gift_success-enviar_por_email"}
              onClick={() =>
                exportAsImage(
                  exportRef.current,
                  `${giftCode}-Casa_do_Saber`,
                  canvasOptions
                )
              }
            >
              <img src={downloadIcon} alt="ícone de download" />
              Salvar Cartão
            </button>
          </S.ButtonsContainer>

          {!!giftData?.endAt && (
            <S.Description>
              Essa assinatura é válida até o dia {formattedDate(giftData.endAt)}
            </S.Description>
          )}
        </S.GiftCheckoutContainer>
      );
    } else {
      return (
        <S.GiftCheckoutContainer>
          <AlertV2 variant={alertContent.type}>{alertContent.message}</AlertV2>
          <S.GiftCard bgImage={statue} id="capture" ref={exportRef}>
            <div className="title-container">
              <div>
                <p className="title">Vale Presente</p>
                <p className="subtitle">Casa do Saber</p>
              </div>
              <LogoCasa
                alt="Casa do Saber +"
                id={"btn-header-logo"}
                name={"btn-header-logo"}
              />
            </div>
            <div>
              <p className="code-label">Código:</p>
              <p className="code-value">{giftCode}</p>
            </div>
          </S.GiftCard>

          {!!giftData?.endAt && (
            <S.Description>
              Essa assinatura é válida até o dia {formattedDate(giftData.endAt)}
            </S.Description>
          )}
          <a
            id={"btn-gift_success-ir_para_a_pagina_inicial"}
            name={"btn-gift_success-ir_para_a_pagina_inicial"}
            href="https://casadosaber.com.br"
            className="style-as-button"
          >
            Ir para a página inicial
          </a>
        </S.GiftCheckoutContainer>
      );
    }
  };

  return (
    <S.Wrapper className="wrapper">
      <S.EmptyHeader />
      <StepperCustom steps={giftSteps} currentStep={currentStep} />
      {renderPage()}
      <CheckoutFooter />
    </S.Wrapper>
  );
};

export default GiftCheckoutSuccess;
