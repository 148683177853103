import React from "react";
import Switch from "react-switch";
import * as S from "./style";

export default function AutoPlay({ toggleAutoplay, isAutoPlay = false }) {
  return (
    <S.SwitchContainer>
      <S.ToolTip>
        <S.TooltipText>
          {isAutoPlay
            ? "Reprodução automática ativada"
            : "Reprodução automática desativada"}
        </S.TooltipText>
        <label>
          autoplay
          <Switch
            id={"btn-component-autoplay"}
            name={"btn-component-autoplay"}
            onChange={toggleAutoplay}
            checked={isAutoPlay}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={"#CFF16B"}
            handleDiameter={20}
            height={14}
            width={34}
          />
        </label>
      </S.ToolTip>
    </S.SwitchContainer>
  );
}
