export const support = {
  whatsapp: {
    href: "https://wa.me/5511966092057",
    target: "_blank",
    rel: "noopener noreferrer",
    text: "(11) 96609-2057",
  },
  mail: {
    href: "mailto:suporte@casadosaber.com.br",
    target: "_blank",
    rel: "noopener noreferrer",
    text: "suporte@casadosaber.com.br",
  },
  floatingWhatsapp: {
    href: "https://api.whatsapp.com/send?phone=5511966092057&text=Ol%C3%A1%2C+eu+preciso+de+ajuda+para+iniciar+a+minha+assinatura.",
    className: "whatsapp-float",
    target: "_blank",
    rel: "noopener noreferrer",
  },
};
