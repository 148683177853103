export const setInitialData = (initialData = {}) => ({
  initialData,
  initialDataUpdatedAt: 1608412420052, //torna a initialData obsoleta e atualiza os dados no cache
});

export const setFreshTime = (minutes = 10) => {
  return {
    staleTime: 1000 * 60 * minutes,
    cacheTime: 1000 * 60 * (minutes + 1),
  };
};
