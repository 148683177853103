import styled from "styled-components";

export const Wrapper = styled.div`
  color: white;
  display: flex;
  align-items: center;
  padding: 1em 0;
  @media (min-width: 768px) {
    padding: 1em 0.5em;
  }
`;

export const PageTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding-top: 3px;
  @media (min-width: 768px) {
    font-size: 24px;
    padding-left: 0.5em;
  }
`;

export const Icon = styled.div`
  padding-right: 10px;
  font-size: 20px;
  cursor: pointer;
  :hover {
    color: #cff16b;
  }
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;
