import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const CourseContent = styled.div`
  ${({ theme }) => css`
    @media (${device.tablet}) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      place-items: start;
      padding: ${theme.spacings.larger} ${theme.spacings.smaller};
    }
  `}
`;

export const CourseHeader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${theme.gap.smaller};
    padding: ${theme.spacings.small} 0;

    @media (${device.tablet}) {
      align-items: flex-start;
    }
  `}
`;

export const CourseTitle = styled.h1`
  ${({ theme }) => css`
    margin: 0;
    font-size: ${theme.font.size.medium};
    font-weight: ${theme.font.weight.bold};
    line-height: 1.4em;
    text-align: center;

    @media (${device.tablet}) {
      text-align: left;
      font-size: ${theme.font.size.larger};
    }
  `}
`;

export const CourseSubtitle = styled.h2`
  ${({ theme }) => css`
    margin: 0;
    font-size: ${theme.font.size.small};
    font-weight: ${theme.font.weight.regular};
    line-height: 1.6em;
    text-align: center;

    @media (${device.tablet}) {
      text-align: left;
      font-size: ${theme.font.size.medium};
    }
  `}
`;

export const CourseDuration = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.gap.smaller};
    font-size: ${theme.font.size.small};
    padding: ${theme.spacings.smallest} 0;

    svg path {
      fill: ${theme.darkTheme.colors.white};
    }
  `}
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.smallest} 0 0;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: ${theme.spacings.small};
    flex-wrap: wrap;
  `}
  @media (${device.tablet}) {
    justify-content: start;
  }
`;

export const ContainerOverProgressBar = styled.div`
  ${({ hideOnMobile }) => css`
    display: ${hideOnMobile ? "none" : "flex"};
    justify-content: flex-start;
    gap: 0.5em;

    > div {
      min-width: 52px;
    }

    @media (${device.tablet}) {
      display: flex;
    }
  `}
`;

export const ContainerBelowProgressBar = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacings.smallest} 0 0;
    justify-content: center;
    align-items: stretch;
    gap: ${theme.spacings.small};

    > div {
      min-width: 52px;
    }

    @media (${device.tablet}) {
      display: none;
    }
  `}
`;

export const ProgressContainer = styled.div`
  ${({ theme }) => css`
    width: 95%;
    max-width: 304px;
    text-align: center;
    font-size: ${theme.font.size.small};

    span {
      margin: 0 0.5em;
    }

    @media (${device.tablet}) {
      text-align: left;
      max-width: 200px;
    }
  `}
`;

export const AnchorDownload = styled.a`
  ${({ theme }) => css`
    width: 100%;
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: ${theme.darkTheme.colors.green};
    background-color: transparent;
    border: 1px solid ${theme.darkTheme.colors.green};
    border-radius: 5px;
    transition: all 0.1s ease-in;
    cursor: pointer;

    :hover {
      background-color: ${theme.darkTheme.colors.darkBlack};
      text-decoration: none;
    }

    @media (${device.regularPhone}) {
      max-width: 250px;
    }
  `}
`;
