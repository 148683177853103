import React from "react";
import {
  useAuthors,
  useCategoriesWithCourses,
  useFavoriteCourses,
  usePopularCourses,
  useRecommendedCourses,
  useSentry,
  useWatchingCourses,
} from "../hooks";
import {
  Container,
  Carousel,
  Slider,
  NoContentLoaded,
  LoadingSpinner,
} from "../components";

import strings from "../config/strings.json";
import { isBigCardOrNot } from "../util/ui-carousel";
import { filterSliderCourses } from "../util/slider";

export default function Index() {
  const { data: sliderCourses, isFetching: isSliderCoursesLoading } =
    useCategoriesWithCourses({
      home: true,
      coursesLimit: 1,
      limit: 10,
    });

  const {
    data: categoryWithCourses,
    isFetching: isCategoriesWithCoursesLoading,
  } = useCategoriesWithCourses();

  const { data: popularCourses, isFetching: isPopularCoursesLoading } =
    usePopularCourses();

  const { data: watchingCourses, isFetching: isWatchingCoursesLoading } =
    useWatchingCourses({
      limit: 30,
    });

  const { data: recommendedCourses } = useRecommendedCourses();

  const { data: authors, isFetching: isAuthorsLoading } = useAuthors({
    showHome: true,
    limit: 30,
  });

  const {
    data: { length: totalAuthors },
  } = useAuthors();

  const { logException } = useSentry(false);
  const { data: favoriteCourses } = useFavoriteCourses();
  const filteredSliderCourses = filterSliderCourses(sliderCourses);

  if (
    isSliderCoursesLoading ||
    isCategoriesWithCoursesLoading ||
    isPopularCoursesLoading ||
    isAuthorsLoading ||
    isWatchingCoursesLoading
  ) {
    return <LoadingSpinner color={"light"} />;
  }

  const hasCourses = categoryWithCourses.length > 0;
  const hasAuthors = authors.length > 0;
  const hasFavoriteCourses = !!favoriteCourses;

  const renderCategories = () => {
    return categoryWithCourses.map((category) => (
      <div id={`vitrine-${category.slug}`} key={category.slug}>
        <Carousel
          title={category.title}
          items={category.courses}
          quantity={category.coursesCount}
          link={category.slug && `/categoria/${category.slug}`}
          bigCard={isBigCardOrNot(category.slug)}
        />
      </div>
    ));
  };

  if (!hasCourses && !hasAuthors) {
    const exceptionParams = {
      error: new Error("Home without courses and authors"),
      transactionName: "Home page",
      origin: "no courses and authors",
      extras: [
        { label: "categoryWithCourses", value: categoryWithCourses },
        { label: "authors", value: authors },
      ],
    };
    logException(exceptionParams);
    return <NoContentLoaded />;
  }

  return (
    <Container type={"full"}>
      <Slider content={filteredSliderCourses} />

      <div id={"vitrine-meus-cursos"}>
        <Carousel
          title={strings.categories.keepWatching}
          items={watchingCourses}
          link={"/meus-cursos"}
          bigCard
        />
      </div>

      {hasFavoriteCourses && (
        <div id={"vitrine-minha-lista"}>
          <Carousel
            title={strings.categories.myList}
            items={favoriteCourses.slice(0, 10)}
            quantity={favoriteCourses.length}
            link={"/minha-lista"}
          />
        </div>
      )}

      <div id={"vitrine-recomendados"}>
        <Carousel
          title={strings.categories.recommendations.title}
          items={recommendedCourses}
          subtitle={strings.categories.recommendations.subtitle}
          link="/escolhas-para-voce"
        />
      </div>

      <div id={"vitrine-populares"}>
        <Carousel
          title={strings.categories.populars}
          items={popularCourses}
          link={"/populares"}
        />
      </div>

      {hasCourses && renderCategories()}

      {hasAuthors && (
        <div id={"vitrine-professores"}>
          <Carousel
            title={"Nossos professores"}
            items={authors}
            quantity={totalAuthors ?? 0}
            link={"/professores"}
            bigCard
            showItemTitle
          />
        </div>
      )}
    </Container>
  );
}
