import React from "react";
import noCourseIcon from "../../assets/icons/my-list-none.svg";
import * as S from "./styles";

const NoCourseContent = () => {
  return (
    <S.Wrapper>
      <S.IconContainer>
        <img src={noCourseIcon} alt="Ícone de Nenhum Curso Favorito" />
      </S.IconContainer>

      <S.Title>Você ainda não possui nenhum curso na lista.</S.Title>

      <S.Description>
        Adicione um curso para assistir quando quiser, eles ficarão visíveis
        aqui.
      </S.Description>
    </S.Wrapper>
  );
};

export default NoCourseContent;
