import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const PlayerContainer = styled.div`
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  transition: all 0.1s ease-out;
  background-color: black;
  z-index: 999;

  .loading-spinner-container {
    display: flex;
    width: 100vw;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    margin: 1em;
    border: none;
    box-sizing: border-box;
    background-color: transparent;

    .video-close-button {
      width: 100%;
      transition: 0.2s all ease-in;
    }

    :hover .video-close-button {
      fill: #cccccc;
      transform: scale(1.1);
    }

    @media (min-width: ${theme.breakPoint.tablet}) {
      margin: 1em 2em;
    }
  `}
`;

export const PlayerSambaContainer = styled.div`
  margin-top: 0;
  overflow: hidden;

  @media (${device.smallPhone}) {
    margin-top: 15px;
  }
`;
