import React from 'react';
import PropTypes from 'prop-types';

const Text = ({html, ...props}) => {

  return (
    <div dangerouslySetInnerHTML={{__html: html || ""}} {...props}/>
  );
};

Text.propTypes = {
  html: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
};

export default Text;