import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import { useAuthContext } from "../../context/AuthContext";
import Datalayer from "../../services/datalayer";
import strings from "../../config/strings.json";

import {
  Alert,
  Button,
  Input,
  PasswordInput,
  PhoneNumberInput,
  CheckoutPageTitle,
  StepperCustom,
  SignupFooter,
  SignupHeader,
} from "../../components";

import * as S from "./styles";
import { useDatalayer, useQuery } from "../../hooks";
import {
  activeSignupUrl,
  isLegacyCheckoutActive,
} from "../../util/newCheckout";

const schema = yup.object({
  email: yup
    .string()
    .required("Preencha um email válido")
    .email("Preencha um email válido")
    .lowercase()
    .trim(),
  password: yup
    .string()
    .required("Por favor, preencha a senha")
    // eslint-disable-next-line no-template-curly-in-string
    .min(6, "A senha deve conter no mínimo ${min} caracteres")
    .trim(),
});

const signupSteps = [
  { title: "Escolha uma senha" },
  { title: "Entenda a assinatura" },
  { title: "Efetue o pagamento" },
];

const SignupScreen = () => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const query = useQuery();
  const [emailBase64, giftCodeParam] = [
    query.get("email"),
    query.get("giftCode"),
  ];
  const emailParam = !!emailBase64 ? atob(emailBase64) : "";

  const { signUp, setIsLoggedIn } = useAuthContext();
  const { handleBlur } = useDatalayer();
  const navigate = useNavigate();

  const [alert, setAlert] = useState({});
  const [phoneError, setPhoneError] = useState({});
  const [loading, setLoading] = useState(false);

  const watchedPhoneNumber = watch("phoneNumber");

  useEffect(() => {
    if (!giftCodeParam && !isLegacyCheckoutActive) {
      window.location.replace(`${activeSignupUrl()}?${query.toString()}`);
    }
  }, [giftCodeParam]);

  useEffect(() => {
    if (
      watchedPhoneNumber &&
      isPossiblePhoneNumber(watchedPhoneNumber) &&
      !!phoneError.phoneNumber
    ) {
      return setPhoneError({});
    }
  }, [watchedPhoneNumber]);

  const onSubmit = async (data) => {
    try {
      // phoneNumber input is not validated by Yup
      if (!data.phoneNumber || !isPossiblePhoneNumber(data.phoneNumber)) {
        throw new Error("PhoneNumberInvalid");
      }
      setPhoneError({});

      setLoading(true);
      const { message, userId } = await signUp(
        data.email,
        data.password,
        data.phoneNumber
      );

      if (message) {
        setAlert({
          type: "success",
          message: message,
        });
      }

      Datalayer.onSignupCallback("sucesso", userId);

      setTimeout(() => {
        let url = "/checkout";
        if (giftCodeParam) {
          url += `?giftCode=${giftCodeParam}`;
        }

        setIsLoggedIn(true);
        navigate(url, { replace: true });
      }, 1500);
    } catch (error) {
      if (error.message === "PhoneNumberInvalid") {
        // this object follows Yup errors structure
        return setPhoneError({
          phoneNumber: { message: "Por favor, preencha um telefone válido" },
        });
      }

      if (error.response) {
        const { status } = error.response.data;

        if (status === 409) {
          Datalayer.onSignupCallback("erro:usuario-ja-registrado");

          return setAlert({
            type: "error",
            message: (
              <span>
                Identificamos um cadastro já existente com esse e-mail. Por
                favor, tente realizar o cadastro com outro e-mail ou entre em
                contato com o suporte para utilizar seu presente.
              </span>
            ),
          });
        }
      }

      Datalayer.onSignupCallback("erro");

      return setAlert({
        type: "error",
        message:
          "Houve um problema, por favor, verifique os dados e tente novamente",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!giftCodeParam && !isLegacyCheckoutActive) {
    return <></>;
  }

  return (
    <S.MainWrapper id={"page-content"}>
      <SignupHeader />

      <StepperCustom steps={signupSteps} currentStep={0} />

      <S.ContentWrapper className="wrapper">
        <CheckoutPageTitle
          title={strings.signUp.register.title}
          subtitle={strings.signUp.register.subtitle}
        />

        {!!alert.message && <Alert variant={alert.type} text={alert.message} />}

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            variant="light"
            label={"E-mail"}
            inputName={"email"}
            registerFunction={register}
            errors={errors}
            style={{ textTransform: "lowercase" }}
            defaultValue={emailParam ?? ""}
            onBlur={(e) => handleBlur(e, "checkout", "1", "email")}
          />

          <PasswordInput
            variant="light"
            label={"Senha"}
            inputName={"password"}
            registerFunction={register}
            errors={errors}
            onBlur={(e) => handleBlur(e, "checkout", "1", "senha")}
          />

          <PhoneNumberInput
            variant="light"
            label={"Telefone"}
            inputName={"phoneNumber"}
            control={control}
            errors={phoneError}
            onBlur={(e) => handleBlur(e, "checkout", "1", "telefone")}
          />

          <S.PrivacyPolicy>
            <p>
              Ao criar uma conta você confirma que concorda com os{" "}
              <a
                id={"btn-signup-termos_de_uso"}
                name={"btn-signup-termos_de_uso"}
                onClick={() =>
                  Datalayer.onClickLink("checkout", "termos-de-uso")
                }
                target="_blank"
                rel="noopener noreferrer"
                href={strings.url.termsOfUse}
              >
                Termos de Uso
              </a>
              ,{" "}
              <a
                id={"btn-signup-politica_de_privacidade"}
                name={"btn-signup-politica_de_privacidade"}
                onClick={() =>
                  Datalayer.onClickLink("checkout", "politica-privacidade")
                }
                target="_blank"
                rel="noopener noreferrer"
                href={strings.url.privacyPolicy}
              >
                Política de Privacidade
              </a>{" "}
              e aceita receber publicidade e promoções da Casa do Saber.
            </p>

            <Button
              variant={"green"}
              size={"full"}
              type="submit"
              id="FinalizarIdentificacao"
              name="FinalizarIdentificacao"
              onClick={() => {
                if (!loading) {
                  Datalayer.onClick("checkout", "botao:1", "continuar");
                }
              }}
              disabled={loading}
              loading={loading}
            >
              Continuar
            </Button>
          </S.PrivacyPolicy>
        </S.Form>
        <S.GiftSection>
          <S.GiftLink
            to="/presente"
            onClick={() =>
              Datalayer.onClickLink("checkout", "quero-presentear-alguem")
            }
          >
            🎁 <span>Quero presentear alguém</span> 🎁
          </S.GiftLink>
        </S.GiftSection>
        <SignupFooter />
      </S.ContentWrapper>
    </S.MainWrapper>
  );
};

export default SignupScreen;
