import React from "react";
import { useCheckoutContext } from "../../context/CheckoutContext";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { support } from "../../data/support";
import * as S from "./styles";

const mail = (
  <a
    href={support.mail.href}
    target={support.mail.target}
    rel={support.mail.rel}
    id={"btn-checkout_footer-mail"}
    name={"btn-checkout_footer-mail"}
  >
    {support.mail.text}
  </a>
);

const phone = (
  <a
    href={support.whatsapp.href}
    target={support.whatsapp.target}
    rel={support.whatsapp.rel}
    id={"btn-checkout_footer-phone"}
    name={"btn-checkout_footer-phone"}
  >
    {support.whatsapp.text}
  </a>
);

const CheckoutFooter = () => {
  const { showWhatsapp } = useCheckoutContext();

  return (
    <S.Wrapper>
      Para tirar dúvidas entre em contato com {mail} ou por WhatsApp no número{" "}
      {phone}, de segunda à sexta-feira das 10h às 19h.
      {showWhatsapp && (
        <a
          href={support.floatingWhatsapp.href}
          target={support.floatingWhatsapp.target}
          rel={support.floatingWhatsapp.rel}
          className={support.floatingWhatsapp.className}
          id={"btn-checkout_footer-floating_whatsapp"}
          name={"btn-checkout_footer-floating_whatsapp"}
        >
          <AiOutlineWhatsApp />
        </a>
      )}
    </S.Wrapper>
  );
};

export default CheckoutFooter;
