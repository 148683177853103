import React from "react";
import AutoPlay from "../AutoPlay";
import VideoContainer from "../VideoContainer";

import * as S from "./styles";

const CourseVideos = ({
  content,
  playVideo,
  hasAccess,
  toggleAutoplay,
  isAutoPlay,
}) => {
  return (
    <>
      <S.AutoplayContainer>
        <AutoPlay toggleAutoplay={toggleAutoplay} isAutoPlay={isAutoPlay} />
      </S.AutoplayContainer>
      {content.map((video) => (
        <VideoContainer
          key={video.title}
          content={video}
          hasAccess={hasAccess}
          playVideo={playVideo}
        >
          {video.title}
        </VideoContainer>
      ))}
    </>
  );
};

export default CourseVideos;
