import styled, { css } from "styled-components";

const buttonModiffiers = {
  //type
  coursePageWatch: (theme) => css`
    padding: ${theme.spacings.smallest} ${theme.spacings.medium};
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.small};

    span {
      width: min-content;
    }

    img {
      padding-bottom: 3px;
    }
  `,
  coursePageCertificate: (theme) => css`
    padding: ${theme.spacings.smaller};
    font-size: ${theme.font.size.small};

    span {
    }

    img {
      padding-bottom: 3px;
    }
  `,

  //variant
  green: (theme) => css`
    border: 1px solid transparent;
    color: ${theme.darkTheme.colors.darkBlack};
    background-color: ${theme.darkTheme.colors.green};
    :hover {
      color: ${theme.darkTheme.colors.darkBlack};
      background-color: ${theme.darkTheme.colors.white};
    }
  `,
  white: (theme) => css`
    border: 1px solid transparent;
    color: ${theme.darkTheme.colors.darkBlack};
    background-color: ${theme.darkTheme.colors.white};
    :hover:not(:disabled) {
      color: ${theme.darkTheme.colors.darkBlack};
      background-color: ${theme.darkTheme.colors.green};
    }
  `,
  transparent: (theme) => css`
    background-color: transparent;
    color: #cccccc;
    border: 1px solid #cccccc;
    :hover {
      color: ${theme.darkTheme.colors.green};
      border-color: ${theme.darkTheme.colors.green};
    }
  `,

  //size
  small: () => css`
    width: 125px;
    padding: 6px 4px 4px;
    text-align: center;
  `,
  full: () => css`
    width: 100%;
    padding: 1em;
  `,
};

export const Button = styled.button`
  ${({ theme, type, variant, size }) => css`
    color: ${theme.font.color.black};
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacings.smaller};
    border: none;
    border-radius: 5px;
    outline: inherit;
    font-family: ${theme.font.family.maisonNeue};
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    :disabled {
      cursor: not-allowed;
    }

    @media (min-width: ${theme.breakPoint.laptop}) {
    }

    ${type && buttonModiffiers[type](theme)};
    ${variant && buttonModiffiers[variant](theme)};
    ${size && buttonModiffiers[size](theme)};
  `}
`;
