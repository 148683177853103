import { secureStorage } from "../util/SecureStorage";

const getSubscriptionId = () => {
  const subscriptionReference = secureStorage.getItem(`reference`);

  return subscriptionReference;
};

const dataLayer = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const Datalayer = {
  onPageLoad(userId) {
    const referrer = document.referrer || undefined;
    const page = window.location.pathname;
    const url = window.location.href;
    const title = document.title;

    const global = {
      event: "global",
      userID: String(userId),
      dimension1: getSubscriptionId(),
    };

    dataLayer(global);

    const pageView = {
      event: "pageView",
      location: url,
      page: page,
      referrer: referrer,
      title: title,
    };

    dataLayer(pageView);
  },
  onClickLink(category, label) {
    const event = {
      event: "event",
      eventCategory: `casadosaber:${category}`,
      eventAction: `clique:link`,
      eventLabel: label,
    };

    dataLayer(event);
  },
  onClickButton(category, label) {
    const event = {
      event: "event",
      eventCategory: `casadosaber:${category}`,
      eventAction: `clique:botao`,
      eventLabel: label,
    };

    dataLayer(event);
  },
  onClick(category, action, label) {
    const event = {
      event: "event",
      eventCategory: `casadosaber:${category}`,
      eventAction: `clique:${action}`,
      eventLabel: label,
    };

    dataLayer(event);
  },
  onFill(category, action, label) {
    const event = {
      event: "event",
      eventCategory: `casadosaber:${category}`,
      eventAction: `preencheu:${action}`,
      eventLabel: label,
    };

    dataLayer(event);
  },
  onCallback(eventCategory, label, subscriptionId, gift) {
    const event = {
      event: "event",
      eventCategory: `casadosaber:${eventCategory}`,
      eventAction: "callback",
      eventLabel: label,
      dimension1: subscriptionId,
      dimension12: gift,
    };
    dataLayer(event);
  },
  onSignupCallback(label, userId) {
    const event = {
      event: "event",
      eventCategory: "casadosaber:checkout",
      eventAction: "cadastro:callback",
      eventLabel: label,
      userId,
    };
    dataLayer(event);
  },
  onCouponCallback(label) {
    const event = {
      event: "event",
      eventCategory: "casadosaber:checkout",
      eventAction: "callback",
      eventLabel: `cupom:${label}`,
    };
    dataLayer(event);
  },
  onSelectProduct(
    productName = undefined,
    productDatabaseId = undefined,
    productPrice = undefined
  ) {
    const formatedPrice =
      typeof productPrice === "string"
        ? productPrice.replace(",", ".")
        : productPrice;

    const event = {
      event: "productDetail",
      eventCategory: "enhanced-ecommerce",
      eventAction: "productDetail",
      noInteraction: "1",
      ecommerce: {
        detail: {
          products: [
            {
              name: productName,
              id: productDatabaseId,
              price: formatedPrice,
            },
          ],
        },
      },
    };
    dataLayer(event);
  },
  addToCart(
    productName = undefined,
    productDatabaseId = undefined,
    productPrice = undefined,
    coupon = undefined
  ) {
    const formatedPrice =
      typeof productPrice === "string"
        ? productPrice.replace(",", ".")
        : productPrice;

    const event = {
      event: "addToCart",
      eventCategory: "enhanced-ecommerce",
      eventAction: "addToCart",
      ecommerce: {
        add: {
          products: [
            {
              name: productName,
              id: productDatabaseId,
              price: formatedPrice,
              quantity: "1",
              coupon: coupon,
            },
          ],
        },
      },
    };

    dataLayer(event);
  },
  onPayment(
    productName = undefined,
    productDatabaseId = undefined,
    productPrice = undefined,
    paymentMethod = undefined,
    coupon = undefined
  ) {
    const formatedPrice =
      typeof productPrice === "string"
        ? productPrice.replace(",", ".")
        : productPrice;

    const event = {
      event: "checkout",
      eventCategory: "enhanced-ecommerce",
      eventAction: "checkout",
      noInteraction: "1",
      ecommerce: {
        checkout: {
          actionField: {
            step: "1",
            option: paymentMethod,
          },
          products: [
            {
              name: productName,
              id: productDatabaseId,
              price: formatedPrice,
              quantity: "1",
              coupon: coupon,
            },
          ],
        },
      },
    };

    dataLayer(event);
  },
  onPurchaseSuccess(
    productName = undefined,
    productDatabaseId = undefined,
    revenue = undefined,
    coupon = undefined
  ) {
    const event = {
      event: "purchase",
      eventCategory: "enhanced-ecommerce",
      eventAction: "purchase",
      eventLabel: "",
      noInteraction: "1",
      ecommerce: {
        purchase: {
          actionField: {
            id: getSubscriptionId(),
            revenue: revenue,
          },
          products: [
            {
              name: productName,
              id: productDatabaseId,
              price: revenue,
              quantity: "1",
              coupon: coupon,
            },
          ],
        },
      },
    };

    dataLayer(event);
  },
};

export default Datalayer;
