import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium} auto;
    padding: ${theme.spacings.small};
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: ${theme.gap.small};
    grid-row-gap: 0px;
    height: 45vw;
    width: 92vw;
    min-width: 280px;
    min-height: 130px;
    max-width: 384px;
    max-height: 186px;
    border-radius: 10px;
    background-color: #323132;
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    grid-area: 1 / 1 / 4 / 4;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${theme.font.size.smaller};

    img {
      max-width: 100%;
      height: auto;
      border-radius: 5px;
    }
  `}
`;
export const HeaderContainer = styled.div`
  ${({ theme }) => css`
    grid-area: 1 / 4 / 2 / 10;
  `}
`;
export const ProgressContainer = styled.div`
  ${({ theme }) => css`
    grid-area: 2 / 4 / 3 / 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `}
`;
export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    grid-area: 3 / 4 / 4 / 10;
    display: flex;
    justify-content: space-around;
    align-items: center;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family.maisonNeue};
    font-size: ${theme.font.size.smallest};
    font-weight: ${theme.font.weight.lightest};
    text-align: left;

    @media (min-width: ${theme.breakPoint.smallPhone}) {
      font-size: ${theme.font.size.default};
    }
  `}
`;
export const Professor = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family.maisonNeue};
    font-size: ${theme.font.size.smallest};
    font-weight: ${theme.font.weight.bold};
    text-align: left;
    max-height: 38%;
    overflow: hidden;

    @media (min-width: ${theme.breakPoint.regularPhone}) {
      font-size: ${theme.font.size.small};
    }
    @media (min-width: ${theme.breakPoint.tablet}) {
      font-size: ${theme.font.size.small};
      max-height: 40px;
    }
  `}
`;
export const FinishDate = styled.div`
  ${({ theme }) => css`
    display: none;

    @media (min-width: ${theme.breakPoint.smallPhone}) {
      display: block;
      font-family: ${theme.font.family.maisonNeue};
      font-size: ${theme.font.size.smallest};
      font-weight: ${theme.font.weight.light};
      text-align: left;
    }
  `}
`;

export const CardButton = styled.button`
  ${({ theme }) => css`
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.gap.smaller}
    font-size: 10px;
    min-width: 70px;
    border-radius: 5px;
    height: 9vw;
    width: 24vw;
    padding: 0;
    box-sizing: border-box;

    :hover {
      cursor: pointer;
    }

    span {
      padding-top: 1px;
    }

    svg {
      font-size: 1.2em;
    }

    @media (min-width: ${theme.breakPoint.smallPhone}) {
      font-size: ${theme.font.size.smallest};
      height: 25px;
      width: 26vw;
      max-width: 110px;
    }
    @media (min-width: ${theme.breakPoint.regularPhone}) {
      font-size: ${theme.font.size.smaller};
      height: 30px;
      width: 110px;
    }
  `}
`;

export const PlayButton = styled(CardButton)`
  ${({ theme }) => css`
    border: 1px solid transparent;
    color: ${theme.lightTheme.colors.text};
    background: ${theme.darkTheme.colors.white};
  `}
`;

export const RemoveButton = styled(CardButton)`
  ${({ theme }) => css`
    background: none;
    color: ${theme.darkTheme.colors.white};
    border: 1px solid ${theme.darkTheme.colors.darkGray};
  `}
`;
