import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.small};

    @media (${device.tablet}) {
      padding: ${theme.spacings.large};
      font-size: ${theme.font.size.larger};
    } ;
  `}
`;
