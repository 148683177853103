import React, { useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { IconContext } from "react-icons";

import * as S from "./styles";
import Star from "../../assets/images/star.svg";
import StarFilled from "../../assets/images/star-filled.svg";
import Button from "../Button";
import { requests } from "../../services/requests";

function CourseRating({ ratingType, onClose, courseId, classId }) {
  const stars = Array.from({ length: 5 });
  const [selectedStarIndex, setSelectedStarIndex] = useState();
  const [step, setStep] = useState(1);
  const [justification, setJustification] = useState();

  const firstClass = ratingType === "firstClass";

  const renderFirstStep = () => (
    <>
      <S.Title>
        Considerando o conteúdo, professor(a) e formato, como você avalia a sua
        experiência com o curso
        {firstClass && " até agora"}?
      </S.Title>
      <S.Text>Escolha uma classificação</S.Text>
      <S.StarSection>
        {stars.map((_, i) => (
          <S.StarButton key={i} onClick={() => setSelectedStarIndex(i)}>
            <img
              src={selectedStarIndex >= i ? StarFilled : Star}
              alt="nota-estrela"
            />
          </S.StarButton>
        ))}
      </S.StarSection>
      <Button
        variant="white"
        type="button"
        disabled={selectedStarIndex === undefined}
        onClick={() => setStep(2)}
      >
        Enviar
      </Button>
    </>
  );

  const renderSecondStep = () => (
    <>
      <S.Title>Explique o motivo da sua avaliação</S.Title>
      <S.TextArea
        placeholder="Escreva sua opinião sobre o curso e o professor"
        rows={7}
        value={justification}
        onChange={(e) => setJustification(e.target.value)}
      />
      <Button
        variant="white"
        type="button"
        onClick={() => {
          sendRating();
          onClose();
        }}
      >
        Enviar
      </Button>
    </>
  );

  const sendRating = () => {
    requests
      .sendRating(
        courseId,
        firstClass ? classId : null,
        selectedStarIndex + 1,
        justification
      )
      .finally(() => onClose());
  };

  const handleClose = () => {
    if (step === 1) {
      return onClose();
    }
    sendRating();
  };

  return (
    <S.Container>
      <S.Top>
        <S.CloseButton
          id={"btn-close"}
          name={"btn-close"}
          onClick={handleClose}
        >
          <IconContext.Provider
            value={{
              color: "#ffff",
            }}
          >
            <IoCloseCircle size={26} />
          </IconContext.Provider>
        </S.CloseButton>
      </S.Top>
      <S.Content>
        {step === 1 ? renderFirstStep() : renderSecondStep()}
        {firstClass && (
          <Button
            type="button"
            onClick={() => {
              if (step === 2) {
                sendRating();
              } else {
                onClose();
              }
            }}
          >
            PERGUNTE-ME AO FINAL DO CURSO
          </Button>
        )}
      </S.Content>
    </S.Container>
  );
}

export default CourseRating;
