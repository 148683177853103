import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 414px;
  margin: 0 auto 16px;

  // access individual step div
  > div > div > div {
    // circle div
    > div:first-child {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;

      // number when active into circle
      a {
        color: #4c917c !important;
      }
    }

    // progress line div
    > div:not(div:first-child) {
      border-top-width: 3px !important;
    }

    // number active and not active
    a,
    span,
    div {
      line-height: 1em !important;
    }

    // Step title
    > a {
      font-weight: 700 !important;
      line-height: 1em !important;
      padding: 0 20px;
    }
  }
`;
