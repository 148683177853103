import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Rating = styled.div`
  ${({ theme }) => css`
    text-align: center;
    font-size: 12px;

    img {
      padding-bottom: 6px;
    }

    .rating-value {
      font-size: 20px;
    }
    .rating-votes {
      color: ${theme.darkTheme.colors.gray};
    }

    @media (${device.tablet}) {
      text-align: left;
    }
  `}
`;
