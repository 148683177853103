import styled, { css } from "styled-components";

export const Fieldset = styled.fieldset`
  ${({ theme }) => css`
    border: none;
    display: flex;
    gap: 10px;

    input {
      width: 40px;
      height: 47px;
      font-size: 1rem;
      text-align: center;
      border: none;
      border-radius: 5px;
      background-color: ${theme.darkTheme.colors.inputBackground};
      color: ${theme.darkTheme.colors.text};
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  `}
`;
