import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  max-width: 420px;
  margin: 4em auto 0;

  display: flex;
  flex-direction: column;
  gap: 25px;

  //footer spacing
  min-height: calc(100vh - 62px - 4em); //header height - Wrapper margin-top
  position: relative;
  padding-bottom: 180px;

  @media (min-width: 900px) {
    min-height: calc(100vh - 151px - 4em); //header height - Wrapper margin-top
  }
`;

export const CancelledContainer = styled.div`
  max-width: 420px;
  margin: 0 auto 0;
  padding-left: 1.5em;
  padding-right: 1.5em;

  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const CancelledHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em;

  button.header-exit-button {
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.darkTheme.colors.text};
    background-color: transparent;
    padding: 5px 10px;
    border: none;

    :hover {
      color: ${(props) => props.theme.darkTheme.colors.green};
    }
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 0; //reset h2 margin
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: normal;
  margin: 0; //reset p margin
`;

export const WhatsappLink = styled.a`
  text-transform: uppercase;
  text-decoration: underline;
  text-align: center;
  font-weight: 700;
  font-size: 0.875rem;
  color: ${(props) => props.theme.darkTheme.colors.text};
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

export const ReasonsList = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  label {
    display: block;
    position: relative;
    margin: 0;
    padding: 12px 10px 12px 40px;
    line-height: 25px;
    background-color: #323132;
    border-radius: 5px;
    :hover {
      cursor: pointer;
    }
  }
  button {
    margin-top: 10px;
  }
`;

export const RadioButton = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked ~ span:after {
    display: block;
  }
`;

export const RadioButtonMark = styled.span`
  position: absolute;
  top: 14px;
  left: 10px;
  height: 20px;
  width: 20px;
  background-color: #323132;
  border: 1px solid #5f5d5f;
  border-radius: 5px;
  padding: 3.2px;

  ::after {
    content: "";
    position: absolute;
    display: none;
    width: 12px;
    height: 12px;
    background-color: #cff16b;
    border-radius: 2px;
  }
`;

export const StyledButton = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    color: #ccc;
    background-color: transparent;
    border: none;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
    :hover {
      text-decoration: none;
      color: ${theme.darkTheme.colors.green};
    }
  `}
`;

export const StyledTextarea = styled.textarea`
  ${({ theme }) => css`
    background-color: ${theme.darkTheme.colors.inputBackground};
    color: ${theme.darkTheme.colors.text};
    border-radius: 5px;
    border: none;
    padding: 0.5rem;
    width: 100%;
    display: block;
    font-size: 16px;

    :disabled {
      opacity: 0.6;
    }

    ::-webkit-scrollbar {
      width: 8px;
      padding: 4px;
      background-color: ${theme.darkTheme.colors.background};
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${theme.darkTheme.colors.green};
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${theme.darkTheme.colors.darkGray};
      border-radius: 10px;
    }
  `}
`;
