import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { default as pt_BR } from "react-phone-number-input/locale/pt-BR.json";
import "react-phone-number-input/style.css";
import { FiAlertTriangle } from "react-icons/fi";
import { Spinner } from "reactstrap";
import { useUserSubscriptionContext } from "../../context/userSubscriptionContext";
import { useCheckoutContext } from "../../context/CheckoutContext";
import CheckoutHeader from "../../components/CheckoutHeader";
import CTAButton from "../../components/CTAButton";
import CheckoutPageTitle from "../../components/CheckoutPageTitle";
import { StepperCustom } from "../../components";
import { subscribeOnVivo } from "../../services/checkout";
import Datalayer from "../../services/datalayer";

import strings from "../../config/strings.json";
import * as S from "./styles";

export const CheckoutVivo = () => {
  const navigate = useNavigate();
  const { personalData } = useUserSubscriptionContext();
  const [customer, setCustomer] = useState(personalData);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { currentStep, checkoutSteps, changeStep } = useCheckoutContext();

  useEffect(() => {
    changeStep("pagamento");
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();

    if (loading) return;
    setErrors(false);
    setLoading(true);

    const validPhoneNumber = isPossiblePhoneNumber(customer.phoneNumber || "");

    if (!validPhoneNumber) {
      setMessage(
        `Para continuar, por favor, no campo "Telefone" selecione a bandeira do país e preencha o número no formato correto`
      );
      setLoading(false);
      return setErrors(true);
    }

    const subscriptionData = {
      planId: "vivo",
      phoneNumber: customer.phoneNumber,
    };

    const vivoSubscription = await subscribeOnVivo(subscriptionData);

    if (!vivoSubscription.success) {
      Datalayer.onCallback("checkout", "erro:vivo");
      setMessage(vivoSubscription.response);

      setTimeout(() => {
        navigate("/checkout/planos", { replace: true });
      }, 3000);

      setLoading(false);
      return setErrors(true);
    }

    if (vivoSubscription.success) {
      Datalayer.onCallback("checkout", "sucesso:vivo");
      setMessage(vivoSubscription.response);
      setSuccess(true);

      setTimeout(() => {
        navigate(`/`);
      }, 3000);
    }
  };

  const handleInput = (event, name) => {
    setCustomer({ ...customer, [name]: event });
  };

  return (
    <S.Wrapper>
      <CheckoutHeader />

      <StepperCustom steps={checkoutSteps} currentStep={currentStep} />

      <S.CheckoutVivo>
        <S.InfoContainer>
          <CheckoutPageTitle title={strings.vivo.title}>
            <p>{strings.vivo.description}</p>
            <p>
              {strings.vivo.support}{" "}
              <a href={strings.vivo.supportUrl}>{strings.vivo.supportUrl}</a>
            </p>
          </CheckoutPageTitle>
        </S.InfoContainer>
        {errors && (
          <S.Errors>
            {" "}
            <FiAlertTriangle /> <span>{message}</span>{" "}
          </S.Errors>
        )}
        {success && (
          <S.SuccessMessage>
            <FiAlertTriangle /> <span>{message}</span>{" "}
          </S.SuccessMessage>
        )}

        <S.Form onSubmit={submitForm}>
          <div className="one-column">
            <label htmlFor={"phoneNumber"}>Telefone</label>
            <PhoneInput
              id="phoneNumber"
              key="phoneNumber"
              name="phoneNumber"
              defaultCountry={"BR"}
              initialValueFormat="national"
              labels={pt_BR}
              value={customer?.phoneNumber}
              onChange={(e) => handleInput(e, "phoneNumber")}
            />
          </div>
          <CTAButton
            id={"btn-subscribe-iniciar_assinatura-vivo"}
            name={"btn-subscribe-iniciar_assinatura-vivo"}
            type="submit"
            onClick={() => {
              if (!loading) {
                Datalayer.onClick(
                  "checkout",
                  "botao:pagamento",
                  "iniciar-assinatura-vivo"
                );
              }
            }}
            text={loading ? <Spinner color="dark" /> : "Iniciar Assinatura"}
          />
        </S.Form>
      </S.CheckoutVivo>
    </S.Wrapper>
  );
};

export default CheckoutVivo;
