import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSearchContext } from "../../context/SearchContext";
import { useUserSubscriptionContext } from "../../context/userSubscriptionContext";
import { IoSearchOutline, IoCloseCircleOutline } from "react-icons/io5";
import { useWindowWidthCalculate } from "../../hooks";
import {
  GoBackButton,
  DesktopMenu,
  FloatingSearch,
  BannerInformative,
  BannerFinishSignup,
} from "../../components";
import { ReactComponent as LogoCasa } from "../../assets/images/logocdsmais.svg";
import { ReactComponent as SimboloCasa } from "../../assets/images/simbolo-cds.svg";
import * as S from "./styles";
import MobileMenu from "../Menus/MobileMenu";

const Header = ({ pageName, type = "full" }) => {
  const { isDesktop } = useWindowWidthCalculate();
  const { hasIncompleteRegister } = useUserSubscriptionContext();
  const { showFormSearch, handleClickSearch } = useSearchContext();
  const navigate = useNavigate();

  const handleClickLogo = () => {
    navigate("/");
  };

  const variant = {
    full: (
      <>
        <S.Wrapper variant={"full"}>
          <div className="logo">
            <LogoCasa
              alt="Casa do Saber +"
              onClick={handleClickLogo}
              id={"btn-header-logo"}
              name={"btn-header-logo"}
            />
          </div>
          <S.Icons>
            <IoSearchOutline
              id={"btn-header-search_icon"}
              name={"btn-header-search_icon"}
              onClick={handleClickSearch}
            />
            <S.CertificateLink
              to={"/minha-conta/certificados"}
              id={"btn-header-certificados"}
              name={"btn-header-certificados"}
            >
              <button>CERTIFICADOS</button>
            </S.CertificateLink>
            <S.ProfileLink
              to={
                hasIncompleteRegister
                  ? "/minha-conta/dados-pessoais"
                  : "/minha-conta"
              }
            >
              MINHA CONTA
            </S.ProfileLink>
          </S.Icons>
        </S.Wrapper>

        <BannerInformative />

        {hasIncompleteRegister && <BannerFinishSignup />}

        <MobileMenu />
      </>
    ),
    clean: (
      <S.Wrapper variant={"clean"}>
        <GoBackButton pageName={pageName} />
        <div
          className="logo"
          onClick={handleClickLogo}
          id={"btn-header-logo"}
          name={"btn-header-logo"}
        >
          <SimboloCasa alt="Símbolo Casa do Saber" />
        </div>
        <S.Icons>
          <IoSearchOutline
            id={"btn-header-search_icon"}
            name={"btn-header-search_icon"}
            onClick={handleClickSearch}
          />
          <S.ProfileLink
            to={
              hasIncompleteRegister
                ? "/minha-conta/dados-pessoais"
                : "/minha-conta"
            }
          >
            MINHA CONTA
          </S.ProfileLink>
        </S.Icons>
      </S.Wrapper>
    ),
    transparent: (
      <S.Wrapper variant={"transparent"}>
        <GoBackButton />
        <S.Icons>
          <IoSearchOutline
            id={"btn-header-search_icon"}
            name={"btn-header-search_icon"}
            onClick={handleClickSearch}
          />
          <S.ProfileLink
            to={
              hasIncompleteRegister
                ? "/minha-conta/dados-pessoais"
                : "/minha-conta"
            }
          >
            MINHA CONTA
          </S.ProfileLink>
        </S.Icons>
      </S.Wrapper>
    ),
    minimal: (
      <S.Wrapper variant={"minimal"}>
        <GoBackButton pageName={pageName} />
      </S.Wrapper>
    ),
    login: (
      <S.Wrapper variant={"minimal"}>
        <GoBackButton />
      </S.Wrapper>
    ),
  };

  const renderMobile = () => {
    return <>{variant[type] || variant["full"]}</>;
  };

  const renderDesktop = () => {
    if (type === "login")
      return (
        <S.Wrapper>
          <div className="logo">
            <LogoCasa
              alt="Casa do Saber +"
              onClick={handleClickLogo}
              id={"btn-header-logo"}
              name={"btn-header-logo"}
            />
          </div>
        </S.Wrapper>
      );

    if (type === "minimal")
      return (
        <>
          <S.Wrapper>
            <div className="logo">
              <LogoCasa
                alt="Casa do Saber +"
                onClick={handleClickLogo}
                id={"btn-header-logo"}
                name={"btn-header-logo"}
              />
            </div>
            <div className="formSearch">
              {showFormSearch && <FloatingSearch close={handleClickSearch} />}
            </div>
            <S.Icons>
              {showFormSearch ? (
                <div
                  id={"btn-header-close_search"}
                  name={"btn-header-close_search"}
                  className={"search-control"}
                  onClick={handleClickSearch}
                >
                  <IoCloseCircleOutline />
                  <span>Fechar busca</span>
                </div>
              ) : (
                <IoSearchOutline
                  id={"btn-header-search_icon"}
                  name={"btn-header-search_icon"}
                  onClick={handleClickSearch}
                />
              )}
              <Link
                to={"/minha-conta/certificados"}
                id={"btn-header-certificados"}
                name={"btn-header-certificados"}
              >
                <button>CERTIFICADOS</button>
              </Link>
              <S.ProfileLink
                to={
                  hasIncompleteRegister
                    ? "/minha-conta/dados-pessoais"
                    : "/minha-conta"
                }
              >
                MINHA CONTA
              </S.ProfileLink>
            </S.Icons>
          </S.Wrapper>

          <BannerInformative />

          {hasIncompleteRegister && <BannerFinishSignup />}

          {pageName && <GoBackButton pageName={pageName} />}
        </>
      );

    return (
      <>
        <S.Wrapper>
          <div className="logo">
            <LogoCasa
              alt="Casa do Saber +"
              onClick={handleClickLogo}
              id={"btn-header-logo"}
              name={"btn-header-logo"}
            />
          </div>
          <div className="formSearch">
            {showFormSearch && <FloatingSearch close={handleClickSearch} />}
          </div>
          <S.Icons>
            {showFormSearch ? (
              <div
                id={"btn-header-close_search"}
                name={"btn-header-close_search"}
                className={"search-control"}
                onClick={handleClickSearch}
              >
                <IoCloseCircleOutline />
                <span>Fechar busca</span>
              </div>
            ) : (
              <IoSearchOutline
                id={"btn-header-search_icon"}
                name={"btn-header-search_icon"}
                onClick={handleClickSearch}
              />
            )}
            <Link
              to={"/minha-conta/certificados"}
              id={"btn-header-certificados"}
              name={"btn-header-certificados"}
            >
              <button>CERTIFICADOS</button>
            </Link>
            <S.ProfileLink
              to={
                hasIncompleteRegister
                  ? "/minha-conta/dados-pessoais"
                  : "/minha-conta"
              }
            >
              MINHA CONTA
            </S.ProfileLink>
          </S.Icons>
        </S.Wrapper>

        <BannerInformative />

        {hasIncompleteRegister && <BannerFinishSignup />}

        <DesktopMenu />

        {pageName && <GoBackButton pageName={pageName} />}
      </>
    );
  };

  return <>{isDesktop ? renderDesktop() : renderMobile()}</>;
};

export default Header;
