import { createGlobalStyle } from "styled-components";
import "../../src/assets/fonts/Maison/css/style.css";
import "../../src/assets/fonts/montserrat/css/style.css";
import "../../src/assets/fonts/RobotoSlab/css/style.css";
import "../../src/assets/fonts/Raleway/css/style.css";
import "react-notifications-component/dist/theme.css";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;  
  }

  *[role="button"], button, a {
    cursor: pointer;
  }

  html {
    scroll-behavior: smooth;
    height: 100%;
  }

  body {
    min-height: 100vh;
    font-family: "MaisonNeue", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    font-weight: 400;
    font-style: normal;
    margin: 0;

    color: #ffffff;
    background: ${(props) => (props.light ? "#FFF" : "#201e20")};
  }

  a {
    text-decoration: none;
  }
  a:hover {
    color: #cff16b;
  }
  section {
    position: relative;
  }
  input, button {
    outline: none !important;
  }
  ul {
    padding-left: 17px;
    margin-bottom: 0;
  }


  b, strong, .bold {
    font-weight: bold;
    font-style: normal;
    i,em {
      font-weight: bold;
      font-style: italic;
    }
  }

  i, em, .italic {
    font-weight: normal;
    font-style: italic;
    
    b, strong, .bold {
      font-weight: bold;
      font-style: italic;
    }
  }

  .text-base {
    font-size: 1rem;
  }

  .rounded {
    border-radius: 50%;
  }
  .error-fragment {
    text-align: center;
  }

  .spinner {
    border-width: 0.25em;
    border-style: solid;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    animation: spin 0.7s linear infinite;
    margin: 0 auto;
    &.gray {
      border-color: #ACA9B3;
      border-left-color: transparent;
    }
    &.green {
      border-color: #cff16b;
      border-left-color: transparent;
    }
    &.black {
      border-color: #100f10;
      border-left-color: transparent;
    }
  }
  .animate-pop {
    animation-duration: 0.5s;
    animation-name: animate-pop;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  }

  // animations
  @keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  //modal backdrop
  .modal-backdrop.show {
    opacity: 0.9;
  }
  // new modal - banner
  .modal-course-details {
    .modal-header {
      padding: 0;
      border-bottom: none;
    }
    .close {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: #323132;
      color: #cccccc;
      font-size: 24px;
      cursor: pointer;

      i {
        font-size: 12px;
      }

      span {
        padding-top: 3px;
      }
    }

    @media (min-width: 414px) {
      .close {
        width: 30px;
        height: 30px;
        i {
          font-size: 17px;
        }
      }
    }

    .modal-footer {
      display: flex;
      border-top: none;
    }
  }
  // end new modal - banner
`;

export default GlobalStyle;
