import React from "react";
import * as S from "./styles";

const Box = ({ children, textCenter, verticalMargin }) => {
  return (
    <S.Wrapper textCenter={textCenter} verticalMargin={verticalMargin}>
      {children}
    </S.Wrapper>
  );
};

export default Box;
