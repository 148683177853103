const CategoryExceptions = {
  hiddenFromSlider: ["em-breve"],
  hiddenFromApplication: ["sem-categoria"],
  showBigCard: ["ultimos-lancamentos", "curtas-do-saber"],
  mainMenu: [
    "ultimos-lancamentos",
    "filosofia",
    "psicologia",
    "psicanalise",
    "historia",
    "artes",
    "gestao-e-negocios",
  ],
  mainSubmenu: ["ultimos-lancamentos", "comece-por-aqui"],
};

export default CategoryExceptions;
