import "react-credit-cards/es/styles-compiled.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";

import { useGetGatewayData, useUpdateInstallments, useUser } from "../../hooks";
import { formattedPrice } from "../../util/Util";

import { Alert, Button, Container, LoadingSpinner } from "../../components";

import * as S from "./styles";

const OPTIONS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

const UpdatePaymentMethod = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [selectedOption, setSelectedOption] = useState(
    state?.installments || 1
  );

  const {
    data: { subscriptionDetails },
  } = useUser();
  const { mutate: sendRequest } = useUpdateInstallments({
    onSuccess: () => {
      return navigate("/minha-conta/assinatura", {
        state: { updatedInstallments: true },
        replace: true,
      });
    },
    onError: () => {
      setAlert({
        type: "error",
        message:
          "Houve um erro ao editar sua forma de pagamento. Por favor, tente novamente ou entre em contato com o suporte",
      });
      setButtonLoading(false);
    },
  });

  const {
    data: gatewayData,
    isLoading: isBillsLoading,
    isError,
  } = useGetGatewayData({
    reference: subscriptionDetails?.reference,
  });

  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  const updateInstallments = () => {
    setButtonLoading(true);
    setAlert({});
    sendRequest({
      installments: selectedOption,
      subscriptionReference: subscriptionDetails.reference,
    });
  };

  useEffect(() => {
    if (isError) {
      return setAlert({
        type: "error",
        message:
          "Houve um erro inesperado ao buscar os dados da sua assinatura. Por favor, recarregue a página ou tente novamente mais tarde",
      });
    }
    return () => setAlert({});
  }, [isError]);

  if (!subscriptionDetails || isBillsLoading) {
    return <LoadingSpinner color="light" />;
  }

  return (
    <Container type={"minimal"} pageName="Alterar forma de pagamento">
      <S.Wrapper>
        {!!alert.message && <Alert variant={alert.type} text={alert.message} />}

        <p>Escolha como deseja pagar na próxima cobrança da sua assinatura.</p>

        <select
          id="installments"
          key="installments"
          name="installments"
          className="form-control"
          type="select"
          value={selectedOption}
          onChange={handleSelect}
        >
          {!!gatewayData &&
            OPTIONS.map((option) => (
              <option value={option} key={option}>
                Em {option}x de{" "}
                {formattedPrice(Number(gatewayData.nextBillingPrice) / option)}
              </option>
            ))}
        </select>

        <Button
          variant={"white"}
          size={"full"}
          onClick={updateInstallments}
          id={"btn-change_payment-salvar"}
          name={"btn-change_payment-salvar"}
          disabled={buttonLoading || isError}
        >
          {buttonLoading ? <Spinner color="dark" /> : "Salvar"}
        </Button>

        <Button
          variant={"transparent"}
          size={"full"}
          onClick={() =>
            navigate("/minha-conta/assinatura", {
              replace: true,
            })
          }
          id={"btn-change_payment-cancelar"}
          name={"btn-change_payment-cancelar"}
        >
          Cancelar
        </Button>
      </S.Wrapper>
    </Container>
  );
};

export default UpdatePaymentMethod;
