import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 10px auto;
    width: 100%;
    text-align: center;
  `}
`;

export const Track = styled.div`
  ${({ theme }) => css`
    padding: 1px;
    border-radius: 30px;
    background: rgba(256, 256, 256, 0.3);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
      0 1px rgba(255, 255, 255, 0.08);
  `}
`;

export const Thumb = styled.div`
  ${({ theme, progress }) => css`
    height: 3px;
    border-radius: 30px;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.05)
    );
    transition: 0.3s ease-out;
    transition-property: width, background-color;
    width: ${progress}%;
    background-color: ${theme.darkTheme.colors.green};
    animation: progressAnimation-${progress} 3s;

    @keyframes progressAnimation-${progress} {
      0% {
        width: 0%;
        background-color: #d4e996;
      }
      100% {
        width: ${progress}%;
        background-color: #ceed72;
      }
    }
  `}
`;
