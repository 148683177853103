import { apiCDS, apiVindiCardToken } from "./api";

export const getVindiCustomerId = async ({ email, identity }) => {
  return await apiCDS
    .post(`/subscriptions/customer`, { email, identity })
    .then((response) => {
      return {
        success: true,
        response: response.data.customerId,
      };
    })
    .catch(() => {
      return {
        success: false,
        response: "create_customer",
      };
    });
};

export const getVindiToken = async (body) => {
  return await apiVindiCardToken
    .post("/payment_profiles", body)
    .then((response) => {
      return {
        success: true,
        response: response.data.payment_profile.gateway_token,
      };
    })
    .catch((error) => {
      if (error.response) {
        return {
          success: false,
          response: error.response.data?.errors,
          message: error.message,
        };
      }

      return {
        success: false,
        response: error,
        message: error.message,
      };
    });
};

export const subscribeGiftOnVindi = async (body) => {
  return await apiCDS
    .post("/subscriptions/vindi-gift", body)
    .then((response) => {
      return {
        success: true,
        response: response.data.giftCode,
      };
    })
    .catch((error) => {
      return {
        success: false,
        message:
          "Estamos com problemas para efetivar a assinatura. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
        redirectToHome: false,
      };
    });
};

export const subscribeOnVindi = async (body) => {
  return await apiCDS
    .post("/subscriptions", body, { timeout: 60000 })
    .then((response) => {
      return {
        success: true,
        response: response.data.vindiSubscriptionId,
      };
    })
    .catch((error) => {
      if (error.response) {
        return {
          success: false,
          message: error.response.data.message,
          redirectToHome: error.response.status === 409 ? true : false,
        };
      }

      return {
        success: false,
        message:
          "Estamos com problemas para efetivar sua assinatura. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
        redirectToHome: false,
      };
    });
};

export const handleVindiErrors = (response = "default") => {
  const errors = {
    create_customer:
      "Ops! Houve um problema ao criar sua assinatura. Por favor, tente novamente. Se o problema persistir, contate o suporte.",
    card_expiration:
      "Ops! Identificamos um problema. Confira a data de vencimento do cartão.",
    card_cvv:
      "Ops! Identificamos um problema. Confira o código de segurança do cartão.",
    payment_company_code:
      "Ops! Identificamos um problema com o número do cartão. Caso esteja correto, entre em contato para verificar se esta bandeira é aceita.",
    payment_company_id:
      "Ops! Houve um problema interno ao cadastrar seu cartão. Por favor, entre em contato com o suporte para verificar se esta bandeira é aceita.",
    gift_flow:
      "Estamos com problemas para efetivar a compra do vale presente. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
    default:
      "Ops! Identificamos um problema. Confira suas informações de pagamento e tente novamente.",
  };

  return errors[response] || errors["default"];
};

export const verifyCouponCode = async (couponCode, planValue, planId) => {
  return await apiCDS
    .get(`/coupons/verify/${couponCode}?planId=${planId}`)
    .then((response) => {
      const convertedData = {
        name: response.data.plan,
        id: response.data.planId,
        installmentPrice: Number(
          response.data.discount / response.data.installments
        )
          .toFixed(2)
          .replace(".", ","),
        installmentsInfo: `em até ${response.data.installments}x de`,
        price: response.data.discount.toFixed(2).replace(".", ","),
        discount: Number(planValue.replace(",", ".") - response.data.discount)
          .toFixed(2)
          .replace(".", ","),
        coupon: couponCode,
        couponId: response.data.couponId,
        discountLabel:
          response.data.discountType === "percentage"
            ? `${response.data.value}%`
            : `R$ ${response.data.value}`,
      };

      return {
        success: true,
        response: convertedData,
      };
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.data.status === 400 ||
          error.response.data.status === 422)
      ) {
        return {
          success: false,
          response: "Cupom inválido",
        };
      }

      return {
        success: false,
        response:
          "Houve um problema ao aplicar o cupom. Por favor, tente novamente ou entre em contato com o suporte.",
      };
    });
};

export const applyGiftCouponCode = async (couponCode, plan) => {
  return await apiCDS
    .get(`/coupons/verify/${couponCode}?planId=${plan.id}`)
    .then((response) => {
      const convertedData = {
        id: response.data.planId,
        installmentPrice: Number(
          response.data.discount / response.data.installments
        )
          .toFixed(2)
          .replace(".", ","),
        installmentsInfo: `em até ${response.data.installments}x de`,
        price: response.data.discount.toFixed(2).replace(".", ","),
        discount: Number(
          plan.originalPrice.replace(",", ".") - response.data.discount
        )
          .toFixed(2)
          .replace(".", ","),
        coupon: couponCode,
        couponId: response.data.couponId,
        discountLabel:
          response.data.discountType === "percentage"
            ? `${response.data.value}%`
            : `R$ ${response.data.value}`,
      };
      return {
        success: true,
        response: convertedData,
      };
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.data.status === 400 ||
          error.response.data.status === 422)
      ) {
        return {
          success: false,
          response: "Cupom inválido",
        };
      }

      return {
        success: false,
        response:
          "Houve um problema ao aplicar o cupom. Por favor, tente novamente ou entre em contato com o suporte.",
      };
    });
};

export const changeCardOnVindi = async (body) => {
  return await apiCDS
    .put("/subscriptions/edit-card", body)
    .then((response) => {
      return {
        success: true,
        response: response.data.message,
      };
    })
    .catch(() => {
      return {
        success: false,
        response: (
          <>
            <strong>Falha no pagamento!</strong> Verifique se os dados
            informados estão corretos ou tente usar um novo cartão de crédito.
          </>
        ),
      };
    });
};

export const subscribeOnVivo = async (body) => {
  return await apiCDS
    .post("/subscriptions/vivo", body)
    .then((response) => {
      return {
        success: true,
        response: response.data.message,
      };
    })
    .catch((err) => {
      if (err.response) {
        return {
          success: false,
          response: err.response.data.message,
        };
      }

      return {
        success: false,
        response:
          "Estamos com problemas para efetivar sua assinatura. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
      };
    });
};
