import styled from "styled-components";

export const Button = styled.button`
  background-color: ${(props) => (props.dark ? "#201e20" : "#ffffff")};
  color: ${(props) => (props.dark ? "#ffffff" : "#201e20")};
  border: ${(props) => (props.dark ? "1px solid #aca9b3" : "none")};
  border-radius: 5px;
  height: 49px;
  width: 100%;
  padding: 0.3rem 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  :hover,
  :active {
    background: transparent;
    border: 2px solid #cff16b;
    color: #fff;
  }
`;
