import React from "react";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";

const GoBackButton = ({ route, pageName }) => {
  let navigate = useNavigate();

  const handleClick = () => {
    if (pageName === "Minha conta") {
      navigate("/");
    } else if (route) {
      navigate(route);
    } else {
      navigate(-1);
    }
  };

  return (
    <S.Wrapper>
      <S.Icon>
        <FiArrowLeft
          id={"btn-component-voltar"}
          name={"btn-component-voltar"}
          onClick={handleClick}
        />
      </S.Icon>
      <S.PageTitle>{pageName}</S.PageTitle>
    </S.Wrapper>
  );
};

export default GoBackButton;
