import strings from "../config/strings.json";

import { useResetCourseProgress } from "./index";

export default function useNextVideo(
  id = "",
  videos = [],
  isFinished = false,
  classesFinished
) {
  const { mutate: resetCourseProgress } = useResetCourseProgress({
    courseId: id,
  });

  const hasUnwatched = !!videos.some((video) => !video.isFinished);
  const firstUnwatchedVideo = videos.filter((video) => !video.isFinished)?.[0];
  const firstWatchedVideo = videos.filter((video) => video.isFinished)?.[0];
  const hasVideoInProgress = videos.some(
    (video) => video.progress !== 0 && typeof video.progress === "number"
  );
  const availableVideos = videos.filter(
    (video) => video.status === "published"
  ).length;

  const playButtonText = () => {
    if (
      !hasUnwatched ||
      (isFinished && !hasVideoInProgress && !firstUnwatchedVideo) ||
      classesFinished === availableVideos
    ) {
      return strings.courseScreen.watchAgainButton;
    } else if ((firstWatchedVideo && hasUnwatched) || hasVideoInProgress) {
      return strings.courseScreen.continueWatchingButton;
    } else {
      return strings.courseScreen.watchButton;
    }
  };

  const nextVideo = () => {
    const firstUnwatchedVideoIndex = videos.findIndex(
      (nextVideo) => nextVideo.id === firstUnwatchedVideo?.id
    );

    // caso existam vídeos a serem liberados e o usuário já assistiu todos disponíveis, irá abrir o primeiro vídeo sem resetar o progresso
    if (classesFinished === availableVideos) {
      return videos[0];
    }

    // caso não tenha um vídeo não assistido, irá abrir o primeiro vídeo e resetar o progresso do curso ("Assistir novamente")
    if (firstUnwatchedVideoIndex === -1) {
      resetCourseProgress();
      return videos[0];
    }

    return videos[firstUnwatchedVideoIndex];
  };

  return { nextVideo, playButtonText };
}
