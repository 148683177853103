import styled, { css } from "styled-components";

export const Paragraph = styled.p`
  ${({ theme }) => css`
    margin-bottom: 0;
    color: ${theme.lightTheme.colors.text};
    font-size: ${theme.font.size.default}
    font-weight: ${theme.font.weight.default};
  `}
`;
