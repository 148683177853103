import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1080px;
  height: 236px;
  margin: 0 -0.7rem;
  background-image: url(${(props) => props.backgroundImage});
  background-position: center;
  background-size: cover;

  a {
    max-width: 140px;
  }

  @media (min-width: 768px) {
    margin: 0 auto;
  }
`;

export const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(32, 30, 32, 0.9);
  padding-bottom: 1em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
`;

export const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 300px;

  margin-bottom: 1.5em;
`;

export const BannerTitle = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`;

export const BannerText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 140%;
`;

export const RenewButton = styled.button`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #100f10;
  background: #cff16b;
  border-radius: 5px;
  width: 140px;
  height: 35px;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  outline: none;
  border: none;

  :hover {
    background: #fff;
  }
`;
export const RenewLink = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #100f10;
  background: #cff16b;
  border-radius: 5px;
  width: 140px;
  height: 35px;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  outline: none;
  border: none;

  :hover {
    background: #fff;
  }
`;
