import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";

import {
  addOneYearAndFormat,
  formatDiscountString,
  formattedPrice,
} from "../../util/Util";
import { getAlertMessageFromState } from "../../util/messages";
import { calcPriceAfterDiscount } from "../../util/checkout";

import { useCheckoutContext } from "../../context/CheckoutContext";
import {
  useExtendsSubscription,
  useGetGatewayData,
  usePromotionalCampaign,
  useUser,
} from "../../hooks";

import {
  Container,
  Button,
  Alert,
  LoadingSpinner,
  Modal,
  Title,
  Text,
} from "../../components";
import { AccountSectionLine } from "../../containers";

import { ReactComponent as QuestionMark } from "../../assets/icons/question-mark-rounded.svg";
import * as S from "./styles";

const SubscriptionExtension = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { plan } = useCheckoutContext();

  const [alert, setAlert] = useState({});
  const [showModal, setShowModal] = useState(false);

  const {
    data: { subscriptionDetails },
    isLoading: isSubscriptionLoading,
  } = useUser();

  const { data: campaign, isLoading: isCampaignLoading } =
    usePromotionalCampaign();

  const { mutate: sendRequest, isLoading: isPurchaseLoading } =
    useExtendsSubscription({
      onSuccess: () => {
        queryClient.resetQueries("user-details");
        queryClient.resetQueries("get-gateway-data");
        queryClient.resetQueries("promotional-campaign");

        return navigate("/minha-conta", {
          state: { processingExtensionPayment: true },
          replace: true,
        });
      },
      onError: (error) => {
        const { status } = error?.response?.data;

        setAlert({
          message: `Houve um problema ao efetivar a compra da sua extensão de assinatura. Por favor, tente novamente. Se o erro persistir, entre em contato com o suporte. (Cód. ${status})`,
          variant: "error",
        });
      },
    });

  const { data: gatewayData, isLoading: isBillsLoading } = useGetGatewayData({
    reference: subscriptionDetails?.reference,
  });

  useEffect(() => {
    if (!campaign && !isCampaignLoading)
      navigate("/minha-conta/assinatura", { replace: true });
  }, [campaign]);

  useEffect(() => {
    const extensionRequestedIsPending =
      subscriptionDetails?.extensionStatus === "processing";

    const extensionSuccess = subscriptionDetails?.extensionStatus === "paid";

    if (extensionRequestedIsPending)
      navigate("/minha-conta", {
        state: { processingExtensionPayment: true },
        replace: true,
      });
    if (extensionSuccess) navigate("/minha-conta", { replace: true });
  }, [subscriptionDetails]);

  useEffect(() => {
    const { message, variant } = getAlertMessageFromState(location);
    if (message) {
      setAlert({ message, variant });
      return window.history.replaceState({}, document.title);
    }
  }, [location]);

  if (!subscriptionDetails || isSubscriptionLoading || isBillsLoading) {
    return <LoadingSpinner color="light" />;
  }

  const { endAt: remainingAccess } = subscriptionDetails;

  const isSinglePayment = gatewayData.installments === 1;
  const priceAfterDiscount = calcPriceAfterDiscount(
    plan.originalPrice,
    campaign.coupon.discountValue,
    campaign.coupon.discountType
  );
  const discountString = formatDiscountString(
    campaign.coupon.discountValue,
    campaign.coupon.discountType
  );
  const formattedExtendedDate = addOneYearAndFormat(remainingAccess);
  const installmentValue = priceAfterDiscount / gatewayData.installments;
  const paymentString = isSinglePayment
    ? formattedPrice(installmentValue)
    : `${gatewayData.installments}x de R$ ${formattedPrice(installmentValue)}`;
  const cardString = `${gatewayData.cardBrand}: **** ${gatewayData.cardFourDigits}`;

  const modalInstallmentsText = isSinglePayment
    ? "1 parcela"
    : `${gatewayData.installments} parcelas`;

  return (
    <Container type={"minimal"} pageName="Estender assinatura">
      <S.Wrapper>
        {!!alert.message && (
          <Alert text={alert.message} variant={alert.variant} />
        )}

        <section>
          <Title htmlTag="h1" style={{ marginTop: "30px" }}>
            Mais um ano com {discountString} de desconto.
          </Title>
          <Text style={{ color: "#ACA9B3" }}>
            A cobrança será feita agora, mas será adicionado um ano extra após a
            vigência da sua assinatura.
          </Text>
        </section>

        <section>
          <AccountSectionLine bottomLine>
            <Text>RESUMO DO PAGAMENTO</Text>
          </AccountSectionLine>

          <section>
            <AccountSectionLine bottomLine>
              <Text>{cardString}</Text>
              <Button
                variant={"transparent"}
                size={"small"}
                id={"btn-account-alterar_pagamento"}
                name={"btn-account-alterar_pagamento"}
                onClick={() => navigate("/minha-conta/alterar-pagamento?exp=1")}
              >
                ALTERAR
              </Button>
            </AccountSectionLine>

            <AccountSectionLine bottomLine>
              <div>
                <Text style={{ fontWeight: "700" }}>
                  Vigência + 1 ano extra
                </Text>
                <Text>Assinatura estendida até {formattedExtendedDate}</Text>
              </div>
            </AccountSectionLine>

            {!isSinglePayment && (
              <AccountSectionLine bottomLine>
                <Text>Valor: {formattedPrice(priceAfterDiscount)}</Text>
              </AccountSectionLine>
            )}

            <AccountSectionLine>
              <Text>Total a pagar: {paymentString}</Text>
              <QuestionMark
                className="question-mark"
                role="button"
                onClick={() => setShowModal(true)}
              />
            </AccountSectionLine>
          </section>
        </section>

        <Button
          variant={"white"}
          size={"full"}
          id={"btn-subscription_extension-comprar_agora"}
          name={"btn-subscription_extension-comprar_agora"}
          onClick={() =>
            sendRequest({
              subscriptionReference: subscriptionDetails.reference,
              couponId: campaign.coupon.couponId,
            })
          }
          disabled={isPurchaseLoading}
        >
          {isPurchaseLoading ? (
            <Spinner color="dark" size="sm" style={{ margin: "0 auto" }} />
          ) : (
            "COMPRAR AGORA"
          )}
        </Button>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Text>Tire suas dúvidas</Text>
          <QuestionMark
            className="question-mark"
            role="button"
            onClick={() => navigate("entenda")}
          />
        </div>

        <S.Disclaimer>
          Após a contratação, você poderá cancelar apenas a renovação da
          assinatura. Seu acesso manterá até o término do período do plano, e as
          parcelas restantes continuarão a ser cobradas.
        </S.Disclaimer>
      </S.Wrapper>

      {!!showModal && (
        <Modal.Root showModal={true} closeModal={() => setShowModal(false)}>
          <Modal.Body>
            <Modal.Paragraph>
              Como você escolheu pagar sua assinatura em {modalInstallmentsText}
              , a extensão do seu plano seguirá o mesmo método de pagamento.
              Importante destacar que a cobrança adicional será feita
              imediatamente.
            </Modal.Paragraph>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={"black"}
              size={"full"}
              id={"btn-subscription_extension_modal-entendi"}
              name={"btn-subscription_extension_modal-entendi"}
              onClick={() => setShowModal(false)}
            >
              Entendi
            </Button>
          </Modal.Footer>
        </Modal.Root>
      )}
    </Container>
  );
};

export default SubscriptionExtension;
