import React from "react";
import * as S from "./styles.js";

const CourseButton = ({
  children,
  text,
  onClick = undefined,
  id = undefined,
  name = undefined,
  disabled = false,
  variant,
  size,
  type = undefined,
  icon,
}) => {
  const buttonColor = {
    green: "green",
    white: "white",
    transparent: "transparent",
    default: "transparent",
  };

  const buttonSize = {
    small: "small",
    full: "full",
    default: "",
  };

  const defineIcon = (icon) => {
    const iconTypes = {
      locked: <i className="fas fa-lock-alt" />,
      default: <img src={icon} alt="ícone" />,
    };

    return iconTypes[icon] || iconTypes["default"];
  };
  return (
    <S.Button
      onClick={onClick}
      id={id}
      name={name}
      type={type}
      disabled={disabled}
      variant={buttonColor[variant] || buttonColor["default"]}
      size={buttonSize[size] || buttonSize["default"]}
    >
      {!!icon && defineIcon(icon)}
      {children}
      <span>{text}</span>
    </S.Button>
  );
};

export default CourseButton;
