import React, { useState } from "react";
import FAQ from "./FAQ";
import * as S from "./styles";

export const Accordion = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "Como funciona o cancelamento",
      answer:
        '<p>Até 7 dias, você pode cancelar a sua assinatura e receber de volta o valor integral. Após esse período, só é possível cancelar a renovação de sua assinatura*.</p><p>Para cancelar, navegue até <strong>Minha Conta</strong> > <strong>Minha Assinatura</strong> > <strong>Detalhes da assinatura</strong> e clique em <strong>Cancelar assinatura*</strong>.</p><p class="disclaimer">*Caso você tenha parcelado o valor da assinatura, o restante das parcelas será cobrado normalmente.</p>',
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <S.Wrapper className="faqs">
      {faqs.map((faq, i) => (
        <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} key={i} />
      ))}
    </S.Wrapper>
  );
};

export default Accordion;
