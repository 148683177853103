import React from "react";
import { Link, useParams } from "react-router-dom";

import { useAuthorDetails } from "../../hooks";
import { Carousel, Container, LoadingSpinner } from "../../components";

import * as S from "./styles";

const ProfessorProfile = () => {
  const { id } = useParams();

  const { data: author, isFetching } = useAuthorDetails(id);

  if (isFetching) return <LoadingSpinner color="light" />;

  const flatAndFilterCategoriesFromCourses = () => {
    if (!author.courses) return null;

    const flattenedCategories = author.courses.flatMap(
      (course) => course.categories
    );

    const categoriesArray = [
      ...new Map(flattenedCategories.map((v) => [v.slug, v])).values(),
    ];

    return categoriesArray;
  };

  const categoriesArray = flatAndFilterCategoriesFromCourses();
  const renderCategories = categoriesArray?.map((category) => {
    return (
      <Link to={`/categoria/${category.slug}`} key={category.slug}>
        {category.title}
      </Link>
    );
  });

  return (
    <>
      <Container type={"clean"}>
        <S.TeacherPicture>
          {author.images.thumbUrl && (
            <>
              <img src={author.images.thumbUrl} alt="professor-avatar" />
              <div className="overlay" />
            </>
          )}
        </S.TeacherPicture>
        <S.Wrapper>
          <div className="block1">
            <S.SubTitle>{author.name}</S.SubTitle>
            <S.TagsContainer>{renderCategories}</S.TagsContainer>
            <S.TeacherInfo>{author.description}</S.TeacherInfo>
          </div>
          {author.courses.length > 0 && (
            <div className="block2">
              <S.SubTitle bold>Cursos</S.SubTitle>
              <Carousel title="" items={author.courses} maxWidth={420} />
            </div>
          )}
        </S.Wrapper>
      </Container>
    </>
  );
};

export default ProfessorProfile;
