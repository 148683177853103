import styled from "styled-components";

export const Wrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: ${(props) => props.theme.lightTheme.colors.background};
  color: ${(props) => props.theme.lightTheme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 86px;
`;

export const CheckoutContainer = styled.div`
  max-width: 414px;
  margin: 0 auto;
  padding: 16px;
`;

export const CardContainer = styled.div`
  margin: 8px auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  input {
    background-color: ${(props) =>
      props.theme.lightTheme.colors.inputBackground};
    color: ${(props) => props.theme.lightTheme.colors.text};
    border-radius: 5px;
    border: none;
    padding: 1.5rem 0.5rem 0.3rem;
    width: 100%;
    height: 50px;
    display: block;
    font-size: 16px;

    :disabled {
      opacity: 0.6;
    }

    text-transform: ${(props) => (props.email ? "lowercase" : "none")};
  }

  .one-column {
    width: 100%;
  }

  .two-column {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
  }

  .one-half {
    width: 50%;
  }

  label {
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 130%;
    color: ${(props) => props.theme.lightTheme.colors.text};
    display: flex;
    align-items: center;
    position: relative;
    top: 14px;
    left: 8px;
    height: 0px;
    width: 100%;
  }

  button {
    background-color: ${(props) =>
      props.theme.lightTheme.colors.buttonBackground};

    :hover {
      color: ${(props) => props.theme.lightTheme.colors.text};
    }
  }
  button:disabled {
    background-color: ${(props) =>
      props.theme.lightTheme.colors.disabledBackground};
    color: ${(props) => props.theme.lightTheme.colors.disabledText};

    :hover {
      border: none;
    }
  }
`;

export const Select = styled.select`
  background-color: ${(props) => props.theme.lightTheme.colors.inputBackground};
  color: ${(props) => props.theme.lightTheme.colors.text};
  border-radius: 5px;
  border: none;
  padding: 1.5rem 0.5rem 0.3rem;
  width: 100%;
  height: 50px;
  display: block;
  font-size: 16px;

  :disabled {
    opacity: 0.6;
  }
`;

export const OrderSummary = styled.div`
  color: ${(props) => props.theme.lightTheme.colors.text};
  font-size: 14px;
  text-align: left;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.675em;

  hr {
    margin: 0; //reset
  }

  p {
    margin-bottom: 0; //reset margin
  }

  a {
    color: ${(props) => props.theme.lightTheme.colors.text};
    font-weight: 800;
    text-decoration: underline;
    text-underline-offset: 1px;

    :hover {
      color: ${(props) => props.theme.lightTheme.colors.text};
    }
  }
`;

export const OrderInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 100;

  span.text {
    color: ${(props) => props.theme.darkTheme.colors.darkGray};
  }

  span.text.final-price {
    font-size: 1rem;
  }

  span.discount {
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    padding: 3px;
    margin-left: 0.5rem;
    background-color: ${(props) => props.theme.darkTheme.colors.green};
    border-radius: 3px;
  }

  span.price {
    text-align: end;
  }
`;

export const Errors = styled.div`
  padding: 15px;
  border-radius: 5px;
  margin-top: -25px;
  border: 1.5px solid #ff7651;
  margin-bottom: 0.7rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  span {
    color: ${(props) => props.theme.lightTheme.colors.text};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    display: inline;
    align-items: center;
  }
  svg {
    color: #ff7651;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  a {
    color: ${(props) => props.theme.lightTheme.colors.text};
    text-decoration: underline;
  }
`;
