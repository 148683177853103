import axios from "axios";
import { VERSION, CDS_API_VERSION } from "../util/settings";
import { interceptRequest } from "./apiInterceptor";
window.Buffer = window.Buffer || require("buffer").Buffer;

/**
 * The base api urls.
 */
export const baseUrl = process.env.REACT_APP_API_CASA_DO_SABER;
export const basePHPUrl = `${process.env.REACT_APP_API_URL}/${VERSION}`;
export const baseSambaUrl = process.env.REACT_APP_API_CARD_TOKEN;
export const baseMundipaggUrl = process.env.REACT_APP_SAMBAPAY_URL;
export const baseCDSApiUrl = `${process.env.REACT_APP_CDS_API_URL}/${CDS_API_VERSION}`;
export const baseVindiUrl = process.env.REACT_APP_API_VINDI_CARD_TOKEN;
export const baseMagicLinkLambdaUrl =
  process.env.REACT_APP_MAGIC_LINK_LAMBDA_URL;
/**
 * Axios instances
 */
const api = axios.create({
  baseURL: basePHPUrl,
  timeout: 10000,
});

export const apiCDS = axios.create({
  baseURL: baseCDSApiUrl,
  timeout: 10000,
});

export const apiCasaDoSaber = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
});

export const apiCardToken = axios.create({
  baseURL: baseSambaUrl,
  timeout: 10000,
});

export const apiMundipagg = axios.create({
  baseURL: baseMundipaggUrl,
  timeout: 10000,
});

export const apiVindiCardToken = axios.create({
  baseURL: baseVindiUrl,
  timeout: 10000,
  headers: {
    Authorization: `Basic ${Buffer.from(
      process.env.REACT_APP_VINDI_PUBLIC_KEY
    ).toString("Base64")}`,
  },
});

export const apiMagicLink = axios.create({
  baseURL: process.env.REACT_APP_MAGIC_LINK_LAMBDA_URL,
});

export const apiLambdaIntegration = axios.create({
  baseURL: process.env.REACT_APP_LAMBDA_INTEGRATION_URL,
});

export const setApiToken = (jwtToken) => {
  api.defaults.headers.common["authorization"] = jwtToken;
  apiCDS.defaults.headers.common["accessToken"] = jwtToken;
  apiLambdaIntegration.defaults.headers.common["authorization"] = jwtToken;
};

interceptRequest(apiCDS, setApiToken);

export default api;
