import React, { useEffect } from "react";
import { useCheckoutContext } from "../../context/CheckoutContext";
import { usePromotionalCampaign } from "../../hooks";
import {
  SignupHeader,
  CheckoutFooter,
  StepperCustom,
  GiftPlans,
  GiftPlansBestFriday,
} from "../../components";

import * as S from "./styles";

const GiftCheckoutPlans = () => {
  const { currentStep, giftSteps, changeStep } = useCheckoutContext();
  const { data: campaign } = usePromotionalCampaign();

  useEffect(() => {
    changeStep("entenda");
  }, []);

  return (
    <S.Wrapper>
      <SignupHeader />
      <StepperCustom steps={giftSteps} currentStep={currentStep} />
      {/* BEST FRIDAY */}
      {!!campaign ? <GiftPlansBestFriday /> : <GiftPlans />}
      <CheckoutFooter />
    </S.Wrapper>
  );
};

export default GiftCheckoutPlans;
