import styled, { css } from "styled-components";
import {
  InputTextFieldContainer,
  ErrorMessage,
  InputContainer,
} from "../Input/styles";

export const InputIconContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translate(0, -50%);
  `}
`;

export const IconButtonContainer = styled.div`
  ${({ theme }) => css`
    background-color: transparent;
    border: none;
    color: ${theme.darkTheme.colors.gray};
    transition: color 100ms ease;
    cursor: pointer;

    :hover {
      color: ${theme.darkTheme.colors.green};
    }
  `}
`;

export { InputTextFieldContainer, ErrorMessage, InputContainer };
