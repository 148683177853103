import "react-credit-cards/es/styles-compiled.css";

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  ModalBody,
  Button as ReactstrapButton,
  ModalFooter,
} from "reactstrap";
import { FiAlertTriangle } from "react-icons/fi";
import MaskedInput from "react-input-mask";
import { Input, Spinner } from "reactstrap";
import Card from "react-credit-cards";

import { useActiveCampaignContact, useUser } from "../../hooks";
import { changeCardOnVindi } from "../../services/checkout";
import { getCardIssuerCode } from "../../util/checkout";

import { Button, Container } from "../../components";

import strings from "../../config/strings.json";
import * as S from "./styles";

const UpdatePaymentMethod = () => {
  const location = useLocation();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [holderName, setHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [cardExpiration, setCardExpiration] = useState("");
  const [cardCvc, setCardCvc] = useState("");
  const [identity, setIdentity] = useState("");
  const [focus, setFocus] = useState("");
  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState(false);
  const [message, setMessage] = useState("");

  const {
    data: { subscriptionDetails, userData },
  } = useUser();

  const { mutate: sendToActiveCampaign } = useActiveCampaignContact();

  const navigate = useNavigate();

  const closeModal = () => {
    setModal(false);
  };

  async function handleChangeCard(e) {
    e.preventDefault();
    setErrors(false);

    const inputs = [
      {
        value: cardNumber,
        name: "Número do cartão",
      },
      {
        value: holderName,
        name: "Nome e sobrenome",
      },
      {
        value: cardExpiration,
        name: "Data de vencimento",
      },
      {
        value: cardCvc,
        name: "Código de segurança",
      },
      {
        value: identity,
        name: "CPF",
      },
    ];

    const emptyField = inputs.find(
      (input) => input.value === undefined || !input.value
    );

    if (emptyField) {
      setMessage(
        `Para continuar, por favor, preencha corretamente o campo ${emptyField.name}.`
      );
      return setErrors(true);
    }

    setModal(true);
  }

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleCardBrand = (e) => {
    if (e.issuer !== cardBrand) {
      setCardBrand(e.issuer);
    }
  };

  const handleConfirmChangeCard = async () => {
    // Vindi update
    const body = {
      holderName: holderName,
      cardExpiration: cardExpiration,
      cardNumber: cardNumber,
      cardCvv: cardCvc,
      paymentMethodCode: "credit_card",
      paymentCompanyCode: getCardIssuerCode(cardBrand),
      subscriptionReference: subscriptionDetails.reference,
    };

    const { success, response } = await changeCardOnVindi(body);

    if (!success) {
      setErrors(true);
      setMessage(response);
      setModal(false);
      return setButtonLoading(false);
    }

    setModal(false);
    setButtonLoading(false);
    window.localStorage.setItem("dismiss-banner-informative", "true");
    sendToActiveCampaign({
      email: userData.email,
      customFields: [
        {
          field: "96",
          value: "28/" + cardExpiration,
        },
      ],
    });

    // SE FLUXO DE EXTENSÃO
    if (location.search?.includes("exp=1"))
      return navigate("/minha-conta/estender-assinatura", {
        state: { updatedExtensionPaymentMethod: true },
        replace: true,
      });

    // SE FLUXO MINHA CONTA
    return navigate("/minha-conta/assinatura", {
      state: { updatedPaymentMethod: true },
      replace: true,
    });
  };

  return (
    <Container type={"minimal"} pageName="Alterar dados de pagamento">
      <S.FormWrapper>
        {!!errors && (
          <S.Errors>
            <FiAlertTriangle /> <span>{message}</span>{" "}
          </S.Errors>
        )}

        <S.CardContainer>
          <Card
            number={cardNumber}
            name={holderName ? holderName : "NOME E SOBRENOME"}
            expiry={cardExpiration}
            cvc={cardCvc}
            focused={focus}
            callback={(e) => handleCardBrand(e)}
          />
        </S.CardContainer>

        <S.FormInputWithLabel>
          <label htmlFor={"number"}>Número do cartão</label>
          <MaskedInput
            id={"number"}
            name={"number"}
            value={cardNumber}
            mask={"9999 9999 9999 9999"}
            maskChar=""
            className="form-control"
            onChange={(e) => setCardNumber(e.target.value)}
            onFocus={(e) => handleInputFocus(e)}
          />
        </S.FormInputWithLabel>
        <S.FormInputWithLabel>
          <label htmlFor={"name"}>Nome e sobrenome</label>
          <Input
            value={holderName}
            id={"name"}
            name="name"
            onChange={(e) =>
              setHolderName(
                e.target.value.replace(/[^a-zA-Z ]/g, "").toUpperCase()
              )
            }
            onFocus={(e) => handleInputFocus(e)}
          />
        </S.FormInputWithLabel>
        <S.FormInputWithLabel>
          <label htmlFor={"card_expiration"}>Data de vencimento</label>
          <MaskedInput
            id={"card_expiration"}
            name={"card_expiration"}
            value={cardExpiration}
            onChange={(e) => setCardExpiration(e.target.value)}
            onFocus={(e) => handleInputFocus(e)}
            mask="99/99"
            maskChar=""
            className="form-control"
          />
        </S.FormInputWithLabel>
        <S.FormInputWithLabel>
          <label htmlFor={"card_cvc"}>Código de segurança</label>

          <MaskedInput
            id="card_cvc"
            name="cvc"
            value={cardCvc}
            mask={"9999"}
            maskChar=""
            className="form-control"
            onChange={(e) => setCardCvc(e.target.value)}
            onFocus={(e) => handleInputFocus(e)}
          />
        </S.FormInputWithLabel>
        <S.FormInputWithLabel>
          <label htmlFor={"identity"}>CPF do titular</label>
          <MaskedInput
            id="identity"
            name="identity"
            value={identity}
            onChange={(e) => setIdentity(e.target.value)}
            maskChar=""
            mask="999.999.999-99"
            className="form-control"
          />
        </S.FormInputWithLabel>

        <S.Disclaimer>
          {subscriptionDetails?.origin === "vindi"
            ? strings.updatePaymentMethod.disclaimer.vindi
            : strings.updatePaymentMethod.disclaimer.default}
        </S.Disclaimer>

        <Button
          variant={"white"}
          size={"full"}
          onClick={(e) => handleChangeCard(e)}
          id={"btn-change_payment-salvar"}
          name={"btn-change_payment-salvar"}
        >
          {buttonLoading ? <Spinner color="light" /> : "Salvar"}
        </Button>

        <Button
          variant={"transparent"}
          size={"full"}
          onClick={(e) => navigate(-1)}
          id={"btn-change_payment-cancelar"}
          name={"btn-change_payment-cancelar"}
        >
          Cancelar
        </Button>

        <S.StyledModal
          centered={true}
          modalClassName="modal-change-card"
          contentClassName="content-modal-change-card"
          isOpen={modal}
          toggle={closeModal}
        >
          <ModalBody>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <div className="text-confirm-change-card">
                      Tem certeza que deseja alterar os dados de pagamento?
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <ReactstrapButton
              className="confirm-change"
              onClick={(e) => handleConfirmChangeCard(e)}
              id={"btn-change_payment_modal-sim"}
              name={"btn-change_payment_modal-sim"}
            >
              Sim
            </ReactstrapButton>
            <ReactstrapButton
              className="cancel-change"
              onClick={(e) => navigate(-1)}
              id={"btn-change_payment_modal-cancelar"}
              name={"btn-change_payment_modal-cancelar"}
            >
              Cancelar
            </ReactstrapButton>
          </ModalFooter>
        </S.StyledModal>
      </S.FormWrapper>
    </Container>
  );
};

export default UpdatePaymentMethod;
