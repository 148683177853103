import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Wrapper = styled.div`
  margin: 0 auto 1rem;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  //AccountFooter spacing
  position: relative;
  padding-bottom: 115px;
`;

export const Badge = styled.div`
  display: inline;
  margin: 6px;
  padding: 2px 6px;
  border-radius: 50px;
  color: white;
  background-color: #126cff;
  text-transform: uppercase;
`;

export const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(32, 30, 32, 0.9);
  padding-bottom: 1em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
`;

export const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 400px;

  margin-bottom: 1.5em;
`;

export const BannerTitle = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`;

export const BannerText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 140%;
`;

export const RenewButton = styled.button`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #100f10;
  background: #cff16b;
  border-radius: 5px;
  width: 140px;
  height: 35px;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  outline: none;
  border: none;

  :hover {
    background: #fff;
  }
`;

export const LinkAsButton = styled.a`
  ${({ theme }) => css`
    width: 125px;
    padding: 6px 4px 4px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    color: #cccccc;
    background-color: transparent;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.1s ease-in;

    :hover {
      color: ${theme.darkTheme.colors.green};
      border: 2px solid ${theme.darkTheme.colors.green};
    }

    @media (${device.smallPhone}) {
      min-width: 125px;
      white-space: nowrap;
    }
  `}
`;
