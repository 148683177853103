import GiftCheckout from "../pages/GiftCheckout";
import GiftCheckoutPlans from "../pages/GiftCheckoutPlans";
import GiftCheckoutPayment from "../pages/GiftCheckoutPayment";
import GiftCheckoutProcessing from "../pages/GiftCheckoutProcessing";
import GiftCheckoutFailed from "../pages/GiftCheckoutFailed";
import GiftCheckoutSuccess from "../pages/GiftCheckoutSuccess";
import GiftCheckoutSendEmail from "../pages/GiftCheckoutSendEmail";

export const giftRoutes = [
  {
    path: "/presente",
    children: [
      { index: true, element: <GiftCheckout /> },
      { path: "planos", element: <GiftCheckoutPlans /> },
      { path: "pagamento", element: <GiftCheckoutPayment /> },
      { path: "processando", element: <GiftCheckoutProcessing /> },
      { path: "vale-presente", element: <GiftCheckoutSuccess /> },
      { path: "enviar-email", element: <GiftCheckoutSendEmail /> },
      { path: "falha-na-compra", element: <GiftCheckoutFailed /> },
    ],
  },
];
