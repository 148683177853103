import React from "react";
import Text from "../Styles/Text";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import CTAButton from "../CTAButton";

const Error404 = ({ message, ...props }) => {
  let navigate = useNavigate();
  return (
    <S.Wrapper>
      <S.Title>Ops!</S.Title>
      <Text
        className="error-description"
        html={message || "Página não encontrada."}
      />
      <CTAButton
        text="Volte a navegação"
        onClick={() => navigate("/")}
        id={"btn-error_404-volte_a_navegacao"}
        name={"btn-error_404-volte_a_navegacao"}
      />
    </S.Wrapper>
  );
};

export default Error404;
