import React, { useCallback, useEffect } from "react";
import _ from "lodash";
import { useSearchContext } from "../../context/SearchContext";
import GoBackButton from "../../components/GoBackButton";
import SearchInput from "../../components/SearchComponents/SearchInput";
import SearchResultsModule from "../../components/SearchComponents/SearchResultsModule";
import * as S from "./styles";
import SearchEmptyResults from "../../components/SearchComponents/SearchEmptyResults";
import { LoadingSpinner } from "../../components";

const Search = () => {
  const {
    loading,
    categories,
    userQuery,
    foldersFound,
    categoriesFound,
    updateQuery,
  } = useSearchContext();

  const delayedQuery = useCallback(_.debounce(updateQuery, 800), [userQuery]);

  useEffect(() => {
    delayedQuery();
    return delayedQuery.cancel;
  }, [userQuery, delayedQuery]);

  const hasCoursesFound = foldersFound && foldersFound.length > 0;
  const hasCategoriesFound = categoriesFound && categoriesFound.length > 0;
  const insufficientInput = userQuery.length >= 0 && userQuery.length <= 2;

  return (
    <S.Wrapper>
      <S.HeaderContainer>
        <GoBackButton />
        <SearchInput />
      </S.HeaderContainer>

      <S.ResultsContainer>
        {loading ? (
          <LoadingSpinner color={"light"} />
        ) : (
          <>
            {insufficientInput ? (
              <SearchResultsModule
                title={"CATEGORIAS"}
                type={"category"}
                renderArray={categories}
              />
            ) : (
              <>
                {hasCoursesFound && (
                  <SearchResultsModule
                    title={"CURSOS ENCONTRADOS"}
                    type={"course"}
                    renderArray={foldersFound}
                  />
                )}

                {hasCategoriesFound && (
                  <SearchResultsModule
                    title={"CATEGORIAS ENCONTRADAS"}
                    type={"category"}
                    renderArray={categoriesFound}
                  />
                )}

                {!hasCategoriesFound && !hasCoursesFound && (
                  <SearchEmptyResults />
                )}
              </>
            )}
          </>
        )}
      </S.ResultsContainer>
    </S.Wrapper>
  );
};

export default Search;
