import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 -0.7rem; //because of Container margin
    padding: ${theme.spacings.smaller};
    position: fixed;
    bottom: 0;
    width: 100vw;
    max-width: 1080px;
    height: auto;
    line-height: ${theme.spacings.small};
    background-color: ${theme.darkTheme.colors.background};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const FooterText = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family.maisonNeue};
    font-size: ${theme.font.size.smallest};
    font-weight: ${theme.font.weight.light};
    a {
      text-decoration: underline;
      color: #ffff;
    }

    @media (min-width: ${theme.breakPoint.tablet}) {
      font-size: ${theme.font.size.small};
    }
  `}
`;
