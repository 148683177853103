import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import { useCopyToClipboard } from "react-use";
import { Spinner } from "reactstrap";
import ReactSlickSlider from "react-slick";
import { ModalBody, ModalHeader } from "reactstrap";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";

import { useUser } from "../../hooks";
import strings from "../../config/strings.json";
import { Container, Button } from "../../components";

import emptyStateImg from "../../assets/images/retro-empty-state.png";
import * as S from "./styles";

export const api = axios.create({
  baseURL: process.env.REACT_APP_RETROSPECTIVE_API_URL,
  timeout: 10000,
});

const sliderConfig = {
  arrows: true,
  dots: true,
  autoplay: false,
};

async function getUser(userId) {
  const { data } = await api.get(`/users/${userId}`);
  return data;
}

const Retrospective = () => {
  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [{ value: isCopied }, copyToClipboard] = useCopyToClipboard();

  const {
    data: { userData },
  } = useUser();

  const userId = userData?.id;

  const { data, isLoading, isError } = useQuery(
    ["retrospective", userId],
    ({ queryKey }) => {
      const [, userId] = queryKey;
      return getUser(userId);
    },
    {
      enabled: !!userId,
      refetchOnWindowFocus: false,
    }
  );

  const images = useMemo(() => {
    if (!data?.links) return [];
    return Object.values(data.links);
  }, [data]);

  function onToggleShareModal() {
    setShareModalVisible((state) => !state);
    copyToClipboard(undefined);
  }

  return (
    <Container type={"clean"}>
      {!isLoading && isError && (
        <S.EmptyState
          style={{
            backgroundImage: `url(${emptyStateImg})`,
          }}
        >
          <Link to={"/"}>
            <S.BackButton>Assista um curso</S.BackButton>
          </Link>
        </S.EmptyState>
      )}

      {isLoading && !isError && (
        <S.LoadingContainer>
          <Spinner color="dark" />
          <p className="text-center mt-2">
            Aguarde enquanto carregamos sua retrospectiva
          </p>
        </S.LoadingContainer>
      )}

      {!isLoading && !isError && Boolean(images.length) && (
        <S.SliderContainer>
          <ReactSlickSlider
            initialSlide={0}
            afterChange={setCurrentSlide}
            {...sliderConfig}
          >
            {images.map((imageUrl, index) => (
              <S.SlideContainer key={`slide-${index}`}>
                <img
                  src={imageUrl}
                  alt={`Retrospectiva - Imagem ${index + 1}`}
                />
              </S.SlideContainer>
            ))}
          </ReactSlickSlider>
          <S.ShareButtonContainer>
            <Button
              type="button"
              variant="green"
              size="full"
              onClick={() => setShareModalVisible(true)}
            >
              Compartilhar
            </Button>
          </S.ShareButtonContainer>
        </S.SliderContainer>
      )}

      <S.Modal
        isOpen={isShareModalVisible}
        toggle={onToggleShareModal}
        backdrop={true}
        fade={false}
        centered
      >
        <ModalHeader toggle={onToggleShareModal}>
          Compartilhe sua Retrospectiva 2022
        </ModalHeader>
        <ModalBody>
          <S.ModalShareButtons>
            <FacebookShareButton
              url={images[currentSlide]}
              quote={strings.retrospective.shareTitle}
            >
              <FacebookIcon size={45} round />
            </FacebookShareButton>
            <WhatsappShareButton
              url={images[currentSlide]}
              title={strings.retrospective.shareTitle}
            >
              <WhatsappIcon size={45} round />
            </WhatsappShareButton>
            <TwitterShareButton
              url={images[currentSlide]}
              title={strings.retrospective.shareTitle}
            >
              <TwitterIcon size={45} round />
            </TwitterShareButton>
          </S.ModalShareButtons>
          <S.ModalCopyContainer>
            <S.ModalCopyUrl>{images[currentSlide]}</S.ModalCopyUrl>
            <S.ModalCopyButton
              onClick={() => {
                copyToClipboard(images[currentSlide]);
              }}
            >
              {isCopied ? "Copiado" : "Copiar"}
            </S.ModalCopyButton>
          </S.ModalCopyContainer>
        </ModalBody>
      </S.Modal>
    </Container>
  );
};

export default Retrospective;
