import React from "react";
import { support } from "../../data/support";
import * as S from "./styles";

const CertificateFooter = (props) => {
  const mail = (
    <a
      href={support.mail.href}
      target={support.mail.target}
      rel={support.mail.rel}
      id={"btn-certificate_footer-mail"}
      name={"btn-certificate_footer-mail"}
    >
      {support.mail.text}
    </a>
  );

  const phone = (
    <a
      href={support.whatsapp.href}
      target={support.whatsapp.target}
      rel={support.whatsapp.rel}
      id={"btn-certificate_footer-phone"}
      name={"btn-certificate_footer-phone"}
    >
      {support.whatsapp.text}
    </a>
  );

  return (
    <S.Wrapper>
      <S.FooterText>
        Para tirar dúvidas entre em contato com {mail} ou por WhatsApp no número{" "}
        {phone}, de segunda à sexta-feira das 10h às 19h.
      </S.FooterText>
    </S.Wrapper>
  );
};

export default CertificateFooter;
