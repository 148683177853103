import ModalRoot from "./ModalRoot";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import ModalTitle from "./ModalTitle";
import ModalParagraph from "./ModalParagraph";

const Modal = {
  Root: ModalRoot,
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
  Title: ModalTitle,
  Paragraph: ModalParagraph,
};

export default Modal;
