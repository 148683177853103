import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MaskedInput from "react-input-mask";
import { Spinner } from "reactstrap";
import _ from "lodash";
import { FiAlertTriangle } from "react-icons/fi";
import Datalayer from "../../services/datalayer";
import { verifyCouponCode } from "../../services/checkout";
import { useCheckoutContext } from "../../context/CheckoutContext";
import {
  Accordion,
  CheckoutDisclaimer,
  CTAButton,
  CheckoutPageTitle,
  LoadingSpinner,
} from "../../components";
import ArrowDown from "../../assets/icons/arrow-down.svg";

import strings from "../../config/strings.json";
import * as S from "./styles";
import {
  formatDiscountLabel,
  formattedDate,
  formattedPrice,
  priceStringToNumber,
} from "../../util/Util";
import { useDatalayer, useQuery, useUser } from "../../hooks";
import { requests } from "../../services/requests";

const GIFT_PLANS = {
  [process.env.REACT_APP_DB_PLAN_VINDI_GIFT_1YEAR]: {
    name: "Plano de um ano para presente",
    description: "um ano de acesso a todos os cursos",
  },
  [process.env.REACT_APP_DB_PLAN_VINDI_GIFT_2YEARS]: {
    name: "Plano de dois anos para presente",
    description: "dois anos de acesso a todos os cursos",
  },
};

export const CheckoutPlans = () => {
  const {
    plan,
    setPlan,
    resetPlan,
    couponFromParam,
    disableInCashDiscount,
    setDisableInCashDiscount,
  } = useCheckoutContext();

  const navigate = useNavigate();
  const { handleBlur } = useDatalayer();

  const [couponCode, setCouponCode] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [giftPlan, setGiftPlan] = useState();
  const query = useQuery();
  const giftCodeParam = query.get("giftCode");

  const {
    data: { userData },
  } = useUser();
  const isGiftFlow = !!giftPlan || giftCodeParam;
  const pageTitle =
    isGiftFlow && giftPlan?.planId
      ? GIFT_PLANS[giftPlan.planId].name
      : "Plano anual";

  useEffect(() => {
    if (giftCodeParam) {
      setCouponCode(giftCodeParam);
      verifyGiftCode(giftCodeParam);
    } else if (couponFromParam) {
      setCouponCode(couponFromParam);
      verifyCouponOrGift(couponFromParam).finally(() => setLoading(false));
    } else if (process.env.REACT_APP_PROMOTIONAL_COUPON_CODE) {
      verifyCouponOrGift(process.env.REACT_APP_PROMOTIONAL_COUPON_CODE);
    } else {
      setLoading(false);
    }

    Datalayer.onSelectProduct(plan.name, plan.id, plan.price);
  }, []);

  useEffect(() => {
    if (!!plan.coupon) {
      setCouponCode(plan.coupon);
      setIsOpen(true);
    }
  }, [plan.coupon]);

  const verifyCouponOrGift = async (couponCode) => {
    if (couponCode === plan.coupon) {
      setLoading(false);
      return;
    }

    if (couponCode === "") {
      resetPlan();
      setGiftPlan();
      return;
    }

    if (couponCode.length < 2) return;

    if (couponCode === process.env.REACT_APP_PROMOTIONAL_COUPON_CODE)
      return verifyPromotionalCoupon();

    const giftRegexPattern = /^PST/g;
    setErrors({});
    resetPlan();
    setGiftPlan();
    setIsButtonLoading(true);
    setButtonDisabled(true);

    if (couponCode.match(giftRegexPattern)) {
      // FLUXO PRESENTE
      verifyGiftCode(couponCode);
    } else {
      const verifyCoupon = await verifyCouponCode(
        couponCode,
        plan.price,
        plan.id
      );
      if (!verifyCoupon.success) {
        Datalayer.onCouponCallback("erro");

        setIsButtonLoading(false);
        setButtonDisabled(false);
        setDisableInCashDiscount(false);
        return setErrors({ message: verifyCoupon.response });
      }

      Datalayer.onCouponCallback("sucesso");
      setPlan({ ...plan, ...verifyCoupon.response });
      setDisableInCashDiscount(false);
    }
    setIsButtonLoading(false);
    setButtonDisabled(false);
  };

  const delayedQuery = useCallback(
    _.debounce(() => verifyCouponOrGift(couponCode), 800),
    [couponCode]
  );

  useEffect(() => {
    delayedQuery();
    return delayedQuery.cancel;
  }, [couponCode, delayedQuery]);

  const verifyGiftCode = async (giftCode) => {
    setIsOpen(true);
    try {
      const { data } = await requests.getGiftByCode(giftCode);
      if (data.billingStatus !== "paid") {
        throw Error("Invalid Status");
      }
      setGiftPlan(data);
      setButtonDisabled(false);
    } catch (e) {
      setButtonDisabled(true);
      setErrors({
        message:
          e.response?.data?.message ?? "Erro ao utilizar o vale presente",
      });
    }
    setLoading(false);
  };

  const verifyPromotionalCoupon = async () => {
    const verifyPromotionalCoupon = await verifyCouponCode(
      process.env.REACT_APP_PROMOTIONAL_COUPON_CODE,
      plan.price,
      plan.id
    );

    if (!verifyPromotionalCoupon.success) {
      setDisableInCashDiscount(false);
      return setLoading(false);
    }

    Datalayer.onCouponCallback("sucesso");
    setCouponCode(process.env.REACT_APP_PROMOTIONAL_COUPON_CODE);
    setPlan({ ...plan, ...verifyPromotionalCoupon.response });
    setDisableInCashDiscount(true);
    setErrors({});
    return setLoading(false);
  };

  const goToPayment = async () => {
    setErrors({});
    setButtonDisabled(true);

    // ENHANCED ECOMMERCE
    Datalayer.addToCart(plan.name, plan.id, plan.price, couponCode);

    navigate(`/checkout/pagamento?plan=${plan.id}`);
  };

  const createSubscriptionFromGift = async () => {
    setButtonDisabled(true);
    try {
      await requests.createSubscriptionFromGift(couponCode);
      const { challenge } = await requests.getIntegrationChallenge(
        userData.email
      );
      const emailEncrypt = btoa(userData.email);
      window.location.replace(
        `${process.env.REACT_APP_REDESIGN_URL}/entrar/integracao?challenge=${challenge}&email=${emailEncrypt}&redirect=/onboarding`
      );
    } catch (e) {
      setErrors({
        message:
          e.response?.data?.message ?? "Falha ao criar assinatura presente",
      });
    } finally {
      setButtonDisabled(false);
    }
  };

  const nextStep = async (e) => {
    e.preventDefault();
    if (buttonDisabled) return;
    setIsButtonLoading(true);

    if (isGiftFlow) {
      await createSubscriptionFromGift();
    } else if (!couponCode) {
      Datalayer.addToCart(plan.name, plan.id, plan.price, plan.coupon);
      return navigate(`/checkout/pagamento?plan=${plan.id}`);
    } else {
      goToPayment();
    }
    setIsButtonLoading(false);
  };

  const vivoPlan = async (e) => {
    e.preventDefault();
    if (buttonDisabled) return;
    Datalayer.onSelectProduct("vivo", "vivo");

    return navigate(`/checkout/vivo`);
  };

  if (loading) return <LoadingSpinner />;

  return (
    <S.CheckoutContainer>
      <CheckoutPageTitle title={pageTitle} subtitle={null} />
      {isGiftFlow ? (
        !errors.message && (
          <>
            <S.PlanBox>
              <h4>{GIFT_PLANS[giftPlan?.planId]?.description}</h4>

              <S.PlanInfo>
                <p>SUA ASSINATURA JÁ FOI PAGA</p>
              </S.PlanInfo>
            </S.PlanBox>
          </>
        )
      ) : (
        <>
          <S.PlanBox>
            <h4>um ano de acesso a todos os cursos</h4>

            <S.PlanInfo>
              <p className="installment-price">
                {plan.installmentsInfo}{" "}
                <strong>
                  R${" "}
                  <span className="installment-value">
                    {plan.installmentPrice}
                  </span>
                </strong>
                {!!plan.discountLabel && (
                  <>
                    {" "}
                    <S.DiscountLabel>
                      ↓{formatDiscountLabel(plan.discountLabel)}
                    </S.DiscountLabel>
                  </>
                )}
              </p>
              {disableInCashDiscount ? (
                <p className="full-price">
                  ou à vista{" "}
                  <strong>
                    {formattedPrice(priceStringToNumber(plan.price))}
                  </strong>
                </p>
              ) : (
                <p className="full-price">
                  ou à vista{" "}
                  <strong>
                    {formattedPrice(priceStringToNumber(plan.price) * 0.9)}
                  </strong>{" "}
                  <S.DiscountLabel>
                    {!!formatDiscountLabel(plan.discountLabel) ? "+" : "↓"}10%
                  </S.DiscountLabel>
                </p>
              )}
            </S.PlanInfo>
          </S.PlanBox>
          <S.VivoPlan
            onClick={(e) => {
              Datalayer.onClick(
                "checkout",
                "plano:2",
                "sou-cliente-vivo-familia"
              );
              vivoPlan(e);
            }}
            data-testid="checkout-vivo-plan-button"
          >
            <span>{strings.vivo.buttonTitle}</span>
            <label>{strings.vivo.buttonSubtitle}</label>
          </S.VivoPlan>
        </>
      )}

      {!isGiftFlow && <Accordion />}

      {errors.message && (
        <S.Errors>
          <FiAlertTriangle /> <span>{errors.message}</span>
        </S.Errors>
      )}

      <S.Coupon
        className={"coupon-container"}
        open={isOpen}
        data-testid={"checkout-coupon-accordion"}
      >
        <S.TitleContainer
          className="coupon-title"
          onClick={() => setIsOpen(!isOpen)}
          id={"btn-plans-coupon"}
          name={"btn-plans-coupon"}
        >
          Cupom de desconto/Vale presente
          <img src={ArrowDown} alt="Seta para cima" />
        </S.TitleContainer>

        <S.InputContainer className="coupon-code">
          <label htmlFor={"couponCode"}>Digite o código</label>
          <MaskedInput
            id="couponCode"
            key="couponCode"
            name="couponCode"
            type="couponCode"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            onBlur={(e) => handleBlur(e, "checkout", "2", "couponCode")}
            autoComplete="off"
            disabled={!!giftCodeParam}
          />
        </S.InputContainer>
      </S.Coupon>

      <CTAButton
        id={"btn-plans-proximo"}
        name={"btn-plans-proximo"}
        type="submit"
        disabled={buttonDisabled}
        onClick={(e) => {
          Datalayer.onClick("checkout", "botao:2", "proximo");
          !buttonDisabled && nextStep(e);
        }}
        text={isButtonLoading ? <Spinner color="dark" /> : "PRÓXIMO"}
      />
      {!!giftPlan ? (
        <p className="disclaimer">
          *Essa assinatura é válida até o dia {formattedDate(giftPlan.endAt)}.
        </p>
      ) : (
        <CheckoutDisclaimer />
      )}
    </S.CheckoutContainer>
  );
};

export default CheckoutPlans;
