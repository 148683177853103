import React from "react";
import * as S from "./style";

const MobileMenu = () => {
  return (
    <S.MenuContainer>
      <S.CategoriesLink
        to="/menu-categorias"
        state={{ origin: window.location.pathname }}
      >
        <i className="fas fa-bars"></i> CATEGORIAS
      </S.CategoriesLink>
      <S.CertificateLink
        to={"/minha-conta/certificados"}
        id={"btn-header-certificados"}
        name={"btn-header-certificados"}
      >
        CERTIFICADOS
      </S.CertificateLink>
    </S.MenuContainer>
  );
};

export default MobileMenu;
