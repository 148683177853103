import React from "react";
import * as S from "./styles";

const CourseBanner = ({ coverDesktop, coverMobile }) => {
  return (
    <S.CoverContainer coverDesktop={coverDesktop} coverMobile={coverMobile}>
      <S.Gradient />
    </S.CoverContainer>
  );
};

export default CourseBanner;
