import React from "react";
import * as S from "./styles";

const CheckoutDisclaimer = () => {
  return (
    <S.Wrapper>
      *7 dias de garantia. Você pode cancelar durante este período e reembolsar
      todo o valor pago. Após este período, a renovação do plano é automática.
    </S.Wrapper>
  );
};

export default CheckoutDisclaimer;
