import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 0;
    padding-bottom: 10vh;
    color: white;

    @media (min-width: ${theme.breakPoint.smallPhone}) {
    }
    @media (min-width: ${theme.breakPoint.laptop}) {
    }
  `}
`;
