import { useMutation, useQuery, useQueryClient } from "react-query";
import { requests } from "../services/requests";

const queryKeys = {
  course: "course-details",
  coursesByAuthor: "author-courses",
  classVideo: "class-details",
  finishedCourses: "finished-courses",
  favoriteCourses: "favorite-courses",
};

export const useCourse = (userId, courseId, options = {}) => {
  return useQuery(
    [queryKeys.course, courseId, userId],
    () => requests.getCourse(courseId, userId),
    {
      enabled: !!userId && !!courseId,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useResetCourseProgress = (courseId, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(() => requests.resetCourseProgress(courseId), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.course);
    },
    ...options,
  });
};

export const useCoursesByAuthor = (authorId, options = {}) => {
  return useQuery(
    [queryKeys.coursesByAuthor, authorId],
    () => requests.getCoursesByAuthor(authorId),
    {
      enabled: !!authorId,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useClassVideo = (
  props = { courseId: undefined, classId: undefined },
  options = {}
) => {
  const { courseId, classId } = props;

  return useQuery(
    [queryKeys.classVideo, props],
    () => requests.getClassVideo(props),
    {
      enabled: !!courseId && !!classId,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useFinishedCourses = (
  props = { limit: undefined },
  options = {}
) => {
  return useQuery(
    [queryKeys.finishedCourses, props],
    ({ queryKey }) => {
      const [, params] = queryKey;
      return requests.getFinishedCourses(params);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useFavoriteCourses = (options = {}) => {
  return useQuery([queryKeys.finishedCourses], () => requests.getFavorites(), {
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useAddFavorite = (options = {}) => {
  return useMutation((courseId) => requests.postFavorite(courseId), {
    ...options,
  });
};
export const useRemoveFavorite = (options = {}) => {
  return useMutation((courseId) => requests.removeFavorite(courseId), {
    ...options,
  });
};
