import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const ForgotPasswordButton = styled.button`
  ${({ theme }) => css`
    font-size: 16px;
    color: ${theme.darkTheme.colors.gray};
    text-decoration: none;
    font-weight: 100;
    background-color: transparent;
    border: 0;
    text-align: start;
    span {
      color: ${theme.darkTheme.colors.white};
    }
    :hover {
      color: ${theme.darkTheme.colors.gray};
      text-decoration: none;
    }
  `}
`;

export const Wrapper = styled.div`
  width: 384px;
  max-width: 414px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  row-gap: 25px;

  @media (${device.laptop}) {
    margin: 10vh auto 0;
    justify-content: start;
  } ;
`;

export const ForgotPasswordAlertLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
