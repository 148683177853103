import React from "react";
import Title from "../Title";
import * as S from "./styles";

const PageTitle = ({ children }) => {
  return (
    <S.Wrapper>
      <Title htmlTag={"h4"}>{children}</Title>
    </S.Wrapper>
  );
};

export default PageTitle;
