import React from "react";
import { useNavigate } from "react-router-dom";
import Datalayer from "../../services/datalayer";
import CheckoutHeader from "../../components/CheckoutHeader";
import CheckoutFooter from "../../components/CheckoutFooter";
import CTAButton from "../../components/CTAButton";
import * as S from "./styles";
import CheckoutPageTitle from "../../components/CheckoutPageTitle";
import MainIcon from "../../assets/icons/sentimos-sua-falta-icon.svg";
import {
  getNewCheckoutUrlWithUserParam,
  isLegacyCheckoutActive,
} from "../../util/newCheckout";
import { useUser } from "../../hooks";

const CheckoutCanceled = () => {
  const navigate = useNavigate();

  const {
    data: { userData },
  } = useUser();

  return (
    <S.Wrapper className="wrapper">
      <CheckoutHeader />

      <S.CheckoutContainer>
        <S.CanceledInfo>
          <S.Image>
            <img src={MainIcon} alt={"status-partial-fail"} />
          </S.Image>

          <CheckoutPageTitle
            title={"Sentimos a sua falta :)"}
            subtitle={
              "Que bom que você quer voltar para Casa. A sua assinatura está cancelada e para você voltar a ter acesso a todo conteúdo do aplicativo, é preciso reativar a sua assinatura. Clique no botão abaixo e siga o passo a passo: é simples e rápido"
            }
          />
        </S.CanceledInfo>

        <CTAButton
          id={"btn-subscribe-iniciar_assinatura"}
          name={"btn-subscribe-iniciar_assinatura"}
          onClick={() => {
            Datalayer.onClick(
              "checkout",
              "botao:erro-assinatura-cancelada",
              "fazer-nova-assinatura"
            );
            isLegacyCheckoutActive
              ? navigate("/checkout/entenda-a-assinatura", {
                  replace: true,
                })
              : window.location.replace(
                  getNewCheckoutUrlWithUserParam({
                    customerId: userData?.id,
                    email: userData?.email,
                    name: userData?.name,
                    phoneNumber: userData?.phoneNumber,
                  })
                );
          }}
          text={"fazer nova assinatura"}
        />
      </S.CheckoutContainer>

      <CheckoutFooter />
    </S.Wrapper>
  );
};

export default CheckoutCanceled;
