import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import activeCampaignTags from "../../constants/active-campaign-tags.json";
import Datalayer from "../../services/datalayer";
import { requests } from "../../services/requests";
import { useAuthContext } from "../../context/AuthContext";
import { translateAmplifyErrorMessage } from "../../util/messages";
import { useActiveCampaignTag, useDatalayer, useSentry } from "../../hooks";

import {
  Button,
  PasswordInput,
  LoginPageTitle,
  LoginContainer,
  Alert,
} from "../../components";

import * as S from "./styles";

const schema = yup.object({
  password: yup
    .string()
    .required("Por favor, preencha a senha")
    // eslint-disable-next-line no-template-curly-in-string
    .min(6, "A senha deve conter no mínimo ${min} caracteres")
    .trim(),
});

const LoginPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { signIn, setIsLoggedIn } = useAuthContext();
  const { mutate: sendActiveCampaignTag } = useActiveCampaignTag();
  const { handleBlur } = useDatalayer();
  const { logException } = useSentry(false);

  const navigate = useNavigate();
  const location = useLocation();
  const props = location.state;

  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props?.email) {
      navigate("/entrar");
    }
  }, [props]);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const response = await signIn(props.email, data.password);

      if (response?.challengeName === "NEW_PASSWORD_REQUIRED") {
        navigate("/nova-senha", {
          state: {
            email: props.email,
            oldPassword: data.password,
          },
        });
      } else {
        setIsLoggedIn(true);
        navigate("/", { replace: true });
        sendActiveCampaignTag({
          email: props.email,
          tagId: activeCampaignTags.sucessologin,
        });
      }
    } catch (error) {
      let message;

      if (error?.message === "Incorrect username or password.") {
        message = (
          <>
            Senha incorreta. Verifique e tente novamente. Se tiver dificuldades,{" "}
            <S.ForgotPasswordAlertLink onClick={handleForgotPassword}>
              recupere a sua senha.
            </S.ForgotPasswordAlertLink>
          </>
        );
      } else {
        message = translateAmplifyErrorMessage(error?.message).message;
      }

      sendActiveCampaignTag({
        email: props.email,
        tagId: activeCampaignTags.falhalogin,
      });
      setAlert({
        message,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  function handleForgotPassword() {
    requests.sendMagicLinkEmail(props.email, true).catch((error) => {
      const exceptionParams = {
        error,
        transactionName: "Login",
        origin: "recoveryPassword",
        tags: [
          { label: "errorMessage", value: error.message },
          { label: "errorCode", value: error.code },
        ],
        extras: [{ label: "data", value: error }],
        email: props.email,
      };
      logException(exceptionParams);
    });

    navigate("/entrar/info", {
      state: {
        email: props.email,
        type: "password",
      },
    });
  }

  return (
    <LoginContainer>
      <S.Wrapper>
        {!!alert.message && <Alert variant={alert.type} text={alert.message} />}

        <LoginPageTitle
          title={"Entrar na conta"}
          subtitle={
            <>
              Digite a sua
              <b> senha </b>
              para acessar.
            </>
          }
        />
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <PasswordInput
            label={"Senha"}
            inputName={"password"}
            registerFunction={register}
            errors={errors}
            onBlur={(e) => handleBlur(e, "login", "campo", "password")}
          />

          <Button
            variant={"white"}
            size={"full"}
            type="submit"
            onClick={() => {
              if (!loading) {
                Datalayer.onClickButton("login", "entrar");
              }
            }}
            disabled={loading}
            loading={loading}
            data-testid={"login-button"}
          >
            Entrar
          </Button>
        </S.Form>
        <S.ForgotPasswordButton
          type="button"
          onClick={() => {
            Datalayer.onClickLink("login", "recupere-a-sua-senha");
            handleForgotPassword();
          }}
        >
          Esqueceu? <span>Recupere a sua senha</span>
        </S.ForgotPasswordButton>
      </S.Wrapper>
    </LoginContainer>
  );
};

export default LoginPassword;
