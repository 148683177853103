import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 16px 0;
`
export const TextContainer = styled.div`
  text-align: center;
  
  img {
    margin-bottom: 10px;
  }

  h1, h2 {
    font-size: 16px;
    font-weight: 700;
  }

  h1 {
    margin-bottom: 25px;
  }

  h2 {
    margin-bottom: 10px;
  }

  ul {
    width: 100%;
    text-align: center;
    list-style-position: inside;
    font-size: 14px;
  }
`