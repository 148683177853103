import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fonts/fontawesome-v5.8.1/css/style.css";
import "./config/firebase";
import "./config/i18n";

import React from "react";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import ReactNotification from "react-notifications-component";
import { ThemeProvider } from "styled-components";
import { sentryConfig } from "./config/sentry";
import { AuthProvider } from "./context/AuthContext";
import Routes from "./routes";

import GlobalStyle from "./styles/globalStyles";
import { CDStheme } from "./styles/theme";

Sentry.init(sentryConfig);

function App() {
  const queryClient = new QueryClient();

  return (
    <>
      <GlobalStyle />
      <ReactNotification />
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <ThemeProvider theme={CDStheme}>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}

export default App;
