import styled, { css } from "styled-components";

const wrapperModifiers = {
  disabled: (theme) => css`
    background-color: ${theme.darkTheme.colors.gray};
    color: ${theme.darkTheme.colors.darkGray};
    border: 1px solid #cccccc;
    :hover {
      background-color: ${theme.darkTheme.colors.gray};
      color: ${theme.darkTheme.colors.darkGray};
      border-color: #cccccc;
    }
  `,
  green: (theme) => css`
    border: 1px solid transparent;
    color: ${theme.darkTheme.colors.darkBlack};
    background-color: ${theme.darkTheme.colors.green};
    :hover {
      color: ${theme.darkTheme.colors.darkBlack};
      background-color: ${theme.darkTheme.colors.white};
    }
  `,
  white: (theme) => css`
    border: 1px solid transparent;
    color: ${theme.darkTheme.colors.darkBlack};
    background-color: ${theme.darkTheme.colors.white};
    :hover {
      color: ${theme.darkTheme.colors.darkBlack};
      background-color: ${theme.darkTheme.colors.green};
    }
  `,
  black: (theme) => css`
    border: 1px solid transparent;
    color: ${theme.darkTheme.colors.white};
    background-color: ${theme.darkTheme.colors.absoluteBlack};
    :hover {
      color: ${theme.darkTheme.colors.darkBlack};
      background-color: ${theme.darkTheme.colors.green};
    }
  `,
  outlined: (theme) => css`
    border: 1px solid ${theme.darkTheme.colors.darkBlack};
    color: ${theme.darkTheme.colors.darkBlack};
    background-color: ${theme.darkTheme.colors.white};
    :hover {
      color: ${theme.darkTheme.colors.darkBlack};
      background-color: ${theme.darkTheme.colors.green};
      border-color: ${theme.darkTheme.colors.green};
    }
  `,
  transparent: (theme) => css`
    background-color: transparent;
    color: #cccccc;
    border: 1px solid #cccccc;
    :hover {
      color: ${theme.darkTheme.colors.green};
      border-color: ${theme.darkTheme.colors.green};
    }
  `,
  small: () => css`
    width: 125px;
    padding: 6px 4px 4px;
    text-align: center;
  `,
  full: () => css`
    width: 100%;
    padding: 1em;
  `,
};

export const Wrapper = styled.button`
  ${({ theme, variant, size, disabled }) => css`
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all 0.1s ease-in;
    cursor: ${disabled ? "not-allowed" : "pointer"};

    ${variant && wrapperModifiers[variant](theme)}
    ${size && wrapperModifiers[size]()}
    ${disabled && wrapperModifiers.disabled(theme)}
  `}
`;
