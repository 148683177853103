import React from "react";
import Stepper from "react-stepper-horizontal";

import * as S from "./styles";

// Documentation: https://yarnpkg.com/package/react-stepper-horizontal

const StepperCustom = ({ steps, currentStep }) => {
  return (
    <S.Wrapper>
      <Stepper
        steps={steps}
        activeStep={currentStep}
        //circle
        defaultColor={"transparent"}
        size={24}
        defaultBorderColor={"#ACA9B3"}
        defaultBorderStyle={"solid"}
        defaultBorderWidth={2}
        circleFontSize={16}
        circleFontColor={"#ACA9B3"}
        //title
        defaultTitleColor={"#ACA9B3"}
        //bar style
        defaultBarColor={"#ACA9B3"}
        completeBarColor={"#4C917C"}
        barStyle={"solid"}
        lineMarginOffset={0}
        //active circle
        activeColor={"transparent"}
        activeBorderColor={"#4C917C"}
        activeBorderStyle={"solid"}
        //completed circle
        completeColor={"#4C917C"}
        completeBorderColor={"#4C917C"}
      />
    </S.Wrapper>
  );
};

export default StepperCustom;
