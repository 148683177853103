import * as Sentry from "@sentry/react";

/** @type {import('@sentry/react').BrowserOptions} */
export const sentryConfig = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: require("../../package.json").version,
  environment: process.env.REACT_APP_NODE_ENV,

  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        "https://ondemand.casadosaber.com.br",
        "https://testing.casadosaber.com.br",
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      networkDetailAllowUrls: [""],
      networkRequestHeaders: ["X-Custom-Header"],
      networkResponseHeaders: ["X-Custom-Header"],
    }),
  ],
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,

  beforeSend(event, hint) {
    if (hint && hint.originalException && hint.originalException.isAxiosError) {
      return null;
    }

    if (
      hint &&
      hint.originalException &&
      hint.originalException instanceof XMLHttpRequest
    ) {
      return null;
    }
    return event;
  },

  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    "Script error.",
    /Network Error/i,
    /Fetch Error/i,
    "XMLHttpRequest",
    /Failed to fetch/,
    "ResizeObserver loop completed with undelivered notifications.",
    "Request failed with status code 400",
    "ResizeObserver loop limit exceeded",
    "acsbJS is not defined",
    "Can't find variable: acsbJS",
  ],
  ignoreUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // CDS Facebook
    /fbapi\.casadosaber\.com.br/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  denyUrls: [
    "https://api.trstplse.com/v1/account/10202/session",
    "https://api.trstplse.com/v1/account/10202/notifications",
  ],
};
