import React from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../ProgressBar";
import useMyList from "../../hooks/useMyList";
import { getProgress } from "../../util/course";

import { IoPlay, IoTrashOutline } from "react-icons/io5";
import * as S from "./styles";

const MyListCard = ({
  id,
  thumb,
  title,
  author,
  slug,
  classes,
  finishedClasses,
  courseId,
}) => {
  const navigate = useNavigate();
  const { removeFavorite } = useMyList();
  const { status, percentage } = getProgress(finishedClasses, classes);

  const renderProgress = {
    inProgress: (
      <>
        <ProgressBar progress={percentage} />
        <S.FinishDate>
          Aula{" "}
          <strong>
            {finishedClasses}/{classes}
          </strong>
        </S.FinishDate>
      </>
    ),
    concluded: (
      <>
        <ProgressBar progress={percentage} />
        <S.FinishDate>
          {classes} aulas <strong>100% concluído</strong>
        </S.FinishDate>
      </>
    ),
    default: <S.FinishDate>{classes} aulas</S.FinishDate>,
  };

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img alt={`Capa do curso ${title}`} src={thumb} />
      </S.ImageContainer>

      <S.HeaderContainer>
        <S.Title>{title}</S.Title>
        <S.Professor>por {author}</S.Professor>
      </S.HeaderContainer>

      <S.ProgressContainer>{renderProgress[status]}</S.ProgressContainer>

      <S.ButtonsContainer>
        <S.PlayButton
          id={`btn-my_list-assistir_${slug}`}
          name={`btn-my_list-assistir_${slug}`}
          onClick={() => navigate(`/curso/${id}/${slug}?play_video=true`)}
        >
          <IoPlay />
          <span>ASSISTIR</span>
        </S.PlayButton>

        <S.RemoveButton
          id={`btn-my_list-remover_${slug}`}
          name={`btn-my_list-remover_${slug}`}
          onClick={() => removeFavorite(courseId)}
        >
          <span>REMOVER</span>
          <IoTrashOutline />
        </S.RemoveButton>
      </S.ButtonsContainer>
    </S.Wrapper>
  );
};

export default MyListCard;
