export const errorsTranslations = {
  generic: {
    "User does not exist.":
      "Não encontramos uma conta com o e-mail informado. Por favor, verifique se o e-mail digitado está correto e tente novamente.",
    "Incorrect username or password.":
      "E-mail e/ou senha incorretos. Por favor, verifique se as informações estão corretas e tente novamente.",
    "Invalid password format.": "O formato da senha é inválido.",
    "Username/client id combination not found.":
      "Não foi localizado nenhum cadastro com este e-mail.",
    "Attempt limit exceeded, please try after some time.":
      "Muitas tentativas de recuperação de senha. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
    "Invalid verification code provided, please try again.":
      "O código informado não é válido. Por favor, verifique e tente novamente.",
    "Password attempts exceeded":
      "Tentativas de senha excedidas, tente novamente mais tarde ou entre em contato com o suporte.",
    "Code expired":
      "O seu acesso expirou. Entre com o seu e-mail e tente novamente.",
  },

  changePassword: {
    "Incorrect username or password.":
      "Senha atual não confere. Por favor, verifique se a senha atual está correta e tente novamente.",
  },

  datalayer: {
    "User does not exist.": "usuario-nao-existe",
    "Incorrect username or password.": "email-ou-senha-incorretos",
    "Invalid password format.": "senha-invalida",
    "Username/client id combination not found.": "cadastro-nao-encontrado",
    "Attempt limit exceeded, please try after some time.":
      "tentativas-excedidas",
    "Invalid verification code provided, please try again.":
      "codigo-de-verificacao-invalido",
    "Password attempts exceeded": "tentativas-de-senha-excedidas",
  },

  datalayerFallback: "erro-nao-mapeado",

  fallback:
    "Houve um problema, por favor, verifique os dados e tente novamente.",
};

/**
 * @typedef TAmplifyErrorMessage
 * @property {string} message
 */

export const getAlertMessageFromState = (location) => {
  const state = location && location.state;
  const type = (!!state && Object.keys(state)[0]) || "default";

  const variants = {
    updatedPersonalData: {
      message: "As alterações do seu cadastro foram salvas.",
      variant: "success",
    },
    updatedPaymentMethod: {
      message: "Os dados do seu pagamento foram alterados.",
      variant: "success",
    },
    updatedPassword: {
      message: "Senha alterada com sucesso.",
      variant: "success",
    },
    updatedInstallments: {
      message: "O parcelamento da sua assinatura foi alterado",
      variant: "success",
    },
    updatedExtensionPaymentMethod: {
      message: (
        <>
          <strong>Os dados do seu pagamento foram alterados.</strong>
          <br />
          Esse cartão também será utilizado na cobrança da renovação da
          assinatura. Se você preferir, pode alterar novamente em MINHA CONTA.
        </>
      ),
      variant: "success",
    },
    processingExtensionPayment: {
      message: (
        <>
          <strong>Estamos processando seu pagamento agora.</strong>
          <br />
          Você receberá uma confirmação por e-mail. Fique atento à sua caixa de
          entrada.
        </>
      ),
      variant: "warning",
    },
    default: "",
  };

  return variants[type];
};

/**
 * Translate Amplify Error message
 * @param {string} amplifyMessage
 * @param {'generic' | 'changePassword'} context
 * @returns {TAmplifyErrorMessage}
 */
export const translateAmplifyErrorMessage = (
  amplifyMessage,
  context = "generic"
) => {
  const message =
    errorsTranslations[context]?.[amplifyMessage] ||
    errorsTranslations["generic"]?.[amplifyMessage];

  if (context === "datalayer")
    return {
      message: message || errorsTranslations["datalayerFallback"],
    };

  return {
    message: message || errorsTranslations["fallback"],
  };
};
