import React from "react";
import { IoLogoWhatsapp, IoMailOutline } from "react-icons/io5";
import { support } from "../../data/support";
import * as S from "./styles";

const phone = (
  <a
    href={support.whatsapp.href}
    target={support.whatsapp.target}
    rel={support.whatsapp.rel}
    id={"btn-support_info-phone"}
    name={"btn-support_info-phone"}
  >
    {support.whatsapp.text}
  </a>
);

const mail = (
  <a
    href={support.mail.href}
    target={support.mail.target}
    rel={support.mail.rel}
    id={"btn-support_info-mail"}
    name={"btn-support_info-mail"}
  >
    {support.mail.text}
  </a>
);

const SupportInfo = () => {
  return (
    <S.Wrapper>
      Para qualquer dificuldade, fale diretamente com nossa equipe de
      atendimento pelo WhatsApp: <IoLogoWhatsapp /> {phone}, de segunda à sexta,
      das 10h às 19h, ou pelo e-mail: <IoMailOutline /> {mail}.
    </S.Wrapper>
  );
};

export default SupportInfo;
