import styled, { css } from "styled-components";

export const Wrapper = styled.header`
  ${({ theme }) => css`
    width: 100%;
    padding: 16px;
    background-color: ${theme.lightTheme.colors.darkGray};

    > div {
      width: 100%;
      max-width: 414px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
    }

    button.header-login-button {
      font-size: 14px;
      font-weight: 700;
      color: ${theme.lightTheme.colors.white};
      background-color: transparent;
      padding: 5px 10px;
      border: none;

      :hover {
        color: ${(props) => props.theme.lightTheme.colors.green};
      }
    }
  `}
`;
