import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formattedDate, calculateDaysLeft } from "../util/Util";
import { useCardExpiration } from "./useSubscriptionQuery";
import { useUser } from "./useUsersQuery";

export const useBannerInfoContent = () => {
  const [showBannerInfo, setShowBannerInfo] = useState(false);
  const [bannerType, setBannerType] = useState("");

  const navigate = useNavigate();

  const {
    data: { subscriptionDetails },
  } = useUser();
  const { data: validCard } = useCardExpiration();

  const endAt = subscriptionDetails?.endAt;

  function handleDismiss() {
    setShowBannerInfo(false);
    window.localStorage.setItem("dismiss-banner-informative", "true");
  }

  const variants = {
    cardExpired: {
      backgroundColor: "purpleRed",
      text: (
        <p>
          Ops! identificamos um problema com seus dados de pagamento. Clique e
          atualize as informações até {formattedDate(endAt)} para seguir
          acessando todos os conteúdos da plataforma.
        </p>
      ),
      actionButton: {
        id: "btn-banner_info-atualizar",
        name: "btn-banner_info-atualizar",
        text: "atualizar",
        action: () => navigate("/minha-conta/alterar-pagamento"),
      },
      dismissButton: {
        action: handleDismiss,
        id: "btn-banner_info-dismiss",
        name: "btn-banner_info-dismiss",
        text: "dispensar",
      },
    },
    default: {},
  };

  useEffect(() => {
    const hasDismissed =
      window.localStorage.getItem("dismiss-banner-informative") === "true";
    if (hasDismissed) return;

    const daysLeft = calculateDaysLeft(endAt);

    if (daysLeft >= 0 && daysLeft <= 7) {
      if (subscriptionDetails.status === "active" && validCard === false) {
        setShowBannerInfo(true);
        return setBannerType("cardExpired");
      }
    }
  }, [subscriptionDetails]);

  const bannerContent = variants[bannerType] || variants["default"];

  return { showBannerInfo, bannerContent };
};

export default useBannerInfoContent;
