import { formattedPrice, priceStringToNumber } from "./Util";

export const getCardIssuerCode = (issuer) => {
  /*
   ** These codes comes from vindi's api `/v1/payment_methods` and it reflects the accepted methods on Casa do Saber's contract with Vindi.
   ** docs: https://vindi.github.io/api-docs/dist/#/payment_methods/getV1PaymentMethods
   */
  const acceptedIssuers = {
    mastercard: "mastercard",
    visa: "visa",
    amex: "american_express",
    dinersclub: "diners_club",
    elo: "elo",
    discover: "elo",
    hipercard: "hipercard",
    jcb: "jcb",
  };

  return acceptedIssuers[issuer] || undefined;
};

export const getCreditCardMask = (issuer) => {
  const numberLength = {
    dinersclub: "9999 999999 9999",
    amex: "9999 999999 99999",
    default: "9999 9999 9999 9999",
  };

  return numberLength[issuer] || numberLength["default"];
};

/**
 * @param {object} param
 * @param {string | number} param.price
 * @param {string} param.installments
 * @param {boolean} param.disableInCashDiscount
 * @returns Object
 */
export const formatOrderSummary = ({
  price,
  installments,
  disableInCashDiscount,
}) => {
  const fullPrice =
    typeof price === "string" ? priceStringToNumber(price) : price;

  const discountAmount = fullPrice * 0.1;
  const priceWithDiscount = fullPrice - discountAmount;

  let formattedFinalPrice = "";
  let dataLayerPrice = "";
  const hasSinglePayment = installments === "1" && !disableInCashDiscount;

  if (hasSinglePayment) {
    formattedFinalPrice = formattedPrice(priceWithDiscount);
    dataLayerPrice = priceWithDiscount.toFixed(2);
  } else {
    formattedFinalPrice = `${installments}x ${formattedPrice(
      fullPrice / Number(installments)
    )}`;
    dataLayerPrice = fullPrice.toFixed(2);
  }

  return {
    hasSinglePayment,
    formattedSinglePaymentDiscountAmount: formattedPrice(discountAmount),
    formattedFinalPrice,
    dataLayerPrice,
  };
};

/**
 * @param {string | number} price
 * @param {string} installments
 * @returns Object
 */
export const formatCampaignOrderSummary = (price, installments) => {
  // BEST FRIDAY
  const fullPrice =
    typeof price === "string" ? priceStringToNumber(price) : price;

  let formattedFinalPrice = "";
  let dataLayerPrice = "";

  if (installments === "1") {
    formattedFinalPrice = formattedPrice(price);
    dataLayerPrice = fullPrice.toFixed(2);
  } else {
    formattedFinalPrice = `${installments}x ${formattedPrice(
      fullPrice / Number(installments)
    )}`;
    dataLayerPrice = fullPrice.toFixed(2);
  }

  return {
    formattedFinalPrice,
    dataLayerPrice,
  };
};

/**
 * @param {string | number} originalPrice
 * @param {string | number} discountValue
 * @param {string} discountType
 * @returns number
 */
export const calcPriceAfterDiscount = (
  originalPrice,
  discountValue,
  discountType = "percentage"
) => {
  const price =
    typeof originalPrice === "string"
      ? priceStringToNumber(originalPrice)
      : originalPrice;
  const discount =
    typeof percentageDiscount === "string"
      ? priceStringToNumber(discountValue)
      : discountValue;

  const result = {
    percentage: price * ((100 - discount) / 100),
    amount: price - discount,
  };

  return result[discountType] || result["percentage"];
};

/**
 * @param {string | number} originalPrice
 * @param {string | number} discountValue
 * @param {string} discountType
 * @returns number
 */
export const formatBestFridayPlanAfterDiscounts = (
  plan,
  couponInfo,
  newPrice
) => {
  const formatted = {
    coupon: couponInfo.code,
    couponId: couponInfo.couponId,
    price: newPrice.toFixed(2).replace(".", ","),
    discount: Number(priceStringToNumber(plan.originalPrice) - newPrice)
      .toFixed(2)
      .replace(".", ","),
    discountType: couponInfo.discountType,
    discountLabel:
      couponInfo.discountType === "percentage"
        ? `${couponInfo.discountValue}%`
        : `R$ ${couponInfo.value}`,
    installments: 12,
    installmentPrice: Number(newPrice / 12)
      .toFixed(2)
      .replace(".", ","),
    value: couponInfo.discountValue,
  };

  return formatted;
};
