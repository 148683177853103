import React, { useEffect } from "react";
import { useSearchContext } from "../../../context/SearchContext";
import SearchIcon from "../../../assets/icons/search-icon.svg";
import * as S from "./styles";

export const SearchInput = () => {
  const { userQuery, searchInput } = useSearchContext();

  useEffect(() => {
    document.getElementById("userQuery").focus();
  }, []);

  return (
    <S.InputGroup>
      <S.Input
        name="userQuery"
        id="userQuery"
        value={userQuery}
        onChange={searchInput}
        placeholder={"Pesquise um curso, professor ou tema"}
      />

      <span>
        <img src={SearchIcon} alt={"Pesquisar"} />
      </span>
    </S.InputGroup>
  );
};

export default SearchInput;
