import { Modal } from "reactstrap";
import styled, { css } from "styled-components";

export const StyledModalRoot = styled(Modal)`
  max-width: 400px;
  .content-custom-modal {
    margin-right: 25px;
    margin-left: 25px;
    padding: 1rem;
  }
`;
export const CloseButton = styled.div`
  ${({ theme }) => css`
    position: absolute;
    z-index: 10;
    top: -24px;
    right: -24px;
    width: 42px;
    height: 42px;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.darkTheme.colors.coal};
    transition: all 0.1s ease-in;
    cursor: pointer;

    i {
      font-size: 24px;
      color: ${theme.darkTheme.colors.darkGray};
      transition: all 0.1s ease-in;
    }

    :hover {
      background-color: ${theme.darkTheme.colors.darkGray};
      i {
        color: ${theme.darkTheme.colors.lightGray};
      }
    }
  `}
`;
