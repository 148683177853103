import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Wrapper = styled.main`
  ${({ theme }) => css`
    margin: 0 0.7rem;
    padding-bottom: ${theme.spacings.medium};

    @media (${device.tablet}) {
      max-width: 1080px;
      padding: 0 0.7rem;
      margin: auto;
    }
  `}
`;
