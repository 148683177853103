import {
  useMutation,
  useQuery,
  UseQueryOptions, // eslint-disable-line no-unused-vars
  UseQueryResult, // eslint-disable-line no-unused-vars
} from "react-query";
import { requests } from "../services/requests";

const queryKeys = {
  giftSubscriptionStatus: "gift-subscription-status",
  giftSendEmail: "gift-send-email",
};

/**
 * Gift Subscription status query
 * @param {UseQueryOptions} options
 * @returns {UseQueryResult}
 */
export const useGiftSubscriptionStatus = (giftCode, options = {}) => {
  return useQuery(
    queryKeys.giftSubscriptionStatus,
    () => requests.getGiftSubscriptionStatus(giftCode),
    {
      ...options,
      retry: 1,
      cacheTime: 0,
      optimisticResults: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSendGiftEmail = (options = {}) => {
  return useMutation(
    [queryKeys.giftSendEmail],
    (body) => requests.sendGiftEmail(body),
    options
  );
};
