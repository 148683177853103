import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components";

import * as S from "./styles";

const CourseAbout = ({
  description,
  author,
  authorId,
  authorDescription,
  authorPhoto,
}) => {
  return (
    <S.Wrapper>
      <S.CourseDescription>{description}</S.CourseDescription>

      <S.Teacher>
        <img src={authorPhoto} alt={`Foto ${author}`} />
        <p>
          <strong>Professor(a): </strong>
          {author}
        </p>
      </S.Teacher>

      <S.CourseDescription>{authorDescription}</S.CourseDescription>

      <Link
        to={`/professor/${authorId}`}
        id={"btn-course_about-saiba_mais"}
        name={"btn-course_about-saiba_mais"}
      >
        <Button variant={"transparent"} size="small">
          Saiba mais
        </Button>
      </Link>
    </S.Wrapper>
  );
};

export default CourseAbout;
