import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  max-width: 992px;
  height: 100vh;
  margin: auto;
  padding: 15px 0.7rem;
  display: flex;
  flex-direction: column;
  background-color: #201e20e5;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    border-radius: 50%;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #cccccc;
    color: #5f5d5f;
    font-size: 24px;
    cursor: pointer;

    i {
      font-size: 26px;
    }

    :hover {
      color: #d7ef7d;
      text-decoration: none;
    }
  }
`;

export const CategoriesList = styled.ul`
  max-height: 100%;
  overflow-y: auto;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;

  @media (min-width: 450px) {
    scrollbar-width: thin;
    ::-webkit-scrollbar-track {
      background-color: #5f5d5f;
      border: none;
      border-radius: 15px;
    }
    ::-webkit-scrollbar {
      height: 2px;
      width: 10px;
      display: block;
    }
    ::-webkit-scrollbar-thumb {
      background: #d7ef7d;
      border-radius: 15px;
    }
  }
`;

export const CategoryItem = styled.li`
  margin: 8px 0;

  a {
    font-family: "MaisonNeueBook", sans-serif;
    font-size: 16px;
    color: #fff;
    line-height: 26px;
    padding: 5px 0;

    :hover {
      color: #cff16b;
      text-decoration: none;
      -webkit-text-decoration: none;
    }
  }

  a#btn-menu-categorias-inicio {
    font-size: 20px;
  }
`;
