import styled, { css } from "styled-components";

export const Wrapper = styled.button`
  ${({ theme, size, round }) => css`
    width: ${size}px;
    height: ${size}px;
    border-radius: ${round ? "50%" : 0};
    border: 1px solid ${theme.darkTheme.colors.gray};
    position: relative;
    display: inline-block;

    img {
      max-width: 90%;
    }

    /*TOOLTIP*/
    /* Tooltip text */
    .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;

      /* Position the tooltip text */
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -60px;

      /* Fade in tooltip */
      opacity: 0;
      transition: opacity 0.3s;
    }

    /* Tooltip arrow */
    .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    :hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  `}
`;
