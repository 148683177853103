import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import { IconContext } from "react-icons";
import { IoCloseCircle } from "react-icons/io5";
import { usePromotionalCampaign, useUser } from "../../hooks";
import { deleteCookie, getCookie, setCookie } from "../../util/Util";
import * as S from "./styles";

const BANNER_DISMISSED = "banner-bf-23-dismissed";

const PromotionalBanner = () => {
  const { width: screenSize } = useWindowSize();
  const { data: campaign, isLoading } = usePromotionalCampaign();
  const {
    data: { subscriptionDetails },
  } = useUser();

  const { extensionStatus, planName } =
    !!subscriptionDetails && subscriptionDetails;

  const isDisengaged = campaign?.showExtensionBanner;

  const userCanExtendSubscription =
    isDisengaged &&
    (extensionStatus === "failed" || extensionStatus === null) &&
    planName?.toLowerCase().includes("anual");

  const wasDismissed = Boolean(getCookie(BANNER_DISMISSED));
  const [isCollapsed, setIsCollapsed] = useState(wasDismissed);

  const toggleBanner = () => {
    if (isCollapsed) {
      deleteCookie(BANNER_DISMISSED);
    } else {
      setCookie(BANNER_DISMISSED, true);
    }

    setIsCollapsed(!isCollapsed);
  };

  if (isLoading || !userCanExtendSubscription || !campaign) return <></>;

  return (
    <S.Wrapper>
      <S.ContentContainer>
        <S.CloseButton
          id={"btn-promotionalBanner-toggle"}
          name={"btn-promotionalBanner-toggle"}
          onClick={toggleBanner}
          isCollapsed={isCollapsed}
        >
          <IconContext.Provider
            value={{
              size: "100%",
              className: "toggle-banner",
            }}
          >
            <IoCloseCircle />
          </IconContext.Provider>
        </S.CloseButton>

        <Link to="/minha-conta/estender-assinatura">
          {isCollapsed ? (
            <p>CONFIRA A OFERTA</p>
          ) : (
            <img
              src={
                screenSize > 1024
                  ? campaign.banners.app.web
                  : campaign.banners.app.mobile
              }
              alt="Banner Promocional"
            />
          )}
        </Link>
      </S.ContentContainer>
    </S.Wrapper>
  );
};

export default PromotionalBanner;
