import Axios from "axios";
import deepLinkingConfig from "../constants/mobile-deep-linking.json";
import { CDS_API_VERSION } from "./settings";

const getLinkData = (link) => {
  const linkWithoutBase = link.replace(
    process.env.REACT_APP_NODE_ENV === "production" ||
      process.env.REACT_APP_NODE_ENV === "staging"
      ? process.env.REACT_APP_WEBSITE_URL
      : `http://${window.location.hostname}:${window.location.port}`,
    ""
  );

  // basic route scenario without ids and params
  const basicPattern = /^\/([^/]+)\/?$/;
  if (basicPattern.test(linkWithoutBase)) {
    const match = basicPattern.exec(linkWithoutBase);
    if (match) {
      return {
        type: match[1],
      };
    }
  }

  const pattern = /\/(\w+)\/([^/]+)(?:\/([^/]+))?/;
  const match = pattern.exec(linkWithoutBase);
  if (match) {
    return {
      type: match[1],
      identifier: match[2],
      subIdentifier: match[3],
    };
  } else {
    return {};
  }
};

export const parseLink = async (link) => {
  let { type, identifier } = getLinkData(link) || {};

  if (type === "categoria") {
    const categories = (
      await Axios.get(
        `${process.env.REACT_APP_CDS_API_URL.replace(
          "app",
          "public"
        )}/${CDS_API_VERSION}/categories`
      )
    ).data;

    const category = categories.find(
      (category) => category.slug === identifier
    );

    if (!category) {
      return;
    }

    identifier = category.id;
  }

  const prefix = deepLinkingConfig.links[type];

  if (!prefix) {
    return;
  }
  const prefixWithIdentifier = prefix.replace(":id", identifier);

  const deepLink = `${deepLinkingConfig.base}${prefixWithIdentifier}`;
  return deepLink;
};
