import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  max-width: 992px;
  height: 100vh;
  margin: auto;
  padding: 15px 0.7rem;
  display: flex;
  flex-direction: column;
  background-color: #100F10;
`;

export const HeaderContainer = styled.div`
  z-index: 1;
`

export const ResultsContainer = styled.div`

  max-height: 100%;
  overflow-y: auto;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: 450px){
    scrollbar-width: thin;
    ::-webkit-scrollbar-track {
      background-color: #5F5D5F;
      border: none;
      border-radius: 15px;
    }
    ::-webkit-scrollbar {
      height: 2px;
      width: 10px;
      display: block;
    }
    ::-webkit-scrollbar-thumb {
      background: #D7EF7D;
      border-radius: 15px;
    }
  }
`