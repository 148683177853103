import styled from "styled-components";
import { Field } from "formik";

export const Wrapper = styled.div`
  margin: 0 auto 1rem;
  max-width: 420px;
`;

export const FormWrapper = styled.div`
  margin: 0 auto 1rem;
  display: flex;
  flex-direction: column;
  max-width: 420px;
  label {
    color: white;
    margin: 0.5rem 0 0;
  }
`;

export const UserInfo = styled.div`
  background-color: #201e20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) => (props.bottom ? "2px solid #5F5D5F" : "none")};
  padding: 0.5rem 1rem;
  margin: ${(props) => (props.space ? "0 0 1rem" : "0")};
  font-size: 14px;
`;

export const Input = styled(Field)`
  background-color: #100f10;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  padding: 1rem 0.5rem 0.3rem;
  width: 100%;
  display: block;
  font-size: 16px;
  :disabled {
    opacity: 0.6;
  }
  ::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }
`;

export const Span = styled.span`
  position: absolute;
  z-index: 1;
  cursor: text;
  pointer-events: none;
  color: #aca9b3;
  line-height: 20px;
  font-size: 12px;
  padding: 0.2rem 0.45rem 0.6rem;
`;

export const Select = styled.select`
  background-color: #100f10;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 1.1rem 0.3rem 0.3rem;
  display: block;
  font-size: 16px;
  width: 100%;
  -webkit-border: none;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: ${(props) => (props.dark ? 700 : 700)};
  margin: ${(props) => (props.dark ? "0 -0.7rem" : "1.8rem 0 0 0")};
  background-color: ${(props) => (props.dark ? "#100F10" : "#201e20")};
  padding: ${(props) => (props.dark ? "0.7rem 1rem" : "0 0.2rem")};
  color: ${(props) => (props.dark ? "#ACA9B3" : "ffffff")};
`;

export const Vivo = styled.div`
  margin-top: 1.6rem;
  margin-right: -0.3rem;
  font-size: 14px;
  position: relative;
  display: inline-block;
  img {
    padding: 0 0.4rem;
  }
  span {
    visibility: hidden;
    width: 120px;
    background-color: #974fe3;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    top: -5px;
    right: 105%;
    z-index: 1;
  }

  :hover span {
    visibility: visible;
  }
`;

export const Contact = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 768px;
`;

export const Menu = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  max-width: calc(420px + 1rem);
  margin: 0.7rem auto;
  padding: 0;
  border-bottom: 1px solid #ffffff;
  position: relative;
  span {
    text-align: center;
    font-size: 12px;
    padding: 0.3rem;
    :hover {
      color: #cff16b;
    }
  }
  span.active {
    width: 110px;
    border: 1px solid #ffffff;
    border-bottom: 1px solid #201e20;
    border-radius: 5px 5px 0 0;
    font-weight: bold;
    font-size: 13px;
    z-index: 2;
    position: relative;
    top: 1px;
  }
  li {
    cursor: pointer;
    z-index: 1;
  }
  @media (min-width: 768px) {
    margin: 1rem auto;
  }
`;

export const Box = styled.div`
  border-radius: 5px;
  border: 1px solid #cff16b;
  margin: 1.5rem;
  padding: 0.5rem;
  text-align: center;
  span {
    color: #aca9b3;
  }
`;

export const SmallButton = styled.div`
  border: 1px solid #ffffff;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  width: 70px;
  text-align: center;
  padding: 6px 4px 4px;
  border-radius: 3px;
  cursor: pointer;
  :hover {
    color: #cff16b;
    border: 1px solid #cff16b;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: 16px;

  input:not([type="checkbox"]) {
    background-color: #100f10;
    color: #fff;
    border-radius: 5px;
    border: none;
    padding: 1.5rem 0.5rem 0.3rem;
    width: 100%;
    height: 50px;
    display: block;
    font-size: 16px;

    :disabled {
      opacity: 0.6;
    }

    &#email {
      text-transform: lowercase;
    }
  }

  .one-column {
    width: 100%;
  }

  .custom-label {
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 130%;
    color: #aca9b3;
    display: flex;
    align-items: center;
    position: relative;
    top: 14px;
    left: 8px;
    height: 0px;
    width: 100%;
  }

  .custom-label.phone {
    left: 45px;
  }

  button {
    background-color: ${(props) => (props.dark ? "#201e20" : "#ffffff")};
    margin-top: 1.5rem;

    :hover {
      background: transparent;
      border: 2px solid #cff16b;
      color: #fff;
    }
  }
  button:disabled {
    background-color: ${(props) =>
      props.theme.lightTheme.colors.disabledBackground};
    color: ${(props) => props.theme.lightTheme.colors.disabledText};

    :hover {
      border: none;
    }
  }
`;

export const Errors = styled.div`
  padding: 15px;
  border-radius: 5px;
  margin-top: -25px;
  border: ${(props) =>
    props.alert ? "1.5px solid #F7CE6B" : "1.5px solid #ff7651"};
  margin-bottom: 0.7rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  span {
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    display: inline;
    align-items: center;
  }
  svg {
    color: ${(props) => (props.alert ? "#F7CE6B" : "#ff7651")};
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
  a {
    color: ${(props) => props.theme.lightTheme.colors.text};
    text-decoration: underline;
  }
`;
