 // WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

 const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c515b674-de34-453f-9430-f831db6207bf",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mp0vcGKnv",
    "aws_user_pools_web_client_id": "2sdu4s9ntpnq6tmd07eq9lc4g9",
    "oauth": {
        "domain": "cds-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://signin.ondemand.casadosaber.com.br/",
        "redirectSignOut": "https://signin.ondemand.casadosaber.com.br/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;