import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 5%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 94vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: ${theme.darkTheme.colors.white};
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
      0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: ${theme.layer.fifth};
    color: ${theme.font.color.lightBlack};

    @media (min-width: ${theme.breakPoint.smallPhone}) {
      width: 260px;
      border-radius: 10px;
      padding: ${theme.spacings.medium};
    }
    @media (min-width: ${theme.breakPoint.laptop}) {
      width: 400px;
      padding: ${theme.spacings.large};
    }
  `}
`;

export const CloseButton = styled.div`
  ${({ theme }) => css`
    margin: -15px;
    position: relative;
    top: -13px;
    right: -52%;
    width: 30px;
    height: 30px;
    background: #323132;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${theme.layer.sixth};
    cursor: pointer;

    svg {
      max-width: 100%;
      max-height: 100%;
    }

    @media (min-width: ${theme.breakPoint.smallPhone}) {
      top: -20px;
      right: -54%;
      width: 42px;
      height: 42px;
    }
    @media (min-width: ${theme.breakPoint.laptop}) {
      top: -25px;
      right: -56%;
      width: 42px;
      height: 42px;
      cursor: pointer;
    }
  `}
`;

export const WarningIcon = styled.div`
  ${({ theme }) => css`
    width: 45px;
    height: 45px;
    background: none;
    color: #ffffff;
    margin: 10px;

    svg {
      background: white;
      max-width: 100%;
      max-height: 100%;
    }

    @media (min-width: ${theme.breakPoint.regularPhone}) {
      width: 50px;
      height: 50px;
    }
    @media (min-width: ${theme.breakPoint.laptop}) {
      margin: 20px;
      width: 60px;
      height: 60px;
    }
  `}
`;

export const TextBox = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family.MaisonNeue};
    font-size: ${theme.font.size.medium};
    font-weight: ${theme.font.weight.light};
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;

    span {
      display: block;
    }

    @media (min-width: ${theme.breakPoint.smallPhone}) {
      font-size: 20px;
      line-height: 26px;
    }
    @media (min-width: ${theme.breakPoint.laptop}) {
      font-size: ${theme.font.size.large};
      line-height: 28px;
    }
  `}
`;

export const DetailsBox = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.family.MaisonNeue};
    font-size: ${theme.font.size.smallest};
    font-weight: ${theme.font.weight.light};
    text-align: center;

    @media (min-width: ${theme.breakPoint.smallPhone}) {
      font-size: ${theme.font.size.smallest};
    }
    @media (min-width: ${theme.breakPoint.laptop}) {
      font-size: 20px;
      line-height: 24px;
    }
  `}
`;

export const ShareBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacings.small};
    padding-top: ${theme.spacings.small};
    padding-bottom: ${theme.spacings.small};
  `}
`;

const buttonModiffiers = {
  share: (theme) => css`
    background-color: ${theme.darkTheme.colors.darkBlack};
    color: ${theme.darkTheme.colors.text};
    @media (min-width: ${theme.breakPoint.laptop}) {
      margin: ${theme.spacings.medium} 0;
    }
  `,
  download: (theme) => css`
    background-color: ${theme.darkTheme.colors.white};
    color: ${theme.darkTheme.colors.darkBlack};
    border: 1px solid ${theme.darkTheme.colors.darkBlack}; ;
  `,
  downloadError: (theme) => css`
    background-color: ${theme.darkTheme.colors.darkBlack};
    color: ${theme.darkTheme.colors.text};
    margin: ${theme.spacings.small} 0;
    @media (min-width: ${theme.breakPoint.laptop}) {
      margin: ${theme.spacings.medium} 0;
    }
  `,
  loading: (theme) => css`
    background-color: ${theme.darkTheme.colors.darkBlack};
    color: ${theme.darkTheme.colors.text};
    margin: ${theme.spacings.small} 0;
    @media (min-width: ${theme.breakPoint.laptop}) {
      margin: ${theme.spacings.medium} 0;
    }
  `,
  shareError: (theme) => css`
    background-color: ${theme.darkTheme.colors.darkBlack};
    color: ${theme.darkTheme.colors.text};
    margin: ${theme.spacings.small} 0;
    @media (min-width: ${theme.breakPoint.laptop}) {
      margin: ${theme.spacings.medium} 0;
    }
  `,
  incompleteRegister: (theme) => css`
    background-color: ${theme.darkTheme.colors.darkBlack};
    color: ${theme.darkTheme.colors.text};
    margin: ${theme.spacings.small} 0;
    @media (min-width: ${theme.breakPoint.laptop}) {
      margin: ${theme.spacings.medium} 0;
    }
  `,
};

export const Button = styled.button`
  ${({ theme, type }) => css`
    text-transform: uppercase;
    border-radius: 5px;
    border: none;
    padding: 0;
    margin: ${theme.spacings.smallest} 0;
    outline: inherit;
    min-width: 75px;
    width: 84vw;
    height: 50px;
    font-size: 11px;

    ${type && buttonModiffiers[type](theme)}

    @media (min-width: ${theme.breakPoint.smallPhone}) {
      font-size: ${theme.font.size.smallest};
      width: 239px;
    }

    @media (min-width: ${theme.breakPoint.laptop}) {
      font-size: ${theme.font.size.small};
      width: 320px;
      cursor: pointer;
    }
  `}
`;
