import styled from "styled-components";

export const Wrapper = styled.div`
  h1 {
    color: ${(props) => props.theme.lightTheme.colors.text};
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    position: relative;
    margin-bottom: 0px;
    text-align: left;
  }
  p {
    color: ${(props) => props.theme.lightTheme.colors.text};
    margin-bottom: 0px;
  }
  a {
    color: ${(props) => props.theme.lightTheme.colors.text};
    text-decoration: underline;
    margin-bottom: 0px;
  }
`;
