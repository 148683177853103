import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import MaskedInput from "react-input-mask";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { default as pt_BR } from "react-phone-number-input/locale/pt-BR.json";
import "react-phone-number-input/style.css";
import { FiAlertTriangle } from "react-icons/fi";
import { BsExclamationCircle } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";

import { requests } from "../../services/requests";
import { useUpdatePersonalData } from "../../hooks";
import { useUserSubscriptionContext } from "../../context/userSubscriptionContext";
import { convertStringToDigits } from "../../util/Util";
import {
  Checkbox,
  Container,
  CTAButton,
  LoadingSpinner,
} from "../../components";

import infoIcon from "../../assets/images/vivo-119.svg";

import strings from "../../config/strings.json";
import * as S from "./styles";

const ProfileForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [message, setMessage] = useState("");
  const { personalData, hasIncompleteRegister, updatePersonalData } =
    useUserSubscriptionContext();
  const [customer, setCustomer] = useState(personalData);

  const { mutateAsync: sendUpdateRequest } = useUpdatePersonalData({
    onSuccess: () => {
      queryClient.resetQueries("user-details");

      updatePersonalData(customer);

      const bodyUpdateActiveCampaign = {
        email: customer.email,
        phoneNumber: convertStringToDigits(customer.phoneNumber),
        cpf: convertStringToDigits(customer.identity),
        fullName: customer.name,
      };
      requests.updateActiveCampaignContact(bodyUpdateActiveCampaign);

      navigate("/minha-conta", {
        state: { updatedPersonalData: true },
      });
    },
    onError: (error) => {
      if (error.response.data.violations) {
        error.response.data.violations.forEach((item) => {
          setMessage(`${t(`forms.${item.propertyPath}`)}: ${item.message}`);
        });
      } else {
        setMessage(error.response.data["hydra:description"]);
      }
      setLoading(false);
      return setErrors(true);
    },
  });

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (loading) return;
    setErrors(false);
    setLoading(true);

    const inputs = [
      {
        value: customer.name,
        name: "Nome",
        pattern: /^[A-Za-z\u00C0-\u00FF]{3,}$/,
      },
      {
        value: customer.identity,
        name: "CPF",
        pattern: /^\d{11}$/,
      },
      {
        value: customer.email,
        name: "E-mail",
        pattern:
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      },
    ];

    const hasEmptyField = inputs.find(
      (input) => input.value === undefined || !input.value
    );

    if (hasEmptyField) {
      setMessage(
        `Para continuar, por favor, preencha corretamente o campo "${hasEmptyField.name}".`
      );
      setLoading(false);
      return setErrors(true);
    }

    const validPhoneNumber = isPossiblePhoneNumber(customer.phoneNumber || "");

    if (!validPhoneNumber) {
      setMessage(
        `Para continuar, por favor, no campo "Telefone" selecione a bandeira do país e preencha o número no formato correto`
      );
      setLoading(false);
      return setErrors(true);
    }

    const regexReplace = / |_|\s|\/|\.|-|\(|\)|\+/g;

    const hasInvalidInput = inputs.find((input) => {
      return !input.pattern.test(input.value.replace(regexReplace, ""));
    });

    if (hasInvalidInput) {
      setMessage(
        `Para continuar, por favor, preencha corretamente o campo "${hasInvalidInput.name}".`
      );
      setLoading(false);
      return setErrors(true);
    }

    const customerFormattedData = {
      ...customer,
      identity: convertStringToDigits(customer.identity),
      phoneNumber: convertStringToDigits(customer.phoneNumber),
    };

    await sendUpdateRequest(customerFormattedData);
  };

  const handleInput = (event, name) => {
    if (name === "phoneNumber") {
      return setCustomer({ ...customer, [name]: event });
    }

    if (name === "mailNotification") {
      return setCustomer({ ...customer, [name]: !customer[name] });
    }

    setCustomer({ ...customer, [name]: event.target.value });
  };

  if (!Object.keys(customer).length > 0) {
    return <LoadingSpinner color={"light"} />;
  }

  return (
    <Container type={"minimal"} pageName="Dados pessoais">
      <S.Wrapper>
        {hasIncompleteRegister && (
          <S.Errors alert>
            {" "}
            <BsExclamationCircle />{" "}
            <span>
              <b>Conclua o seu cadastro com atenção!</b>
              <br />
              Confira seus dados, pois serão utilizados na emissão de
              certificado após a conclusão dos cursos.
            </span>{" "}
          </S.Errors>
        )}
        {errors && (
          <S.Errors>
            {" "}
            <FiAlertTriangle /> <span>{message}</span>{" "}
          </S.Errors>
        )}
        <S.Form onSubmit={(e) => handleSubmitForm(e)}>
          <div className="one-column">
            <label htmlFor={"name"} className={"custom-label"}>
              Nome
            </label>
            <MaskedInput
              id="name"
              key="name"
              name="name"
              type="name"
              value={customer.name}
              onChange={(e) => handleInput(e, "name")}
              autoComplete="off"
            />
          </div>{" "}
          <div className="one-column">
            <label htmlFor={"identity"} className={"custom-label"}>
              CPF
            </label>
            <MaskedInput
              id="identity"
              key="identity"
              name="identity"
              type="identity"
              value={customer.identity}
              mask="999.999.999-99"
              onChange={(e) => handleInput(e, "identity")}
              autoComplete="off"
            />
          </div>
          <S.Contact>
            <S.SubTitle>Contato</S.SubTitle>
            <S.Vivo>
              {strings.vivo.personalDataTitle}
              <img src={infoIcon} alt={"info"} />
              <span>{strings.vivo.personalDataDescription}</span>
            </S.Vivo>
          </S.Contact>
          <div className="phone-number-column">
            <label htmlFor={"phoneNumber"} className={"custom-label phone"}>
              Telefone
            </label>
            <PhoneInput
              id="phoneNumber"
              key="phoneNumber"
              name="phoneNumber"
              defaultCountry={"BR"}
              initialValueFormat="national"
              labels={pt_BR}
              value={customer.phoneNumber}
              onChange={(e) => handleInput(e, "phoneNumber")}
            />
          </div>
          <div className="one-column">
            <label htmlFor={"email"} className={"custom-label"}>
              E-mail
            </label>
            <MaskedInput
              id="email"
              key="email"
              name="email"
              type="email"
              value={customer.email}
              readOnly
            />
          </div>
          <div className="one-column">
            <Checkbox
              label={"Permitir envio de certificado por email"}
              value={customer.mailNotification}
              onChange={(e) => handleInput(e, "mailNotification")}
              id={"mailNotification"}
            />
          </div>
          <CTAButton
            id={"btn-personal-data-salvar"}
            name={"btn-personal-data-salvar"}
            text={loading ? <Spinner color="dark" /> : "Salvar"}
          />
        </S.Form>
      </S.Wrapper>
    </Container>
  );
};

export default ProfileForm;
