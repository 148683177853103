import React from "react";
import { AccountSubscriptionDisclaimer, Container } from "../../../components";
import {
  RoundedNumberOne,
  RoundedNumberThree,
  RoundedNumberTwo,
} from "../../../assets/images";

import * as S from "./styles";

const Android = () => {
  return (
    <Container type={"minimal"} pageName={"Cancelar assinatura"}>
      <S.Wrapper>
        <S.Title>Poxa, já vai tão cedo?</S.Title>
        <S.Text>
          Que pena que você quer cancelar a sua assinatura*.
          <br />
          Acesse as <strong>
            configurações de Assinatura da Play Store
          </strong>{" "}
          para realizar o cancelamento. Veja como é simples
        </S.Text>
        <S.StepsContainer>
          <S.StepsItem>
            <S.ImageNumber src={RoundedNumberOne} alt={"Passo 1"} />
            <S.Text>
              Acesse a <strong>Play Store</strong>.
            </S.Text>
          </S.StepsItem>

          <S.StepsItem>
            <S.ImageNumber src={RoundedNumberTwo} alt={"Passo 2"} />
            <S.Text>
              À esquerda, toque em <strong>“Minhas assinaturas”</strong>.
              Selecione a assinatura que você quer cancelar.
            </S.Text>
          </S.StepsItem>

          <S.StepsItem>
            <S.ImageNumber src={RoundedNumberThree} alt={"Passo 3"} />
            <S.Text>
              Toque em <strong>"Gerenciar”</strong> e{" "}
              <strong>"Cancelar assinatura”</strong>. No pop-up de confirmação,
              toque em <strong>"Sim”</strong>.
            </S.Text>
          </S.StepsItem>
        </S.StepsContainer>
        <S.Text>
          O cancelamento pode demorar alguns minutos para ser confirmado em
          nossa plataforma.
        </S.Text>
        <AccountSubscriptionDisclaimer />
      </S.Wrapper>
    </Container>
  );
};

export default Android;
