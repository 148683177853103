import React, { createContext, useContext, useEffect } from "react";
import { useFinishedCourses, useMyList } from "../hooks";

const UserContentContext = createContext();
UserContentContext.displayName = "UserContentContext";

const UserContentProvider = ({ children }) => {
  const { data: finishedCoursesList, refetch: refetchFinisedCourses } =
    useFinishedCourses();

  const { fetchFavorites } = useMyList();

  useEffect(() => {
    fetchFavorites();
  }, []);

  return (
    <UserContentContext.Provider
      value={{ finishedCoursesList, refetchFinisedCourses }}
    >
      {children}
    </UserContentContext.Provider>
  );
};

/**
 *
 * @returns UserContentContext
 */
const useContentContext = () => {
  const context = useContext(UserContentContext);

  if (!context) {
    throw new Error(
      "UserContentContext must be used within an UserContentProvider"
    );
  }

  return context;
};

export { UserContentContext, UserContentProvider, useContentContext };
