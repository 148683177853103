import React from "react";
import { useAuthContext } from "../../context/AuthContext";
import * as S from "./styles";

const CheckoutHeader = () => {
  const { signOut } = useAuthContext();

  return (
    <S.Wrapper>
      <div>
        <button
          className="header-exit-button"
          id="btn-checkout_header-sair"
          name="btn-checkout_header-sair"
          onClick={() => signOut()}
        >
          SAIR
        </button>
      </div>
    </S.Wrapper>
  );
};

export default CheckoutHeader;
