import React, { useEffect } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import Datalayer from "../services/datalayer";

import Login from "../pages/Login";
import Signup from "../pages/Signup";
import ForgotPassword from "../pages/ForgotPassword";
import RecoverPassword from "../pages/RecoverPassword";
import NewPassword from "../pages/NewPassword";
import LoginPassword from "../pages/LoginPassword";
import LoginMessage from "../pages/LoginMessage";
import Redirect from "../pages/Redirect";
import { giftRoutes } from "./giftRoutes";
import LoginIntegration from "../pages/LoginIntegration";

export function AuthRoutes() {
  const location = useLocation();

  useEffect(() => {
    Datalayer.onPageLoad();
  }, [location]);

  const authRoutes = useRoutes([
    { path: "/entrar", exact: true, element: <Login /> },
    { path: "/entrar/integracao", exact: true, element: <LoginIntegration /> },
    { path: "/entrar/senha", exact: true, element: <LoginPassword /> },
    { path: "/entrar/info", exact: true, element: <LoginMessage /> },
    { path: "/cadastro", exact: true, element: <Signup /> },
    { path: "/esqueci-minha-senha", exact: true, element: <ForgotPassword /> },
    { path: "/recuperar-conta", exact: true, element: <RecoverPassword /> },
    { path: "/nova-senha", exact: true, element: <NewPassword /> },
    { path: "/signup", element: <Navigate replace to="/cadastro" /> },
    { path: "/redirect", element: <Redirect /> },
    ...giftRoutes,
    { path: "*", element: <Navigate replace to="/entrar" /> },
  ]);

  return authRoutes;
}
