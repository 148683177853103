import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import {
  Alert,
  Button,
  LoginContainer,
  LoginPageTitle,
} from "../../components";
import { requests } from "../../services/requests";
import Datalayer from "../../services/datalayer";
import { useSentry } from "../../hooks";
import * as S from "./styles";

const EMAIL_PROVIDERS = {
  gmail: "https://mail.google.com/",
  outlook: "https://www.outlook.com/",
  hotmail: "https://www.outlook.com/",
  icloud: "https://www.icloud.com/#mail",
  yahoo: "https://mail.yahoo.com/",
};

const LoginMessage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const props = location.state;
  const emailProvider = props?.email?.split("@")[1]?.split(".")[0];
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [resendButtonVisible, setResendButtonVisible] = useState(false);
  const { logException } = useSentry(false);

  useEffect(() => {
    const oneMinuteInMilliseconds = 60000;
    const timeout = setTimeout(() => {
      setResendButtonVisible(true);
    }, oneMinuteInMilliseconds);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (!props?.email || !props?.type) {
      navigate("/entrar");
    }
  }, [props]);

  const datalayerLabel = {
    login: "reenviar-link-magico",
    password: "reenviar-recuperacao-de-senha",
  };

  const messages = {
    login: {
      title: "Acessar por e-mail",
      subtitle: `O acesso à sua conta foi enviado para o seu e-mail (${props?.email}) e expira em dez minutos.`,
    },
    password: {
      title: "Recuperar senha",
      subtitle: (
        <>
          As instruções para redefinir a sua senha foram enviadas para o seu
          e-mail:
          <br />
          <span className="text-white">{props?.email}</span>
        </>
      ),
    },
  };

  const handleResend = async () => {
    setLoading(true);
    let recoverPassword = false;
    if (props.type === "password") recoverPassword = true;
    try {
      await requests.sendMagicLinkEmail(props.email, recoverPassword);
      setAlert({
        type: "success",
        message: "As instruções foram enviadas para o seu e-mail novamente.",
      });
    } catch (error) {
      const exceptionParams = {
        error,
        transactionName: "Login",
        origin: recoverPassword ? "resendRecoveryPassword" : "resendMagicLink",
        tags: [
          { label: "errorMessage", value: error.message },
          { label: "errorCode", value: error.code },
        ],
        extras: [{ label: "data", value: error }],
        email: props.email,
      };
      logException(exceptionParams);

      setAlert({
        type: "error",
        message:
          "Ocorreu um erro. Seu e-mail não foi reenviado. Tente novamente.",
      });
    }
    setLoading(false);
  };

  return (
    <LoginContainer>
      <S.Wrapper>
        {!!alert.message && <Alert variant={alert.type} text={alert.message} />}

        <LoginPageTitle
          title={messages[props?.type]?.title}
          subtitle={messages[props?.type]?.subtitle}
          titleStyle={{ marginBottom: "20px" }}
        />
        {EMAIL_PROVIDERS[emailProvider] && (
          <Button
            onClick={() =>
              window.open(EMAIL_PROVIDERS[emailProvider], "_blank")
            }
          >
            Ir para {emailProvider}
          </Button>
        )}
        <div>
          <p>
            Se você não encontrou o e-mail na caixa de entrada, verifique a sua
            pasta de spam, a lixeira ou aguarde alguns minutos.
          </p>
          {resendButtonVisible && (
            <S.SimpleButton
              onClick={() => {
                if (!loading) {
                  Datalayer.onClickLink("login", datalayerLabel[props.type]);
                }
                handleResend();
              }}
              disabled={loading}
            >
              REENVIAR
              {loading && (
                <>
                  {" "}
                  <Spinner size="sm" />
                </>
              )}
            </S.SimpleButton>
          )}
        </div>
      </S.Wrapper>
    </LoginContainer>
  );
};

export default LoginMessage;
