import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    background-color: ${theme.darkTheme.colors.coal};
    overflow: hidden;
    margin: ${theme.spacings.medium} 0;
    padding: 0 ${theme.spacings.medium};
    min-height: 105px;
  `}
`;

export const Image = styled.img`
  ${({ theme }) => css`
    width: 133px;
    height: auto;
    border-radius: 5px;
  `}
`;

export const PlayButtonContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    min-width: 40px;
  `}
`;

export const LockedButtonContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    min-width: 40px;
    height: 40px;
    border: 1px solid ${theme.darkTheme.colors.white};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const WatchedTag = styled.div`
  ${({ theme }) => css`
    padding: 1px 5px;
    border-radius: 6px;
    color: ${theme.darkTheme.colors.darkBlack};
    background-color: ${theme.darkTheme.colors.green};
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    font-size: ${theme.font.size.smallest};
  `}
`;

export const ContentInfo = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${theme.gap.small};
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
  `}
`;
export const Duration = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.gap.small};
    color: rgb(119, 119, 119);
    font-size: ${theme.font.size.smallest};
  `}
`;
