import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuthContext } from "../../context/AuthContext";
import Datalayer from "../../services/datalayer";
import { useWindowWidthCalculate } from "../../hooks";
import { translateAmplifyErrorMessage } from "../../util/messages";

import {
  GoBackButton,
  Input,
  Button,
  LoginPageTitle,
  LoginContainer,
  Alert,
} from "../../components";

import * as S from "./styles";

const schema = yup.object({
  email: yup
    .string()
    .required("Preencha um email válido")
    .email("Preencha um email válido")
    .trim()
    .lowercase(),
});

const ForgotPassword = () => {
  const { isDesktop } = useWindowWidthCalculate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { forgotPassword } = useAuthContext();

  const [alert, setAlert] = useState({});

  const onSubmit = async (data) => {
    try {
      await forgotPassword(data.email);
      return navigate("/recuperar-conta", {
        state: { email: data.email },
      });
    } catch (error) {
      const { message } = translateAmplifyErrorMessage(error?.message);

      setAlert({
        message,
        type: "error",
      });
    }
  };

  return (
    <LoginContainer>
      {!isDesktop && <GoBackButton />}

      <S.LoginWrapper>
        {isDesktop && <GoBackButton />}

        {!!alert.message && <Alert variant={alert.type} text={alert.message} />}

        <LoginPageTitle
          title={"Esqueci minha senha"}
          subtitle={"Informe o seu e-mail para criar uma senha nova."}
        />

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label={"E-mail"}
            inputName={"email"}
            registerFunction={register}
            errors={errors}
            style={{ textTransform: "lowercase" }}
          />

          <Button
            variant={"white"}
            size={"full"}
            type="submit"
            onClick={() =>
              Datalayer.onClick(
                "forgot-password",
                "botao:recuperacao-de-senha",
                "recovery-password"
              )
            }
          >
            Recuperar Senha
          </Button>
        </S.Form>
      </S.LoginWrapper>
    </LoginContainer>
  );
};

export default ForgotPassword;
