import styled from "styled-components";

export const InputGroup = styled.div`
  position: relative;

  span {
    pointer-events: none;
    position: absolute;
    top: 13px;
    left: 15px;
    z-index: 2;
    border: none;
    background: transparent;
    outline: none;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  margin: 10px 0;
  padding: 0.3rem 2.5rem 0.3rem 2.8rem;
  display: block;
  font-family: "MaisonNeueBook", sans-serif;
  font-size: 16px;
  background-color: #201e20;
  color: #ffffff;
  border-radius: 5px;
  border: none;

  :focus-visible {
    outline: #d7ef7d auto 1px;
  }

  @media (min-width: 993px) {
    border: 1px solid #cccccc;
  }
`;
