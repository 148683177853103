export const VERSION = "v1";
export const CDS_API_VERSION = "v1";

export const notifications = {
  title: "",
  insert: "top",
  container: "top-right",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
  dismiss: {
    duration: 1000 * 3,
    onScreen: true,
  },
};
