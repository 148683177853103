import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 1rem 0;
  flex: 1;
`;

export const ResultsTitle = styled.h2`
  font-size: 16px;
  padding-bottom: 1rem;
  border-bottom: 2px solid #5f5d5f;
  margin-bottom: 0;
`;

export const ResultsList = styled.ul`
  list-style: none;
  padding-left: 0;

  ${(props) =>
    props.buttons &&
    css`
      display: flex;
      flex-wrap: wrap;

      button {
        font-family: "MaisonNeue", sans-serif;
        background-color: #cccccc;
        border: none;
        border-radius: 25px;
        padding: 4px 8px;
        margin: 5px;
      }
    `}
`;

export const ResultsItem = styled.li`
  margin: 1rem 0;

  a {
    font-family: "MaisonNeueBook", sans-serif;
    color: #fff;
    line-height: 24px;
    padding: 5px 0;

    :hover {
      color: #cff16b;
      text-decoration: none;
      -webkit-text-decoration: none;
    }
  }
`;

export const SearchCard = styled.div`
  width: 90%;
  max-width: 475px;
  min-height: 160px;
  background-color: #323132;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 10px auto;
  padding: 16px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center
  gap: 15px;
`;

export const ImageContainer = styled.div`
  width: 80px;
  max-height: 125px;
  display: flex;
  border-radius: 5px;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

export const InfoContainer = styled.div`
  font-family: "MaisonNeueBook", sans-serif;
  width: 65%;
  height: 100%;
  max-height: 125px;

  p {
    margin-bottom: 0;
  }
`;
