import React from "react";
import { useNavigate } from "react-router-dom";
import { CTAButton, OnboardingHeader } from "../../components";
import * as S from "./styles";

const Onboarding = () => {
  const navigate = useNavigate();
  return (
    <S.Wrapper>
      <OnboardingHeader />
      <S.Container>
        <h1>Sinta-se em casa!</h1>
        <p>
          Finalmente você tem acesso a todos os nossos conteúdos. Que tal
          escolher um curso e começar a aprender agora mesmo? <br />
          <br />
          Algumas dicas sobre a plataforma foram enviadas para o seu e-mail.
        </p>
        <CTAButton
          onClick={() => navigate("/onboarding/preferencia-categorias")}
        >
          Continuar
        </CTAButton>
      </S.Container>
    </S.Wrapper>
  );
};

export default Onboarding;
