import styled from "styled-components";

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;

  svg {
    height: 30px;
    width: 30px;
    margin-right: 8px;
    color: #fff;
  }
`;
export const SearchInput = styled.input`
  color: #fff;
  background: transparent;
  border: 0;
  width: 100%;
`;
export const NotFoundContainer = styled.div`
  > p {
    margin-bottom: 4rem;
  }
`;
