import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  margin: 0;
  padding-bottom: 10vh;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family.maisonNeue};
    font-size: ${theme.font.size.small};
    font-weight: ${theme.font.weight.light};
    line-height: ${theme.font.lineHeight.small};
    text-align: center;
    padding: ${theme.spacings.smallest};

    @media (min-width: ${theme.breakPoint.tablet}) {
      font-size: ${theme.font.size.default};
    }
  `}
`;
