import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1000;
  height: 50px;
  margin-bottom: 10px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #9b9b9b;
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const Span = styled.span`
  position: absolute;
  top: 8px;
  right: 5px;
  z-index: 4;
  cursor: pointer;
  color: #aca9b3;
  line-height: 20px;
  font-size: 16px;
  padding: 0.4rem 0.45rem 0.6rem;

  :hover {
    transition: all 0.1s ease-out;
    color: #d7ef7d;
  }
`;

export const ResultsContainer = styled.div`
  background-color: #100F10;
  min-height: 80vh;
  max-height: 100%;
  overflow-y: auto;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: 450px){
    scrollbar-width: thin;
    ::-webkit-scrollbar-track {
      background-color: #5F5D5F;
      border: none;
      border-radius: 15px;
    }
    ::-webkit-scrollbar {
      height: 2px;
      width: 8px;
      display: block;
    }
    ::-webkit-scrollbar-thumb {
      background: #D7EF7D;
      border-radius: 15px;
      margin-right: 2px;
    }
  }

  @media (min-width: 993px){
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    padding: 8px 16px;
`;
