import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const AutoplayContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;

    @media (${device.tablet}) {
      justify-content: flex-start;
      padding: ${theme.spacings.smaller} 0;
    }
  `}
`;
