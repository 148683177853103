import React, { useEffect, useState } from "react";
import * as S from "./styles";

const AnimationContainer = ({ show, children }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };
  return (
    !!shouldRender && (
      <S.Wrapper
        style={{ animationName: `${show ? "fadeIn" : "fadeOut"}` }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </S.Wrapper>
    )
  );
};

export default AnimationContainer;
