import React from "react";
import * as S from "./styles";

const Title = ({ children, size, ...rest }) => {
  const font = {
    smallest: "smallest",
    smaller: "smaller",
    small: "small",
    default: "default",
    medium: "medium",
    large: "large",
    larger: "larger",
    largest: "largest",
  };
  return (
    <S.Text size={font[size] || font["default"]} {...rest}>
      {children}
    </S.Text>
  );
};

export default Title;
