import React from "react";
import strings from "../../config/strings.json";
import * as S from "./styles";
import ShareModal from "../ShareModal";

const CertificateButton = ({
  id,
  onClick,
  type,
  role,
  fileName = "certificado",
  showShareOptions,
  certificateUrl,
}) => {
  const shareText = `Finalizei um curso na Casa do Saber e garanti o meu certificado. Olha só: \n \n ${certificateUrl} \n \n Também gostaria de fazer cursos da Casa do Saber? Acesse:`;

  return (
    <>
      {type === "download" && (
        <S.DownloadAnchor
          download={fileName}
          href={certificateUrl}
          target="_blank"
          id={"btn-certificate_button-download"}
          name={"btn-certificate_button-download"}
          onClick={onClick}
        >
          <span>Baixar</span>
        </S.DownloadAnchor>
      )}

      {type === "share" && (
        <S.ShareButton
          type={type}
          id={id}
          name={id}
          onClick={onClick}
          role={role}
        >
          Compartilhar
          {showShareOptions && (
            <ShareModal
              whatsappText={shareText}
              facebookText={shareText}
              emailSubject="Mais um certificado Casa do Saber"
              emailText={shareText}
              url={strings.certificates.shareButtonUrl}
              copyText={certificateUrl}
              options={{
                emailEnabled: true,
                facebookEnabled: true,
                linkedinEnabled: false,
                whatsappEnabled: true,
              }}
            />
          )}
        </S.ShareButton>
      )}
    </>
  );
};

export default CertificateButton;
