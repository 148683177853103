import React, { useEffect } from "react";
import { IconContext } from "react-icons";
import { IoCloseCircle } from "react-icons/io5";
import PlayerSambaVideos from "./PlayerSambaVideos";
import * as S from "./styles";

const Player = ({
  video,
  ignoreProgress,
  courseId,
  isAutoPlay,
  onClose,
  onFinishVideo,
}) => {
  const { id: classId } = !!video && video;

  useEffect(() => {
    if (classId) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => (document.body.style.overflow = "auto");
  }, [classId]);

  if (!classId) return null;

  return (
    <S.PlayerContainer
      onClick={onClose}
      id={"btn-player_container-close_overlay"}
      name={"btn-player_container-close_overlay"}
    >
      <>
        <S.CloseButton
          id={"btn-player-close_overlay"}
          name={"btn-player-close_overlay"}
          onClick={onClose}
        >
          <IconContext.Provider
            value={{
              color: "#ffff",
              size: "100%",
              className: "video-close-button",
            }}
          >
            <IoCloseCircle />
          </IconContext.Provider>
        </S.CloseButton>
        <PlayerSambaVideos
          video={video}
          ignoreProgress={ignoreProgress}
          courseId={courseId}
          isAutoPlay={isAutoPlay}
          onFinishVideo={onFinishVideo}
        />
      </>
    </S.PlayerContainer>
  );
};

export default Player;
