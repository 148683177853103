import styled from "styled-components";

export const Wrapper = styled.div`
  width: 90%;
  max-width: 475px;
  min-height: 160px;
  background-color: #323132;
  border-radius: 10px;
  box-sizing: border-box;
  margin: auto;
  padding: 16px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;

export const ImageContainer = styled.div`
  width: 80px;
  max-height: 125px;
  display: flex;
  border-radius: 5px;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

export const InfoContainer = styled.div`
  width: 65%;
  height: 100%;
  max-height: 125px;

  p {
    margin-bottom: 0;
    font-size: 16px;
  }

  .search-course-author {
    font-family: "MaisonNeue", sans-serif;
    font-weight: 100;
    padding-bottom: 0.5em;
  }

  .classes img {
    fill: #fff;
  }
`;
