import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const MainWrapper = styled.main`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacings.medium};
    background-color: ${theme.lightTheme.colors.background};
    height: 100%;

    @media (${device.tablet}) {
      margin: auto;
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 414px;
    margin: auto;
    width: 100%;
    padding: 25px 16px 0px;
    min-height: calc(100vh - 64px); // header height: 62px
    margin: auto;
    position: relative;

    .alert-box span {
      color: ${theme.lightTheme.colors.text};
    }
  `}
`;
