import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0 -0.7rem; // offset the container margin

    @media (${device.tablet}) {
      margin: 0;
      border: 1px solid ${theme.darkTheme.colors.coal};
      width: 100%;
    }
  `}
`;

export const TabsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.14));
    margin-bottom: 20px;
  `}
`;

export const Tab = styled.button`
  ${({ theme, active }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 10px 0;
    font-size: ${theme.font.size.small};
    border: none;
    color: ${theme.darkTheme.colors.gray};
    background-color: ${theme.darkTheme.colors.coal};
    border-bottom: 2px solid transparent;
    outline: none;

    ${active &&
    css`
      color: ${theme.darkTheme.colors.green};
      border-bottom: 2px solid ${theme.darkTheme.colors.green};
    `}
  `}
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacings.smaller};
  `}
`;
