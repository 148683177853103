import { useMutation } from "react-query";
import { requests } from "../services/requests";

const queryKeys = {
  activeCampaign: "active-campaign",
};

export const useActiveCampaignTag = (options = {}) => {
  return useMutation(
    [queryKeys.activeCampaign],
    (params) => requests.createActiveCampaignTag(params),
    options
  );
};

export const useActiveCampaignContact = (options = {}) => {
  return useMutation(
    [queryKeys.activeCampaign],
    (params) => requests.createActiveCampaignContact(params),
    options
  );
};

export { queryKeys as activeCampaignQueryKeys };
