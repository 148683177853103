import React from "react";
import * as S from "./styles";

const ModalRoot = ({ children, showModal, closeModal }) => {
  return (
    <S.StyledModalRoot
      isOpen={showModal}
      toggle={closeModal}
      backdrop={"static"}
      className={"custom-modal"}
      contentClassName={"content-custom-modal"}
      centered
    >
      <S.CloseButton
        className="modal-close-button"
        onClick={closeModal}
        id={"btn-close_modal"}
        name={"btn-close_modal"}
      >
        <i className="fas fa-times" />
      </S.CloseButton>

      {children}
    </S.StyledModalRoot>
  );
};

export default ModalRoot;
