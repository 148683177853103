import React, { useEffect } from "react";
import { useCheckoutContext } from "../../context/CheckoutContext";
import {
  useCheckoutSubscriptionStatus,
  usePromotionalCampaign,
} from "../../hooks";
import {
  CheckoutHeader,
  CheckoutFooter,
  StepperCustom,
  CheckoutVindi,
  CheckoutVindiBestFriday,
} from "../../components";

import * as S from "./styles";

const CheckoutPayment = () => {
  const { currentStep, checkoutSteps, changeStep } = useCheckoutContext();
  const { data: campaign } = usePromotionalCampaign();

  useEffect(() => {
    changeStep("pagamento");
  }, []);

  useCheckoutSubscriptionStatus();

  return (
    <S.Wrapper className="wrapper">
      <CheckoutHeader />
      <StepperCustom steps={checkoutSteps} currentStep={currentStep} />
      {!!campaign ? <CheckoutVindiBestFriday /> : <CheckoutVindi />}
      <CheckoutFooter />
    </S.Wrapper>
  );
};

export default CheckoutPayment;
