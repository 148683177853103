import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Wrapper = styled.footer`
  ${({ theme }) => css`
    font-family: "MaisonNeueBook", sans-serif;
    color: ${theme.lightTheme.colors.text};
    font-size: 12px;
    text-align: left;
    padding: 16px;
    position: absolute;
    bottom: 0;
    max-width: 414px;
    margin: 0 auto;
    width: 100%;

    a {
      color: ${theme.lightTheme.colors.text};
      text-decoration: underline;
      text-underline-offset: 1px;

      :hover {
        font-weight: 800;
        color: ${theme.lightTheme.colors.text};
        transition: all 0.2s ease-in;
      }
    }

    .whatsapp-float {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      width: 3.5rem;
      height: 3.5rem;
      bottom: 4.5rem;
      right: 1rem;
      background-color: #00d000;
      color: #fff;
      border-radius: 50px;
      text-align: center;
      font-size: 40px;
      z-index: 100;

      @media (${device.tablet}) {
        bottom: 2rem;
      }
    }
  `}
`;
