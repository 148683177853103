import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    svg.question-mark:hover {
      path {
        fill: ${theme.darkTheme.colors.green};
      }
    }

    //AccountSubscriptionDisclaimer spacing
    min-height: calc(100vh - 62px); //header height
    position: relative;
    padding-bottom: 180px;

    @media (min-width: 900px) {
      min-height: calc(100vh - 151px); //header height
    }
  `}
`;

export const StatusText = styled.span`
  display: flex;
  gap: 10px;
  color: ${(props) => props.theme.darkTheme.colors[props.color]};
  svg {
    font-size: large;
  }
`;

export const BillingBox = styled.div`
  ${({ theme, active }) => css`
    width: 100%;
    border: 1px solid ${active ? theme.darkTheme.colors.green : "transparent"};
    font-size: 14px;
    border-radius: 5px;
    padding: ${active ? "0.4rem 1rem" : "0"};
    span {
      font-weight: bold;
      display: block;
    }
    @media (${device.tablet}) {
      font-size: 16px;
    }
  `}
`;
