import styled, { css } from "styled-components";

export const NoCourseContainer = styled.div`
  margin-bottom: 4em;
`;

export const Title = styled.h2`
  font-family: "MaisonNeue", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1em;
  margin-top: 1em
  margin-bottom: 0;
`;
export const NoCourseText = styled.p`
  font-family: "MaisonNeue", sans-serif;
  font-weight: 500;
  font-size: 1em;
  color: #fff;
  margin: 1em 0;
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    max-width: 435px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.gap.larger};
  `}
`;
