import React from "react";
import PhoneSearch from "../../assets/icons/phone-search.svg";
import * as S from "./styles";

const NoCourseContent = () => {
  return (
    <S.Wrapper>
      <S.IconContainer>
        <img
          src={PhoneSearch}
          alt="Ícone de nenhum curso encontrado na categoria"
        />
      </S.IconContainer>

      <S.Title>Não encontramos nenhum curso cadastrado na categoria</S.Title>

      <S.Description>
        Você pode utilizar a busca para encontrar algum título de sua
        preferência ou acessar o menu para escolher outra categoria.
      </S.Description>
    </S.Wrapper>
  );
};

export default NoCourseContent;
