import Axios from "axios";
import { secureStorage } from "../util/SecureStorage";
import CategoryExceptions from "../constants/categoryExceptions";
import {
  apiCasaDoSaber,
  apiCDS,
  apiLambdaIntegration,
  apiMagicLink,
  apiMundipagg,
  baseCDSApiUrl,
} from "./api";

/**
 * Manages all requests to the APIs.
 */
export const requests = {
  /**
   * Realiza o cadastro de novo usuário
   * @param email string
   * @param password string
   * @param phoneNumber string
   * @returns Promise
   */
  async signup(email, password, phoneNumber) {
    const { data } = await apiCDS.post("/signup", {
      email,
      password,
      phoneNumber,
    });
    return data;
  },

  /**
   * Busca o status da assinatura
   * @returns Promise
   */
  async getSubscriptionStatus() {
    const { data } = await apiCDS.get("/subscriptions/status");
    return data;
  },

  /**
   * Busca os itens para pesquisa de cancelamento
   * @returns Promise
   */
  async getCancellationReasons() {
    const { data } = await apiCDS.get("/research-cancellation-reasons");
    return data;
  },

  /**
   * Salva as respostas pesquisa de cancelamento
   * @param body object
   * @returns Promise
   */
  async saveCancellationReasons(body) {
    const { data } = await apiCDS.post(
      "/users/research/cancellation-reasons",
      body
    );
    return data;
  },

  /**
   * Busca o status da assinatura de presente
   * @param giftCode string
   * @returns Promise
   */
  async getGiftSubscriptionStatus(giftCode) {
    const { data } = await apiCDS.get(`/subscriptions/gift/${giftCode}`);
    return data;
  },

  /**
   * Busca dados do usuário logado
   */
  async getUser() {
    const { data } = await apiCDS.get("/users/me");

    const hasSubscriptionActive =
      data?.subscriptionDetails?.status === "active" ||
      data?.subscriptionDetails?.status === "unrenewed";

    if (hasSubscriptionActive) {
      secureStorage.setItem("reference", data?.subscriptionDetails?.reference);
    }

    return data;
  },

  /**
   * Busca verificar se o cartão é válido
   * @param origin string
   * @param email string
   */
  async getCardExpiration(origin, email) {
    let validCard = "";

    try {
      if (origin === "vindi") {
        const { data } = await apiCDS.get(`/customers/card-expiration`);
        validCard = data.stillValid;
      } else {
        const { data } = await apiCasaDoSaber.get(
          `/subscription/${email}/card-expiration`
        );
        validCard = data.expiry_status === "fit" ? true : false;
      }

      return validCard;
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Busca os detalhes de cobrança no gateway
   * @param reference string
   * @param origin string
   */
  async getGatewayData(reference) {
    try {
      const { data } = await apiCDS.get(`/subscriptions/${reference}/bills`);

      return {
        installments: data.nextBill.installments,
        installmentValue: data.nextBill.installmentAmount,
        cardBrand: data.cardBrand,
        cardFourDigits: data.lastFourDigits,
        nextBillingDate: data.nextBill.billingAt,
        nextBillingPrice: data.nextBill.amount,
      };
    } catch (error) {
      return null;
    }
  },

  /**
   * Busca os detalhes de cobrança no gateway
   * @param reference string
   * @param body object
   */
  async updateMundipaggCard(reference, body) {
    return await apiMundipagg.patch(`/subscriptions/${reference}/card`, body);
  },

  /**
   * Retorna lista de cursos mais assistidos
   * @param params object
   * @returns Promise
   */
  async getPopularCourses(params) {
    const { data } = await apiCDS.get("/courses/populars", {
      params,
    });
    return data;
  },

  /**
   * Retorna lista de cursos em andamento pelo usuário
   * @param params object
   * @returns Promise
   */
  async getWatchingCourses(params) {
    const { data } = await apiCDS.get("/users/courses/watching", {
      params,
    });
    return data;
  },

  /**
   * Retorna lista finalizados pelo usuário
   * @param params object
   * @returns Promise
   */
  async getFinishedCourses(params) {
    const { data } = await apiCDS.get("/users/courses/finished", {
      params,
    });
    return data;
  },

  /**
   * Retorna lista de autores
   * @param params object
   * @returns Promise
   */
  async getAuthors(params) {
    const { data } = await apiCDS.get("/authors", {
      params,
    });
    return data;
  },

  /**
   * Retorna lista de categorias sem cursos
   * @param params object
   * @returns Promise
   */
  async getCategories(params) {
    const { data } = await apiCDS.get("/categories", {
      params,
    });

    if (!data) return [];

    const filteredCategories = data.filter(
      (course) =>
        !CategoryExceptions.hiddenFromApplication.includes(course.slug)
    );

    return filteredCategories;
  },

  /**
   * Retorna lista de cursos das categorias
   * @param params object
   * @returns Promise
   */
  async getCategoriesWithCourses(params) {
    const { data } = await apiCDS.get("/categories/courses", {
      params,
    });
    return data;
  },

  /**
   * Busca os cursos de determinada categoria pelo id
   * @param categoryId string
   * @returns Promise
   */
  async getCategoryCourses({ categoryId }) {
    const { data } = await apiCDS.get(`/categories/${categoryId}`);

    return data;
  },

  /**
   * Busca os detalhes de um autor
   * @param authorId string
   * @returns Promise
   */
  async getAuthorDetails(authorId) {
    const { data } = await apiCDS.get(`/authors/${authorId}/courses`);
    return data;
  },

  /**
   * Busca os cursos relacionadas a um autor
   * @param authorId string
   * @returns Promise
   */
  async getCoursesByAuthor(authorId) {
    const { data } = await apiCDS.get(`/authors/${authorId}/courses`);

    /**
     * Convert raw response to a semantic data
     * know by the components
     */
    const convertData = (raw) => {
      return {
        id: raw.id,
        name: raw.name,
        description: raw.description,
        photo: raw.images?.thumbUrl,
        contents: raw.courses.map((item) => ({
          id: item.id,
          slug: item.slug,
          title: item.title,
          thumb: item.images?.thumbUrl,
        })),
      };
    };
    try {
      return convertData(data);
    } catch (error) {
      throw new Error(error?.message);
    }
  },

  /**
   * Busca detalhes de um curso
   * @param courseId string
   * @param userId string
   * @returns Promise
   */
  async getCourse(courseId) {
    const { data: course } = await apiCDS.get(`/courses/${courseId}`);
    const { data: customer } = await apiCDS.get(`/courses/${courseId}/user`);

    /**
     * Convert raw response to a semantic data
     * know by the components
     */
    const convertData = (raw) => {
      const unifyContents = () => {
        if (!raw.course && !raw.customer) return [];
        if (!raw.customer && !!raw.course) return raw.course.contents;

        const customerContent = raw.customer.contents?.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});

        return raw.course?.contents.map((content) => ({
          ...content,
          ...(customerContent[content.id] || {}),
        }));
      };

      const unifiedContents = unifyContents();

      return {
        id: raw.course.id,
        userCourseId: raw.customer.userCourseId,
        slug: raw.course.slug,
        title: raw.course.title,
        description: raw.course.description,
        complementaryFile: raw.course.images.complemantaryFileUrl,
        cover: raw.course.images.coverUrl,
        coverMobile: raw.course.images.coverMobileUrl,
        authorId: raw.course.author.id,
        author: raw.course.author.name,
        courseDuration: raw.course.duration,
        classes: raw.course.classes,
        classesFinished: raw.customer.classesFinished,
        isFinished: raw.customer.isFinished,
        hasAccess: raw.customer.hasAccess,
        activeContent:
          unifiedContents.find(
            (video) =>
              video.isFinished === false && video.status === "published"
          ) || unifiedContents[0],
        contents: unifiedContents,
        categories: raw.course.categories || [],
        published: raw.course.published,
        publishedAt: raw.course.publishedAt
          ? new Date(raw.course.publishedAt)
          : null,
        trailer: raw.course.trailer?.props,
        ratingAnswered: raw.customer.ratingAnswered,
        rating: raw.course.rating,
        totalRatings: raw.course.totalRatings,
      };
    };

    const data = convertData({ course, customer });

    return data;
  },

  /**
   * Busca detalhes de uma aula
   * @param courseId string
   * @param classId string
   * @returns Promise
   */
  async getClassVideo({ courseId, classId }) {
    const { data } = await apiCDS.get(
      `/courses/${courseId}/classes/${classId}`
    );

    return data;
  },

  /**
   * Muda o autoplay do usuário no banco de dados
   * @param body object
   * @returns Promise
   */
  async updateAutoPlay(body) {
    return await apiCDS.patch("/users/me", body);
  },

  /**
   * Atualiza os dados do usuário no banco de dados
   * @param body object
   * @returns Promise
   */
  async updatePersonalData(body) {
    return await apiCDS.patch("/users/me", body);
  },

  /**
   * Atualiza os dados do usuário no ActiveCampaign
   * @param body object
   * @returns Promise
   */
  async updateActiveCampaignContact(body) {
    return await apiCasaDoSaber.put("/activecampaign/updatecontact", body);
  },

  /**
   * Reseta o progresso das aulas assistidas do curso
   * @param courseId string
   * @returns Promise<any>
   */
  async resetCourseProgress({ courseId }) {
    const { data } = await apiCDS.delete(`/courses/${courseId}/user/replay`);

    return data;
  },

  /**
   * Cria o progresso de uma aula
   * @param courseId string
   * @param classId string
   * @returns Promise<any>
   */
  async createClassProgress({ courseId, classId }) {
    return await apiCDS.post(`/users/courses/classes/create`, {
      courseId,
      classId,
    });
  },

  /**
   * Atualiza o progresso de uma aula
   * @param courseId string
   * @param classId string
   * @param progress number
   * @returns Promise<any>
   */
  async updateClassProgress({ courseId, classId, progress }) {
    return await apiCDS.patch(`/users/courses/classes/progress`, {
      courseId,
      classId,
      progress,
    });
  },

  /**
   * Finaliza o progresso da aula
   * @param courseId string
   * @param classId string
   * @returns Promise<any>
   */
  async finishClassProgress({ courseId, classId }) {
    return await apiCDS.patch(`/users/courses/classes/finished`, {
      courseId,
      classId,
    });
  },

  /**
   * Busca cursos, categorias e autores
   * de acordo com o termo de pesquisa
   * @param query string
   * @returns Promise<any>
   */
  async getSearchResults(query) {
    return await apiCDS.get("/search", {
      params: { searchString: query },
    });
  },

  /**
   * Busca e cria o certificado de um curso concluído
   * @param userCourseId string
   * @returns Promise<any>
   */
  async getAndCreateCertificate(courseId) {
    const { data } = await apiCDS.get(`/courses/certificate/${courseId}`);

    const { certificate } = data;

    return certificate;
  },

  /**
   * Busca a lista de cursos favoritos
   * @returns Promise<any>
   */
  async getFavorites() {
    const { data } = await apiCDS.get(`/users/courses/favorites`);
    return data;
  },

  /**
   * Adiciona um curso favorito à lista
   * @param courseId string
   * @returns Promise<any>
   */
  async postFavorite(courseId) {
    return await apiCDS.post(`/users/courses/favorites`, {
      courseId,
    });
  },

  /**
   * Remove um curso favorito à lista
   * @param courseId string
   * @returns Promise<any>
   */
  async removeFavorite(courseId) {
    return await apiCDS.delete(`/users/courses/favorites/${courseId}`);
  },

  /**
   * Dispara uma tag para o ActiveCampaign
   * @param email string
   * @param tagId string
   * @returns Promise<{message: string}>
   */
  async createActiveCampaignTag({ email, tagId }) {
    const { data } = await Axios.post(
      `${baseCDSApiUrl.replace("/app", "/public")}/activecampaign/tag`,
      {
        email,
        tagId,
      }
    );

    return data;
  },

  /**
   * Cria contato e alimenta campos no ActiveCampaign
   * @param email string
   * @param customFields objects[]
   * @returns Promise<{message: string}>
   */
  async createActiveCampaignContact({ email, customFields }) {
    const { data } = await Axios.post(
      `${baseCDSApiUrl.replace("/app", "/public")}/activecampaign/contact`,
      {
        email,
        fieldValues: customFields,
      }
    );

    return data;
  },

  /**
   * Retorna canais onde conheceu a casa
   * @returns Promise<any>
   */
  async getChannels() {
    const { data } = await apiCDS.get(`/research-channel`);
    return data;
  },

  /**
   * Cria categorias de preferência do usuário
   * @param {Array<String>} categories
   * @returns Promise<any>
   */
  async createCategoriesUserPreference(categories) {
    const { data } = await apiCDS.post(`/users/categories`, { categories });
    return data;
  },

  /**
   * Altera senha do usuário
   * @param {string} newPassword
   * @returns
   */
  async updatePassword(newPassword) {
    return await apiCDS.patch("/users/password", {
      newPassword,
    });
  },

  /**
   * Envia email com magic link para login
   * @param {String} email
   * @param {boolean} pass
   * @param {string} redirect
   * @returns
   */
  async sendMagicLinkEmail(email, pass = false, redirect = null) {
    return await apiMagicLink.post("/signin", {
      email,
      pass,
      redirect,
    });
  },

  /**
   * Busca usuário existente com o email
   * @param {String} email
   * @returns Promise<any>
   */
  async verifyUser(email) {
    return await apiCDS.post("/users/verify", {
      email,
    });
  },

  /**
   * Verifica se usuário está autorizado a receber assinatura presente
   * @param giverEmail string
   * @param recipientEmail string
   * @returns Promise<any>
   */
  async verifyGiftSubscription(giverEmail, recipientEmail) {
    return await apiCDS.post(`/users/verify/gift-subscription`, {
      giverEmail,
      recipientEmail,
    });
  },

  /**
   * Envia o email com vale presente
   * @param body object
   * @returns Promise<{message: string}>
   */
  async sendGiftEmail(body) {
    const { data } = await apiCDS.post(`/emails/gift-recipient`, body);

    return data;
  },

  /**
   * Atualiza o parcelamento para o novo ciclo de cobrança
   * @param body object
   * @returns Promise<{message: string}>
   */
  async updateInstallments(body) {
    const { data } = await apiCDS.put(`/subscriptions`, body);

    return data;
  },

  /**
   * Reativa a assinatura
   * @param subscriptionReference string
   * @returns Promise<{message: string}>
   */
  async reactivateSubscription(subscriptionReference) {
    const { data } = await apiCDS.post(`/subscriptions/reactivate`, {
      subscriptionReference,
    });

    return data;
  },

  /**
   * Busca código de vale presente
   * @param {string} giftCode
   * @returns Promise<any>
   */
  async getGiftByCode(giftCode) {
    return await apiCDS.get(`/subscriptions/gift/${giftCode}`);
  },

  /**
   * Cria assinatura a partir do código do vale presente
   * @param {string} giftCode
   * @returns Promise<any>
   */
  async createSubscriptionFromGift(giftCode) {
    return await apiCDS.post("subscriptions/gift", {
      giftCode,
    });
  },

  /**
   * Retorna lista de cursos recomendados
   * @returns Promise
   */
  async getRecommendedCourses() {
    const { data } = await apiCDS.get("/courses/recommendations");
    return data;
  },

  /**
   * Envia avaliação do curso/aula
   * @param folderId string
   * @param contentId string
   * @param rating number
   * @param text string
   * @returns Promise<any>
   */
  async sendRating(folderId, contentId, rating, text) {
    return await apiCDS.post(`/courses/rating`, {
      folderId,
      contentId,
      rating,
      text,
    });
  },

  /**
   * Cancela a assinatura de um usuário na vindi
   * @param subscriptionReference string
   * @returns Promise<any>
   */
  async cancelOnVindi(subscriptionReference) {
    return await apiCDS
      .delete(`/subscriptions/${subscriptionReference}`)
      .then(() => {
        return {
          message: "Assinatura Cancelada",
        };
      })
      .catch(() => {
        throw new Error(
          "Erro ao cancelar assinatura. Por favor, entre em contato com o suporte."
        );
      });
  },

  /**
   * Retorna dados da campanha promocional
   * @param context string<"app" | "public">
   * @param testingDate string
   * @returns Promise<{
    showExtensionBanner: boolean
    currentDate: Date
    coupon: {
      validUntil: Date
      discountType: string
      discountValue: number
      couponId: string
      code: string
    }
    banners: {
      app: {
        mobile: string
        web: string
      }
      public: {
        mobile: string
        web: string
      }
    }
  } | null>
   */
  async getPromotionalCampaign(context = "app", testingDate = null) {
    // ex.: 2023-11-28T03:00:00.000Z`
    const params = testingDate ? `?currentDate=${testingDate}` : "";

    if (context === "public") {
      const { data: publicCampaign } = await Axios.get(
        `${baseCDSApiUrl.replace("/app", "/public")}/best-week${params}`
      );
      return publicCampaign;
    }

    const { data: privateCampaign } = await apiCDS.get(
      `/users/best-week${params}`
    );
    return privateCampaign;
  },

  /**
   * Post que estende a assinatura
   * @param subscriptionReference string
   * @param couponId string
   * @returns Promise<{message: string}>
   */
  async extendsSubscription({ subscriptionReference, couponId }) {
    const { data } = await apiCDS.post("/subscriptions/extension", {
      subscriptionReference,
      couponId,
    });

    return data;
  },

  /**
   * Retorna código para login via magic link no redesign
   * @param  email string
   * @returns Promise<{challenge: string}>
   */
  async getIntegrationChallenge(email) {
    const { data } = await apiLambdaIntegration.post("/signin-integration", {
      email,
    });

    return data;
  },
};
