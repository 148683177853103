import styled from "styled-components";

export const Wrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: ${(props) => props.theme.lightTheme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 86px;
  button {
    background-color: ${(props) =>
      props.theme.lightTheme.colors.buttonBackground};
  }
  button:hover {
    color: ${(props) => props.theme.lightTheme.colors.text};
  }
`;
