import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCategories } from "../hooks";
import { requests } from "../services/requests";
import { size } from "../styles/mediaQueries";

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [userQuery, setUserQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [foldersFound, setFoldersFound] = useState([]);
  const [categoriesFound, setCategoriesFound] = useState([]);
  const [showFormSearch, setShowFormSearch] = useState(false);
  const location = useLocation();

  const { data: categories, isFetching: categoriesLoading } = useCategories();

  useEffect(() => {
    if (showFormSearch) {
      setShowFormSearch(false);
      setUserQuery("");
    }
  }, [location]);

  return (
    <SearchContext.Provider
      value={{
        categories,
        showFormSearch,
        setShowFormSearch,
        userQuery,
        setUserQuery,
        categoriesLoading,
        searchLoading,
        setSearchLoading,
        foldersFound,
        setFoldersFound,
        categoriesFound,
        setCategoriesFound,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const {
    categories,
    showFormSearch,
    setShowFormSearch,
    userQuery,
    setUserQuery,
    categoriesLoading,
    searchLoading,
    setSearchLoading,
    foldersFound,
    setFoldersFound,
    categoriesFound,
    setCategoriesFound,
  } = useContext(SearchContext);

  const navigate = useNavigate();

  const loading = searchLoading || categoriesLoading;

  const handleClickSearch = () => {
    const isDesktop = window.innerWidth >= size.laptop;

    resetSearch();
    if (isDesktop) {
      setShowFormSearch(!showFormSearch);
    } else {
      setShowFormSearch(false);
      navigate("/pesquisa");
    }
  };

  const searchInput = (e) => {
    setUserQuery(e.target.value);
    if (e.target.value.length > 2) setSearchLoading(true);
  };

  const fetchSearchResults = async (query) => {
    try {
      const { data: searchResults } = await requests.getSearchResults(query);
      setFoldersFound(searchResults);
      setSearchLoading(false);
    } catch (e) {
      console.error(e);
      setSearchLoading(false);
    }
  };

  const updateQuery = () => {
    if (userQuery.length > 2) {
      const categoriesFiltered = categories.filter((item) =>
        item.title.toLowerCase().includes(userQuery.toLowerCase())
      );

      setCategoriesFound(categoriesFiltered);
      fetchSearchResults(userQuery);
    } else {
      setFoldersFound([]);
      setCategoriesFound([]);
    }
  };

  const resetSearch = () => {
    setUserQuery("");
    setFoldersFound([]);
    setSearchLoading(false);
    setCategoriesFound([]);
  };

  return {
    categories,
    showFormSearch,
    setShowFormSearch,
    userQuery,
    loading,
    handleClickSearch,
    searchInput,
    categoriesFound,
    foldersFound,
    updateQuery,
    resetSearch,
  };
};
