import React from "react";
import * as S from "./styles";

const mail = (
  <a
    href="mailto:suporte@casadosaber.com.br"
    target="_blank"
    rel="noopener noreferrer"
  >
    suporte@casadosaber.com.br
  </a>
);

const phone = (
  <a
    href="https://wa.me/5511966092057"
    target="_blank"
    rel="noopener noreferrer"
  >
    (11) 96609-2057
  </a>
);

const SignupFooter = () => {
  return (
    <S.Wrapper>
      Para tirar dúvidas entre em contato com {mail} ou por WhatsApp no número{" "}
      {phone}, de segunda à sexta-feira das 10h às 19h.
    </S.Wrapper>
  );
};

export default SignupFooter;
