import React from "react";
import { useLocation } from "react-router-dom";
import Android from "./Android";
import Ios from "./Ios";
import Web from "./Web";

const CancelPlan = () => {
  const location = useLocation();
  const origin = location.state?.origin;

  const renderPage = () => {
    if (origin === "android") return <Android />;
    if (origin === "ios") return <Ios />;
    return <Web />;
  };

  return renderPage();
};

export default CancelPlan;
