import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuthContext } from "../../context/AuthContext";
import { useUser } from "../../hooks";
import { planNameStandardization } from "../../util/Util";
import { getAlertMessageFromState } from "../../util/messages";
import { Button, AccountFooter, Alert, Container } from "../../components";
import { AccountSectionHeader, AccountSectionLine } from "../../containers";

import * as S from "./styles";

const Account = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: { userData, subscriptionDetails },
  } = useUser();

  const { signOut } = useAuthContext();
  const [alert, setAlert] = useState({});

  useEffect(() => {
    const { message, variant } = getAlertMessageFromState(location);
    if (message) {
      setAlert({ message, variant });
      return window.history.replaceState({}, document.title);
    }
  }, []);

  const showSubscriptionDetails = !["vivo", "zapier"].includes(
    subscriptionDetails?.origin
  );
  const planName = planNameStandardization(subscriptionDetails?.planId);

  return (
    <Container type={"minimal"} pageName="Minha conta">
      <S.Wrapper>
        {!!alert.message && (
          <Alert text={alert.message} variant={alert.variant} />
        )}

        <section>
          <AccountSectionHeader>
            {"Programa de indicações"}
            <S.Badge>novo</S.Badge>
          </AccountSectionHeader>

          <AccountSectionLine>
            Troque seus pontos por benefícios
            <S.LinkAsButton
              href={
                "https://paineldoembaixador.com.br/indicador/65fadcd5d154a49850ec1259/OJJY?bl_ref=KN86E"
              }
              target={"_blank"}
              rel={"noopener noreferrer"}
              id={"btn-account-indicacoes"}
              name={"btn-account-indicacoes"}
            >
              Saiba Mais
            </S.LinkAsButton>
          </AccountSectionLine>
        </section>

        <section>
          <AccountSectionHeader>{"Informações da conta"}</AccountSectionHeader>

          <AccountSectionLine bottomLine>{userData?.email}</AccountSectionLine>
          <AccountSectionLine bottomLine>
            Dados Pessoais
            <Button
              variant={"transparent"}
              size={"small"}
              id={"btn-account-dados_pessoais"}
              name={"btn-account-dados_pessoais"}
              onClick={() => navigate("/minha-conta/dados-pessoais")}
            >
              ALTERAR
            </Button>
          </AccountSectionLine>

          <AccountSectionLine>
            Senha: ******
            <Button
              variant={"transparent"}
              size={"small"}
              id={"btn-account-alterar_senha"}
              name={"btn-account-alterar_senha"}
              onClick={() => navigate("/minha-conta/alterar-senha")}
            >
              ALTERAR
            </Button>
          </AccountSectionLine>
        </section>

        <section>
          <AccountSectionHeader>Minha Assinatura</AccountSectionHeader>
          {planName && (
            <AccountSectionLine bottomLine={showSubscriptionDetails}>
              {planName}
            </AccountSectionLine>
          )}

          {showSubscriptionDetails && (
            <AccountSectionLine>
              Detalhes da assinatura
              <Button
                variant={"transparent"}
                size={"small"}
                id={"btn-account-assinatura"}
                name={"btn-account-assinatura"}
                onClick={() => navigate("/minha-conta/assinatura")}
              >
                VER
              </Button>
            </AccountSectionLine>
          )}
        </section>

        <section>
          <AccountSectionHeader>Certificados de conclusão</AccountSectionHeader>
          <AccountSectionLine space>
            Meus certificados
            <Button
              variant={"transparent"}
              size={"small"}
              id={"btn-account-certificados"}
              name={"btn-account-certificados"}
              onClick={() => navigate("/minha-conta/certificados")}
            >
              VER
            </Button>
          </AccountSectionLine>
        </section>

        <section>
          <AccountSectionHeader>
            Minha lista de cursos para assistir depois
          </AccountSectionHeader>
          <AccountSectionLine space>
            Minha lista
            <Button
              variant={"transparent"}
              size={"small"}
              id={"btn-account-minha_lista"}
              name={"btn-account-minha_lista"}
              onClick={() => navigate("/minha-lista")}
            >
              VER
            </Button>
          </AccountSectionLine>
        </section>

        <Button
          variant={"transparent"}
          size={"full"}
          id={"btn-account-sair_da_conta"}
          name={"btn-account-sair_da_conta"}
          onClick={() => signOut()}
        >
          SAIR DA CONTA
        </Button>
        <AccountFooter />
      </S.Wrapper>
    </Container>
  );
};

export default Account;
