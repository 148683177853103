import React from "react";

import { ReactComponent as FailIcon } from "../../assets/icons/fail-icon.svg";
import strings from "../../config/strings.json";
import * as S from "./styles";

const NoContentLoaded = () => {
  return (
    <S.Wrapper>
      <S.WarningIcon>
        <FailIcon alt={`Ícone de aviso de falha`} />
      </S.WarningIcon>
      {strings.noContent.loadProblem.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </S.Wrapper>
  );
};

export default NoContentLoaded;
