import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: 14px;
  padding: 0.6rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  border-bottom: ${(props) =>
    props.bottomLine ? "1px solid #5F5D5F" : "none"};
`;
