import styled from "styled-components";
import {
  InputTextFieldContainer,
  InputContainer,
  ErrorMessage,
} from "../Input/styles";

export const InputPhoneFieldContainer = styled(InputTextFieldContainer)`
  label {
    left: 50px;
  }
`;

export { InputContainer, ErrorMessage };
