import React, { useState } from "react";
import { MdSearch } from "react-icons/md";
import { useAuthors } from "../../hooks";
import {
  CardsGrid,
  Container,
  LoadingSpinner,
  PageTitle,
} from "../../components";

import * as S from "./styles";

export default function Index() {
  const [authorsFound, setAuthorsFound] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const { data: authors, isLoading: isAuthorsLoading } = useAuthors();

  const handleSearch = (e) => {
    const query = e.target.value;

    setSearchInput(query);

    const filteredAuthors = authors.filter((author) =>
      author.name.toLowerCase().includes(query.toLowerCase())
    );

    setAuthorsFound(filteredAuthors);
  };

  if (isAuthorsLoading) {
    return <LoadingSpinner color={"light"} />;
  }

  return (
    <Container type={"clean"}>
      <PageTitle>Professores</PageTitle>
      <S.SearchContainer>
        <MdSearch />
        <S.SearchInput
          name="searchInput"
          id="searchInput"
          value={searchInput}
          placeholder={"Busque por nome ou sobrenome..."}
          onChange={handleSearch}
        />
      </S.SearchContainer>
      {searchInput ? (
        authorsFound.length > 0 ? (
          <CardsGrid
            content={authorsFound}
            link={"/professores"}
            showItemTitle={true}
          />
        ) : (
          <S.NotFoundContainer>
            <p>
              {" "}
              <b>Não encontramos nenhum professor.</b>
              <br /> Tente escrever de outro jeito ou navegue pelo catálogo.
            </p>
          </S.NotFoundContainer>
        )
      ) : (
        <CardsGrid
          content={authors}
          link={"/professores"}
          showItemTitle={true}
        />
      )}
    </Container>
  );
}
