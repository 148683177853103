import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    144px
  ); // ../Carousel/CourseCard big-size
  justify-content: center;
  grid-gap: 16px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(
      auto-fill,
      202px
    ); // ../Carousel/CourseCard big-size
  }
`;
