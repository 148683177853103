import styled from "styled-components";

export const Wrapper = styled.footer`
  font-family: "MaisonNeueBook", sans-serif;
  color: ${(props) => props.theme.lightTheme.colors.text};
  font-size: 12px;
  text-align: left;
  padding: 16px;
  max-width: 414px;
  margin: 0 auto;
  width: 100%;

  a {
    color: ${(props) => props.theme.lightTheme.colors.text};
    text-decoration: underline;
    text-underline-offset: 1px;

    :hover {
      font-weight: 800;
      color: ${(props) => props.theme.lightTheme.colors.text};
    }
  }
`;
