import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Container, Button, PasswordInput, Alert } from "../../components";

import * as S from "./styles";
import { translateAmplifyErrorMessage } from "../../util/messages";
import { requests } from "../../services/requests";

const schema = yup
  .object({
    newPassword: yup
      .string()
      // eslint-disable-next-line no-template-curly-in-string
      .min(6, "A senha deve conter no mínimo ${min} caracteres")
      .trim(),
    newPasswordConfirmation: yup
      .string()
      // eslint-disable-next-line no-template-curly-in-string
      .min(6, "A senha deve conter no mínimo ${min} caracteres")
      .oneOf([yup.ref("newPassword"), null], "As senhas não conferem")
      .trim(),
  })
  .required("Por favor, preencha a nova senha");

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      await requests.updatePassword(data.newPassword);

      setAlert({
        type: "success",
        message: "Senha alterada com sucesso!",
      });

      setTimeout(() => {
        navigate(-1);
      }, 1500);
    } catch (error) {
      const { message } = translateAmplifyErrorMessage(
        error?.message,
        "changePassword"
      );

      setAlert({
        message,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container type={"minimal"} pageName="Alterar senha">
      <S.Wrapper>
        {!!alert.message && <Alert variant={alert.type} text={alert.message} />}

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <PasswordInput
            label={"Nova senha"}
            inputName={"newPassword"}
            registerFunction={register}
            errors={errors}
          />

          <PasswordInput
            label={"Confirmar nova senha"}
            inputName={"newPasswordConfirmation"}
            registerFunction={register}
            errors={errors}
          />

          <Button
            variant={"white"}
            size={"full"}
            type="submit"
            disabled={loading}
            loading={loading}
          >
            Alterar senha
          </Button>
        </S.Form>
      </S.Wrapper>
    </Container>
  );
};

export default ChangePassword;
