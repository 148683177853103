import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Form = styled.form`
  ${() => css`
    min-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `}
`;

export const ForgotPasswordLink = styled(Link)`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.darkTheme.colors.gray};
    text-decoration: none;
    width: max-content;
    margin: 0 auto 1em
    text-align: center;
    transition: all 100ms ease;

    :hover {
      text-decoration: none;
    }
  `}
`;

// Applies to ForgotPassword and RecoverPassword components
export const LoginWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 414px;
    margin: 0 auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    row-gap: 25px;

    @media (${device.laptop}) {
      margin: 10vh auto 0;
      justify-content: start;
    } ;
  `}
`;
