import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import React, { useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import {
  ControlledMenu,
  MenuItem,
  MenuButton,
  useMenuState,
} from "@szhsin/react-menu";
import { useCategories, useWatchingCourses } from "../../../hooks";
import {
  filterAndOrderMenu,
  getSubmenuHeaderCategories,
} from "../../../util/menu";

import strings from "../../../config/strings.json";
import * as S from "./style";

const DesktopMenu = () => {
  const ref = useRef(null);
  const { state, endTransition, toggleMenu } = useMenuState({
    transition: true,
  });

  const { data: categories, isFetching: isCategoriesLoading } = useCategories();
  const {
    data: { length: hasWatchingCourses },
    isFetching: isWatchingCoursesLoading,
  } = useWatchingCourses({
    limit: 1,
  });

  const orderedMenu = filterAndOrderMenu(categories) || [];
  const orderedSubmenuHeader = getSubmenuHeaderCategories(categories) || [];

  const stateClass = useMemo(() => {
    switch (state) {
      case "open":
        return "open";
      case "opening":
        return "opening";
      case "closing":
        return "closing";
      default:
        return "closed";
    }
  }, [state]);

  const renderMainMenuItems = orderedMenu.map((category) => (
    <Link
      to={`/categoria/${category.slug}`}
      title={category.title}
      key={category.id}
    >
      {category.title}
    </Link>
  ));

  const renderSubmenuHeaderItems = () => {
    return (
      <>
        {!!hasWatchingCourses && (
          <MenuItem
            href="/meus-cursos"
            title={strings.categories.watching}
            id={"btn-submenu_desktop-assistindo"}
            name={"btn-submenu_desktop-assistindo"}
          >
            {strings.categories.watching}
          </MenuItem>
        )}

        {orderedSubmenuHeader.map((category) => (
          <MenuItem
            href={`/categoria/${category.slug}`}
            title={category.title}
            key={category.id}
            id={`btn-submenu_desktop-${category.slug}`}
            name={`btn-submenu_desktop-${category.slug}`}
          >
            {category.title}
          </MenuItem>
        ))}

        <MenuItem
          href="/populares"
          title={strings.categories.populars}
          id={"btn-submenu_desktop-populares"}
          name={"btn-submenu_desktop-populares"}
        >
          {strings.categories.populars}
        </MenuItem>
      </>
    );
  };

  const renderAllItems = categories.map((category) => (
    <MenuItem
      href={`/categoria/${category.slug}`}
      title={category.title}
      key={category.id}
      className={"react-menu-body-item"}
      id={`btn-submenu_desktop-${category.slug}`}
      name={`btn-submenu_desktop-${category.slug}`}
    >
      {category.title}
    </MenuItem>
  ));

  if (isCategoriesLoading || isWatchingCoursesLoading) {
    return <S.NavBar />;
  }

  return (
    <>
      <S.NavBar>
        <MenuButton
          ref={ref}
          onPointerEnter={() => toggleMenu(true)}
          className={"react-menu-button"}
          id={"btn-menu_desktop-todas_as_categorias"}
          name={"btn-menu_desktop-todas_as_categorias"}
          title={strings.categories.allCategories}
        >
          <i className="fas fa-bars"></i> {strings.categories.allCategories}
        </MenuButton>

        <ControlledMenu
          anchorRef={ref}
          state={state}
          endTransition={endTransition}
          onPointerLeave={() => toggleMenu(false)}
          onClose={() => toggleMenu(false)}
          menuClassName={"react-menu-container"}
          offsetY={8}
          position={"initial"}
          overflow={"auto"}
        >
          <S.SubmenuHeader>{renderSubmenuHeaderItems()}</S.SubmenuHeader>
          <S.SubmenuBody takeOverflow>{renderAllItems}</S.SubmenuBody>
        </ControlledMenu>

        <S.MainMenu>
          <span>|</span>

          {!!hasWatchingCourses && (
            <Link to={`/meus-cursos`} title={strings.categories.watching}>
              {strings.categories.watching}
            </Link>
          )}

          {renderMainMenuItems}
        </S.MainMenu>
      </S.NavBar>

      <S.Backdrop className={stateClass} />
    </>
  );
};

export default DesktopMenu;
