import React from 'react';
import { useSearchContext } from '../../../context/SearchContext';
import SearchResultsModule from '../SearchResultsModule';
import PhoneSearch from '../../../assets/icons/phone-search.svg';
import * as S from './styles';

const SearchEmptyResults = () => {

  const { categories, userQuery } = useSearchContext()

  return (
    <S.Wrapper>
      <S.TextContainer>
        <img src={PhoneSearch} alt={'Ícone resultado não encontrado'} />
        <h1>NÃO ENCONTRAMOS RESULTADOS PARA "{userQuery}"</h1>
        <h2>SUGESTÕES:</h2>
        <ul>
          <li>tente palavras-chave diferentes</li>
          <li>experimente buscar pelo nome do curso ou professor</li>
          <li>navegue pelas categorias abaixo</li>
        </ul>
      </S.TextContainer>
      <SearchResultsModule 
        title={"CATEGORIAS"} 
        type={"category"}
        renderArray={categories} 
      />
    </S.Wrapper>
  )
}

export default SearchEmptyResults