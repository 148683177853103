import styled, { css } from "styled-components";
import { LoginWrapper } from "../Login/styles";

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;

    .number-code-input-fieldset,
    .password-input-container:last-of-type {
      margin-bottom: 16px;
    }
  `}
`;

export { LoginWrapper };
