import React from "react";
import { Link } from "react-router-dom";
import SearchCard from "../SearchCard";
import * as S from "./styles";

export const SearchResultsModule = ({ title, renderArray, type }) => {
  const sortCategories = (a, b) => {
    if (a.category > b.category) return 1;
    if (a.category < b.category) return -1;
    return 0;
  };

  const renderCourses = renderArray.map((course) => (
    <S.ResultsItem key={course.id}>
      <Link to={`/curso/${course.id}/${course.slug}`}>
        <SearchCard course={course} />
      </Link>
    </S.ResultsItem>
  ));

  const renderCategories = renderArray.sort(sortCategories).map((folder) => (
    <S.ResultsItem id={folder.title} key={folder.id}>
      <Link to={`/categoria/${folder.slug}`} title={folder.title}>
        {folder.title}
      </Link>
    </S.ResultsItem>
  ));

  return (
    <S.Wrapper>
      <S.ResultsTitle>{title}</S.ResultsTitle>
      <S.ResultsList>
        {type === "course" ? (
          renderCourses
        ) : type === "category" ? (
          renderCategories
        ) : (
          <></>
        )}
      </S.ResultsList>
    </S.Wrapper>
  );
};

export default SearchResultsModule;
