import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useQuery, useSubscriptionStatus, useUser } from "../hooks";
import { LoadingSpinner } from "../components";
import { useAuthContext } from "./AuthContext";
import { queryKeys } from "../hooks/useSubscriptionQuery";
import {
  getNewCheckoutUrlWithUserParam,
  isLegacyCheckoutActive,
} from "../util/newCheckout";

export const UserSubscriptionContext = createContext();
UserSubscriptionContext.displayName = "UserSubscriptionContext";

export const UserSubscriptionProvider = ({ children }) => {
  const navigate = useNavigate();
  const query = useQuery();
  const queryClient = useQueryClient();

  const { setPathToRedirect } = useAuthContext();

  const [isLoading, setLoading] = useState(true);
  const [personalData, setPersonalData] = useState({});
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);

  const {
    data: { userData },
  } = useUser();

  const {
    data: status,
    refetch: refetchStatus,
    isLoading: isStatusLoading,
    isFetching: isStatusFetching,
  } = useSubscriptionStatus();

  const formatCustomerPersonalData = async () => {
    const customerData = {
      name: userData.name || "",
      identity: userData.identity || "",
      phoneNumber: userData.phoneNumber || "",
      email: userData.email || "",
      mailNotification: userData.mailNotification,
    };
    if (customerData.name.includes("@")) {
      customerData.name = "";
    }
    if (!customerData.phoneNumber.includes("+")) {
      customerData.phoneNumber = `+${customerData.phoneNumber}`;
    }
    setPersonalData(customerData);
  };

  const verifyStatusAndApplyAccessRules = () => {
    /*
     ** Ao limpar a query no react-query (useSubscriptionStatus),
     ** é feito um novo fetch ao endpoint: /subscriptions/status,
     ** que dispara o useEffect abaixo, que aplica as regras de acessso à aplicação
     ** e direciona para a página correta.
     */
    queryClient.resetQueries(queryKeys.subscriptionStatus);
  };

  const applyAccesRules = (pathString) => {
    navigate(pathString, { replace: true });
    setPathToRedirect("");
    if (hasActiveSubscription) setHasActiveSubscription(false);
  };

  useEffect(() => {
    if (isStatusLoading || isStatusFetching) return;

    switch (status.subscriptionStatus) {
      case "INACTIVE":
        if (query.has("giftCode")) {
          applyAccesRules("/checkout/planos?" + query.toString());
        } else {
          if (isLegacyCheckoutActive) {
            applyAccesRules(
              "/checkout/entenda-a-assinatura?" + query.toString()
            );
          } else if (window.location.pathname !== "/entrar/integracao") {
            window.location.replace(
              getNewCheckoutUrlWithUserParam({
                customerId: userData?.id,
                email: userData?.email,
                name: userData?.name,
                phoneNumber: userData?.phoneNumber,
              }).replace("&skipPassword=true", "")
            );
            localStorage.clear();
          }
        }
        break;
      case "FAILED":
        applyAccesRules("/checkout/falha");
        break;
      case "CANCELED":
        applyAccesRules("/checkout/cancelamento");
        break;
      case "PROCESSING":
        applyAccesRules("/checkout/processando");
        break;
      case "ACTIVE":
        if (!hasActiveSubscription) {
          setHasActiveSubscription(true);
        }
        break;
      default:
        break;
    }
    setLoading(false);
  }, [status]);

  useEffect(() => {
    refetchStatus();
  }, [hasActiveSubscription]);

  useEffect(() => {
    if (userData) {
      formatCustomerPersonalData();
    }
  }, [userData]);

  return (
    <UserSubscriptionContext.Provider
      value={{
        hasActiveSubscription,
        setHasActiveSubscription,
        personalData,
        setPersonalData,
        verifyStatusAndApplyAccessRules,
      }}
    >
      {isLoading ? <LoadingSpinner color="light" /> : children}
    </UserSubscriptionContext.Provider>
  );
};

export const useUserSubscriptionContext = () => {
  const {
    hasActiveSubscription,
    setHasActiveSubscription,
    personalData,
    setPersonalData,
    verifyStatusAndApplyAccessRules,
  } = useContext(UserSubscriptionContext);

  const hasIncompleteRegister =
    personalData &&
    Object.values(personalData).some((input) => {
      if (typeof input !== "boolean" && (!input || input.lenght === 0)) {
        return true;
      }
      return false;
    });

  const updatePersonalData = (customerUpdatedData) => {
    setPersonalData(customerUpdatedData);
  };

  return {
    hasActiveSubscription,
    setHasActiveSubscription,
    personalData,
    hasIncompleteRegister,
    updatePersonalData,
    verifyStatusAndApplyAccessRules,
  };
};
