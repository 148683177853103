import styled from "styled-components";

export const Wrapper = styled.main`
  max-width: 420px;
  margin: 4em auto 0;

  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  margin: 0; //reset h2 margin
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: normal;
  margin: 0; //reset p margin
`;
