import styled from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Wrapper = styled.div`
  max-width: 414px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  row-gap: 25px;

  p {
    color: #aca9b3;
    font-weight: 100;
    margin-bottom: 0px;
  }

  @media (${device.laptop}) {
    margin: 10vh auto 0;
    justify-content: start;
  } ;
`;

export const SimpleButton = styled.button`
  background-color: transparent;
  border: 0;
  color: #fff;
  font-size: 14px;
  margin-top: 15px;
`;
