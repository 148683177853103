import styled from "styled-components";

export const Wrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: ${(props) => props.theme.lightTheme.colors.background};
  color: ${(props) => props.theme.lightTheme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 86px;
  button {
    background-color: ${(props) =>
      props.theme.lightTheme.colors.buttonBackground};
  }
  button:hover {
    color: ${(props) => props.theme.lightTheme.colors.text};
  }
`;

export const CheckoutContainer = styled.div`
  max-width: 414px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const Image = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const CanceledInfo = styled.div`
  line-height: 160%;
`;
