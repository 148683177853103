import React from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";

import { store } from "react-notifications-component";
import { notifications } from "../../../../util/settings";

import { useReactivateSubscription, useUser } from "../../../../hooks";
import { formattedDate } from "../../../../util/Util";
import { Button, Container, SupportInfo } from "../../../../components";

import * as S from "./styles";

const RenewalCanceled = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  function setNotification(message, type = "danger") {
    store.addNotification({
      ...notifications,
      message: message,
      type: type,
    });
  }

  const {
    data: { subscriptionDetails },
  } = useUser();

  const { mutate: sendRequest, isLoading: isReactivationLoading } =
    useReactivateSubscription({
      onSuccess: () => {
        queryClient.resetQueries("user-details");
        setNotification("Assinatura reativada", "success");
        return navigate("/minha-conta/assinatura", { replace: true });
      },
      onError: () => {
        setNotification(
          "Houve um erro ao reativar sua assinatura. Por favor, tente novamente ou entre em contato com o suporte",
          "danger"
        );
        return navigate("/minha-conta/assinatura", { replace: true });
      },
    });

  const expiryDate = subscriptionDetails?.endAt;

  return (
    <Container type={"minimal"} pageName="Confirmação de cancelamento">
      <S.Wrapper className="wrapper">
        <S.Title>A renovação da sua assinatura foi cancelada.</S.Title>

        <S.Text>
          Como já passamos do período de garantia, você segue tendo acesso à
          nossa plataforma.
        </S.Text>
        {expiryDate ? (
          <S.Text>
            Até o dia {formattedDate(expiryDate)} você poderá assistir os cursos
            que quiser. Após este período, seu acesso à plataforma será
            cancelado.
          </S.Text>
        ) : (
          <S.Text>
            Até o final da vigência do seu plano você poderá assistir os cursos
            que quiser. Após este período, seu acesso à plataforma será
            cancelado.
          </S.Text>
        )}
        <S.Text>
          Se quiser desfazer esta ação, clique abaixo e reative sua assinatura.
        </S.Text>

        <Button
          variant={"green"}
          size={"full"}
          id={"btn-renewal_canceled-reativar_minha_assinatura"}
          name={"btn-renewal_canceled-reativar_minha_assinatura"}
          onClick={() => sendRequest(subscriptionDetails.reference)}
          disabled={!!isReactivationLoading}
        >
          {isReactivationLoading ? (
            <Spinner
              color="dark"
              style={{
                height: "1.3em",
                width: "1.3em",
              }}
            />
          ) : (
            "ATIVAR RENOVAÇÃO AUTOMÁTICA"
          )}
        </Button>

        <Button
          variant={"transparent"}
          size={"full"}
          id={"btn-renewal_canceled-voltar-para-o-catalogo"}
          name={"btn-renewal_canceled-voltar-para-o-catalogo"}
          onClick={() => navigate("/", { replace: true })}
          disabled={!!isReactivationLoading}
        >
          VOLTAR PARA O CATÁLOGO DE CURSOS
        </Button>

        <SupportInfo />
      </S.Wrapper>
    </Container>
  );
};

export default RenewalCanceled;
