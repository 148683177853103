import { Auth } from "@aws-amplify/auth";

export function interceptRequest(api, setApiToken) {
  api.interceptors.request.use(
    async (config) => {
      try {
        const session = await Auth.currentSession();

        if (session.isValid()) {
          const jwtToken = session.getIdToken().getJwtToken();
          setApiToken(jwtToken);
        } else {
          window.location.reload();
        }
      } catch (error) {
        if (
          (typeof error === "string" ? error : error.message) ===
          "Refresh Token has expired"
        ) {
          window.location.reload();
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
}
