import styled, { css } from "styled-components";

export const Wrapper = styled.main`
  ${({ theme }) => css`
    width: 100%;
    min-height: 100vh;
    margin: auto;
    background-color: ${theme.lightTheme.colors.background};
    color: ${theme.lightTheme.colors.text};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: 86px;
  `}
`;

export const EmptyHeader = styled.div`
  width: 100%;
  height: 63px;
  background-color: ${(props) => props.theme.lightTheme.colors.darkGray};
`;

export const GiftCheckoutContainer = styled.div`
  ${({ theme }) => css`
    max-width: 414px;
    margin: 0 auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    button:hover {
      border-color: ${theme.darkTheme.colors.lightGray};
    }

    .alert-box {
      margin: 0 auto;
    }
    .alert-box span,
    .alert-box p {
      color: ${theme.lightTheme.colors.text};
    }
  `}
`;

export const GiftCard = styled.div`
  ${({ theme, bgImage }) => css`
    width: 290px;
    height: 183px;
    background: ${theme.lightTheme.colors.darkGray};
    background-image: ${`url(${bgImage})`};
    background-repeat: no-repeat;
    background-position: right bottom;

    font-size: 18px;
    color: ${theme.lightTheme.colors.white};
    border-radius: 15px;
    margin: 0 auto;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    p {
      margin: 0; //reset
      line-height: 1em;
    }

    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;

      .title {
        font-weight: 700;
      }
      .subtitle {
        font-weight: 100;
      }
    }

    .code-label {
      font-size: 14px;
      font-weight: 100;
    }
    .code-value {
      font-size: 25px;
      margin-top: 3px;
    }
  `}
`;

export const Description = styled.p`
  font-weight: 100;
  margin-bottom: 0; //reset
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
