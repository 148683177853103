import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { FiAlertTriangle } from "react-icons/fi";

import { useCheckoutContext } from "../../context/CheckoutContext";
import { usePromotionalCampaign } from "../../hooks";
import Datalayer from "../../services/datalayer";
import {
  calcPriceAfterDiscount,
  formatBestFridayPlanAfterDiscounts,
} from "../../util/checkout";
import { secureStorage } from "../../util/SecureStorage";

import { CTAButton, CheckoutPageTitle, LoadingSpinner } from "../../components";

import strings from "../../config/strings.json";
import * as S from "./styles";

export const GiftPlansBestFriday = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { giverEmail, recipientEmail } = state ?? {};

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);

  const { giftPlans, setGiftPlans, selectedPlan, setSelectedPlan } =
    useCheckoutContext();

  const { data: campaign } = usePromotionalCampaign();

  const oneYearGiftPlan = giftPlans[0];

  useEffect(() => {
    if (!giverEmail || !recipientEmail)
      navigate("/presente", { replace: true });
    if (!secureStorage.getItem(giverEmail + recipientEmail))
      navigate("/presente", { replace: true });
  }, [giverEmail, recipientEmail]);

  useEffect(() => {
    if (campaign.coupon) {
      const priceAfterDiscount = calcPriceAfterDiscount(
        oneYearGiftPlan.originalPrice,
        campaign.coupon.discountValue,
        campaign.coupon.discountType
      );

      const bestFridayGiftPlan = formatBestFridayPlanAfterDiscounts(
        oneYearGiftPlan,
        campaign.coupon,
        priceAfterDiscount
      );

      const updatedPlan = { ...oneYearGiftPlan, ...bestFridayGiftPlan };
      setGiftPlans([updatedPlan]);

      Datalayer.onSelectProduct(
        updatedPlan.name,
        updatedPlan.id,
        updatedPlan.price
      );
      setSelectedPlan(updatedPlan);
    }

    setLoading(false);
  }, []);

  const goToPayment = async (e) => {
    e.preventDefault();
    if (buttonDisabled) return;
    if (!selectedPlan) {
      setMessage("Por favor, selecione um plano para prosseguir");
      setButtonDisabled(false);
      return setErrors(true);
    }

    Datalayer.addToCart(
      selectedPlan.name,
      selectedPlan.id,
      selectedPlan.price,
      selectedPlan.coupon
    );

    return navigate("/presente/pagamento", {
      state: {
        ...state,
        plan: selectedPlan.id,
      },
    });
  };

  if (loading) return <LoadingSpinner />;

  return (
    <S.CheckoutContainer>
      <CheckoutPageTitle
        title={"Best Friday - Plano Presente"}
        subtitle={null}
      />

      <S.PlansContainer>
        <S.PlanItem
          key={oneYearGiftPlan.name}
          onClick={() => {
            setErrors(false);
          }}
          selected={true}
        >
          <span className="plan-name">{oneYearGiftPlan.name}</span>
          <span className="plan-value">
            {oneYearGiftPlan.installmentsInfo}
            <strong> R$ {oneYearGiftPlan.installmentPrice}</strong>
            {!!oneYearGiftPlan.discount && (
              <S.DiscountLabel>
                ↓{oneYearGiftPlan.discountLabel}
              </S.DiscountLabel>
            )}
          </span>
        </S.PlanItem>
      </S.PlansContainer>

      {errors && (
        <S.Errors>
          {" "}
          <FiAlertTriangle /> <span>{message}</span>{" "}
        </S.Errors>
      )}

      <CTAButton
        id={"btn-gift_plans-proximo"}
        name={"btn-gift_plans-proximo"}
        type="submit"
        disabled={buttonDisabled}
        onClick={(e) => {
          Datalayer.onClick("checkout", "botao:presente:2", "proximo");
          !buttonDisabled && goToPayment(e);
        }}
        text={buttonDisabled ? <Spinner color="dark" /> : "PRÓXIMO"}
      />

      <S.GiftDisclaimer>{strings.giftCheckout.disclaimer}</S.GiftDisclaimer>
    </S.CheckoutContainer>
  );
};

export default GiftPlansBestFriday;
