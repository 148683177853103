import styled, { css } from "styled-components";
import { LoginWrapper } from "../Login/styles";

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `}
`;

export { LoginWrapper };
