import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  LoadingSpinner,
  OnboardingHeader,
  Button,
} from "../../components";
import {
  useActiveCampaignTag,
  useCategories,
  useCreateCategoriesUserPreference,
  useUser,
} from "../../hooks";
import * as S from "./styles";

const OTHER_OPTIONS = {
  id: "others",
  title: "Outros",
  acTagId: 781,
};

const OnboardingCategoriesPreference = () => {
  const navigate = useNavigate();
  const {
    data: { userData },
  } = useUser();
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const { data, isFetching: categoriesLoading } = useCategories({
    showQuestion: true,
  });
  const { mutate: sendCategories } = useCreateCategoriesUserPreference();
  const { mutate: sendToActiveCampaign } = useActiveCampaignTag();
  const categories = useMemo(() => [...data, OTHER_OPTIONS], [data]);

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  function onChange(e) {
    const {
      target: { checked, value },
    } = e;

    if (checked && selectedCategoriesIds.length === 3) {
      setShowAlert(true);
      return;
    } else if (showAlert) {
      setShowAlert(false);
    }

    setSelectedCategoriesIds((prev) => {
      if (checked) return [...prev, value];
      return prev.filter((item) => item !== value);
    });
  }

  function onContinue() {
    sendCategories(
      selectedCategoriesIds.filter((item) => item !== OTHER_OPTIONS.id)
    );

    const selectedCategories = categories.filter((item) =>
      selectedCategoriesIds.includes(item.id)
    );
    selectedCategories.forEach(({ acTagId }) =>
      sendToActiveCampaign({
        email: userData.email,
        tagId: acTagId ?? OTHER_OPTIONS.acTagId,
      })
    );

    navigate("/onboarding/tempo-disponivel", { replace: true });
  }

  if (categoriesLoading) return <LoadingSpinner color="light" />;

  return (
    <S.Wrapper>
      <OnboardingHeader />
      <S.Container>
        <h1>Quais são os temas que mais te interessam?</h1>
        {!!showAlert ? (
          <Alert
            text={"Você pode escolher no máximo três opções"}
            variant="error"
          />
        ) : (
          <p>Escolha até 3 opções</p>
        )}
        <S.CategoriesList>
          {categories.map(({ id, title }) => (
            <label htmlFor={`category-${id}`} key={`category-${id}`}>
              <S.Checkbox
                type="checkbox"
                id={`category-${id}`}
                name={`category-${id}`}
                value={id}
                onChange={onChange}
                checked={selectedCategoriesIds.includes(id)}
              />
              <S.CheckboxMark />
              {title}
            </label>
          ))}
        </S.CategoriesList>
        <Button
          onClick={onContinue}
          disabled={selectedCategoriesIds.length === 0}
          variant={"white"}
        >
          RESPONDER
        </Button>
        <S.LinkStyled to="/">PULAR E COMEÇAR</S.LinkStyled>
      </S.Container>
    </S.Wrapper>
  );
};

export default OnboardingCategoriesPreference;
