import { useUser } from "./useUsersQuery";
import * as Sentry from "@sentry/react";

const useSentry = (isLoggedIn = true) => {
  const {
    data: { userData },
  } = useUser({
    enabled: isLoggedIn,
  });

  const logException = ({
    error,
    transactionName,
    origin,
    tags = [],
    extras = [],
    email = undefined,
  }) => {
    Sentry.captureException(error, (scope) => {
      scope.setTransactionName(
        `${transactionName}[${origin}]: ${error?.message ?? error?.code}`
      );
      scope.setUser({ email: email ?? userData?.email });
      tags.forEach((tag) => scope.setTag(tag?.label, tag?.value));
      extras.forEach((extra) => scope.setExtra(extra?.label, extra?.value));
      return scope;
    });
  };

  return { logException };
};

export default useSentry;
