import { useState } from "react";
import { useUserSubscriptionContext } from "../context/userSubscriptionContext";
import { useCertificate } from "./useCertificateQuery";

const useCertificateModal = (userCourseId) => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const { hasIncompleteRegister } = useUserSubscriptionContext();

  const {
    data: certificate,
    isError: certificateError,
    refetch: refetchCertificateData,
  } = useCertificate(userCourseId, {
    enabled: false,
    onSuccess: () => {
      openCertificateModal();
    },
  });

  const openCertificateModal = async () => {
    if (hasIncompleteRegister) {
      setModalType("incompleteRegister");
      return setShowModal(true);
    }

    if (certificate) {
      setModalType("download");
      return setShowModal(true);
    }

    if (certificateError) {
      setModalType("downloadError");
      return setShowModal(true);
    }

    return refetchCertificateData();
  };

  return {
    showModal,
    modalType,
    certificate,
    setShowModal,
    setModalType,
    openCertificateModal,
  };
};

export default useCertificateModal;
