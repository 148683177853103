import React from "react";
import Header from "../Header";
import PromotionalBanner from "../PromotionalBanner";
import * as S from "./styles.js";

const Container = (props) => {
  return (
    <>
      <PromotionalBanner />
      <S.Wrapper id={"page-content"}>
        <Header {...props} />
        {props.children}
      </S.Wrapper>
    </>
  );
};

export default Container;
