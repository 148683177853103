import React from "react";
import { Spinner } from "reactstrap";
import * as S from "./styles";

const LoadingSpinner = ({ color = "dark", background }) => {
  return (
    <S.Wrapper background={background}>
      <Spinner color={color} />
    </S.Wrapper>
  );
};

export default LoadingSpinner;
