import { useQuery } from "react-query";
import { requests } from "../services/requests";
import {
  setInitialData,
  setFreshTime,
} from "../constants/reactQueryDefaultOption";

const queryKeys = {
  categories: "categories",
  categoriesWithCourses: "categories-with-courses",
  categoryCourses: "category-courses",
  popularCourses: "popular-courses",
  watchingCourses: "watching-courses",
  recommendedCourses: "recommended-courses",
};

export const useCategories = (
  props = { limit: undefined, showQuestion: undefined },
  options = {}
) => {
  const { limit, showQuestion } = props;
  return useQuery(
    queryKeys.categories,
    () => requests.getCategories({ limit, showQuestion }),
    {
      refetchOnWindowFocus: false,
      ...setInitialData([]),
      ...setFreshTime(10),
      ...options,
    }
  );
};

export const useCategoriesWithCourses = (
  props = { home: true, limit: undefined, coursesLimit: 30 },
  options = {}
) => {
  return useQuery(
    [queryKeys.categoriesWithCourses, props],
    ({ queryKey }) => {
      const [, params] = queryKey;
      return requests.getCategoriesWithCourses(params);
    },
    {
      refetchOnWindowFocus: false,
      ...setInitialData([]),
      ...setFreshTime(10),
      ...options,
    }
  );
};

export const useCategoryCourses = (
  props = { categoryId: undefined },
  options = {}
) => {
  return useQuery(
    [queryKeys.categoryCourses, props],
    ({ queryKey }) => {
      const [, params] = queryKey;
      return requests.getCategoryCourses(params);
    },
    {
      refetchOnWindowFocus: false,
      ...setFreshTime(10),
      ...options,
    }
  );
};

export const useWatchingCourses = (
  props = { limit: undefined },
  options = {}
) => {
  return useQuery(
    [queryKeys.watchingCourses, props],
    ({ queryKey }) => {
      const [, params] = queryKey;
      return requests.getWatchingCourses(params);
    },
    {
      refetchOnWindowFocus: false,
      initialData: [],
      ...options,
    }
  );
};

export const usePopularCourses = (props = { limit: 10 }, options = {}) => {
  return useQuery(
    [queryKeys.popularCourses, props],
    ({ queryKey }) => {
      const [, params] = queryKey;
      return requests.getPopularCourses(params);
    },
    {
      refetchOnWindowFocus: false,
      ...setInitialData([]),
      ...setFreshTime(10),
      ...options,
    }
  );
};

export const useRecommendedCourses = (options = {}) => {
  return useQuery(
    [queryKeys.recommendedCourses],
    () => {
      return requests.getRecommendedCourses();
    },
    {
      refetchOnWindowFocus: false,
      ...setInitialData([]),
      ...setFreshTime(10),
      ...options,
    }
  );
};
