import React from "react";
import { useNavigate } from "react-router-dom";
import { useWatchingCourses } from "../../hooks";
import {
  LoadingSpinner,
  CardsGrid,
  Container,
  Title,
  Text,
  Button,
} from "../../components";
import { Box } from "../../containers";

import strings from "../../config/strings.json";
import * as S from "./styles";

export default function WatchingCourses() {
  const { data: watchingCourses, isFetching: isWatchingCoursesLoading } =
    useWatchingCourses({
      limit: 30,
    });

  const navigate = useNavigate();

  const hasWatchingCourses = watchingCourses.length > 0;

  if (isWatchingCoursesLoading) return <LoadingSpinner color="light" />;

  return (
    <Container type={"clean"}>
      {hasWatchingCourses ? (
        <CardsGrid
          content={watchingCourses}
          pageName={strings.categories.keepWatching}
        />
      ) : (
        <S.NoCourseContainer>
          <S.Title>Cursos em andamento</S.Title>
          <S.NoCourseText>
            Você ainda não possui cursos em andamento. Confira o catálogo da
            Casa do Saber e comece a aprender!
          </S.NoCourseText>
        </S.NoCourseContainer>
      )}

      <Box textCenter verticalMargin={30}>
        <S.ContentContainer>
          <div>
            <Title htmlTag={"h1"} marginBottom={5}>
              Já finalizou algum curso?
            </Title>
            <Text margin={[0, 0, 24, 0]}>
              Então aproveite e confira o seu certificado de conclusão.
            </Text>
          </div>
          <Button
            variant={"white"}
            size={"full"}
            onClick={() => navigate("/minha-conta/certificados")}
            id={"btn-personal_courses-acessar_meus_certificados"}
            name={"btn-personal_courses-acessar_meus_certificados"}
          >
            ACESSAR MEUS CERTIFICADOS
          </Button>
        </S.ContentContainer>
      </Box>
    </Container>
  );
}
