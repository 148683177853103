import styled from "styled-components";

export const Wrapper = styled.header`
  width: 100%;
  padding: 16px;
  background-color: ${(props) => props.theme.lightTheme.colors.darkGray};

  button.header-exit-button {
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.darkTheme.colors.text};
    background-color: transparent;
    padding: 5px 10px;
    border: none;

    :hover {
      color: ${(props) => props.theme.darkTheme.colors.green};
    }
  }

  > div {
    width: 100%;
    max-width: 414px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }
`;
