import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { MdErrorOutline } from "react-icons/md";
import { AnimationContainer } from "../../containers";
import * as S from "./styles";

const Alert = ({ children, text = "", variant = "success" }) => {
  const variants = {
    success: (
      <S.Wrapper variant={variant} className={"alert-box"}>
        <FiCheckCircle />
        <span>
          {children}
          {text}
        </span>
      </S.Wrapper>
    ),
    error: (
      <S.Wrapper variant={variant} className={"alert-box"}>
        <MdErrorOutline />
        <span>
          {children}
          {text}
        </span>
      </S.Wrapper>
    ),
    warning: (
      <S.Wrapper variant={variant} className={"alert-box"}>
        <MdErrorOutline />
        <span>
          {children}
          {text}
        </span>
      </S.Wrapper>
    ),
    generic: (
      <S.Wrapper variant={"generic"} className={"alert-box"}>
        <span>
          {children}
          {text}
        </span>
      </S.Wrapper>
    ),
    default: <></>,
  };

  return (
    <AnimationContainer show={true}>
      {variants[variant] || variant["default"]}
    </AnimationContainer>
  );
};

export default Alert;
