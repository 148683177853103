import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import { useUser, useMyList } from "../../hooks";
import { CTAButton, Button, MyListButton } from "../../components";

import ClassesIcon from "../../assets/icons/classes-icon.svg";
import * as S from "./styles";

const Slide = ({ course, category, categorySlug = null }) => {
  const [showModal, setShowModal] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const {
    data: { userData },
  } = useUser();

  const {
    addFavorite,
    removeFavorite,
    isFetchingFavorites,
    verifyFavoriteStatus,
    isLoadingAddOrRemove,
  } = useMyList();

  useEffect(() => {
    if (!isFetchingFavorites) setIsFavorite(verifyFavoriteStatus(course?.id));
  }, [isFetchingFavorites]);

  const handleFavorite = () => {
    if (isFavorite) return removeFavorite(course.id);

    return addFavorite(course.id);
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const createExcerpt = (description = "") => {
    const excerptLength = 140;

    if (description === null)
      return <p>Clique em "Ver todas aulas e informações" e saiba mais.</p>;

    if (description.length > excerptLength)
      return <p>{description.substring(0, excerptLength)}...</p>;

    if (description.length <= excerptLength) return <p>{description}</p>;
  };

  const suffix = (course?.classes && Number(course?.classes)) > 1 ? "s" : "";

  const renderFavoriteButton = () => {
    if (isLoadingAddOrRemove || isFetchingFavorites) return <Spinner />;

    if (isFavorite)
      return <i className="fas fa-check-circle checked animate-pop" />;

    return <i className="fal fa-plus-circle animate-pop" />;
  };

  if (!course) return null;

  return (
    <>
      <S.SlideContainer
        key={course.title}
        coverDesktop={course.images?.coverUrl}
        coverMobile={course.images?.coverMobileUrl || course.images?.coverUrl}
      >
        <S.SlideInfo>
          <S.CourseNameContainer>
            <S.Title>{course.title}</S.Title>
            <S.Author>por {course.author?.name}</S.Author>
          </S.CourseNameContainer>

          <S.SliderButtons>
            <S.ActionButtonContainer
              onClick={() => handleModal(course, userData)}
              id={"btn-banner-saiba_mais"}
              name={"btn-banner-saiba_mais"}
            >
              <i className="fal fa-info-circle" />
              saiba mais
            </S.ActionButtonContainer>

            <MyListButton courseId={course.id} />

            <S.ButtonLinkContainer
              to={`/curso/${course.id}/${course.slug}?play_video=true`}
              id={"btn-banner-assistir"}
              name={"btn-banner-assistir"}
            >
              <Button variant={"white"} size={"full"}>
                <i className="fas fa-play" /> ASSISTIR
              </Button>
            </S.ButtonLinkContainer>
          </S.SliderButtons>

          <div>
            {categorySlug ? (
              <S.TagsContainer>
                <Link to={`/categoria/${categorySlug}`} key={category}>
                  {category}
                </Link>
              </S.TagsContainer>
            ) : (
              <S.TagsContainer>
                <span>{category}</span>
              </S.TagsContainer>
            )}
          </div>
        </S.SlideInfo>
      </S.SlideContainer>
      <Modal
        isOpen={showModal}
        toggle={handleModal}
        backdrop={true}
        className={"modal-course-details"}
        contentClassName={"content-modal-course-details"}
        centered
        fade={false}
      >
        <ModalBody>
          <S.ModalCustomGrid>
            <S.ModalImageContainer>
              <img
                src={course.images?.thumbUrl}
                alt={course.title || "Miniatura do curso"}
              />
            </S.ModalImageContainer>
            <S.ModalDescriptionContainer>
              <h2>{course.title}</h2>
              {createExcerpt(course.description)}
            </S.ModalDescriptionContainer>

            <div
              className="close"
              onClick={handleModal}
              id={"btn-banner_slide-close_modal"}
              name={"btn-banner_slide-close_modal"}
            >
              <i className="fas fa-times" />
            </div>

            <S.ModalButtonsContainer>
              <div className="classes">
                <img src={ClassesIcon} alt={"icone"} />
                <span>
                  {course.classes} aula{suffix}
                </span>
              </div>

              <button
                className="favorite"
                onClick={() => handleFavorite()}
                id={"btn-banner_slide-minha_lista"}
                name={"btn-banner_slide-minha_lista"}
              >
                {renderFavoriteButton()} minha lista
              </button>
            </S.ModalButtonsContainer>
          </S.ModalCustomGrid>
        </ModalBody>
        <ModalFooter>
          <S.ButtonsContainer>
            <Link to={`/curso/${course.id}/${course.slug}?play_video=true`}>
              <CTAButton dark>
                <i className="fas fa-play" /> ASSISTIR
              </CTAButton>
            </Link>
            <Link to={`/curso/${course.id}/${course.slug}`}>
              <CTAButton className="add-border-gray">
                VER TODAS AULAS E INFORMAÇÕES
                <i className="fas fa-angle-right" />
              </CTAButton>
            </Link>
          </S.ButtonsContainer>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Slide;
