import CategoryExceptions from "../constants/categoryExceptions";

export const filterAndOrderMenu = (categoriesArray) => {
  return categoriesArray
    .filter((category) => CategoryExceptions.mainMenu.includes(category.slug))
    .sort(
      (a, b) =>
        CategoryExceptions.mainMenu.indexOf(a.slug) -
        CategoryExceptions.mainMenu.indexOf(b.slug)
    );
};
export const getSubmenuHeaderCategories = (categoriesArray) => {
  return categoriesArray.filter((category) =>
    CategoryExceptions.mainSubmenu.includes(category.slug)
  );
};
