import React from "react";
import { useRecommendedCourses } from "../../hooks";
import Container from "../../components/Container";
import CardsGrid from "../../components/CardsGrid";
import { LoadingSpinner } from "../../components";
import strings from "../../config/strings.json";

function Recommendations() {
  const { data, isFetching } = useRecommendedCourses();

  if (isFetching) {
    return <LoadingSpinner color={"light"} />;
  }

  return (
    <Container type={"clean"}>
      <CardsGrid
        content={data}
        pageName={strings.categories.recommendations.title}
        link="/escolhas-para-voce"
      />
    </Container>
  );
}

export default Recommendations;
