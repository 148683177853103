import styled, { css } from "styled-components";
import arrowDown from "../../assets/icons/arrow-down-2.svg";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 420px;
    padding-bottom: 64px; //zendesk button spacing

    p {
      color: ${theme.darkTheme.colors.gray};
      padding-top: 45px;
    }

    button {
      margin: 5px auto;
    }

    .form-control {
      width: 100%;
      height: 50px;
      font-size: 14px;
      background: ${theme.darkTheme.colors.inputBackground};
      color: ${theme.darkTheme.colors.white};
      border-radius: 5px;
      padding: 0 10px;
      margin-bottom: 45px;
      outline: none !important;
      border: none;

      transition: all 300ms linear;

      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url(${arrowDown});
      background-repeat: no-repeat;
      background-position-x: 96%;
      background-position-y: 50%;

      &:disabled {
        background-color: ${theme.darkTheme.colors.coal};
        border-color: ${theme.darkTheme.colors.coal};
        color: ${theme.darkTheme.colors.darkBlack};
      }

      &:focus {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
      }
    }
  `}
`;
