import styled from "styled-components";

export const Label = styled.label`
  display: flex;
  gap: 10px;
  margin: 5px 0;
  cursor: pointer;
  font-family: "MaisonNeueBook";

  input {
    width: 1em;
  }
`;
