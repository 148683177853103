import React from "react";
import { useCheckoutContext } from "../../context/CheckoutContext";
import {
  SignupHeader,
  StepperCustom,
  CheckoutFooter,
  GiftPayment,
  GiftPaymentBestFriday,
} from "../../components";
import * as S from "./styles";
import { usePromotionalCampaign } from "../../hooks";

const GiftCheckoutPayment = () => {
  const { currentStep, giftSteps } = useCheckoutContext();
  const { data: campaign } = usePromotionalCampaign();

  return (
    <S.MainWrapper>
      <SignupHeader />
      <StepperCustom steps={giftSteps} currentStep={currentStep} />
      <S.ContentWrapper>
        {!!campaign ? <GiftPaymentBestFriday /> : <GiftPayment />}
        <CheckoutFooter />
      </S.ContentWrapper>
    </S.MainWrapper>
  );
};

export default GiftCheckoutPayment;
