import React, { useEffect, useRef, useCallback } from "react";
import { useSentry } from "../../hooks";
import { requests } from "../../services/requests";
import * as S from "./styles";

const PlayerSambaVideos = ({
  video,
  ignoreProgress,
  courseId,
  isAutoPlay,
  height = 200,
  width = 500,
  onFinishVideo,
}) => {
  const { logException } = useSentry();

  const player = "player";

  const {
    id: classId,
    isFinished: isClassFinished,
    title: videoTitle,
    mediaId: videoReference,
    progress: videoProgress,
  } = !!video && video;

  let setProgress = false;

  const playerRef = useRef(null);
  const videoRef = useRef(null);

  // Armazena o progresso do vídeo a cada 15 segundos
  function updateCurrentProgress(player) {
    const moreThanFifteenSecondsToFinish =
      player.duration / 1000 - player.eventParam > 15;

    if (
      !setProgress &&
      player.eventParam > 1 &&
      parseInt(player.eventParam) % 15 === 0 &&
      moreThanFifteenSecondsToFinish
    ) {
      setProgress = true;

      requests.updateClassProgress({
        courseId: String(courseId),
        classId: String(classId),
        progress: Number(player.eventParam),
      });

      setTimeout(() => {
        setProgress = false;
      }, 1000);
    }
  }

  const eventListener = (player) => {
    switch (player.event) {
      case "onLoad":
        videoReference && isAutoPlay && playerRef.current.play();
        break;
      case "onStart":
        requests.createClassProgress({
          courseId: String(courseId),
          classId: String(classId),
        });
        break;
      case "onListen":
        updateCurrentProgress(player);
        break;
      case "onPause":
        break;
      case "onResume":
        break;
      case "onSeek":
        break;
      case "onCuepoint":
        break;
      case "onMediaView":
        break;
      case "onProgress":
        break;
      case "onFinish":
        requests.finishClassProgress({
          courseId: String(courseId),
          classId: String(classId),
        });
        requests.updateClassProgress({
          courseId: String(courseId),
          classId: String(classId),
          progress: Number(player.duration / 1000), //ms to seconds (100%)
        });
        onFinishVideo();
        break;
      case "onError":
        const exceptionParams = {
          error: new Error("evento onError Sambaplayer"),
          transactionName: "Player",
          origin: "SambaPlayer",
          extras: [
            { label: "class", value: video },
            { label: "courseId", value: courseId },
          ],
        };
        logException(exceptionParams);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    initPlayer();
  }, [videoReference]);

  const currentProgress = videoProgress ?? 0;
  const initPlayer = useCallback(async () => {
    playerRef.current = null;
    videoRef.current.innerHTML = "";

    playerRef.current = new window.SambaPlayer(player, {
      height,
      width,
      ph: process.env.REACT_APP_SAMBAVIDEOS_PLAYER_KEY,
      m: videoReference, //mediaId
      playerParams: {
        enableShare: false,
        initialTime: isClassFinished || ignoreProgress ? 0 : currentProgress,
        title: videoTitle?.replace("#", "") || "",
        cast: true,
      },
      events: {
        "*": eventListener,
      },
    });
  }, [videoReference, currentProgress]);

  return (
    <S.PlayerSambaContainer className={`ratio ratio-16x9 mb-5`}>
      <div ref={videoRef} className="ratio ratio-16x9" id="player" />
    </S.PlayerSambaContainer>
  );
};

export default PlayerSambaVideos;
