import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    position: absolute;
    border-radius: 5px;
    background: #ffff;
    padding: 20px;
    z-index: 1;

    @media (min-width: ${theme.breakPoint.regularPhone}) {
      left: 20%;
    }

    @media (min-width: ${theme.breakPoint.laptop}) {
      left: 30%;
    }
    @media (min-width: ${theme.breakPoint.desktop}) {
      left: 40%;
    }
  `}
`;

export const ShareContent = styled.div`
  display: flex;
`;

export const ShareOptions = styled.div`
  ${({ theme }) => css`
    width: 45px;
    height: 45px;
    background: none;
    color: #ffffff;
    margin-right: 10px;

    img {
      background: white;
      max-width: 100%;
      max-height: 100%;
    }

    @media (min-width: ${theme.breakPoint.regularPhone}) {
      width: 50px;
      height: 50px;
    }
    @media (min-width: ${theme.breakPoint.laptop}) {
      width: 60px;
      height: 60px;
      cursor: pointer;
    }
  `}
`;

export const CloseButton = styled.div`
  ${({ theme }) => css`
    width: 25px;
    height: 25px;
    background: #323132;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      cursor: pointer;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    @media (min-width: ${theme.breakPoint.regularPhone}) {
      width: 30px;
      height: 30px;
    }
  `}
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.p`
  color: #000;
`;
