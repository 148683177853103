import styled from "styled-components";

export const Wrapper = styled.div`
  background: #f7ce6b;
  color: #100f10;
  font-size: 14px;
  font-weight: 400;
  display: block;
  padding: 1rem 1.5rem;
  margin: 0 -0.7rem; // offset the container margin

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 0 auto;
  }
`;

export const OverlayContainer = styled.div`
  a {
    line-height: 160%;
  }
`;

export const BannerActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  @media (min-width: 768px) {
    align-items: center;
    margin-top: 0;
  }
`;

export const CompleteButton = styled.button`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #100f10;
  background: none;
  height: 17px;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  outline: none;
  border: none;
  text-transform: uppercase;

  :hover {
    color: #fff;
  }
`;

export const DismissButton = styled.button`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #b08f3e;
  background: none;
  height: 17px;
  justify-content: center;
  transition: all 0.4s;
  cursor: pointer;
  outline: none;
  border: none;
  text-transform: uppercase;
  margin: 0 15px;

  :hover {
    color: #100f10;
  }
`;
