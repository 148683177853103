import { VERSION } from "./settings";
import * as Sentry from "@sentry/react";

export const normalizeEndpoint = (endpoint) => {
  return endpoint.replace(`/${VERSION}/`, "/");
};

export const isExternalLink = (url) => {
  const regex = /^(?:http(s)?:\/\/)/;
  return regex.test(url);
};

export const millisToMinutesAndSeconds = (millis) => {
  if (!millis) return "0";

  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return seconds === 60
    ? minutes + 1 + ":00"
    : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};

export const setCookie = (name, value, days = 7, path = "/") => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie =
    name +
    "=" +
    encodeURIComponent(value) +
    "; expires=" +
    expires +
    "; path=" +
    path;
};

export const getCookie = (name) => {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");
};

export const deleteCookie = (name, path) => {
  setCookie(name, "", -1, path);
};

export const normalizeString = (string = "") => {
  if (!string || typeof string !== "string") return string;

  let newString = string;
  let lastStep;

  try {
    const steps = [
      (str) => str.toLowerCase(),
      (str) => str.normalize("NFD"),
      (str) => str.replace(/[\u0300-\u036f]/g, ""),
      (str) => str.replaceAll(" ", "_"),
    ];

    for (let i = 0; i < steps.length; i++) {
      lastStep = i;
      newString = steps[i](newString);
    }

    return newString;
  } catch (error) {
    Sentry.captureException(error, (scope) => {
      scope.setTransactionName(
        `Util[normalizeString]: ${error?.message ?? error?.code}`
      );
      scope.setExtra("string", string);
      scope.setExtra("newString", newString);
      scope.setExtra("lastStep", lastStep);
      return scope;
    });

    return newString;
  }
};

export const planNameStandardization = (planId) => {
  const plan = {
    //PROD
    17: "Plano Vivo",
    59: "Plano TAG Livros",
    71: "Plano Anual",
    72: "Plano Anual",
    73: "Plano Anual",
    74: "Plano Anual",
    75: "Plano Anual",
    76: "Plano Anual",
    77: "Plano Anual",
    78: "Plano Anual",
    79: "Plano Anual",
    80: "Plano Anual",
    81: "Plano Mensal",
    82: "Plano Presente 1 ano", //presente
    83: "Plano Presente 2 anos", //presente
    84: "Plano Mensal", //mensal página de pagamento
    85: "Plano Mensal", //mensal página de pagamento
    //STAGING
    298: "Plano Vivo",
    173: "Sem plano ativo",
    277: "Plano Padrão",
    274: "Plano Anual",
    284: "Plano Anual",
    291: "Plano Anual",
    294: "Plano Anual",
    295: "Plano Presente 1 ano",
    296: "Plano Presente 2 anos",
    299: "Plano Mensal", //mensal página de pagamento
    300: "Plano Mensal", //mensal página de pagamento
    default: "",
  };
  return plan[planId] || plan["default"];
};

export const formattedDate = (date) => {
  if (date) {
    const brazilDate = date.replace("+00:00", "-03:00").replace("Z", "-03:00");
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const newDate = new Date(brazilDate).toLocaleDateString("pt-BR", options);

    return newDate;
  }
  return "";
};

export const formattedPrice = (price) => {
  return Number(price).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
};

/**
 * @param {string | number} amount
 * @param {string} discountType
 * @returns string
 */
export const formatDiscountString = (amount, discountType = "percentage") => {
  const amountNumber =
    typeof amount === "string" ? Number(amount.replace(",", ".")) : amount;

  const result = {
    percentage: `${amount}%`,
    amount: amountNumber.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    }),
  };

  return result[discountType] || result["percentage"];
};

export const formatDiscountLabel = (string) => {
  if (!string) return;

  if (string.includes("%")) {
    const splittedValue = string.replace("%", "").split(".");
    return `${splittedValue[0]}%`;
  }

  if (string.includes("R$")) {
    const value = string.replace("R$", "");
    return formattedPrice(value);
  }
};

export const formatCPFOnlyNumbers = (string) => {
  if (!string) return;
  if (typeof string != "string") return string;

  return string.replace(/\D/g, "");
};

export const copyLink = async (link) => {
  let inputTest = document.createElement("input");
  inputTest.value = link;
  document.body.appendChild(inputTest);
  inputTest.select();
  inputTest.setSelectionRange(0, 99999); /* For mobile devices */
  document.execCommand("copy");
  document.body.removeChild(inputTest);
  return "Copiado!";
};

export const convertStringToDigits = (rawString) => {
  return rawString.replace(/[^\d]/gi, "");
};

export const calculateDaysLeft = (endAt) => {
  if (!endAt || typeof endAt !== "string") return undefined;

  const today = new Date();
  const endDate = new Date(endAt);

  const millisecondsInADay = 1000 * 3600 * 24;

  // To calculate the time difference of two dates
  const differenceInMilliseconds = endDate.getTime() - today.getTime();

  // To calculate the no. of days between two dates
  const differenceInDays = differenceInMilliseconds / millisecondsInADay;

  return Number(differenceInDays);
};

export const verifyWarranty = (startDate) => {
  const currentDate = Date.now();
  const eightDays = 691200000;
  const subscriptionStartDate = new Date(startDate)?.getTime();
  const afterEightDays = subscriptionStartDate + eightDays;
  const firstEightDays = currentDate < afterEightDays;

  return firstEightDays;
};

export const priceStringToNumber = (price) => Number(price.replace(",", "."));

/**
 * @param {string} value
 * @returns string
 */
export const getURLParamValue = (value = "") => {
  const msgParam = new URLSearchParams(window.location.search);
  return msgParam.get(value);
};

/**
 * @param {string} dateString
 * @returns string
 */
export const addOneYearAndFormat = (dateString) => {
  const date = new Date(dateString);

  date.setFullYear(date.getFullYear() + 1);
  return date.toLocaleDateString("pt-BR", { timeZone: "UTC" });
};

/**
 * @param {string} dateString
 * @returns string
 */
export const addOneYear = (dateString) => {
  const date = new Date(dateString);

  date.setFullYear(date.getFullYear() + 1);
  return new Date(date);
};
