import React from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input";

import "react-phone-number-input/style.css";
import * as S from "./styles";

const PhoneNumberInput = ({
  label,
  inputName,
  errors,
  control,
  variant = "dark",
  ...props
}) => {
  if (!label || !inputName) return null;

  return (
    <div className="input-container">
      <S.InputContainer>
        <S.InputPhoneFieldContainer
          invalid={!!errors?.[inputName]}
          variant={variant}
        >
          <label htmlFor={inputName}>{label}</label>

          <Controller
            name={inputName}
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                {...props}
                aria-invalid={errors?.[inputName] ? "true" : "false"}
                value={value}
                onChange={onChange}
                id={inputName}
                key={inputName}
                defaultCountry={"BR"}
                initialValueFormat="national"
              />
            )}
          />
        </S.InputPhoneFieldContainer>
      </S.InputContainer>

      {errors?.[inputName] && (
        <S.ErrorMessage variant={variant}>
          {errors[inputName].message}
        </S.ErrorMessage>
      )}
    </div>
  );
};

export default PhoneNumberInput;
