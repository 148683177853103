import styled, { css } from "styled-components";

const wrapperModifiers = {
  smallCard: () => css`
    width: 101px;
    height: 144px;
    font-size: 12px;

    @media (min-width: 768px) {
      width: 126px;
      height: 185px;
    }
  `,
  bigCard: () => css`
    width: 140px;
    height: 205px;
    font-size: 16px;

    @media (min-width: 768px) {
      width: 196px;
      height: 288px;
    }
  `,
  progress: (theme) => css`
    background-color: ${theme.darkTheme.colors.darkGray};
    padding: ${theme.spacings.smaller};
    font-size: ${theme.font.size.smaller};
    font-weight: ${theme.font.weight.regular};

    @media (min-width: 768px) {
    }
  `,
  notStarted: (theme) => css`
    background-color: ${theme.darkTheme.colors.coal};
    padding: 5px ${theme.spacings.smaller};
    font-size: ${theme.font.size.smaller};
    font-weight: ${theme.font.weight.regular};
  `,
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    text-decoration: none !important;
    color: ${theme.darkTheme.colors.text}
    max-width: 100%;
    
    :hover {
      color: ${theme.darkTheme.colors.green}
    }
  `}
`;

export const ThumbContainer = styled.div`
  ${({ variant }) => css`
    max-width: 100%;
    max-height: 100%;
    position: relative;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    cursor: pointer;

    ${variant === "smallCard" && wrapperModifiers.smallCard()}
    ${variant === "bigCard" && wrapperModifiers.bigCard()}
  `}
`;

export const ThumbImage = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;

  &.fit-cover {
    object-fit: cover;
  }
`;

export const InfoContainer = styled.div`
  ${({ theme, variant }) => css`
    position: relative;

    ${variant && wrapperModifiers[variant](theme)}
  `}
`;

export const ThumbCaption = styled.div`
  cursor: pointer;
  width: 100%;
  height: 38%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(32, 30, 32, 0) 0%, #201e20 100%);
  opacity: 0.9;
  transition: all 0.3s;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;

  ${ThumbContainer}:hover & {
    opacity: 1;
  }

  p {
    text-align: left;
    margin-left: 10px;
    margin-right: 5px;
    color: #ffffff;
    font-family: "MaisonNeue", sans-serif;
  }
`;
