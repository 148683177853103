import styled from "styled-components";

export const Wrapper = styled.footer`
  font-family: "MaisonNeueBook", sans-serif;
  color: ${(props) => props.theme.darkTheme.colors.text};
  font-size: 12px;
  line-height: 22px
  text-align: left;
  padding: 16px 0;
  margin: 0;
  width: 100%;

  a {
    color: ${(props) => props.theme.darkTheme.colors.text};
    text-decoration: underline;
    text-underline-offset: 1px;

    :hover {
      color: ${(props) => props.theme.darkTheme.colors.green};
    }
  }
`;
