import CryptoJS from "crypto-js";

class SecureStorage {
  SECRET_KEY = "]mZRi8A~v@1jI@zI%Wc#F[3t,Me=A*i:g1L5xHe4";

  hash(key) {
    key = CryptoJS.SHA256(key, this.SECRET_KEY);

    return key.toString();
  }

  encrypt(data) {
    data = CryptoJS.AES.encrypt(data, this.SECRET_KEY);

    data = data.toString();

    return data;
  }

  decrypt(data) {
    if (!data) return undefined;
    data = CryptoJS.AES.decrypt(data, this.SECRET_KEY);

    data = data.toString(CryptoJS.enc.Utf8);

    return data;
  }

  setItem(key, data) {
    localStorage.setItem(this.hash(key), this.encrypt(data));
  }

  getItem(key) {
    return this.decrypt(localStorage.getItem(this.hash(key)));
  }

  removeItem(key) {
    return localStorage.removeItem(this.hash(key));
  }
}

export const secureStorage = new SecureStorage();
