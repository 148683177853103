import React, { useState } from "react";
import * as S from "./styles";
import ShareModal from "../ShareModal";
import { useLocation } from "react-router-dom";

const ShareButton = (props) => {
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <S.Wrapper
        onClick={() => setIsModalOpen(true)}
        id={"btn-share_button"}
        name={"btn-share_button"}
        {...props}
      >
        <svg
          id="share"
          width="35"
          height="31"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="animate-pop"
        >
          <path
            className="stroke-solid"
            d="M15 28C21.9036 28 27.5 22.4036 27.5 15.5C27.5 8.59644 21.9036 3 15 3C8.09644 3 2.5 8.59644 2.5 15.5C2.5 22.4036 8.09644 28 15 28Z"
          />
          <g clipPath="url(#clip0_10331_423)">
            <path
              className="icon"
              d="M10 18C8.6 18 7.5 16.9 7.5 15.5C7.5 14.1 8.6 13 10 13C11.4 13 12.5 14.1 12.5 15.5C12.5 16.9 11.4 18 10 18ZM10 14C9.15 14 8.5 14.65 8.5 15.5C8.5 16.35 9.15 17 10 17C10.85 17 11.5 16.35 11.5 15.5C11.5 14.65 10.85 14 10 14ZM20 13C18.6 13 17.5 11.9 17.5 10.5C17.5 9.1 18.6 8 20 8C21.4 8 22.5 9.1 22.5 10.5C22.5 11.9 21.4 13 20 13ZM20 9C19.15 9 18.5 9.65 18.5 10.5C18.5 11.35 19.15 12 20 12C20.85 12 21.5 11.35 21.5 10.5C21.5 9.65 20.85 9 20 9ZM20 23C18.6 23 17.5 21.9 17.5 20.5C17.5 19.1 18.6 18 20 18C21.4 18 22.5 19.1 22.5 20.5C22.5 21.9 21.4 23 20 23ZM20 19C19.15 19 18.5 19.65 18.5 20.5C18.5 21.35 19.15 22 20 22C20.85 22 21.5 21.35 21.5 20.5C21.5 19.65 20.85 19 20 19Z"
            />
            <path
              className="icon"
              d="M12.0034 15.9425L18.4434 19.1625L17.9959 20.0565L11.5559 16.8365L12.0034 15.9425ZM17.9964 10.9425L18.4434 11.8375L12.0034 15.0565L11.5564 14.1615L17.9964 10.9425Z"
            />
          </g>
          <defs>
            <clipPath id="clip0_10331_423">
              <rect
                width="15"
                height="15"
                fill="white"
                transform="translate(7.5 8)"
              />
            </clipPath>
          </defs>
        </svg>
        compartilhar
      </S.Wrapper>
      {isModalOpen && (
        <ShareModal
          onClose={() => setIsModalOpen(false)}
          options={{
            emailEnabled: false,
            facebookEnabled: true,
            linkedinEnabled: true,
            whatsappEnabled: true,
          }}
          url={`www.casadosaber.com.br${location.pathname}`}
        />
      )}
    </>
  );
};

export default ShareButton;
