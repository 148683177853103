export const isLegacyCheckoutActive =
  process.env.REACT_APP_CHECKOUT_LEGACY_ACTIVE === "true";

const newCheckoutPaymentUrl = process.env.REACT_APP_CHECKOUT_URL + "/pagamento";
const newCheckoutSignupUrl = process.env.REACT_APP_CHECKOUT_URL + "/cadastro";

const legacyCheckoutSignupPath = "/cadastro";

export const formatUserToNewCheckoutParam = (
  customerId,
  email,
  name,
  phoneNumber
) => {
  return `?user=${btoa(
    JSON.stringify({
      customerId,
      email,
      name,
      phoneNumber,
    })
  )}`;
};

export const activeSignupUrl = () => {
  return isLegacyCheckoutActive
    ? legacyCheckoutSignupPath
    : newCheckoutSignupUrl;
};

export const getNewCheckoutUrlWithUserParam = ({
  customerId,
  email,
  name,
  phoneNumber,
}) => {
  if (customerId && email && name && phoneNumber) {
    return `${newCheckoutPaymentUrl}${formatUserToNewCheckoutParam(
      customerId,
      email,
      name,
      phoneNumber
    )}&skipPassword=true`;
  }
  return newCheckoutSignupUrl;
};
