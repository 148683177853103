import { useState } from "react";
import Datalayer from "../services/datalayer";

export const useDatalayer = () => {
  const [lastData, setLastData] = useState({});

  const handleBlur = (e, category, action, inputName) => {
    setLastData({ ...lastData, [inputName]: e.target.value });

    if (
      e.target &&
      e.target.value.length > 0 &&
      e.target.value !== lastData[inputName]
    ) {
      Datalayer.onFill(category, action, inputName);
    }
  };

  return { handleBlur };
};

export default useDatalayer;
