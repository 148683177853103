import { useMutation, useQuery, useQueryClient } from "react-query";
import { requests } from "../services/requests";
import {
  setInitialData,
  setFreshTime,
} from "../constants/reactQueryDefaultOption";

const queryKeys = {
  user: "user-details",
};

export const useUser = (options = {}) => {
  return useQuery([queryKeys.user], requests.getUser, {
    ...setInitialData({}),
    ...setFreshTime(10),
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useUpdateAutoplay = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (userDataObject) => requests.updateAutoPlay(userDataObject),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.user);
      },
      ...options,
    }
  );
};

export const useCreateCategoriesUserPreference = (options = {}) => {
  return useMutation(
    (categories) => requests.createCategoriesUserPreference(categories),
    {
      ...options,
    }
  );
};

export const useUpdatePersonalData = (options = {}) => {
  return useMutation((body) => requests.updatePersonalData(body), options);
};
