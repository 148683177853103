import styled, { css } from "styled-components";

export const CheckoutContainer = styled.div`
  ${({ theme }) => css`
    max-width: 414px;
    margin: 0 auto;
    padding: 16px;
  `}
`;

export const PlansContainer = styled.div`
  margin-top: 1.5em;
`;
export const PlanItem = styled.div`
  ${({ theme, selected }) => css`
    color: ${theme.lightTheme.colors.text};
    border-radius: 5px;
    border-color: ${selected ? theme.lightTheme.colors.darkBlack : "#ccc"};

    border-style: solid;
    border-width: 1px;
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 11px 26px;
    position: static;
    margin-bottom: 0.5rem;
    cursor: pointer;

    * {
      cursor: pointer;
    }
    .plan-name {
      text-align: left;
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.6em;
    }
    .plan-value {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.6em;
      margin-bottom: 0;
    }

    // hover animation
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    :after {
      content: "";
      border-radius: 5px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      opacity: 0;
      -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    :hover {
      -webkit-transform: scale(1.05, 1.05);
      transform: scale(1.05, 1.05);
    }

    :hover::after {
      opacity: 1;
    }
  `}
`;

export const DiscountLabel = styled.span`
  display: inline-block;
  background-color: ${(props) => props.theme.darkTheme.colors.green};
  border-radius: 3px;
  padding: 3px;
  margin-left: 2px;
  font-size: 12px;
  line-height: 1.5em;
  font-weight: 800;
  font-family: "Avenir", sans-serif;
`;

export const Errors = styled.div`
  padding: 15px;
  border-radius: 5px;
  margin-top: -25px;
  border: 1.5px solid #ff7651;
  margin-bottom: 0.7rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  span {
    color: ${(props) => props.theme.lightTheme.colors.text};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    display: inline;
    align-items: center;
  }
  svg {
    color: #ff7651;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  a {
    color: ${(props) => props.theme.lightTheme.colors.text};
    text-decoration: underline;
  }
`;

export const GiftDisclaimer = styled.p`
  font-family: "MaisonNeueBook", sans-serif;
  color: ${(props) => props.theme.lightTheme.colors.text};
  font-size: 12px;
  text-align: left;
  padding: 1em 0;
  margin-bottom: 0; //reset default
`;
