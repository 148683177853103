import styled, { css } from "styled-components";
import { size } from "../../../styles/mediaQueries";
import { Link } from "react-router-dom";

export const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto 5px;
  padding: 0.5em 0;
  text-transform: uppercase;
  font-family: "MaisonNeueBook", sans-serif;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: ${size.regularPhone}px) {
    justify-content: space-between;
  }
`;

export const CategoriesLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.darkTheme.colors.text};

    :hover {
      text-decoration: none;
      color: ${theme.darkTheme.colors.green};
    }
  `}
`;

export const CertificateLink = styled(Link)`
  ${({ theme }) => css`
    font-size: 12px;
    line-height: normal;
    color: #cccccc;
    background-color: ${theme.lightTheme.colors.darkGray};
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px;
    transition: all 0.2s ease-out;
    text-decoration: none !important;
    display: none;
    margin-right: 10px;

    :hover {
      color: ${theme.darkTheme.colors.green};
      border: 1px solid ${theme.darkTheme.colors.green};
    }

    @media (max-width: ${size.regularPhone}px) {
      display: block;
    }
  `}
`;
