import styled from "styled-components";
import { Modal as RSModal } from "reactstrap";
import { Button } from "../../components";
import { device } from "../../styles/mediaQueries";

export const EmptyState = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  background-position: center;
  background-size: 500px;
  background-repeat: no-repeat;
`;

export const BackButton = styled(Button).attrs({
  type: "button",
  variant: "green",
  size: "full",
})`
  position: absolute;
  width: 200px;
  border-radius: 9999px;
  top: 63vh;
  left: 50%;
  transform: translateX(-50%);
`;

export const LoadingContainer = styled.div`
  max-width: 500px;
  margin: 50px auto;
  text-align: center;
`;

export const SliderContainer = styled.div`
  position: relative;
  max-width: 440px;
  margin: 0 auto;

  @media (${device.tablet}) {
    max-width: 340px;
    margin: 50px auto;
  }

  .slick-dots {
    bottom: 86px;

    & > li button:before {
      font-size: 14px;
      color: ${({ theme }) => theme.darkTheme.colors.white};
    }

    & > li.slick-active button:before {
      color: ${({ theme }) => theme.darkTheme.colors.white};
    }
  }

  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    z-index: 20;
  }
  .slick-prev {
    left: -20px;
  }
  .slick-next {
    right: -20px;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 40px;
  }
`;

export const SlideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    min-height: 674px;
    object-fit: cover;
  }
`;

export const ShareButtonContainer = styled.div`
  position: absolute;
  bottom: 26px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
`;

export const Modal = styled(RSModal)`
  .modal-header {
    color: black;
    border-bottom: none;
  }
`;

export const ModalShareButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ModalCopyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.gap.small};
  padding: ${({ theme }) => theme.gap.medium};
  margin-top: ${({ theme }) => theme.gap.medium};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.lightTheme.colors.darkGray};
`;

export const ModalCopyUrl = styled.div`
  display: flex;
  flex: 1;
  overflow-x: auto;
  white-space: nowrap;
`;

export const ModalCopyButton = styled.button`
  width: auto;
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  padding: ${({ theme }) => theme.gap.smaller};
  color: white;
`;
