import React, { useEffect, useState } from "react";
import { useSubscriptionStatus } from "../../hooks";
import Datalayer from "../../services/datalayer";
import { secureStorage } from "../../util/SecureStorage";

import {
  CheckoutHeader,
  CheckoutFooter,
  CheckoutPageTitle,
  CTAButton,
} from "../../components";

import * as S from "./styles";
import { useCheckoutContext } from "../../context/CheckoutContext";
import { useAuthContext } from "../../context/AuthContext";

const CheckoutProcessing = () => {
  const [fakeRefresh, setFakeRefresh] = useState(false);
  const { data: userData } = useCheckoutContext();
  const { setPathToRedirect } = useAuthContext();

  const { data, isLoading, isFetching, refetch } = useSubscriptionStatus({
    refetchInterval: 2500,
  });

  const saved = secureStorage.getItem(`submitted-plan-${userData.email}`);
  const storedPurchase = saved && JSON.parse(saved);

  useEffect(() => {
    if (isLoading || isFetching) return;

    if (data?.subscriptionStatus === "ACTIVE") {
      Datalayer.onPurchaseSuccess(
        storedPurchase?.name,
        storedPurchase?.id,
        storedPurchase?.revenue,
        storedPurchase?.coupon
      );

      setPathToRedirect("/onboarding");
      secureStorage.removeItem(`submitted-plan-${userData.email}`);
    }
  }, [data, isLoading, isFetching]);

  if (fakeRefresh)
    return (
      <S.Wrapper className="wrapper">
        <CheckoutHeader />
      </S.Wrapper>
    );

  return (
    <S.Wrapper className="wrapper">
      <CheckoutHeader />

      <S.CheckoutContainer>
        <S.ProcessingInfo>
          <S.LoadingAnimation />

          <CheckoutPageTitle
            title={"Agora falta pouco!"}
            subtitle={
              "Seu pagamento já está sendo processado. Assim que for confirmado, você receberá um e-mail informando que o sistema liberou automaticamente o seu acesso. Se não encontrar o e-mail, confira na sua caixa de spam e/ou promoções."
            }
          />

          <p className="lighter">
            Este processo pode demorar até 24h. Durante este período, por favor
            não tente realizar uma nova compra, nem cancelar o seu pagamento.
          </p>
        </S.ProcessingInfo>

        <CTAButton
          id={"btn-processing-recarregar"}
          name={"btn-processing-recarregar"}
          onClick={() => {
            Datalayer.onClick("checkout", "botao:processando", "recarregar");
            setFakeRefresh(true);
            setTimeout(() => {
              refetch();
              setFakeRefresh(false);
            }, 250);
          }}
          text="RECARREGAR"
        />
      </S.CheckoutContainer>

      <CheckoutFooter />
    </S.Wrapper>
  );
};

export default CheckoutProcessing;
