import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { MdErrorOutline } from "react-icons/md";
import * as S from "./styles";

const AlertV2 = ({ children, text = "", variant = "success" }) => {
  const variants = {
    success: (
      <S.Wrapper variant={variant} className={"alert-box"}>
        <FiCheckCircle />
        <span>
          {children}
          {text}
        </span>
      </S.Wrapper>
    ),
    error: (
      <S.Wrapper variant={variant} className={"alert-box"}>
        <MdErrorOutline />
        <span>
          {children}
          {text}
        </span>
      </S.Wrapper>
    ),
    warning: (
      <S.Wrapper variant={variant} className={"alert-box"}>
        <MdErrorOutline />
        <span>
          {children}
          {text}
        </span>
      </S.Wrapper>
    ),
    generic: (
      <S.Wrapper variant={"generic"} className={"alert-box"}>
        <span>
          {children}
          {text}
        </span>
      </S.Wrapper>
    ),
    default: <></>,
  };

  return <>{variants[variant] || variant["default"]}</>;
};

export default AlertV2;
