import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import ReactSlickSlider from "react-slick";
import { Link } from "react-router-dom";
import flags from "../../config/remote_config_defaults.json";
import Slide from "./Slide";

import * as S from "./styles";

const Slider = ({ content = [] }) => {
  const config = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const hasContent = content.length > 0;

  return (
    <S.SliderContainer>
      {hasContent && (
        <ReactSlickSlider {...config}>
          {Boolean(flags.retrospective_enabled) && (
            <S.SlideContainer
              coverDesktop="https://cds-retrospective.s3.amazonaws.com/images/banner-desktop.jpg"
              coverMobile="https://cds-retrospective.s3.amazonaws.com/images/banner-mobile.jpg"
            >
              <Link
                to="/retrospectiva"
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 0,
                }}
              />
            </S.SlideContainer>
          )}
          {content.map((item) => (
            <Slide
              course={item.courses[0]}
              category={item.title}
              categorySlug={item.slug || null}
              key={item.slug}
            />
          ))}
        </ReactSlickSlider>
      )}
    </S.SliderContainer>
  );
};

export default Slider;
