import React from "react";
import thumbDefault from "../../assets/images/thumbdefault.jpeg";
import { formatVideoTime } from "../../util/course";
import { formattedDate } from "../../util/Util.js";
import { ProgressBar, PlayButton } from "../../components";
import * as S from "./styles.js";

const VideoContainer = ({ hasAccess = true, content, playVideo }) => {
  return (
    <S.Wrapper>
      <div>
        <S.Image
          src={content.images.thumbUrl ? content.images.thumbUrl : thumbDefault}
          alt="imagem da aula"
        />
        <ProgressBar progress={content.progressInPercentage} />
      </div>

      <S.ContentInfo>
        <S.Title>{content.title}</S.Title>
        <S.Title>
          {content.status === "unpublished" &&
            !!content.publishAt &&
            `Disponível em ${formattedDate(content.publishAt)}`}
        </S.Title>
        <S.Duration>
          {typeof content.duration === "number" &&
            formatVideoTime(content.duration)}
          {content.isFinished && (
            <S.WatchedTag>
              <span>Visto</span>
            </S.WatchedTag>
          )}
        </S.Duration>
      </S.ContentInfo>

      {hasAccess && content.status === "published" ? (
        <S.PlayButtonContainer>
          <PlayButton
            onClick={() => playVideo(content.slug)}
            className={`content-video-play-button_${content.slug}`}
          />
        </S.PlayButtonContainer>
      ) : (
        <S.LockedButtonContainer>
          <i className="fas fa-lock-alt" />
        </S.LockedButtonContainer>
      )}
    </S.Wrapper>
  );
};

export default VideoContainer;
