import React from "react";
import * as S from "./styles";

const LoginPageTitle = ({ title, subtitle, titleStyle }) => {
  return (
    <S.Wrapper>
      <h1 style={{ ...titleStyle }}>{title}</h1>
      <p>{subtitle}</p>
    </S.Wrapper>
  );
};

export default LoginPageTitle;
