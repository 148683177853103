import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    svg.question-mark:hover {
      path {
        fill: ${theme.darkTheme.colors.green};
      }
    }

    //Disclaimer spacing
    min-height: calc(100vh - 62px); //header height
    position: relative;
    padding-bottom: 180px;

    @media (min-width: 900px) {
      min-height: calc(100vh - 151px); //header height
    }
  `}
`;

export const Disclaimer = styled.footer`
  position: absolute;
  bottom: 0;
  max-height: 180px;
  font-family: "MaisonNeueBook", sans-serif;
  color: ${(props) => props.theme.darkTheme.colors.text};
  font-size: 12px;
  text-align: center;
  padding: 16px;
  margin: 0 -0.7rem;
  width: 100%;
`;
