import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const CarouselContainer = styled.div`
  ${({ theme }) => css`
    margin: 25px auto;
    max-width: 1080px;

    .rec-slider-container {
      margin: 0 !important;
    }

    .rec-pagination {
      align-items: center;
      min-height: 20px;
    }
    .rec-pagination button.rec-dot {
      background-color: ${theme.darkTheme.colors.gray};
      box-shadow: none;
      height: 8px;
      width: 8px;
    }
    .rec-pagination button.rec-dot:last-child:not(.rec-dot_active),
    .rec-pagination button.rec-dot:first-child:not(.rec-dot_active) {
      height: 6px;
      width: 6px;
    }
    .rec-pagination button.rec-dot_active {
      background-color: ${theme.darkTheme.colors.green};
      box-shadow: none;
      height: 10px;
      width: 10px;
    }
  `}
`;

export const HeaderCarousel = styled.div`
  margin: 0 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ShowAllLink = styled(Link)`
  ${({ theme }) => css`
    display: inline;
    color: ${theme.darkTheme.colors.text};
    border-bottom: solid 1px;
    font-size: 12px;
    line-height: 1em;
    font-weight: 300;
    padding: 0 2px;

    :hover {
      text-decoration: none;
      color: ${theme.darkTheme.colors.green};
    }

    @media (min-width: 414px) {
      line-height: 1em;
      font-size: 16px;
    }
  `}
`;

export const TitleCarousel = styled.p`
  display: inline;
  font-family: "MaisonNeue", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;

  @media (min-width: 414px) {
    line-height: 25.6px;
    font-size: 16px;
  }
`;

export const ArrowButton = styled.button`
  ${({ hidden }) => css`
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    display: "flex";
    visibility: ${hidden ? "hidden" : "visible"};
    align-items: center;
  `}
`;

export const Subtitle = styled.p`
  margin: 0 7px 5px 7px;
  color: #ccc;
  font-size: 13px;
`;
