import React, { useCallback, useEffect, useRef } from "react";
import { IconContext } from "react-icons";
import { IoCloseCircle } from "react-icons/io5";
import * as S from "./styles";

const PlayerTrailer = ({ trailer, videoTitle, onClose }) => {
  const player = "player";
  const playerRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => (document.body.style.overflow = "auto");
  }, []);

  const eventListener = (player) => {
    switch (player.event) {
      case "onLoad":
        trailer.videoReference && playerRef.current.play();
        break;
      case "onStart":
        break;
      case "onListen":
        break;
      case "onPause":
        break;
      case "onResume":
        break;
      case "onSeek":
        break;
      case "onCuepoint":
        break;
      case "onMediaView":
        break;
      case "onProgress":
        break;
      case "onFinish":
        break;
      case "onError":
        break;
      default:
        break;
    }
  };

  const initPlayer = useCallback(async () => {
    playerRef.current = null;
    videoRef.current.innerHTML = "";

    playerRef.current = new window.SambaPlayer(player, {
      height: 200,
      width: 500,
      ph: process.env.REACT_APP_SAMBAVIDEOS_PLAYER_KEY,
      m: trailer.videoReference,
      playerParams: {
        enableShare: false,
        initialTime: 0,
        title: videoTitle || "",
        cast: true,
      },
      events: {
        "*": eventListener,
      },
    });
  }, [trailer.videoReference]);

  useEffect(() => {
    initPlayer();
  }, [trailer.videoReference]);

  if (!trailer) return null;

  return (
    <S.PlayerContainer
      onClick={onClose}
      id={"btn-player_container-close_overlay"}
      name={"btn-player_container-close_overlay"}
    >
      <>
        <S.CloseButton
          id={"btn-player-close_overlay"}
          name={"btn-player-close_overlay"}
          onClick={onClose}
        >
          <IconContext.Provider
            value={{
              color: "#ffff",
              size: "100%",
              className: "video-close-button",
            }}
          >
            <IoCloseCircle />
          </IconContext.Provider>
        </S.CloseButton>
        <S.PlayerSambaContainer className={`ratio ratio-16x9 mb-5`}>
          <div ref={videoRef} className="ratio ratio-16x9" id="player" />
        </S.PlayerSambaContainer>
      </>
    </S.PlayerContainer>
  );
};

export default PlayerTrailer;
