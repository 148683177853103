import React from "react";
import * as S from "./styles";

const AccountSubscriptionDisclaimer = () => {
  return (
    <S.Wrapper>
      *7 dias de garantia. Você pode cancelar durante este período e reembolsar
      todo o valor pago. Após este período, a renovação do plano é automática e
      será possível cancelar apenas a renovação, sendo o valor remanescente do
      plano anual cobrado integralmente em sua próxima fatura do cartão de
      crédito. Seu acesso será mantido durante o período contratado.
    </S.Wrapper>
  );
};

export default AccountSubscriptionDisclaimer;
