import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import activeCampaignTags from "../../constants/active-campaign-tags.json";
import Datalayer from "../../services/datalayer";
import { useAuthContext } from "../../context/AuthContext";
import { translateAmplifyErrorMessage } from "../../util/messages";
import { useActiveCampaignTag, useDatalayer, useSentry } from "../../hooks";
import { requests } from "../../services/requests";

import {
  Button,
  Input,
  LoginPageTitle,
  LoginContainer,
  Alert,
} from "../../components";

import * as S from "./styles";
import { activeSignupUrl } from "../../util/newCheckout";

const schema = yup.object({
  email: yup
    .string()
    .required("Preencha um email válido")
    .email("Preencha um email válido")
    .trim()
    .lowercase(),
});

const Login = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { setIsLoggedIn, answerCustomChallenge, pathToRedirect } =
    useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: sendActiveCampaignTag } = useActiveCampaignTag();
  const { handleBlur } = useDatalayer();
  const { logException } = useSentry(false);

  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState({ magicLink: false, password: false });
  const isLoading = Object.values(loading).some((v) => v === true);

  const params = new URLSearchParams(location.search);
  const [emailBase64, challengeParam, changePassword, redirectParam] = [
    params.get("email"),
    params.get("challenge"),
    params.get("pass"),
    params.get("redirect"),
  ];
  const emailParam = !!emailBase64 ? atob(emailBase64) : "";

  useEffect(() => {
    async function answerChallenge() {
      setLoadingMagicLink(true);
      try {
        const response = await answerCustomChallenge(
          emailParam,
          challengeParam
        );

        if (response && !response.signInUserSession)
          throw Error("Code expired");

        setIsLoggedIn(true);
        sendActiveCampaignTag({
          email: emailParam,
          tagId: activeCampaignTags.sucessologin,
        });

        if (changePassword) {
          navigate("/minha-conta/alterar-senha");
        }

        if (redirectParam) {
          navigate(redirectParam);
        }
      } catch (error) {
        const { message } = translateAmplifyErrorMessage(error?.message);

        setAlert({
          type: "error",
          message,
        });
      } finally {
        setLoadingMagicLink(false);
      }
    }

    if (emailParam) {
      setValue("email", emailParam);

      if (challengeParam) {
        answerChallenge();
      }
    }

    if (location.state?.passwordUpdated) {
      setAlert({ message: location.state.message, type: "success" });
      return window.history.replaceState({}, document.title);
    }
  }, []);

  const setLoadingMagicLink = (value) =>
    setLoading({ magicLink: value, password: false });

  const setLoadingPassword = (value) =>
    setLoading({ magicLink: false, password: value });

  const verifyUser = async (email) => {
    try {
      await requests.verifyUser(email.toLowerCase());
      return true;
    } catch {
      const message = (
        <>
          Não foi encontrada uma conta com esse endereço de e-mail. Tente
          novamente ou <a href={activeSignupUrl()}>crie uma nova conta.</a>
        </>
      );
      setAlert({
        message,
        type: "error",
      });
      return false;
    }
  };

  const onSubmit = async (data) => {
    setLoadingPassword(true);
    const userExists = await verifyUser(data.email);
    if (!userExists) {
      setLoadingPassword(false);
      return;
    }

    navigate("/entrar/senha", {
      state: {
        email: data.email,
      },
    });
  };

  const handleLoginWithMagicLink = async (formEmail) => {
    const isValid = await trigger("email");

    if (isValid) {
      setLoadingMagicLink(true);
      const userExists = await verifyUser(formEmail);
      if (!userExists) {
        setLoadingMagicLink(false);
        return;
      }

      try {
        await requests.sendMagicLinkEmail(formEmail, false, pathToRedirect);
        navigate("/entrar/info", {
          state: {
            email: formEmail,
            type: "login",
          },
        });
      } catch (error) {
        const exceptionParams = {
          error,
          transactionName: "Login",
          origin: "sendMagicLink",
          tags: [
            { label: "errorMessage", value: error.message },
            { label: "errorCode", value: error.code },
          ],
          extras: [{ label: "data", value: error }],
          email: formEmail,
        };
        logException(exceptionParams);

        const { message } = translateAmplifyErrorMessage(error?.message);
        sendActiveCampaignTag({
          email: formEmail,
          tagId: activeCampaignTags.falhalogin,
        });

        setAlert({
          message,
          type: "error",
        });
      } finally {
        setLoadingMagicLink(false);
      }
    }
  };

  return (
    <LoginContainer>
      <S.LoginWrapper>
        {!!alert.message && <Alert variant={alert.type} text={alert.message} />}

        <LoginPageTitle
          title={"Entrar na conta"}
          subtitle={
            <>
              Digite o seu <b>e-mail</b> e escolha como quer acessar a
              plataforma.
            </>
          }
        />
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label={"E-mail"}
            inputName={"email"}
            registerFunction={register}
            onBlur={(e) => handleBlur(e, "login", "campo", "email")}
            errors={errors}
            control={control}
            style={{ textTransform: "lowercase" }}
            defaultValue={emailParam || ""}
          />
          <Button
            variant={"white"}
            size={"full"}
            type="submit"
            disabled={isLoading}
            loading={loading.password}
            data-testid={"password-login-button"}
            onClick={() => {
              if (!isLoading) {
                Datalayer.onClickButton("login", "entrar-com-senha");
              }
            }}
          >
            Entrar com senha
          </Button>
        </S.Form>
        <Button
          variant={"transparent"}
          size={"full"}
          onClick={() => {
            if (!isLoading) {
              Datalayer.onClickButton("login", "receber-acesso-por-email");
            }
            handleLoginWithMagicLink(getValues("email")?.toLowerCase());
          }}
          disabled={isLoading}
          loading={loading.magicLink}
          data-testid={"magic-link-login-button"}
        >
          Receber acesso por e-mail
        </Button>
        <S.SignUpLink
          href={activeSignupUrl()}
          onClick={() => Datalayer.onClickLink("login", "assine-agora")}
        >
          Não tem conta? <span>Assine agora</span>
        </S.SignUpLink>
      </S.LoginWrapper>
    </LoginContainer>
  );
};

export default Login;
