import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import { IoCheckmark, IoClose } from "react-icons/io5";

import { store } from "react-notifications-component";
import { notifications } from "../../../util/settings";

import {
  useCancelSubscription,
  useGetGatewayData,
  usePromotionalCampaign,
  useReactivateSubscription,
  useUser,
} from "../../../hooks";
import {
  addOneYear,
  formatDiscountString,
  formattedDate,
  formattedPrice,
  planNameStandardization,
  verifyWarranty,
} from "../../../util/Util";
import { getAlertMessageFromState } from "../../../util/messages";

import {
  Container,
  Button,
  AccountSubscriptionDisclaimer,
  Alert,
  LoadingSpinner,
  Modal,
} from "../../../components";
import { AccountSectionHeader, AccountSectionLine } from "../../../containers";

import { ReactComponent as QuestionMark } from "../../../assets/icons/question-mark-rounded.svg";
import strings from "../../../config/strings.json";
import * as S from "../styles";

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24; //86400000
const TRIAL_DAYS = 8;

const CreditCard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  function setNotification(message, type = "danger") {
    store.addNotification({
      ...notifications,
      message: message,
      type: type,
    });
  }

  const [alert, setAlert] = useState({});
  const [modalType, setModalType] = useState("");

  const {
    data: { subscriptionDetails },
    refetch: refetchUserData,
    isLoading: isSubscriptionLoading,
  } = useUser();

  const { data: campaign } = usePromotionalCampaign();

  const {
    data: gatewayData,
    isLoading: isBillsLoading,
    refetch: refetchGatewayData,
  } = useGetGatewayData({
    reference: subscriptionDetails?.reference,
  });

  const { mutate: sendReactivationRequest, isLoading: isReactivationLoading } =
    useReactivateSubscription({
      onSuccess: () => {
        refetchUserData();
        refetchGatewayData();
        setModalType("");
        return setNotification("Assinatura reativada", "success");
      },
      onError: () => {
        setModalType("");
        setNotification(
          "Houve um erro ao reativar sua assinatura. Por favor, tente novamente ou entre em contato com o suporte",
          "danger"
        );
      },
    });

  const { mutate: sendCancelExtension, isLoading: isCancelLoading } =
    useCancelSubscription({
      onSuccess: (data) => {
        refetchUserData();
        refetchGatewayData();
        setModalType("");
        setAlert({
          message: (
            <>
              <strong>A extensão da sua assinatura foi cancelada.</strong> O
              valor total da sua compra foi estornado e será creditado em até
              duas faturas.
            </>
          ),
          variant: "success",
        });
      },
      onError: () => {
        setModalType("");
        setAlert({
          message:
            "Houve um erro inesperado ao cancelar sua extensão. Por favor, tente novamente e, se o erro persistir, entre em contato com o suporte",
          variant: "error",
        });
      },
    });

  useEffect(() => {
    const { message, variant } = getAlertMessageFromState(location);
    if (message) {
      setAlert({ message, variant });
      return window.history.replaceState({}, document.title);
    }
  }, [location]);

  if (!subscriptionDetails || isSubscriptionLoading || isBillsLoading) {
    return <LoadingSpinner color="light" />;
  }

  const {
    nextBillingAt: renewalDate,
    startAt: startAccess,
    endAt: remainingAccess,
    cancelRequestedAt: cancellationRequestedDate,
    planId: currentPlanId,
    status: subscriptionStatus,
    warranty: isInTrialPeriod,
    planName: DBPlanName,
    extensionAt,
    extensionStatus,
    reference,
  } = subscriptionDetails;

  const hasActivePlan = subscriptionStatus === "active";
  const hasGiftPlan = subscriptionStatus === "unrenewed" && !gatewayData;
  const hasGatewayData = !!gatewayData;

  const hasExtensionPaid = extensionStatus === "paid";
  const isInExtensionWarranty = verifyWarranty(extensionAt);
  const userCanExtendSubscription =
    !!campaign &&
    (extensionStatus === "failed" || extensionStatus === null) &&
    DBPlanName?.toLowerCase().includes("anual");

  const planName = planNameStandardization(currentPlanId);
  const extensionString =
    hasExtensionPaid && addOneYear(extensionAt) >= new Date()
      ? " estendido"
      : "";
  const discountString =
    userCanExtendSubscription &&
    formatDiscountString(
      campaign.coupon.discountValue,
      campaign.coupon.discountType
    );

  const hasAccess = new Date(remainingAccess) > new Date();
  const cancelledInTrial =
    Math.floor(
      (new Date(remainingAccess) - new Date(startAccess)) / DAY_IN_MILLISECONDS
    ) <= TRIAL_DAYS;
  const isGiftAndValid =
    planName.toLowerCase().includes("presente") && hasAccess;

  const renderStatusText = () => {
    const canceledTitle =
      subscriptionDetails?.status === "unrenewed" && cancelledInTrial
        ? "Assinatura cancelada"
        : "Renovação automática cancelada";
    const title =
      hasActivePlan || isGiftAndValid ? "Assinatura ativa" : canceledTitle;
    const icon =
      hasActivePlan || isGiftAndValid ? <IoCheckmark /> : <IoClose />;
    const color = hasActivePlan || isGiftAndValid ? "success" : "danger";

    return (
      <AccountSectionLine bottomLine>
        <S.StatusText color={color}>
          {icon} {title}
        </S.StatusText>
      </AccountSectionLine>
    );
  };

  const renderCancelButton = () => {
    /* cancel extension */
    if (hasExtensionPaid && isInExtensionWarranty) {
      return (
        <Button
          variant={"transparent"}
          size={"full"}
          id={"btn-account-cancelar_assinatura"}
          name={"btn-account-cancelar_assinatura"}
          onClick={() => setModalType("cancelExtension")}
        >
          cancelar extensão da assinatura
        </Button>
      );
    }

    if (isInTrialPeriod) {
      return (
        <Button
          variant={"transparent"}
          size={"full"}
          id={"btn-account-cancelar_assinatura"}
          name={"btn-account-cancelar_assinatura"}
          onClick={() =>
            navigate("/cancelar-plano", {
              state: {
                origin: subscriptionDetails?.origin,
              },
            })
          }
        >
          CANCELAR ASSINATURA
        </Button>
      );
    }

    return null;
  };

  return (
    <Container type={"minimal"} pageName="Detalhes da assinatura">
      <S.Wrapper>
        {!!alert.message && (
          <Alert text={alert.message} variant={alert.variant} />
        )}

        <section>
          <AccountSectionHeader>{"Meu Plano"}</AccountSectionHeader>
          {(hasGatewayData || hasActivePlan || isGiftAndValid) &&
            renderStatusText()}

          {!!planName && (
            <AccountSectionLine bottomLine>
              <strong>
                {planName}
                {extensionString}
              </strong>
            </AccountSectionLine>
          )}

          {!!cancellationRequestedDate && cancelledInTrial && (
            <AccountSectionLine bottomLine>
              Cancelamento efetuado em{" "}
              {formattedDate(cancellationRequestedDate)}
            </AccountSectionLine>
          )}

          {hasActivePlan
            ? !!renewalDate &&
              hasGatewayData && (
                <AccountSectionLine>
                  <span>
                    Renova automaticamente em {formattedDate(renewalDate)}
                  </span>
                  <QuestionMark
                    className="question-mark"
                    role="button"
                    onClick={() => setModalType("renew")}
                  />
                </AccountSectionLine>
              )
            : !!remainingAccess && (
                <AccountSectionLine>
                  <span>
                    Seu acesso expira em {formattedDate(remainingAccess)}
                  </span>
                  {!cancelledInTrial && !hasGiftPlan && (
                    <QuestionMark
                      className="question-mark"
                      role="button"
                      onClick={() => setModalType("unrenewed")}
                    />
                  )}
                </AccountSectionLine>
              )}
        </section>

        {hasGatewayData && (
          <>
            {userCanExtendSubscription && (
              <Button
                variant={"transparent"}
                size={"full"}
                id={"btn-subscription_details-estender_assinatura"}
                name={"btn-subscription_details-estender_assinatura"}
                onClick={() => navigate("/minha-conta/estender-assinatura")}
              >
                estender assinatura com {discountString} desconto
              </Button>
            )}

            {!hasActivePlan && !cancelledInTrial && (
              <Button
                variant={"green"}
                size={"full"}
                id={"btn-subscription_details-reativar_minha_assinatura"}
                name={"btn-subscription_details-reativar_minha_assinatura"}
                onClick={() =>
                  sendReactivationRequest(subscriptionDetails.reference)
                }
                disabled={!!isReactivationLoading}
              >
                {isReactivationLoading ? (
                  <Spinner
                    color="dark"
                    style={{
                      height: "1.3em",
                      width: "1.3em",
                    }}
                  />
                ) : (
                  "ATIVAR RENOVAÇÃO AUTOMÁTICA"
                )}
              </Button>
            )}

            {hasActivePlan && (
              <>
                <section>
                  <S.BillingBox active>
                    <AccountSectionLine bottomLine>
                      <div>
                        <span className="text-base">Sua próxima fatura</span>
                        {formattedDate(renewalDate)}
                      </div>
                    </AccountSectionLine>

                    {/* cancel renewal */}
                    {!isInTrialPeriod && (
                      <AccountSectionLine bottomLine>
                        <S.StatusText color={"success"}>
                          <IoCheckmark />
                          Renovação automática
                        </S.StatusText>
                        <Button
                          variant={"transparent"}
                          size={"small"}
                          id={"btn-account-alterar_pagamento"}
                          name={"btn-account-alterar_pagamento"}
                          onClick={() =>
                            navigate("/cancelar-plano", {
                              state: {
                                origin: subscriptionDetails?.origin,
                              },
                            })
                          }
                        >
                          CANCELAR
                        </Button>
                      </AccountSectionLine>
                    )}

                    <AccountSectionLine bottomLine>
                      Em {gatewayData.installments}x de{" "}
                      {formattedPrice(gatewayData.installmentValue)}
                      {planName !== "Plano Mensal" && (
                        <Button
                          variant={"transparent"}
                          size={"small"}
                          id={"btn-account-alterar_pagamento"}
                          name={"btn-account-alterar_pagamento"}
                          onClick={() =>
                            navigate("/minha-conta/alterar-parcelamento", {
                              state: { installments: gatewayData.installments },
                              replace: true,
                            })
                          }
                        >
                          ALTERAR
                        </Button>
                      )}
                    </AccountSectionLine>
                    <AccountSectionLine>
                      {gatewayData.cardBrand}: **** {gatewayData.cardFourDigits}
                      <Button
                        variant={"transparent"}
                        size={"small"}
                        id={"btn-account-alterar_pagamento"}
                        name={"btn-account-alterar_pagamento"}
                        onClick={() =>
                          navigate("/minha-conta/alterar-pagamento", {
                            replace: true,
                            state: { installments: gatewayData.installments },
                          })
                        }
                      >
                        ALTERAR
                      </Button>
                    </AccountSectionLine>
                  </S.BillingBox>
                </section>

                {renderCancelButton()}

                <AccountSubscriptionDisclaimer />
              </>
            )}
          </>
        )}
      </S.Wrapper>

      {!!modalType && (
        <Modal.Root showModal={!!modalType} closeModal={() => setModalType("")}>
          <Modal.Body>
            <Modal.Title>
              {strings.subscriptionDetails.modal[modalType].title}
            </Modal.Title>
            <Modal.Paragraph>
              {strings.subscriptionDetails.modal[modalType].paragraph}
            </Modal.Paragraph>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={"black"}
              size={"full"}
              id={"btn-subscription_details-entendi"}
              name={"btn-subscription_details-entendi"}
              type="submit"
              onClick={() => setModalType("")}
            >
              {strings.subscriptionDetails.modal[modalType].buttonDefault}
            </Button>
            {modalType === "unrenewed" && (
              <Button
                variant={"outlined"}
                size={"full"}
                id={"btn-subscription_details-reativar_assinatura"}
                name={"btn-subscription_details-reativar_assinatura"}
                type="submit"
                onClick={() =>
                  sendReactivationRequest(subscriptionDetails.reference)
                }
                disabled={!!isReactivationLoading}
              >
                {isReactivationLoading ? (
                  <Spinner
                    color="dark"
                    style={{
                      height: "1.2em",
                      width: "1.2em",
                    }}
                  />
                ) : (
                  strings.subscriptionDetails.modal[modalType].buttonReactivate
                )}
              </Button>
            )}
            {modalType === "cancelExtension" && (
              <Button
                variant={"outlined"}
                size={"full"}
                id={"btn-subscription_details-cancelar_extensao"}
                name={"btn-subscription_details-cancelar_extensao"}
                type="submit"
                onClick={() => sendCancelExtension(reference)}
                disabled={!!isCancelLoading}
              >
                {isCancelLoading ? (
                  <Spinner
                    color="dark"
                    style={{
                      height: "1.2em",
                      width: "1.2em",
                    }}
                  />
                ) : (
                  strings.subscriptionDetails.modal[modalType]
                    .buttonCancelExtension
                )}
              </Button>
            )}
          </Modal.Footer>
        </Modal.Root>
      )}
    </Container>
  );
};

export default CreditCard;
