import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MaskedInput from "react-input-mask";
import Card from "react-credit-cards";

import { Spinner } from "reactstrap";
import { FiAlertTriangle } from "react-icons/fi";
import { useCheckoutContext } from "../../context/CheckoutContext";
import { useUserSubscriptionContext } from "../../context/userSubscriptionContext";
import Datalayer from "../../services/datalayer";
import { useDatalayer, useSentry, useActiveCampaignContact } from "../../hooks";
import {
  getVindiCustomerId,
  getVindiToken,
  subscribeOnVindi,
  handleVindiErrors,
} from "../../services/checkout";
import {
  convertStringToDigits,
  formatCPFOnlyNumbers,
  formattedPrice,
  getCookie,
  getURLParamValue,
  priceStringToNumber,
} from "../../util/Util";
import { secureStorage } from "../../util/SecureStorage";
import { getGAClientId, getGASessionId } from "../../util/analytics";
import {
  getCardIssuerCode,
  getCreditCardMask,
  formatCampaignOrderSummary,
} from "../../util/checkout";
import { CheckoutDisclaimer, CheckoutPageTitle, CTAButton } from "..";

import * as S from "./styles";

const CheckoutVindi = () => {
  const { plan, data, handleInput, handleCreditCardIssuer, changeStep } =
    useCheckoutContext();
  const { verifyStatusAndApplyAccessRules } = useUserSubscriptionContext();
  const { handleBlur } = useDatalayer();
  const { logException } = useSentry();
  const navigate = useNavigate();

  const planFromParams = getURLParamValue("plan");

  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const { mutate: sendToActiveCampaign } = useActiveCampaignContact();

  useEffect(() => {
    changeStep("pagamento");
  }, []);

  const price = priceStringToNumber(plan.price);
  const hasCoupon = !!plan.discount;

  const { formattedFinalPrice, dataLayerPrice } = formatCampaignOrderSummary(
    price,
    data.installments
  );

  const hasEmptyInput = useMemo(() => {
    if (data) {
      const inputs = [
        data.cardNumber,
        data.cardName,
        data.cardExpiration,
        data.cvv,
        data.cpf,
        data.installments,
      ];
      return inputs.some((value) => !value);
    }
    return false;
  }, [data]);

  const submitForm = async (e) => {
    e.preventDefault();

    Datalayer.onPayment(
      plan.name,
      plan.id,
      dataLayerPrice,
      "credito",
      plan.coupon
    );

    if (loading) return;
    setErrors(false);
    setLoading(true);

    const inputs = [
      {
        value: data.cardNumber,
        name: "Número do cartão",
        pattern: /^\d{14,16}$/,
      },
      {
        value: data.cardName,
        name: "Nome impresso no cartão",
        pattern: /^[A-Za-z\u00C0-\u00FF ]{3,}$/,
      },
      {
        value: data.cardExpiration,
        name: "Data de vencimento",
        pattern: /^\d{4}$/,
      },
      {
        value: data.cvv,
        name: "Código de segurança",
        pattern: /^\d{3,4}$/,
      },
      {
        value: data.cpf,
        name: "CPF do titular",
        pattern: /^\d{11}$/,
      },
      {
        value: data.installments,
        name: "Parcelas",
        pattern: /^\d{1,2}$/,
      },
    ];

    const hasEmptyField = inputs.find(
      (input) => input.value === undefined || !input.value
    );

    if (hasEmptyField) {
      setMessage(
        `Para continuar, por favor, preencha corretamente o campo "${hasEmptyField.name}".`
      );
      setLoading(false);
      setErrors(true);
      return;
    }

    const regexReplace = / |_|\s|\/|\.|-/g;

    const hasInvalidInput = inputs.find((input) => {
      return !input.pattern.test(input.value.replace(regexReplace, ""));
    });

    if (hasInvalidInput) {
      setMessage(
        `Para continuar, por favor, preencha corretamente o campo "${hasInvalidInput.name}".`
      );
      setLoading(false);
      setErrors(true);
      return;
    }

    const cpfOnlyNumbers = formatCPFOnlyNumbers(data.cpf);

    const vindiCustomerId = await getVindiCustomerId({
      email: data.email,
      identity: cpfOnlyNumbers,
    });

    if (!vindiCustomerId.success) {
      Datalayer.onCallback("checkout", "erro:falha-obtencao-vindi-customer-id");
      setMessage(handleVindiErrors(vindiCustomerId.response));
      setLoading(false);
      setErrors(true);
      window.scrollTo(0, 0);
      return;
    }

    const generateTokenBody = {
      customer_id: vindiCustomerId.response,
      holder_name: data.cardName
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase(),
      registry_code: data.cpf.replace(/\D/g, ""),
      card_expiration: data.cardExpiration,
      allow_as_fallback: true,
      card_number: data.cardNumber.replace(/ |_/g, ""),
      card_cvv: data.cvv.replace(/_/g, ""),
      payment_method_code: "credit_card",
      payment_company_code: getCardIssuerCode(data.issuer),
    };

    const gatewayToken = await getVindiToken(generateTokenBody);

    if (!gatewayToken.success) {
      const exceptionParams = {
        error: new Error("gatewayToken error"),
        transactionName: "Checkout",
        origin: "vindiToken",
        tags: [{ label: "errorMessage", value: gatewayToken.message }],
        extras: [{ label: "data", value: gatewayToken.response }],
      };
      logException(exceptionParams);

      Datalayer.onCallback("checkout", "erro:falha-token-payment-profile");
      setMessage(handleVindiErrors(gatewayToken.response[0].parameter));
      setLoading(false);
      setErrors(true);
      window.scrollTo(0, 0);
      return;
    }

    const { gaSessionId, gaSessionNumber } = getGASessionId();

    const subscriptionData = {
      vindiCustomerId: vindiCustomerId.response,
      identity: convertStringToDigits(data.cpf),
      planId: plan.id,
      gatewayToken: gatewayToken.response,
      installments: data.installments,
      gaClientId: getGAClientId(),
      gaOriginMTZ: decodeURIComponent(getCookie("origemMTZ")),
      gaSessionId: gaSessionId,
      gaSessionNumber: gaSessionNumber,
      couponId: plan.couponId,
    };

    const vindiSubscription = await subscribeOnVindi(subscriptionData);

    if (!vindiSubscription.success) {
      setMessage(vindiSubscription.message);

      if (vindiSubscription.redirectToHome) {
        Datalayer.onCallback("checkout", "erro:usuario-ja-registrado");
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 3000);
      } else {
        Datalayer.onCallback("checkout", "erro");
      }

      setLoading(false);
      setErrors(true);
      window.scrollTo(0, 0);
      return;
    }

    if (vindiSubscription.success) {
      secureStorage.setItem(
        `submitted-plan-${data.email}`,
        JSON.stringify({ ...plan, revenue: dataLayerPrice })
      );
      secureStorage.setItem("reference", vindiSubscription.response);
      Datalayer.onCallback("checkout", "sucesso", vindiSubscription.response);

      sendToActiveCampaign({
        email: data.email,
        customFields: [
          {
            field: "96",
            value: "28/" + data.cardExpiration,
          },
        ],
      });

      verifyStatusAndApplyAccessRules();
    }
  };

  return (
    <S.CheckoutContainer>
      <div>
        <CheckoutPageTitle
          title={"Pagamento"}
          subtitle={"Informe os dados do seu cartão de crédito "}
        />

        {errors ? (
          <S.Errors>
            {" "}
            <FiAlertTriangle /> <span>{message}</span>{" "}
          </S.Errors>
        ) : null}

        <S.CardContainer>
          <Card
            number={data.cardNumber}
            name={data.cardName || "NOME E SOBRENOME"}
            expiry={data.cardExpiration}
            cvc={data.cvv}
            callback={(e) => handleCreditCardIssuer(e.issuer)}
          />
        </S.CardContainer>

        <S.Form onSubmit={submitForm}>
          <div className="one-column">
            <label htmlFor={"cardNumber"}>Número do cartão</label>
            <MaskedInput
              id="cardNumber"
              key="cardNumber"
              name="cardNumber"
              type="cardNumber"
              value={data.cardNumber}
              mask={getCreditCardMask(data.issuer)}
              onChange={(e) => handleInput(e, "cardNumber")}
              onBlur={(e) =>
                handleBlur(e, "checkout", "pagamento", "cardNumber")
              }
              autoComplete="off"
            />
          </div>

          <div className="one-column">
            <label htmlFor={"cardName"}>Nome impresso no cartão</label>
            <MaskedInput
              id="cardName"
              key="cardName"
              name="cardName"
              type="cardName"
              value={data.cardName}
              onChange={(e) => handleInput(e, "cardName")}
              onBlur={(e) => handleBlur(e, "checkout", "pagamento", "cardName")}
              autoComplete="off"
            />
          </div>

          <div className="two-column">
            <div className="one-half">
              <label htmlFor={"cardExpiration"}>Data de vencimento</label>
              <MaskedInput
                id="cardExpiration"
                key="cardExpiration"
                name="cardExpiration"
                type="cardExpiration"
                placeholder="MM/AA"
                value={data.cardExpiration}
                mask="99/99"
                onChange={(e) => handleInput(e, "cardExpiration")}
                onBlur={(e) =>
                  handleBlur(e, "checkout", "pagamento", "cardExpiration")
                }
                autoComplete="off"
              />
            </div>

            <div className="one-half">
              <label htmlFor={"cvv"}>Código de segurança</label>
              <MaskedInput
                id="cvv"
                key="cvv"
                name="cvc"
                type="cvv"
                placeholder="CVV"
                value={data.cvv}
                mask="9999"
                maskChar={null}
                onChange={(e) => handleInput(e, "cvv")}
                onBlur={(e) => handleBlur(e, "checkout", "pagamento", "cvv")}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="one-column">
            <label htmlFor={"cpf"}>CPF do titular</label>
            <MaskedInput
              id="cpf"
              key="cpf"
              name="cpf"
              type="cpf"
              value={data.cpf}
              mask="999.999.999-99"
              onChange={(e) => handleInput(e, "cpf")}
              onBlur={(e) => handleBlur(e, "checkout", "pagamento", "cpf")}
              autoComplete="off"
            />
          </div>

          <div className="one-column">
            <label htmlFor={"installments"}>Parcelas</label>
            <S.Select
              id="installments"
              key="installments"
              name="installments"
              type="select"
              value={data.installments}
              onChange={(e) => handleInput(e, "installments")}
              onBlur={(e) =>
                handleBlur(e, "checkout", "pagamento", "installments")
              }
            >
              <option disabled value="">
                Selecione o parcelamento
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </S.Select>
          </div>

          <S.OrderSummary>
            <h5>Resumo do pedido:</h5>
            {plan.id === planFromParams ? (
              <>
                <S.OrderInfoContainer>
                  <span className="text">{plan.name}: </span>
                  <span>R$ {plan.originalPrice}</span>
                </S.OrderInfoContainer>

                {hasCoupon && (
                  <>
                    <S.OrderInfoContainer>
                      <span className="text">Cupom aplicado: </span>
                      <div>
                        <span className="price">
                          - {formattedPrice(priceStringToNumber(plan.discount))}
                        </span>
                        <span className="discount">↓{plan.discountLabel}</span>
                      </div>
                    </S.OrderInfoContainer>{" "}
                  </>
                )}

                <hr />
                <S.OrderInfoContainer>
                  <span className="text final-price">Total a pagar: </span>
                  <span>
                    <strong>{formattedFinalPrice}</strong>
                  </span>
                </S.OrderInfoContainer>
              </>
            ) : (
              <p>
                Houve um problema na seleção do seu plano. Por favor,{" "}
                <Link to={"/checkout/entenda-a-assinatura"}>clique aqui</Link> e
                selecione o plano novamente
              </p>
            )}
          </S.OrderSummary>

          <CTAButton
            disabled={
              plan.id !== planFromParams || hasEmptyInput ? true : false
            }
            id={"btn-subscribe-iniciar_assinatura"}
            name={"btn-subscribe-iniciar_assinatura"}
            type="submit"
            onClick={() => {
              if (!loading) {
                Datalayer.onClick(
                  "checkout",
                  "botao:pagamento",
                  "iniciar-assinatura"
                );
              }
            }}
            text={loading ? <Spinner color="dark" /> : "Iniciar Assinatura"}
          />
        </S.Form>
      </div>

      <CheckoutDisclaimer />
    </S.CheckoutContainer>
  );
};

export default CheckoutVindi;
