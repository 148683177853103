import { getCookie } from "./Util";

export const getGAClientId = () => {
  const gaFullCode = getCookie("_ga");

  if (!gaFullCode) return "";

  const splitted = gaFullCode.split(".");

  const clientIdPart1 = splitted[splitted.length - 2];
  const clientIdPart2 = splitted[splitted.length - 1];

  if (!clientIdPart1 || !clientIdPart2) return "";

  return clientIdPart1.concat(".", clientIdPart2);
};

export const getGASessionId = () => {
  const gaFullCode = getCookie("_ga_BTTNE5XRRY");
  //example: GS1.1.1234567890.1.0.00000000000.00.0.0

  if (!gaFullCode) return { gaSessionId: "", gaSessionNumber: "" };

  const splitted = gaFullCode.split(".");

  const gaSessionId = splitted[2] ?? ""; // third position - example: 1234567890
  const gaSessionNumber = splitted[3] ?? ""; // fourth position - example: 1

  return { gaSessionId, gaSessionNumber };
};
