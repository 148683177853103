import { useQuery } from "react-query";
import { requests } from "../services/requests";

const queryKeys = {
  certificate: "certificate",
};

export const useCertificate = (userCourseId, options = {}) => {
  return useQuery(
    [queryKeys.certificate, userCourseId],
    ({ queryKey }) => {
      const [, params] = queryKey;
      return requests.getAndCreateCertificate(params);
    },
    {
      retry: 3,
      staleTime: 1000 * 6 * 60,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
