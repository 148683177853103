import styled, { css } from "styled-components";

const CollapsedState = {
  true: () => css`
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  `,
  false: () => css`
    top: 10px;
    right: 10px;
  `,
};

export const Wrapper = styled.article`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #2ee48f;
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: center;
  position: relative;

  a {
    display: flex;
    justify-content: center;
  }

  img {
    max-width: 150%;
  }

  p {
    color: #000;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0;
    padding: 13px 0;
  }
`;

export const CloseButton = styled.button`
  ${({ isCollapsed }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    position: absolute;
    border: none;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #cccccc;
    ${CollapsedState[isCollapsed]}

    .toggle-banner {
      transition: 0.2s all ease-in;
      transform: scale(1.3);
    }

    :hover .toggle-banner {
      transform: scale(1.5);
    }
  `}
`;
