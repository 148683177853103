import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { store } from "react-notifications-component";
import { notifications } from "../../util/settings";
import { useGiftSubscriptionStatus } from "../../hooks";
import Datalayer from "../../services/datalayer";

import { CheckoutFooter, CheckoutPageTitle, CTAButton } from "../../components";

import * as S from "./styles";

const GiftCheckoutProcessing = () => {
  const [fakeRefresh, setFakeRefresh] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { giftCode } = Object.fromEntries([...searchParams]);

  const { data, isLoading, isFetching, error, refetch } =
    useGiftSubscriptionStatus(giftCode, {
      retry: false,
      refetchInterval: 2500,
    });

  function setNotification(message, type = "danger") {
    store.addNotification({
      ...notifications,
      message: message,
      type: type,
    });
  }

  useEffect(() => {
    if (isLoading || isFetching) return;

    if (error?.response?.data?.status === 409) {
      setNotification(error.response.data.message);
      return navigate("/entrar", { replace: true });
    }

    if (error?.response?.data?.status === 400) {
      setNotification(error.response.data.message);
      return navigate("/presente", { replace: true });
    }

    switch (data?.billingStatus) {
      case "failed":
        return navigate("/presente/falha-na-compra", { replace: true });
      case "paid":
        return navigate(`/presente/vale-presente?giftCode=${giftCode}`, {
          replace: true,
          state: { ...data },
        });
      default:
        break;
    }
  }, [data, isLoading, isFetching]);

  if (fakeRefresh)
    return (
      <S.Wrapper className="wrapper">
        <S.EmptyHeader />
      </S.Wrapper>
    );

  return (
    <S.Wrapper className="wrapper">
      <S.EmptyHeader />

      <S.CheckoutContainer>
        <S.ProcessingInfo>
          <S.LoadingAnimation />

          <CheckoutPageTitle
            title={"Agora falta pouco!"}
            subtitle={
              "Agora falta pouco! Seu pagamento já está sendo processado. Assim que for confirmado, você receberá um e-mail com o vale presente e as instruções de acesso. Se não encontrar o e-mail, confira na sua caixa de spam e/ou promoções."
            }
          />

          <p className="lighter">
            Este processo pode demorar até 24h. Durante este período, por favor
            não tente realizar uma nova compra, nem cancelar o seu pagamento.
          </p>
        </S.ProcessingInfo>

        <CTAButton
          id={"btn-gift_processing-recarregar"}
          name={"btn-gift_processing-recarregar"}
          onClick={() => {
            Datalayer.onClick(
              "checkout",
              "botao:presente:processando",
              "recarregar"
            );
            setFakeRefresh(true);
            setTimeout(() => {
              refetch();
              setFakeRefresh(false);
            }, 250);
          }}
          text="RECARREGAR"
        />
      </S.CheckoutContainer>
      <CheckoutFooter />
    </S.Wrapper>
  );
};

export default GiftCheckoutProcessing;
