import React from "react";
import starIcon from "../../assets/icons/star.svg";
import * as S from "./styles";

const Rating = ({ value, totalVotes }) => {
  if (totalVotes == null || totalVotes === undefined) return <></>;

  return (
    <S.Rating>
      <img src={starIcon} alt="ícone estrela" />{" "}
      {value > 0 && (
        <span>
          <span className="rating-value">
            {String(value).replace(".", ",")}
          </span>
          / 5
        </span>
      )}
      <div className="rating-votes">
        {totalVotes.toLocaleString("pt-BR")}{" "}
        {totalVotes === 1 ? "avaliação" : "avaliações"}
      </div>
    </S.Rating>
  );
};

export default Rating;
