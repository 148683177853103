import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import * as S from "./styles";

const PasswordInput = ({
  label,
  inputName = "password",
  registerFunction,
  errors,
  variant = "dark",
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  if (!label || typeof inputName !== "string" || !registerFunction) return null;

  return (
    <div className="password-input-container">
      <S.InputContainer>
        <S.InputTextFieldContainer
          invalid={!!errors[inputName]}
          variant={variant}
        >
          <label htmlFor={inputName}>{label}</label>
          <input
            {...props}
            {...registerFunction(inputName, { onBlur: props.onBlur })}
            aria-invalid={errors?.[inputName] ? "true" : "false"}
            type={showPassword ? "text" : "password"}
            autoComplete="on"
          />
        </S.InputTextFieldContainer>
        <S.InputIconContainer data-testid="password-toggle">
          <S.IconButtonContainer
            onClick={(e) => {
              e.preventDefault();
              setShowPassword(!showPassword);
            }}
            tabIndex="-1"
            role="button"
          >
            {showPassword ? <FiEyeOff /> : <FiEye />}
          </S.IconButtonContainer>
        </S.InputIconContainer>
      </S.InputContainer>

      {errors?.[inputName] && (
        <S.ErrorMessage variant={variant}>
          {errors[inputName].message}
        </S.ErrorMessage>
      )}
    </div>
  );
};

export default PasswordInput;
