import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const CoverContainer = styled.div`
  ${({ theme, coverMobile, coverDesktop }) => css`
    max-width: calc(100% + 1.4rem); // container margin;
    margin: 0 -0.7rem; // offset the container margin
    padding: 0 ${theme.spacings.smaller};
    height: 272px;
    position: relative;
    background-image: url(${coverMobile});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (${device.tablet}) {
      height: 358px;
      background-image: url(${coverDesktop});
    }
  `}
`;

export const Gradient = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 -0.7rem; // offset the container margin
    height: 117.5px;
    background: linear-gradient(180deg, rgba(32, 30, 32, 0) 0%, #201e20 100%);
  `}
`;
