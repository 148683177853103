import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium} auto;
    padding: ${theme.spacings.small};
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: ${theme.gap.small};
    grid-row-gap: 0px;
    height: 45vw;
    width: 92vw;
    min-width: 280px;
    min-height: 130px;
    max-width: 384px;
    max-height: 186px;
    border-radius: 10px;
    background-color: #323132;
  `}
`;

export const CertificateDiv1 = styled.div`
  ${({ theme }) => css`
    grid-area: 1 / 1 / 5 / 4;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${theme.font.size.smaller};

    img {
      max-width: 100%;
      height: auto;
      border-radius: 5px;
    }
  `}
`;
export const CertificateDiv2 = styled.div`
  ${({ theme }) => css`
    grid-area: 1 / 4 / 2 / 10;
  `}
`;
export const CertificateDiv3 = styled.div`
  ${({ theme }) => css`
    grid-area: 2 / 4 / 3 / 10;
    display: flex;
    align-items: flex-end;
  `}
`;
export const CertificateDiv4 = styled.div`
  ${({ theme }) => css`
    grid-area: 3 / 4 / 4 / 10;
    display: flex;
    justify-content: space-around;
    align-items: center;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family.maisonNeue};
    font-size: ${theme.font.size.smallest};
    font-weight: ${theme.font.weight.lightest};
    text-align: left;

    @media (min-width: ${theme.breakPoint.smallPhone}) {
      font-size: ${theme.font.size.default};
    }
  `}
`;
export const Professor = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family.maisonNeue};
    font-size: ${theme.font.size.smallest};
    font-weight: ${theme.font.weight.bold};
    text-align: left;
    max-height: 38%;
    overflow: hidden;

    @media (min-width: ${theme.breakPoint.regularPhone}) {
      font-size: ${theme.font.size.small};
    }
    @media (min-width: ${theme.breakPoint.tablet}) {
      font-size: ${theme.font.size.small};
      max-height: 40px;
    }
  `}
`;
export const FinishDate = styled.div`
  ${({ theme }) => css`
    display: none;

    @media (min-width: ${theme.breakPoint.smallPhone}) {
      display: block;
      margin-top: -10px;
      font-family: ${theme.font.family.maisonNeue};
      font-size: ${theme.font.size.smallest};
      font-weight: ${theme.font.weight.light};
      text-align: left;
    }
  `}
`;
