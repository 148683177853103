import React from "react";
import { IoCheckmark } from "react-icons/io5";

import { useUser } from "../../../hooks";
import { formattedDate, planNameStandardization } from "../../../util/Util";

import { Container, LoadingSpinner } from "../../../components";
import { AccountSectionHeader, AccountSectionLine } from "../../../containers";

import * as S from "../styles";

const Cash = () => {
  const {
    data: { subscriptionDetails },
    isLoading: isSubscriptionLoading,
  } = useUser();

  if (!subscriptionDetails || isSubscriptionLoading) {
    return <LoadingSpinner color="light" />;
  }

  const { endAt: remainingAccess, planId: currentPlanId } = subscriptionDetails;

  return (
    <Container type={"minimal"} pageName="Detalhes da assinatura">
      <S.Wrapper>
        <section>
          <AccountSectionHeader>{"Meu Plano"}</AccountSectionHeader>

          <AccountSectionLine bottomLine>
            <S.StatusText color={"success"}>
              <IoCheckmark />
              Assinatura ativa
            </S.StatusText>
          </AccountSectionLine>

          {!!currentPlanId && (
            <AccountSectionLine bottomLine>
              <strong>{planNameStandardization(currentPlanId)}</strong>
            </AccountSectionLine>
          )}

          {!!remainingAccess && (
            <AccountSectionLine>
              <span>Seu acesso expira em {formattedDate(remainingAccess)}</span>
            </AccountSectionLine>
          )}
        </section>
      </S.Wrapper>
    </Container>
  );
};

export default Cash;
