import React, { useEffect } from "react";

import { AppRoutes } from "./appRoutes";
import { AuthRoutes } from "./authRoutes";
import { useAuthContext } from "../context/AuthContext";
import { CheckoutProvider } from "../context/CheckoutContext";
import { UserSubscriptionProvider } from "../context/userSubscriptionContext";
import { LoadingSpinner } from "../components";
import { useLocation } from "react-router-dom";

const Routes = () => {
  const { isLoggedIn, loading, setPathToRedirect } = useAuthContext();

  const location = useLocation();
  const { pathname, search } = location;

  const hasExcludedPaths = [
    "/entrar",
    "/checkout",
    "/cadastro",
    "/presente",
    "/redirect",
  ].some((path) => pathname.includes(path));

  const queryString = !!pathname && !hasExcludedPaths ? pathname + search : "";

  useEffect(() => {
    if (!loading && !isLoggedIn && queryString) setPathToRedirect(queryString);
  }, [loading]);

  if (loading) {
    return <LoadingSpinner color="light" />;
  }

  return (
    <CheckoutProvider>
      {isLoggedIn ? (
        <UserSubscriptionProvider>
          <AppRoutes />
        </UserSubscriptionProvider>
      ) : (
        <AuthRoutes />
      )}
    </CheckoutProvider>
  );
};

export default Routes;
