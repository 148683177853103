import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSubscriptionStatus } from "./useSubscriptionQuery";
import { useAuthContext } from "../context/AuthContext";
import { useUserSubscriptionContext } from "../context/userSubscriptionContext";

export const useCheckoutSubscriptionStatus = () => {
  const navigate = useNavigate();

  const { data, isLoading, isFetching } = useSubscriptionStatus();
  const { setPathToRedirect } = useAuthContext();
  const { verifyStatusAndApplyAccessRules } = useUserSubscriptionContext();

  useEffect(() => {
    if (isLoading || isFetching) return;

    switch (data?.subscriptionStatus) {
      case "ACTIVE":
        setPathToRedirect("/onboarding");
        verifyStatusAndApplyAccessRules();
        break;
      case "PROCESSING":
        return navigate("/checkout/processando", { replace: true });
      default:
        break;
    }
  }, [data, isLoading, isFetching]);

  return { loadingStatus: isLoading | isFetching };
};
