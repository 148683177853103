import { useState } from "react";
import { size } from "../styles/mediaQueries";

const useWindowWidthCalculate = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= size.laptop);

  window.addEventListener("resize", function () {
    calculateWindowWidth();
  });

  const calculateWindowWidth = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth >= size.laptop && isDesktop === false) {
      return setIsDesktop(true);
    }
    if (innerWidth < size.laptop && isDesktop === true) {
      return setIsDesktop(false);
    }
  };

  return { isDesktop };
};

export default useWindowWidthCalculate;
