import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import { useMyList } from "../../hooks";
import * as S from "./styles";

const MyListButton = ({ courseId }) => {
  const {
    addFavorite,
    removeFavorite,
    isFetchingFavorites,
    verifyFavoriteStatus,
    isLoadingAddOrRemove,
  } = useMyList();

  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (!isFetchingFavorites) setIsFavorite(verifyFavoriteStatus(courseId));
  }, [isFetchingFavorites]);

  const handleFavorite = () => {
    if (isFavorite) return removeFavorite(courseId);

    return addFavorite(courseId);
  };

  const renderFavoriteButton = () => {
    if (isFetchingFavorites || isLoadingAddOrRemove) return <Spinner />;

    if (isFavorite)
      return <i className="fas fa-check-circle checked animate-pop" />;

    return <i className="fal fa-plus-circle animate-pop" />;
  };

  return (
    <S.Wrapper
      onClick={() => handleFavorite()}
      id={"btn-my_list_button-minha_lista"}
      name={"btn-my_list_button-minha_lista"}
    >
      {renderFavoriteButton()}
      minha lista
    </S.Wrapper>
  );
};

export default MyListButton;
