import styled, { css } from "styled-components";

export const Wrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: ${(props) => props.theme.lightTheme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 86px;
`;

export const CheckoutContainer = styled.div`
  max-width: 414px;
  margin: 0 auto;
  padding: 16px;
`;

export const PlanBox = styled.div`
  background-color: ${(props) => props.theme.lightTheme.colors.background};
  cursor: pointer;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  margin-top: 5%;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: ${(props) => `1px solid ${props.theme.lightTheme.colors.gray}`};
  * {
    cursor: pointer;
  }
  h4 {
    width: calc(100% + 2px);
    margin: -1px 0 0 -1px;
    padding: 7px 0;
    font-weight: normal;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    line-height: 18px;
    color: ${(props) => props.theme.lightTheme.colors.green};
    background: ${(props) => props.theme.lightTheme.colors.darkGray};
    border-radius: 5px 5px 0px 0px;
    border: ${(props) => `1px solid ${props.theme.lightTheme.colors.darkGray}`};
  }
  .installment-price {
    font-size: 16px;
    margin-bottom: 0;
  }
  span {
    font-size: 28px;
  }
  .full-price {
    font-size: 16px;
    color: ${(props) => props.theme.lightTheme.colors.gray};
  }
`;

export const PlanInfo = styled.div`
  min-height: 178px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 0;
  margin-top: 0px;
  border-radius: 0px 0px 5px 5px;
  color: ${(props) => props.theme.lightTheme.colors.text};
  text-align: center;
`;

export const Errors = styled.div`
  padding: 15px;
  border-radius: 5px;
  margin-top: -25px;
  border: 1.5px solid #ff7651;
  margin-bottom: 0.7rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  span {
    color: ${(props) => props.theme.lightTheme.colors.text};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    display: inline;
    align-items: center;
  }
  svg {
    color: #ff7651;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  a {
    color: ${(props) => props.theme.lightTheme.colors.text};
    text-decoration: underline;
  }
`;

export const Coupon = styled.div`
  margin: 20px 0;

  ${(props) =>
    props.open &&
    css`
      .coupon-title {
        margin-bottom: 15px;
      }
      .coupon-title img {
        transform: translateY(-50%) rotate(180deg);
      }
      .coupon-code {
        max-height: 1000px;
        opacity: 1;
      }
    `}
`;

export const TitleContainer = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.lightTheme.colors.text};
  text-align: center;
  transition: all 0.4s ease;

  img {
    transition: all 0.4s ease-out;
    margin-left: 10px;
  }
`;

export const InputContainer = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.lightTheme.colors.text};

  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease;

  input {
    background-color: ${(props) =>
      props.theme.lightTheme.colors.inputBackground};
    color: ${(props) => props.theme.lightTheme.colors.text};
    border-radius: 5px;
    border: none;
    padding: 1.5rem 0.5rem 0.3rem;
    width: 100%;
    height: 50px;
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    box-sizing: border-box;

    :disabled {
      opacity: 0.6;
    }

    :focus-visible {
      outline: none;
    }
  }

  label {
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 130%;
    color: ${(props) => props.theme.lightTheme.colors.text};
    display: flex;
    align-items: center;
    position: relative;
    top: 14px;
    left: 8px;
    height: 0px;
    width: calc(100% - 8px);
  }
`;
