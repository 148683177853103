import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

const BannerFinishSignup = () => {
  const defaultShow = JSON.parse(localStorage.getItem("banner-finish-signup"));
  const [show, setShow] = useState(defaultShow === null ? true : defaultShow);
  let navigate = useNavigate();

  function handleRoute(route) {
    navigate(route);
  }

  function handleDismiss() {
    setShow(false);
    window.localStorage.setItem("banner-finish-signup", false);
  }

  if (!show) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.OverlayContainer>
        Conclua seu cadastro para garantir acesso a todas as funcionalidades da
        plataforma.
      </S.OverlayContainer>
      <S.BannerActions>
        <S.DismissButton
          onClick={() => handleDismiss()}
          id={"btn-banner_finish_signup-dismiss"}
          name={"btn-banner_finish_signup-dismiss"}
        >
          {"dispensar"}
        </S.DismissButton>
        <S.CompleteButton
          onClick={() => handleRoute("/minha-conta/dados-pessoais")}
          id={"btn-banner_finishi_signup-complete"}
          name={"btn-banner_finishi_signup-complete"}
        >
          {"completar cadastro"}
        </S.CompleteButton>
      </S.BannerActions>
    </S.Wrapper>
  );
};

export default BannerFinishSignup;
