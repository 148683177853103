import React from "react";
import * as S from "./styles";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import copyIcon from "../../assets/icons/link-icon.svg";
import facebookIcon from "../../assets/icons/facebook-icon.svg";
import closeIcon from "../../assets/icons/close-icon-certificate.svg";
import whatsIcon from "../../assets/icons/whats-icon-4.png";
import emailIcon from "../../assets/icons/email-icon-2.png";
import linkedinIcon from "../../assets/icons/linkedin-icon.svg";
import { store } from "react-notifications-component";
import { notifications } from "../../util/settings";

const defaultOptions = {
  whatsappEnabled: true,
  facebookEnabled: true,
  linkedinEnabled: true,
  emailEnabled: true,
};
function ShareModal({
  onClose,
  whatsappText,
  facebookText,
  linkedinText,
  emailSubject,
  emailText,
  url,
  copyText,
  options = defaultOptions,
}) {
  function handleCopyLink() {
    navigator.clipboard.writeText(copyText ?? url);
    store.addNotification({
      ...notifications,
      message: "Link copiado com sucesso!",
      type: "success",
    });
    onClose();
  }

  return (
    <S.Container>
      <S.Top>
        <S.Text>Compartilhar com:</S.Text>
        <S.CloseButton id={"btn-close"} name={"btn-close"} onClick={onClose}>
          <img src={closeIcon} alt="fechar" />
        </S.CloseButton>
      </S.Top>
      <S.ShareContent>
        {options.whatsappEnabled && (
          <S.ShareOptions>
            <WhatsappShareButton
              separator={"\n\n"}
              url={url}
              title={whatsappText}
              id={"btn-whatsapp_share"}
              name={"btn-whatsapp_share"}
            >
              <img src={whatsIcon} alt="compartilhar no whatsapp" />
            </WhatsappShareButton>
          </S.ShareOptions>
        )}
        {options.facebookEnabled && (
          <S.ShareOptions>
            <FacebookShareButton
              url={url}
              quote={facebookText}
              id={"btn-facebook_share"}
              name={"btn-facebook_share"}
            >
              <img src={facebookIcon} alt="compartilhar no facebook" />
            </FacebookShareButton>
          </S.ShareOptions>
        )}
        {options.linkedinEnabled && (
          <S.ShareOptions>
            <LinkedinShareButton
              title={linkedinText}
              id="btn-linkedin_share"
              name="btn-linkedin_share"
              url={url}
            >
              <img src={linkedinIcon} alt="compartilhar no linkedin" />
            </LinkedinShareButton>
          </S.ShareOptions>
        )}
        {options.emailEnabled && (
          <S.ShareOptions>
            <EmailShareButton
              openwindow={"true"}
              subject={emailSubject}
              url={url}
              separator={"\n\n"}
              body={emailText}
              id={"btn-email_share"}
              name={"btn-email_share"}
            >
              <img src={emailIcon} alt="compartilhar por email" />
            </EmailShareButton>
          </S.ShareOptions>
        )}
        <S.ShareOptions
          onClick={handleCopyLink}
          id={"btn-copy_link"}
          name={"btn-copy_link"}
        >
          <img src={copyIcon} alt="copiar link" />
        </S.ShareOptions>
      </S.ShareContent>
    </S.Container>
  );
}

export default ShareModal;
