import React, { useEffect } from "react";
import {
  useLocation,
  useRoutes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useQueryClient } from "react-query";

import flags from "../config/remote_config_defaults.json";

import { SearchProvider } from "../context/SearchContext";
import { UserContentProvider } from "../context/userContentContext";
import { useUserSubscriptionContext } from "../context/userSubscriptionContext";
import { useAuthContext } from "../context/AuthContext";
import { useUser } from "../hooks";
import Datalayer from "../services/datalayer";

import Errors from "../pages/Errors";
import Index from "../pages/Index";
import WatchingCourses from "../pages/WatchingCourses";
import Search from "../pages/Search";
import CategoriesList from "../pages/CategoriesList";
import Certificates from "../pages/Certificates";
import MyList from "../pages/MyList";
import ProfessorProfile from "../pages/ProfessorProfile";
import ChangePassword from "../pages/ChangePassword";
import Category from "../pages/Category/Index";
import Authors from "../pages/Authors/Index";
import CancelPlan from "../pages/CancelPlan/index";
import RenewalCanceled from "../pages/CancelPlan/Web/RenewalCanceled";
import UpdatePaymentInstallments from "../pages/UpdatePaymentInstallments/index";
import UpdatePaymentMethod from "../pages/UpdatePaymentMethod/index";
import Account from "../pages/Account";
import PersonalData from "../pages/PersonalData";
import Subscription from "../pages/Subscription";
import CheckoutPlans from "../pages/CheckoutPlans";
import CheckoutPayment from "../pages/CheckoutPayment";
import CheckoutProcessing from "../pages/CheckoutProcessing";
import CheckoutFailed from "../pages/CheckoutFailed";
import CheckoutCanceled from "../pages/CheckoutCanceled";
import Popular from "../pages/Popular";
import CheckoutAccountCreated from "../pages/CheckoutAccountCreated";
import CheckoutPlansPreview from "../pages/CheckoutPlansPreview";
import CheckoutVivo from "../pages/CheckoutVivo";
import Course from "../pages/Course";
import Retrospective from "../pages/Retrospective";
import Onboarding from "../pages/Onboarding";
import OnboardingCategoriesPreference from "../pages/OnboardingCategoriesPreference";
import OnboardingOriginChannel from "../pages/OnboardingOriginChannel";
import OnboardingStudyTime from "../pages/OnboardingStudyTime";
import Recommendations from "../pages/Recommendations";
import SubscriptionExtension from "../pages/SubscriptionExtension";
import SubscriptionExtensionDetails from "../pages/SubscriptionExtension/Details";
import { giftRoutes } from "./giftRoutes";
import Redirect from "../pages/Redirect";
import LoginIntegration from "../pages/LoginIntegration";

export function AppRoutes() {
  const appRoutes = useRoutes([
    { path: "/", element: <Index /> },
    Boolean(flags.retrospective_enabled) && {
      path: "/retrospectiva",
      element: <Retrospective />,
    },
    { path: "/menu-categorias", element: <CategoriesList /> },
    { path: "/pesquisa", element: <Search /> },
    { path: "/minha-lista", element: <MyList /> },
    { path: "/meus-cursos", element: <WatchingCourses /> },
    { path: "/populares", element: <Popular /> },
    { path: "/escolhas-para-voce", element: <Recommendations /> },
    { path: "/professores", element: <Authors /> },
    { path: "/professor/:id", element: <ProfessorProfile /> },
    { path: "/categoria/:slug", element: <Category /> },
    { path: "/curso/:id/:slug", element: <Course /> },
    {
      path: "/cancelar-plano",
      children: [
        { index: true, element: <CancelPlan /> },
        { path: "renovacao-cancelada", element: <RenewalCanceled /> },
      ],
    },
    {
      path: "/minha-conta",
      children: [
        { index: true, element: <Account /> },
        { path: "certificados", element: <Certificates /> },
        { path: "assinatura", element: <Subscription /> },
        {
          path: "estender-assinatura",
          children: [
            { index: true, element: <SubscriptionExtension /> },
            { path: "entenda", element: <SubscriptionExtensionDetails /> },
          ],
        },
        { path: "dados-pessoais", element: <PersonalData /> },
        { path: "alterar-senha", element: <ChangePassword /> },
        { path: "alterar-pagamento", element: <UpdatePaymentMethod /> },
        {
          path: "alterar-parcelamento",
          element: <UpdatePaymentInstallments />,
        },
      ],
    },
    {
      path: "/onboarding",
      children: [
        { index: true, element: <Onboarding /> },
        {
          path: "preferencia-categorias",
          element: <OnboardingCategoriesPreference />,
        },
        {
          path: "tempo-disponivel",
          element: <OnboardingStudyTime />,
        },
        {
          path: "como-conheceu-a-casa-do-saber",
          element: <OnboardingOriginChannel />,
        },
      ],
    },
    { path: "/error", element: <Errors /> },
    { path: "/entrar", element: <Navigate replace to="/" /> },
    { path: "/cadastro", element: <Navigate replace to="/" /> },
    { path: "/redirect", element: <Redirect /> },
    { path: "/entrar/integracao", exact: true, element: <LoginIntegration /> },
    ...giftRoutes,
    { path: "*", element: <Navigate replace to="/error" /> },
  ]);

  const checkoutRoutes = useRoutes([
    {
      path: "/checkout",
      children: [
        { index: true, element: <CheckoutPlansPreview /> },
        { path: "vivo", element: <CheckoutVivo /> },
        { path: "entenda-a-assinatura", element: <CheckoutPlansPreview /> },
        { path: "planos", element: <CheckoutPlans /> },
        { path: "pagamento", element: <CheckoutPayment /> },
        { path: "processando", element: <CheckoutProcessing /> },
        { path: "falha", element: <CheckoutFailed /> },
        { path: "cancelamento", element: <CheckoutCanceled /> },
        { path: "conta-criada", element: <CheckoutAccountCreated /> },
      ],
    },
  ]);

  const {
    data: { userData },
  } = useUser();
  const userId = userData?.id;
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pathToRedirect, setPathToRedirect } = useAuthContext();
  const { hasActiveSubscription } = useUserSubscriptionContext();

  useEffect(() => {
    if (!!pathToRedirect) {
      navigate(pathToRedirect);
      setPathToRedirect("");
    }
    queryClient.resetQueries("user-details");
  }, [hasActiveSubscription, pathToRedirect]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (userId) {
      Datalayer.onPageLoad(userId);
    }
  }, [location, userId]);

  if (!hasActiveSubscription) return <>{checkoutRoutes}</>;

  return (
    <UserContentProvider>
      <SearchProvider>{appRoutes}</SearchProvider>
    </UserContentProvider>
  );
}
