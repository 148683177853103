import formatDuration from "format-duration";

export const formatCourseTime = (totalminutes) => {
  let hours = Math.floor(totalminutes / 60);

  let minutes = totalminutes % 60;

  if (minutes === 60) {
    hours = +1;
  }
  const convertHour = hours || 0;
  const convertMinutes = minutes < 10 ? `0${minutes}` : String(minutes);

  const format = () => {
    if (convertHour === 0) return `${convertMinutes} min`;
    if (convertMinutes === "00") return `${convertHour} h`;
    else return `${convertHour}h ${convertMinutes} min`;
  };

  return `Duração ${format()}`;
};
export const getProgress = (finishedClasses, classes) => {
  if (!classes) return { status: undefined, percentage: undefined };
  if (!finishedClasses) return { status: "notStarted", percentage: undefined };

  if (Number(finishedClasses) === Number(classes))
    return { status: "concluded", percentage: 100 };

  if (Number(finishedClasses) < Number(classes)) {
    const percentage = (Number(finishedClasses) * 100) / Number(classes);

    const roundedPercentage = Math.round(percentage);

    return { status: "inProgress", percentage: roundedPercentage };
  }

  return { status: "default", percentage: undefined };
};

export const formatVideoTime = (totalminutes) => {
  let formattedTime = formatDuration(totalminutes * 1000); // hh:mm
  const hourAndMinute = formattedTime.split(":");

  const [hour, min] = hourAndMinute;

  const format = () => {
    if (hour === "0") return `${Number(min)}min`;
    if (min === "00") return `${hour}h`;
    return `${hour}h ${Number(min)}min`;
  };

  return format();
};
