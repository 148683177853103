import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 600px;
  margin: 15vh auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const WarningIcon = styled.div`
  background: none;
  color: #ffffff;
  margin: 32px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;
