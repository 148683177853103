import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 420px;
  margin: 0 auto;
  text-align: left;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 1rem 0;
`;
