import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

import { notifications } from "../../../util/settings";
import { useUserSubscriptionContext } from "../../../context/userSubscriptionContext";
import {
  useActiveCampaignTag,
  useCancelSubscription,
  useCancellationReasons,
  useSaveCancellationReasons,
  useUser,
} from "../../../hooks";
import {
  Button,
  AccountSubscriptionDisclaimer,
  Container,
  LoadingSpinner,
  Alert,
  SupportInfo,
} from "../../../components";
import { ReactComponent as LogoCasa } from "../../../assets/images/logocdsmais.svg";

import strings from "../../../config/strings.json";
import * as S from "./styles";
import { useAuthContext } from "../../../context/AuthContext";
import {
  getNewCheckoutUrlWithUserParam,
  isLegacyCheckoutActive,
} from "../../../util/newCheckout";

const SURVEY_REASONS = "reasons";
const SURVEY_SUGGESTION = "suggestion";
const CANCELLED = "cancelled-in-trial";

const Web = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    hasActiveSubscription,
    setHasActiveSubscription,
    verifyStatusAndApplyAccessRules,
  } = useUserSubscriptionContext();
  const { signOut } = useAuthContext();

  const [disableButton, setDisableButton] = useState(false);
  const [step, setStep] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedReasonId, setSelectedReasonId] = useState();
  const [suggestionContent, setSuggestionContent] = useState("");

  const {
    data: { userData, subscriptionDetails },
    isLoading,
  } = useUser();
  const { data: reasons, isLoading: isCategoriesLoading } =
    useCancellationReasons();

  const { mutate: sendToActiveCampaign } = useActiveCampaignTag();
  const { mutate: saveResponses } = useSaveCancellationReasons();
  const { mutate: sendCancelRequest } = useCancelSubscription({
    onSuccess: (data) => {
      if (subscriptionDetails?.id && selectedReasonId) {
        saveResponses({
          subscriptionId: subscriptionDetails.id,
          cancellationReasonsId: selectedReasonId,
          suggestion: suggestionContent,
        });
      }
      queryClient.resetQueries("user-details");

      setNotification(data.message, "success");
      if (warranty) {
        setStep(CANCELLED);
      } else {
        navigate("/cancelar-plano/renovacao-cancelada", { replace: true });
      }
    },
    onError: (error) => {
      setStep(null);
      setDisableButton(false);
      return setNotification(error.message, "danger");
    },
  });

  const { origin, warranty } = !!subscriptionDetails && subscriptionDetails;
  const warrantyStatus = String(warranty);

  useEffect(() => {
    setHasActiveSubscription(hasActiveSubscription);

    return () => {
      if (step === CANCELLED) {
        window.addEventListener("popstate", () => {
          return verifyStatusAndApplyAccessRules();
        });
        setHasActiveSubscription(false);
      }
    };
  });

  const setNotification = (message, type = "danger") => {
    store.addNotification({
      ...notifications,
      message: message,
      type: type,
    });
  };

  const submitActiveCampaignTag = async (e) => {
    e.preventDefault();

    if (!e.target.reason.value) return setShowAlert(true);

    setSelectedReasonId(e.target.reason.value);

    const tagId = reasons.find(
      (item) => item.id === e.target.reason.value
    )?.acTagId;

    sendToActiveCampaign({
      email: userData.email,
      tagId: tagId,
    });

    setShowAlert(false);
    setStep(SURVEY_SUGGESTION);
  };

  const cancelSubscription = async (e) => {
    e.preventDefault();
    setDisableButton(true);

    if (
      origin === "vindi" ||
      origin === "pagina_vendas_pix_e_boleto" ||
      origin === "pagina_vendas_mensal" ||
      origin === "ms_subscription"
    ) {
      sendCancelRequest(subscriptionDetails.reference);
    } else {
      setDisableButton(false);
      return setNotification(
        "Erro ao cancelar a assinatura. Por favor, entre em contato com o suporte.",
        "danger"
      );
    }
  };

  const renderPageContent = () => {
    if (step === SURVEY_REASONS)
      return (
        <Container type={"minimal"} pageName={"Cancelar assinatura"}>
          <S.Wrapper>
            {showAlert && (
              <Alert
                text="Você precisa selecionar uma resposta ou pode pular a pergunta para cancelar a assinatura."
                variant="error"
              />
            )}

            <S.Title>{strings.cancelPlan.web.researchReasonTitle}</S.Title>

            <S.ReasonsList onSubmit={(e) => submitActiveCampaignTag(e)}>
              {reasons.map(({ id, title }) => (
                <label htmlFor={`reason-${id}`} key={`reason-${id}`}>
                  <S.RadioButton
                    type="radio"
                    name={`reason`}
                    id={`reason-${id}`}
                    value={id}
                  />
                  <S.RadioButtonMark />
                  {title}
                </label>
              ))}

              <Button
                variant={"white"}
                size={"full"}
                id={"btn-cancel_plan_web-cancelar_assinatura"}
                name={"btn-cancel_plan_web-cancelar_assinatura"}
                type="submit"
                disabled={!!disableButton}
              >
                {strings.cancelPlan.web.button.answer}
              </Button>
            </S.ReasonsList>

            <S.StyledButton
              onClick={(e) => cancelSubscription(e)}
              disabled={!!disableButton}
            >
              {strings.cancelPlan.web.button.skipAndCancel}
            </S.StyledButton>
            {disableButton && (
              <Spinner color="light" style={{ margin: "0 auto" }} />
            )}
          </S.Wrapper>
        </Container>
      );

    if (step === SURVEY_SUGGESTION)
      return (
        <Container type={"minimal"} pageName={"Cancelar assinatura"}>
          <S.Wrapper>
            {showAlert && (
              <Alert
                text="Você precisa escrever uma resposta ou pode pular a pergunta para cancelar a assinatura."
                variant="error"
              />
            )}
            <S.Title>{strings.cancelPlan.web.researchSuggestionTitle}</S.Title>

            <S.StyledTextarea
              name="suggestionContent"
              placeholder="Escreva sua sugestão"
              value={suggestionContent}
              onChange={(e) => setSuggestionContent(e.target.value)}
              rows={4}
              cols={40}
            />

            <Button
              variant={"white"}
              size={"full"}
              id={"btn-cancel_plan_web-cancelar_assinatura"}
              name={"btn-cancel_plan_web-cancelar_assinatura"}
              type="submit"
              disabled={!!disableButton}
              onClick={(e) => {
                if (suggestionContent.trim().length > 0) {
                  setShowAlert(false);
                  cancelSubscription(e);
                } else {
                  setShowAlert(true);
                }
              }}
            >
              {strings.cancelPlan.web.button.answerAndCancel}
            </Button>

            <S.StyledButton
              onClick={(e) => cancelSubscription(e)}
              disabled={!!disableButton}
            >
              {strings.cancelPlan.web.button.skipAndCancel}
            </S.StyledButton>
            {disableButton && (
              <Spinner color="light" style={{ margin: "0 auto" }} />
            )}
          </S.Wrapper>
        </Container>
      );

    if (step === CANCELLED)
      return (
        <>
          <S.CancelledHeader>
            <div className="logo">
              <LogoCasa
                alt="Casa do Saber +"
                id={"btn-header-logo"}
                name={"btn-header-logo"}
              />
            </div>
            <button
              className="header-exit-button"
              id="btn-checkout_header-sair"
              name="btn-checkout_header-sair"
              onClick={() => signOut()}
            >
              SAIR
            </button>
          </S.CancelledHeader>
          <S.CancelledContainer>
            <S.Wrapper className="wrapper">
              <S.Title>Sua assinatura foi cancelada.</S.Title>

              <S.Text>
                O valor total da sua compra foi estornado e será creditado em
                até duas faturas.
              </S.Text>

              <S.Text>
                Se quiser obter acesso a plataforma novamente, refaça sua
                assinatura.
              </S.Text>

              <Button
                variant={"green"}
                size={"full"}
                id={"btn-trial_canceled-fazer-nova-assinatura"}
                name={"btn-trial_canceled-fazer-nova-assinatura"}
                onClick={() => {
                  isLegacyCheckoutActive
                    ? navigate("/checkout/entenda-a-assinatura", {
                        replace: true,
                      })
                    : window.location.replace(
                        getNewCheckoutUrlWithUserParam({
                          customerId: userData?.id,
                          email: userData?.email,
                          name: userData?.name,
                          phoneNumber: userData?.phoneNumber,
                        })
                      );
                }}
              >
                FAZER NOVA ASSINATURA
              </Button>

              <SupportInfo />
            </S.Wrapper>
          </S.CancelledContainer>
        </>
      );

    return (
      <Container type={"minimal"} pageName={"Cancelar assinatura"}>
        <S.Wrapper>
          <S.Title>
            {strings.cancelPlan.warranty[warrantyStatus]?.title}
          </S.Title>
          <S.Text>
            {strings.cancelPlan.warranty[warrantyStatus]?.paragraph1}
          </S.Text>
          <S.Text>
            {strings.cancelPlan.warranty[warrantyStatus]?.paragraph2}
          </S.Text>
          <S.Text>
            {strings.cancelPlan.warranty[warrantyStatus]?.paragraph3}
          </S.Text>

          <S.WhatsappLink
            href={strings.cancelPlan.web.whatsapp.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {strings.cancelPlan.web.whatsapp.text}
          </S.WhatsappLink>

          <Button
            variant={"transparent"}
            size={"full"}
            id={"btn-cancel_plan_web-cancelar_assinatura"}
            name={"btn-cancel_plan_web-cancelar_assinatura"}
            type="submit"
            onClick={() => setStep(SURVEY_REASONS)}
            disabled={!!disableButton}
          >
            {disableButton ? (
              <Spinner
                color="dark"
                style={{
                  height: "1.3em",
                  width: "1.3em",
                }}
              />
            ) : (
              strings.cancelPlan.web.button.cancel
            )}
          </Button>

          <Button
            variant={"white"}
            size={"full"}
            id={"btn-cancel_plan_web-continuar_assistindo"}
            name={"btn-cancel_plan_web-continuar_assistindo"}
            onClick={() => navigate("/")}
            disabled={!!disableButton}
          >
            {strings.cancelPlan.web.button.keepWatching}
          </Button>

          <AccountSubscriptionDisclaimer />
        </S.Wrapper>
      </Container>
    );
  };

  if (isLoading || isCategoriesLoading) {
    return <LoadingSpinner color={"light"} />;
  }

  return <>{renderPageContent()}</>;
};

export default Web;
