import React from "react";
import { ReactComponent as LogoCasa } from "../../assets/images/logocdsmais.svg";
import * as S from "./styles";

const OnboardingHeader = () => {
  return (
    <S.Wrapper>
      <LogoCasa alt="Casa do Saber +" />
    </S.Wrapper>
  );
};

export default OnboardingHeader;
