import styled from "styled-components";

export const Wrapper = styled.main`
  max-width: 420px;
  margin: 4em auto 0;

  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: normal;
  margin: 0; //reset p margin
`;

export const Support = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    color: ${(props) => props.theme.darkTheme.colors.text};
    text-decoration: underline;
    text-underline-offset: 1px;

    :hover {
      font-weight: 800;
      color: ${(props) => props.theme.darkTheme.colors.text};
    }
  }
`;
