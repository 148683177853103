import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUserSubscriptionContext } from "../../context/userSubscriptionContext";
import { useFinishedCourses } from "../../hooks";
import {
  Alert,
  CertificateCard,
  CertificateFooter,
  Container,
  LoadingSpinner,
  NoCertificateContent,
} from "../../components";

import * as S from "./styles";

const Certificates = () => {
  const { t } = useTranslation();
  const {
    data: finishedCoursesList,
    isLoading,
    isFetching,
  } = useFinishedCourses();

  const { hasIncompleteRegister } = useUserSubscriptionContext();

  if (isLoading || isFetching) return <LoadingSpinner color="light" />;

  return (
    <>
      <Container type={"minimal"} pageName={t("certificates.title")}>
        <S.Wrapper>
          {!!hasIncompleteRegister && (
            <Alert variant={"warning"}>
              Para emitir o certificado é necessário preencher todos os seus
              dados pessoais.{" "}
              <Link to={"/minha-conta/dados-pessoais"}>
                Complete o seu cadastro.
              </Link>
            </Alert>
          )}
          <S.Description>
            Aqui você pode baixar seu certificado em formato PNG ou
            compartilhá-lo em suas redes sociais.
          </S.Description>
          {finishedCoursesList?.length > 0 ? (
            finishedCoursesList.map((course) => {
              const formatedFinishedAt = new Date(
                course.finishAt
              ).toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });

              return (
                <CertificateCard
                  key={course.slug}
                  userCourseId={course.userCourseId}
                  title={course.title}
                  slug={course.slug}
                  thumb={course.images?.thumbUrl}
                  finishedAt={formatedFinishedAt}
                  author={course.author.name}
                  certificateUrl={course.certificateUrl}
                />
              );
            })
          ) : (
            <NoCertificateContent />
          )}

          <CertificateFooter />
        </S.Wrapper>
      </Container>
    </>
  );
};

export default Certificates;
