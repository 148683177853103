import { MenuGroup } from "@szhsin/react-menu";
import styled, { css } from "styled-components";

export const NavBar = styled.nav`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1080px;
    min-height: 35px;
    margin: 0 auto 5px;
    padding: 0.5em 0;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    z-index: 999;

    .react-menu-container {
      background-color: ${theme.darkTheme.colors.darkBlack};
      border-radius: 10px;
      padding: 2em;
    }

    .react-menu-button {
      background-color: transparent;
      color: ${theme.darkTheme.colors.white};
      border: none;
      margin: 0 0.5em;
      min-width: fit-content;
      text-transform: uppercase;

      :hover {
        color: ${theme.darkTheme.colors.green};
      }
    }
  `}
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: ${({ theme }) => theme.darkTheme.colors.darkBlack};
  transition-property: opacity, visibility;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;
  z-index: 1;

  &.opening,
  &.open {
    opacity: 0.8;
    visibility: visible;
  }
`;

export const MainMenu = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-around;

    span {
      color: ${theme.darkTheme.colors.gray};
      margin: 0 0.5em;
    }

    a {
      text-decoration: none;
      color: ${theme.darkTheme.colors.gray};
      margin: 0 0.5em;
    }
    a:hover {
      text-decoration: none;
      color: ${theme.darkTheme.colors.green};
    }
  `}
`;

export const SubmenuHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1.5em;
    padding-bottom: 1.5em;
    font-size: 14px;
    text-transform: uppercase;

    a {
      text-decoration: none;
      padding: 0.5em;
      line-height: 1.6;
      color: ${theme.darkTheme.colors.white};
      border-radius: 5px;
    }
    a:hover,
    a.szh-menu__item--hover {
      text-decoration: none;
      color: ${theme.darkTheme.colors.white};
      background-color: ${theme.darkTheme.colors.background};
    }
  `}
`;

export const SubmenuBody = styled(MenuGroup)`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1.5em;
    grid-row-gap: 0px;

    align-items: center;
    text-transform: initial;
    font-size: 14px;

    .react-menu-body-item {
      padding: 0;
      line-height: 1.6;
    }

    a {
      text-decoration: none;
      color: ${theme.darkTheme.colors.gray};
      border-radius: 5px;
    }
    a:hover,
    a.szh-menu__item--hover {
      text-decoration: none;
      color: ${theme.darkTheme.colors.white};
      background-color: ${theme.darkTheme.colors.background};
    }

    ::-webkit-scrollbar {
      width: 8px;
      padding: 4px;
      background-color: ${theme.darkTheme.colors.background};
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${theme.darkTheme.colors.green};
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${theme.darkTheme.colors.darkGray};
      border-radius: 10px;
    }
  `}
`;
