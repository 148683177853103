import React from "react";
import { useUser } from "../../hooks";
import { LoadingSpinner } from "../../components";
import CreditCard from "./CreditCard";
import Default from "./Default";
import MsSubscriptionCreditCard from "./MsSubscriptionCreditCard";

const Subscription = () => {
  const {
    data: { subscriptionDetails },
    isLoading: isSubscriptionLoading,
  } = useUser();

  if (!subscriptionDetails || isSubscriptionLoading) {
    return <LoadingSpinner color="light" />;
  }

  const renderPage = () => {
    if (
      subscriptionDetails?.origin === "vindi" ||
      subscriptionDetails?.origin === "pagina_vendas_mensal"
    ) {
      return <CreditCard />;
    }
    if (subscriptionDetails?.origin === "ms_subscription") {
      return <MsSubscriptionCreditCard />;
    }
    return <Default />;
  };

  return <>{renderPage()}</>;
};

export default Subscription;
