import React from "react";
import Container from "../../components/Container";
import CardsGrid from "../../components/CardsGrid";
import { LoadingSpinner } from "../../components";
import { usePopularCourses } from "../../hooks";

import strings from "../../config/strings.json";

export default function Popular() {
  const { data: popularCourses, isFetching: isPopularCoursesLoading } =
    usePopularCourses();

  if (isPopularCoursesLoading) {
    return <LoadingSpinner color={"light"} />;
  }

  return (
    <Container type={"clean"}>
      <CardsGrid
        content={popularCourses}
        pageName={strings.categories.populars}
      />
    </Container>
  );
}
