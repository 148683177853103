import React from "react";
import { Spinner } from "reactstrap";

import { useCategoryCourses } from "../../hooks";
import Carousel from "../../components/Carousel";

import noCertificateIcon from "../../assets/icons/certificate-none.svg";
import * as S from "./styles";

const NoCertificateContent = () => {
  const { data: startHereCategory, isError } = useCategoryCourses(
    {
      categoryId: process.env.REACT_APP_NO_CERTIFICATE_CATEGORY_ID,
    },
    { retry: false }
  );
  return (
    <S.NoCertificateContent>
      <S.NoCertificateIcon>
        <img
          src={noCertificateIcon}
          alt="Ícone de Nenhum Certificado"
          id={"icon-no-certificate"}
          name={"icon-no-certificate"}
        />
      </S.NoCertificateIcon>

      <S.NoCertificateTitle>
        Você ainda não possui nenhum certificado.
      </S.NoCertificateTitle>

      <S.NoCertificateDescription>
        Para obter seu primeiro certificado assista um de nossos cursos até o
        final.
      </S.NoCertificateDescription>

      {startHereCategory ? (
        <S.StartHereContainer>
          <Carousel
            title={startHereCategory.title}
            items={startHereCategory.courses}
            link={
              !!startHereCategory.slug && `/categoria/${startHereCategory.slug}`
            }
            bigCard={false}
          />
        </S.StartHereContainer>
      ) : (
        !isError && <Spinner />
      )}
    </S.NoCertificateContent>
  );
};

export default NoCertificateContent;
