import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCheckoutContext } from "../../context/CheckoutContext";
import { useCheckoutSubscriptionStatus, useQuery } from "../../hooks";
import { normalizeString } from "../../util/Util";
import Datalayer from "../../services/datalayer";
import checkIconGreen from "../../assets/icons/check-green.svg";
import {
  CheckoutHeader,
  CheckoutPageTitle,
  CheckoutDisclaimer,
  CheckoutFooter,
  CTAButton,
  StepperCustom,
} from "../../components";

import * as S from "./styles";

export const CheckoutPlansPreview = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const { checkoutSteps, currentStep, changeStep } = useCheckoutContext();

  useEffect(() => {
    changeStep("entenda");
  }, []);

  useCheckoutSubscriptionStatus();

  const pageTitle = "Entenda a assinatura";
  const bullets = [
    "Acesso ilimitado a mais de 300 cursos originais",
    "Mais de 100 dos melhores professores e professoras do país",
    "Repertório para abrir a sua mente",
    "Multiplaforma para aprender quando e onde quiser",
    "Novos conteúdos toda semana",
    "Certificado de conclusão para todos os conteúdos",
    "7 dias de garantia* com cancelamento sem custo adicional",
  ];

  return (
    <S.Wrapper>
      <CheckoutHeader />

      <StepperCustom steps={checkoutSteps} currentStep={currentStep} />

      <S.CheckoutContainer>
        <CheckoutPageTitle title={pageTitle} subtitle={null} />

        <S.BulletsContainer marker={checkIconGreen}>
          {bullets.map((string) => (
            <li key={normalizeString(string)}>{string}</li>
          ))}
        </S.BulletsContainer>

        <CTAButton
          id={"btn-entenda-proximo"}
          name={"btn-entenda-proximo"}
          onClick={() => {
            Datalayer.onClick(
              "checkout",
              "botao:entenda-a-assinatura",
              "proximo"
            );
            navigate(`/checkout/planos?${query.toString()}`);
          }}
          text="PRÓXIMO"
        />

        <CheckoutDisclaimer />
      </S.CheckoutContainer>
      <CheckoutFooter />
    </S.Wrapper>
  );
};

export default CheckoutPlansPreview;
