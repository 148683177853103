import styled, { css } from "styled-components";

const wrapperModifiers = {
  purpleRed: (theme) => css`
    color: ${theme.darkTheme.colors.text};
    background-color: ${theme.darkTheme.colors.purpleRed};

    ${Button} {
      color: ${theme.darkTheme.colors.text};
    }
  `,
  yellow: (theme) => css`
    color: ${theme.darkTheme.colors.darkBlack};
    background-color: ${theme.darkTheme.colors.yellow};

    ${Button} {
      color: ${theme.darkTheme.colors.darkBlack};
    }
  `,
};

export const Wrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: block;
  padding: 1rem 1.5rem;
  margin: 0 -0.7rem; // offset the container margin

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 0 auto;
  }

  ${({ theme, color }) => css`
    ${color === "purpleRed" && wrapperModifiers.purpleRed(theme)}
    ${color === "yellow" && wrapperModifiers.yellow(theme)}
  `}
`;

export const Content = styled.div`
  a {
    line-height: 160%;
  }
  p {
    margin: 0; // reset margin
  }
`;

export const BannerActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  margin-top: 1rem;

  @media (min-width: 768px) {
    align-items: center;
    margin-top: 0;
  }
`;

export const Button = styled.button`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  background: none;
  height: 17px;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  outline: none;
  border: none;
  text-transform: uppercase;

  ${(props) =>
    props.opacity &&
    css`
      opacity: 0.3;
      :hover {
        opacity: 1;
      }
    `}
`;
