import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.darkTheme.colors.gray};
  background-color: ${(props) => props.theme.darkTheme.colors.darkBlack};
  padding: 1rem;
  margin: 0 -0.7rem 0.5rem;
`;
