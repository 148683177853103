import React from "react";
import * as S from "./styles";
import { Link, useLocation } from "react-router-dom";
import { useCategories } from "../../hooks";

const CategoriesList = () => {
  const { data: categories } = useCategories();

  const location = useLocation();
  const lastPage = location.state?.origin || "/";

  const listItem = categories.map((category) => (
    <S.CategoryItem key={category.id}>
      <Link
        to={`/categoria/${category.slug}`}
        id={`btn-menu-categorias-${category.title
          .toLowerCase()
          .replace(" ", "-")}`}
        name={`btn-menu-categorias-${category.title
          .toLowerCase()
          .replace(" ", "-")}`}
        replace
      >
        {category.title}
      </Link>
    </S.CategoryItem>
  ));

  return (
    <S.Wrapper>
      <S.HeaderContainer>
        <Link
          to={lastPage}
          id={"btn-menu-categorias-fechar"}
          name={"btn-menu-categorias-fechar"}
          replace
        >
          <i className="fas fa-times" />
        </Link>
      </S.HeaderContainer>

      <S.CategoriesList>
        <S.CategoryItem key={"btn-menu-categorias-inicio"}>
          <Link
            to={`/`}
            id={"btn-menu-categorias-inicio"}
            name={"btn-menu-categorias-inicio"}
            replace
          >
            <strong>Início</strong>
          </Link>
        </S.CategoryItem>
        {listItem}
      </S.CategoriesList>
    </S.Wrapper>
  );
};

export default CategoriesList;
