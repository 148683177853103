import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../../styles/mediaQueries";

export const SliderContainer = styled.div`
  max-width: 1080px;
  margin: 0 -0.7rem; // offset the container margin
  @media (min-width: 768) {
    margin: 0 auto;
  }
`;

// Individual Slide
export const SlideContainer = styled.div`
  ${({ coverMobile, coverDesktop }) => css`
    max-width: 100%;
    height: 272px;
    position: relative;
    background-image: url(${coverMobile});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (${device.tablet}) {
      height: 358px;
      background-image: url(${coverDesktop});
    }
  `}
`;

export const SlideInfo = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    padding: 1em 1em 5px 1em;
    background: linear-gradient(180deg, rgba(32, 30, 32, 0) 0%, #201e20 100%);

    .flex-container {
      max-width: 300px;
      display: flex;
      justify-content: space-evenly;
      gap: 10px;
    }

    @media (${device.tablet}) {
      .flex-container {
        max-width: 500px;
      }
    }
  `}
`;

export const CourseNameContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: ${theme.font.size.large};
    @media (min-width: 768px) {
      width: max-content;
      max-width: 450px;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    margin-bottom: 0;
    color: ${theme.darkTheme.colors.white};
    font-size: ${theme.font.size.medium}
    font-weight: ${theme.font.weight.light};
  `}
`;

export const Author = styled.p`
  ${({ theme }) => css`
    margin-bottom: 0px;
    color: ${theme.darkTheme.colors.white};
    font-size: ${theme.font.size.smallest};
    font-weight: ${theme.font.weight.bold};
    line-height: 1.2em;
  `}
`;

export const SliderButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const ActionButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    font-size: 12px;
    width: 57px;
    min-width: 57px;
    transition: all 0.1s ease-in;
    cursor: pointer;

    i {
      font-size: 28px;
    }

    i.checked {
      color: ${theme.darkTheme.colors.green};
      border-color: ${theme.darkTheme.colors.green};
    }

    :hover {
      i {
        color: ${theme.darkTheme.colors.green};
        border-color: ${theme.darkTheme.colors.green};
      }
    }

    //loading must be the same icon font-size
    .spinner-border {
      max-width: 28px;
      max-height: 28px;
    }

    @media (${device.regularPhone}) {
      font-size: 12px;
      i {
        font-size: 30px;
      }
      .spinner-border {
        max-width: 30px;
        max-height: 30px;
      }
    }
  `}
`;

export const ButtonLinkContainer = styled(Link)`
  ${({ theme }) => css`
    button {
      padding-top: ${theme.gap.smaller};
      padding-bottom: ${theme.gap.smaller};
    }
    img {
      max-width: 10px;
    }
  `}
`;

export const TagsContainer = styled.div`
  width: 100%;
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;

  a,
  span {
    background-color: #100f10;
    color: #aca9b3;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 3px 10px 3px 0;
    transition: all 0.1s ease-in;
  }

  a:hover {
    text-decoration: none;
    color: #cff16b;
  }
`;

export const ModalCustomGrid = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  column-gap: 16px;
  grid-template-rows: 3fr max-content;
  row-gap: 24px;
`;

export const ModalImageContainer = styled.div`
  width: 101px;
  height: 144px;

  img {
    max-width: 100%;
  }
`;

export const ModalDescriptionContainer = styled.div`
  h2,
  p {
    color: #000;
    margin-bottom: 0; //reset margin
  }

  h2 {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3em;
  }

  p {
    font-size: 10px;
  }

  @media (min-width: 414px) {
    h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.3em;
    }
    p {
      font-size: 16px;
    }
  }
`;

export const ModalButtonsContainer = styled.div`
  ${({ theme }) => css`
    grid-column: 1 / -1;
    display: flex;
    justify-content: flex-start;
    gap: ${theme.gap.medium};
    color: ${theme.darkTheme.colors.mediumBlack};
    font-size: ${theme.font.size.smaller};

    @media (${device.smallPhone}) {
      font-size: ${theme.font.size.small};
    }

    > div,
    button {
      display: flex;
      align-items: center;
      border: none;
      background-color: transparent;
      column-gap: ${theme.gap.smaller};
    }

    button {
      cursor: pointer;
    }

    .classes img {
      max-width: 30px;
    }

    .favorite i {
      font-size: 24px;
    }

    .spinner-border {
      max-width: 24px;
      max-height: 24px;
    }
    }
  `}
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  border-top: none;

  button {
    transition: all 0.2s ease-in;
    border: 1px solid #cccccc;
  }

  button.add-border-gray {
    border: 1px solid #cccccc;
  }

  button:hover {
    background-color: #2c2c2c;
  }

  button i.fa-angle-right {
    float: right;
    margin-right: 10px;
    font-size: 18px;
  }
`;
