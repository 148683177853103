import "../config/amplify";

import React, { useState, useContext, createContext, useEffect } from "react";
import { useQueryClient } from "react-query";
import {
  Auth,
  CognitoUser, // eslint-disable-line no-unused-vars
} from "@aws-amplify/auth";
import api, { apiCDS, setApiToken } from "../services/api";
import { requests } from "../services/requests";
import Datalayer from "../services/datalayer";
import { secureStorage } from "../util/SecureStorage";
import { translateAmplifyErrorMessage } from "../util/messages";
import { useUser, useSentry } from "../hooks";
import * as Sentry from "@sentry/react";
import { getURLParamValue } from "../util/Util";

/**
 * @typedef TAuthContext
 * @property {boolean} loading
 * @property {() => void} setIsLoggedIn
 * @property {boolean} isLoggedIn
 * @property {() => Promise<any>} signIn
 * @property {() => Promise<{status: boolean, message: string, userId: string}>} signUp
 * @property {() => Promise<any>} signOut
 * @property {() => Promise<any>} forgotPassword
 * @property {() => Promise<any>} recoverPassword
 * @property {(email: string, answer: string) => Promise<any>} answerCustomChallenge
 * @property {string} pathToRedirect
 */

/** @type {import('react').Context<TAuthContext>} */
const AuthContext = createContext();
AuthContext.displayName = "Autenticação";

const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pathToRedirect, setPathToRedirect] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const { logException } = useSentry(isLoggedIn);

  useUser({
    enabled: loginSuccess,
    onError: (error) => {
      if (error?.response?.data?.message === "Token inválido.") signOut();
    },
    onSettled: (data) => {
      if (loginSuccess) {
        Datalayer.onCallback(
          "login",
          "sucesso",
          data?.subscriptionDetails?.reference ?? "undefined"
        );
        setLoginSuccess(false);
      }
    },
  });

  async function loadAuthSession() {
    if (window.location.pathname === "/entrar/integracao") {
      setLoading(false);
      setIsLoggedIn(false);
      return;
    }
    setLoading(true);
    try {
      const session = await Auth.currentSession();

      if (session.isValid()) {
        const jwtToken = session.getIdToken().getJwtToken();
        setApiToken(jwtToken);

        Sentry.setUser({ email: session.getIdToken()?.payload?.email });

        setIsLoggedIn(true);
      }
    } catch (error) {
      queryClient.clear();
      setIsLoggedIn(false);

      const errorMessage = typeof error === "string" ? error : error.message;
      if (
        errorMessage.includes("No current user") ||
        errorMessage.includes("Refresh Token has expired")
      )
        return;

      const exceptionParams = {
        error,
        transactionName: "Auth Context",
        origin: "loadAuthSession",
        tags: [
          { label: "errorMessage", value: error.message },
          { label: "errorCode", value: error.code },
        ],
        extras: [{ label: "data", value: error }],
      };
      logException(exceptionParams);

      throw error;
    } finally {
      setLoading(false);
    }
  }

  /***** FOR TESTING PURPOSES *****/
  const [testingDate, setTestingDate] = useState(null);
  useEffect(() => {
    const testing = getURLParamValue("tbf");
    if (testing) {
      setTestingDate(testing);
    }
  }, []);
  /**********/

  useEffect(() => {
    // Every time the App is opened, this provider is rendered
    // and call de loadAuthSession function.
    loadAuthSession();
    // it also clear localStorage reference id
    // used for datalayer.
    secureStorage.removeItem("reference");
  }, []);

  /**
   * signInSuccess
   * @param {string} email
   * @param {CognitoUser} authResponse
   */
  async function signInSuccess(email, authResponse) {
    try {
      const jwtToken = authResponse
        .getSignInUserSession()
        .getIdToken()
        .getJwtToken();
      setApiToken(jwtToken);

      Sentry.setUser({ email });
      setLoginSuccess(true);
    } catch (error) {
      signInError(email, error, "signInSuccess");
    }
  }

  /**
   * signInError
   * @param {string} email
   * @param {Error} error
   */
  function signInError(email, error, origin) {
    const action = translateAmplifyErrorMessage(
      error?.message,
      "datalayer"
    ).message;

    Datalayer.onCallback("login", `erro:${action}`, "undefined");

    const exceptionParams = {
      error,
      transactionName: "Auth Context",
      origin,
      tags: [
        { label: "errorMessage", value: error.message },
        { label: "errorCode", value: error.code },
      ],
      extras: [{ label: "data", value: error }],
      email,
    };
    logException(exceptionParams);
    throw error;
  }

  /**
   * SignIn
   * @param {string} email
   * @param {string} password
   * @returns {Promise<CognitoUser | any>}
   */
  async function signIn(email, password) {
    try {
      const authResponse = await Auth.signIn(email, password);

      if (!authResponse.signInUserSession) return authResponse;

      await signInSuccess(email, authResponse);
    } catch (error) {
      signInError(email, error, "password");
    }
  }

  /**
   * answerCustomChallenge
   * @param {string} email
   * @param {string} answer
   * @returns {Promise<any>}
   */
  async function answerCustomChallenge(email, answer) {
    let cognitoUser = null;

    try {
      cognitoUser = await Auth.signIn(email);
      const authResponse = await Auth.sendCustomChallengeAnswer(
        cognitoUser,
        answer
      );

      if (!authResponse.signInUserSession) return authResponse;

      await signInSuccess(email, authResponse);
    } catch (error) {
      const exceptionParams = {
        error,
        transactionName: "Auth Context",
        origin: "answerCustomChallenge",
        tags: [
          { label: "errorMessage", value: error.message },
          { label: "errorCode", value: error.code },
        ],
        extras: [
          { label: "data", value: error },
          { label: "cognitoUser", value: !!cognitoUser },
        ],
        email,
      };
      logException(exceptionParams);

      throw error;
    }
  }

  /**
   * SignUp
   * @param {string} email
   * @param {string} password
   * @param {string} phoneNumber
   * @returns {Promise<{status: boolean, message: string, userId: string}>}
   */
  async function signUp(email, password, phoneNumber) {
    const { message, userId } = await requests.signup(
      email,
      password,
      phoneNumber
    );

    try {
      const authResponse = await Auth.signIn(email, password);

      const jwtToken = authResponse
        .getSignInUserSession()
        .getIdToken()
        .getJwtToken();
      setApiToken(jwtToken);

      return { message, userId };
    } catch (error) {
      const exceptionParams = {
        error,
        transactionName: "Auth Context",
        origin: "signUp",
        tags: [
          { label: "errorMessage", value: error.message },
          { label: "errorCode", value: error.code },
        ],
        extras: [{ label: "data", value: error }],
        email,
      };
      logException(exceptionParams);

      throw error;
    }
  }

  async function signOut() {
    await Auth.signOut();
    delete api.defaults.headers.common["authorization"];
    delete apiCDS.defaults.headers.common["accessToken"];
    queryClient.clear();
    if (window) localStorage.clear();
    setLoginSuccess(false);
    setPathToRedirect("");
    setIsLoggedIn(false);
    return (window.location.href = "/entrar");
  }

  async function forgotPassword(email) {
    return Auth.forgotPassword(email);
  }

  async function recoverPassword(email, code, new_password) {
    return Auth.forgotPasswordSubmit(email, code, new_password);
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        setIsLoggedIn,
        isLoggedIn,
        signIn,
        signUp,
        signOut,
        forgotPassword,
        recoverPassword,
        answerCustomChallenge,
        pathToRedirect,
        setPathToRedirect,
        testingDate, // BF Testing
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 *
 * @returns AuthContext
 */
const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }

  return context;
};

export { AuthContext, AuthProvider, useAuthContext };
