import React from "react";
import Error from "../../components/Errors/index";
import Container from "../../components/Container";

const Errors = (props) => {
  return (
    <Container type={"full"}>
      <Error status={404} />
    </Container>
  );
};

export default Errors;
