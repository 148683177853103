import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCheckoutContext } from "../../context/CheckoutContext";
import { usePromotionalCampaign, useUser } from "../../hooks";
import { secureStorage } from "../../util/SecureStorage";
import Datalayer from "../../services/datalayer";
import { support } from "../../data/support";
import {
  CheckoutHeader,
  CheckoutFooter,
  CTAButton,
  CheckoutPageTitle,
} from "../../components";
import PartialFail from "../../assets/icons/status-partial-fail.png";

import strings from "../../config/strings.json";
import * as S from "./styles";
import {
  getNewCheckoutUrlWithUserParam,
  isLegacyCheckoutActive,
} from "../../util/newCheckout";

const CheckoutFailed = () => {
  const navigate = useNavigate();

  const { plan, data, setPlan } = useCheckoutContext();
  const { data: campaign } = usePromotionalCampaign();

  const localStoragePlan = secureStorage.getItem(
    `submitted-plan-${data.email}`
  );
  const storedPlan = !!localStoragePlan && JSON.parse(localStoragePlan);

  const {
    data: { userData },
  } = useUser();

  useEffect(() => {
    if (storedPlan) setPlan(storedPlan);
  }, [localStoragePlan]);

  const whatsapp = (
    <a
      href={support.whatsapp.href}
      target={support.whatsapp.target}
      rel={support.whatsapp.rel}
      id={"btn-checkout_failed-whatsapp"}
      name={"btn-checkout_failed-whatsapp"}
    >
      {support.whatsapp.text}
    </a>
  );

  const manageClick = () => {
    if (localStoragePlan) {
      navigate(`/checkout/pagamento?plan=${plan.id}`, {
        replace: true,
      });
    } else {
      navigate(`/checkout/planos`, {
        replace: true,
      });
    }
  };

  return (
    <S.Wrapper className="wrapper">
      <CheckoutHeader />

      <S.CheckoutContainer>
        <S.FailedInfo>
          <S.Image>
            <img src={PartialFail} alt={"status-partial-fail"} />
          </S.Image>

          <CheckoutPageTitle title={strings.checkout.pages.failed.title}>
            {/* BEST FRIDAY */}
            <S.FailedInfoSubtitle>
              {!!campaign
                ? "Não perca a Best Week e aproveite o desconto."
                : strings.checkout.pages.failed.subtitle}
            </S.FailedInfoSubtitle>
            {strings.checkout.pages.failed.message}
          </CheckoutPageTitle>
        </S.FailedInfo>

        <CTAButton
          id={"btn-falha-assinatura"}
          name={"btn-falha-assinatura"}
          onClick={() => {
            Datalayer.onClick(
              "checkout",
              "botao:erro-falha-na-compra",
              "tente-novamente"
            );

            isLegacyCheckoutActive
              ? manageClick()
              : window.location.replace(
                  getNewCheckoutUrlWithUserParam({
                    customerId: userData?.id,
                    email: userData?.email,
                    name: userData?.name,
                    phoneNumber: userData?.phoneNumber,
                  })
                );
          }}
          text={strings.checkout.pages.failed.buttonText}
        />

        <S.AlternativePaymentInfo>
          <strong>E SE O ERRO CONTINUAR?</strong>
          <p>
            Entre em contato com o nosso suporte para receber ajuda ou solicitar
            uma outra forma de pagamento pelo WhatsApp: {whatsapp}, de segunda à
            sexta, das 10h às 19h.
          </p>
        </S.AlternativePaymentInfo>
      </S.CheckoutContainer>

      <CheckoutFooter />
    </S.Wrapper>
  );
};

export default CheckoutFailed;
