import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme, textCenter, verticalMargin }) => css`
    width: 100%;
    padding: ${theme.spacings.medium};
    background-color: ${theme.darkTheme.colors.mediumBlack};
    border-radius: 5px;

    text-align: ${textCenter && "center"};
    margin: ${verticalMargin}px auto;
  `}
`;
