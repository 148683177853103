import styled, { css } from "styled-components";

export const Wrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: ${(props) => props.theme.lightTheme.colors.background};
  color: ${(props) => props.theme.lightTheme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 86px;

  button {
    background-color: ${(props) =>
      props.theme.lightTheme.colors.buttonBackground};
  }
  button:hover {
    color: ${(props) => props.theme.lightTheme.colors.text};
  }
`;

export const EmptyHeader = styled.div`
  width: 100%;
  height: 63px;
  background-color: ${(props) => props.theme.lightTheme.colors.darkGray};
`;

export const CheckoutContainer = styled.div`
  max-width: 414px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const Description = styled.p`
  font-weight: 100;
  margin-bottom: 0; //reset

  .lighter {
    font-size: 14px;
    line-height: 120%;
  }
  a {
    text-decoration: underline;
    color: #000;
  }
`;

export const Image = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const LoadingAnimation = styled.div`
  ${({ theme }) => css`
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: red;
    background: linear-gradient(
      to right,
      ${theme.lightTheme.colors.green} 10%,
      rgba(255, 255, 255, 0) 42%
    );
    position: relative;
    animation: loading 2.5s infinite linear;
    transform: translateZ(0);

    &:before {
      width: 50%;
      height: 50%;
      background: ${theme.lightTheme.colors.green};
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
    }
    &:after {
      background: #fff;
      width: 75%;
      height: 75%;
      border-radius: 50%;
      content: "";
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `}
`;
