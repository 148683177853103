import React from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

const SignupHeader = () => {
  const navigate = useNavigate();

  return (
    <S.Wrapper>
      <div>
        <button
          className="header-login-button"
          id="btn-signup_header_entrar"
          name="btn-signup_header_entrar"
          onClick={() => navigate("/entrar")}
        >
          ENTRAR
        </button>
      </div>
    </S.Wrapper>
  );
};

export default SignupHeader;
