import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MaskedInput from "react-input-mask";
import { FiAlertTriangle } from "react-icons/fi";
import _ from "lodash";

import { useAuthContext } from "../../context/AuthContext";
import { useCheckoutContext } from "../../context/CheckoutContext";
import { useUserSubscriptionContext } from "../../context/userSubscriptionContext";

import { useDatalayer, usePromotionalCampaign, useQuery } from "../../hooks";
import Datalayer from "../../services/datalayer";
import { requests } from "../../services/requests";
import {
  formattedDate,
  formattedPrice,
  priceStringToNumber,
} from "../../util/Util";
import {
  calcPriceAfterDiscount,
  formatBestFridayPlanAfterDiscounts,
} from "../../util/checkout";

import {
  Accordion,
  CheckoutDisclaimer,
  CheckoutPageTitle,
  LoadingSpinner,
  Button,
} from "../../components";
import ArrowDown from "../../assets/icons/arrow-down.svg";
import strings from "../../config/strings.json";

import * as S from "./styles";

const GIFT_PLANS = {
  [process.env.REACT_APP_DB_PLAN_VINDI_GIFT_1YEAR]: {
    name: "Plano de um ano para presente",
    description: "um ano de acesso a todos os cursos",
  },
  [process.env.REACT_APP_DB_PLAN_VINDI_GIFT_2YEARS]: {
    name: "Plano de dois anos para presente",
    description: "dois anos de acesso a todos os cursos",
  },
};

export const CheckoutPlansBestFriday = () => {
  const { plan, setPlan } = useCheckoutContext();
  const { setPathToRedirect } = useAuthContext();
  const { verifyStatusAndApplyAccessRules } = useUserSubscriptionContext();

  const navigate = useNavigate();
  const query = useQuery();
  const { handleBlur } = useDatalayer();

  const { data: campaign } = usePromotionalCampaign();

  const [giftCode, setGiftCode] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [giftPlan, setGiftPlan] = useState();
  const giftCodeParam = query.get("giftCode");

  const isGiftFlow = !!giftPlan || giftCodeParam;
  const pageTitle =
    isGiftFlow && giftPlan?.planId
      ? GIFT_PLANS[giftPlan.planId].name
      : "Best Friday - Plano anual";

  useEffect(() => {
    if (giftCodeParam) {
      setGiftCode(giftCodeParam);
    } else if (campaign.coupon) {
      const priceAfterDiscount = calcPriceAfterDiscount(
        plan.originalPrice,
        campaign.coupon.discountValue,
        campaign.coupon.discountType
      );

      const bestFridayPlan = formatBestFridayPlanAfterDiscounts(
        plan,
        campaign.coupon,
        priceAfterDiscount
      );

      setPlan({ ...plan, ...bestFridayPlan });
      setLoading(false);
    } else {
      setLoading(false);
    }

    Datalayer.onSelectProduct(plan.name, plan.id, plan.price);
  }, []);

  const delayedQuery = useCallback(
    _.debounce(() => verifyGiftCode(giftCode), 800),
    [giftCode]
  );

  useEffect(() => {
    if (giftCode.length >= 2) {
      delayedQuery();
    }
    return delayedQuery.cancel;
  }, [giftCode, delayedQuery]);

  const verifyGiftCode = async (giftCode) => {
    setIsOpen(true);

    const giftRegexPattern = /^PST/g;
    if (!giftCode.match(giftRegexPattern)) {
      setErrors({ message: "O código do vale presente é inválido" });
      return setButtonDisabled(false);
    }

    try {
      const { data } = await requests.getGiftByCode(giftCode);
      if (data.billingStatus !== "paid") {
        throw Error("Invalid Status");
      }
      setGiftPlan(data);
      setButtonDisabled(false);
    } catch (e) {
      setErrors({
        message:
          e.response?.data?.message ?? "Erro ao utilizar o vale presente",
      });
      setButtonDisabled(false);
      setGiftPlan(null);
    }
    setLoading(false);
  };

  const goToPayment = async () => {
    setErrors({});
    setButtonDisabled(true);

    // ENHANCED ECOMMERCE
    Datalayer.addToCart(plan.name, plan.id, plan.price, plan.coupon);

    navigate(`/checkout/pagamento?plan=${plan.id}`);
  };

  const createSubscriptionFromGift = async () => {
    setButtonDisabled(true);
    try {
      await requests.createSubscriptionFromGift(giftCode);
      setPathToRedirect("/onboarding");
      verifyStatusAndApplyAccessRules();
    } catch (e) {
      setErrors({
        message:
          e.response?.data?.message ?? "Falha ao criar assinatura presente",
      });
    } finally {
      setButtonDisabled(false);
    }
  };

  const onClickNextButton = async (e) => {
    e.preventDefault();
    if (buttonDisabled) return;
    setIsButtonLoading(true);

    if (isGiftFlow) {
      await createSubscriptionFromGift();
    } else if (!giftCode) {
      Datalayer.addToCart(plan.name, plan.id, plan.price, plan.coupon);
      return navigate(`/checkout/pagamento?plan=${plan.id}`);
    } else {
      goToPayment();
    }
    setIsButtonLoading(false);
  };

  const vivoPlan = async (e) => {
    e.preventDefault();
    if (buttonDisabled) return;
    Datalayer.onSelectProduct("vivo", "vivo");

    return navigate(`/checkout/vivo`);
  };

  if (loading) return <LoadingSpinner />;

  return (
    <S.CheckoutContainer>
      <CheckoutPageTitle title={pageTitle} subtitle={null} />
      {isGiftFlow ? (
        !errors.message && (
          <>
            <S.PlanBox>
              <h4>{GIFT_PLANS[giftPlan?.planId]?.description}</h4>

              <S.PlanInfo>
                <p>SUA ASSINATURA JÁ FOI PAGA</p>
              </S.PlanInfo>
            </S.PlanBox>
          </>
        )
      ) : (
        <>
          <S.PlanBox>
            <h4>um ano de acesso a todos os cursos</h4>

            <S.PlanInfo>
              <p className="installment-price">
                {plan.installmentsInfo}{" "}
                <strong>
                  R${" "}
                  <span className="installment-value">
                    {plan.installmentPrice}
                  </span>
                </strong>{" "}
                <S.DiscountLabel>↓{plan.discountLabel}</S.DiscountLabel>
              </p>
              <p className="full-price">
                ou à vista{" "}
                <strong>
                  {formattedPrice(priceStringToNumber(plan.price))}
                </strong>
              </p>
            </S.PlanInfo>
          </S.PlanBox>
          <S.VivoPlan
            onClick={(e) => {
              Datalayer.onClick(
                "checkout",
                "plano:2",
                "sou-cliente-vivo-familia"
              );
              vivoPlan(e);
            }}
            data-testid="checkout-vivo-plan-button"
          >
            <span>{strings.vivo.buttonTitle}</span>
            <label>{strings.vivo.buttonSubtitle}</label>
          </S.VivoPlan>
          <Accordion />
        </>
      )}

      {errors.message && (
        <S.Errors>
          <FiAlertTriangle /> <span>{errors.message}</span>
        </S.Errors>
      )}

      <S.Coupon
        className={"coupon-container"}
        open={isOpen}
        data-testid={"checkout-coupon-accordion"}
      >
        <S.TitleContainer
          className="coupon-title"
          onClick={() => setIsOpen(!isOpen)}
          id={"btn-plans-coupon"}
          name={"btn-plans-coupon"}
        >
          Vale presente
          <img src={ArrowDown} alt="Seta para cima" />
        </S.TitleContainer>

        <S.InputContainer className="coupon-code">
          <label htmlFor={"giftCode"}>Digite o código</label>
          <MaskedInput
            id="giftCode"
            key="giftCode"
            name="giftCode"
            type="giftCode"
            value={giftCode}
            onChange={(e) => setGiftCode(e.target.value.toUpperCase())}
            onBlur={(e) => handleBlur(e, "checkout", "2", "giftCode")}
            autoComplete="off"
            disabled={!!giftCodeParam}
          />
        </S.InputContainer>
      </S.Coupon>

      <Button
        id={"btn-plans-proximo"}
        name={"btn-plans-proximo"}
        type="submit"
        variant={"green"}
        disabled={buttonDisabled}
        loading={isButtonLoading}
        onClick={(e) => {
          Datalayer.onClick("checkout", "botao:2", "proximo");
          !buttonDisabled && onClickNextButton(e);
        }}
      >
        PRÓXIMO
      </Button>

      {!!giftPlan ? (
        <p className="disclaimer">
          *Essa assinatura é válida até o dia {formattedDate(giftPlan.endAt)}.
        </p>
      ) : (
        <CheckoutDisclaimer />
      )}
    </S.CheckoutContainer>
  );
};

export default CheckoutPlansBestFriday;
