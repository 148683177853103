import {
  useMutation,
  useQuery,
  UseQueryOptions, // eslint-disable-line no-unused-vars
  UseQueryResult, // eslint-disable-line no-unused-vars
} from "react-query";
import { requests } from "../services/requests";
import { useUser } from "./useUsersQuery";
import { setFreshTime } from "../constants/reactQueryDefaultOption";

export const queryKeys = {
  subscriptionStatus: "subscription-status",
  cardExpiration: "card-expiration",
  updateInstallments: "update-installments",
  getGatewayData: "get-gateway-data",
  reactivateSubscription: "reactivate-subscription",
  cancelSubscription: "cancel-subscription",
  cancellationReasons: "cancellation-reasons",
  saveCancellationReasons: "save-cancellation-reasons",
};

/**
 * Subscription status query
 * @param {UseQueryOptions} options
 * @returns {UseQueryResult}
 */
export const useSubscriptionStatus = (options = {}) => {
  return useQuery(
    queryKeys.subscriptionStatus,
    requests.getSubscriptionStatus,
    {
      ...options,
      ...setFreshTime(600),
      optimisticResults: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useCardExpiration = (options = {}) => {
  const {
    data: { userData, subscriptionDetails },
  } = useUser();

  return useQuery(
    queryKeys.cardExpiration,
    () =>
      requests.getCardExpiration(subscriptionDetails.origin, userData.email),
    {
      ...options,
      ...setFreshTime(60),
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateInstallments = (options = {}) => {
  return useMutation(
    [queryKeys.updateInstallments],
    (body) => requests.updateInstallments(body),
    options
  );
};

export const useGetGatewayData = ({ reference = undefined }, options = {}) => {
  return useQuery(
    [queryKeys.getGatewayData, reference],
    () => requests.getGatewayData(reference),
    {
      enabled: !!reference,
      retry: 1,
      ...options,
      refetchOnWindowFocus: false,
    }
  );
};

export const useReactivateSubscription = (options = {}) => {
  return useMutation(
    [queryKeys.reactivateSubscription],
    (subscriptionReference) =>
      requests.reactivateSubscription(subscriptionReference),
    options
  );
};
export const useCancelSubscription = (options = {}) => {
  return useMutation(
    [queryKeys.cancelSubscription],
    (subscriptionReference) => requests.cancelOnVindi(subscriptionReference),
    options
  );
};

export const useCancellationReasons = (options = {}) => {
  return useQuery(
    queryKeys.cancellationReasons,
    requests.getCancellationReasons,
    {
      ...options,
      cacheTime: 0,
      optimisticResults: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveCancellationReasons = (options = {}) => {
  return useMutation(
    [queryKeys.saveCancellationReasons],
    (body) => requests.saveCancellationReasons(body),
    {
      ...options,
    }
  );
};
