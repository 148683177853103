import { store } from "react-notifications-component";
import { notifications } from "../util/settings";
import {
  useAddFavorite,
  useFavoriteCourses,
  useRemoveFavorite,
} from "./useCoursesQuery";
import strings from "../config/strings.json";

export default function useMyList() {
  const {
    data: favoriteCourses,
    isFetching,
    refetch: fetchFavorites,
  } = useFavoriteCourses();

  function setNotification(message, type = "danger") {
    store.addNotification({
      ...notifications,
      message: message,
      type: type,
      dismiss: {
        duration: 1000 * 1.5,
        onScreen: true,
      },
    });
  }

  const { mutate: addFavorite, isLoading: isLoadingAddFavorite } =
    useAddFavorite({
      onSuccess: () => {
        setNotification(strings.favorites.courseAdded, "success");
        fetchFavorites();
      },
      onError: () => {
        setNotification(strings.favorites.error, "danger");
      },
    });

  const { mutate: removeFavorite, isLoading: isLoadingRemoveFavorite } =
    useRemoveFavorite({
      onSuccess: () => {
        setNotification(strings.favorites.courseRemoved, "success");
        fetchFavorites();
      },
      onError: () => {
        setNotification(strings.favorites.error, "danger");
      },
    });

  const verifyFavoriteStatus = (courseId) => {
    if (!favoriteCourses || !courseId) return false;

    return favoriteCourses.some(
      (favoriteCourse) => String(favoriteCourse.id) === String(courseId)
    );
  };

  return {
    fetchFavorites,
    addFavorite,
    removeFavorite,
    verifyFavoriteStatus,
    isFetchingFavorites: isFetching,
    isLoadingAddOrRemove: isLoadingAddFavorite || isLoadingRemoveFavorite,
  };
}
