import styled, { css } from "styled-components";

const inputModifiers = {
  dark: (theme) => css`
    input {
      background-color: ${theme.darkTheme.colors.inputBackground};
      color: ${theme.darkTheme.colors.text};
    }
    label {
      color: ${theme.darkTheme.colors.gray};
    }
  `,
  light: (theme) => css`
    input {
      background-color: ${theme.lightTheme.colors.inputBackground};
      color: ${theme.lightTheme.colors.text};
    }
    label {
      color: ${theme.lightTheme.colors.gray};
    }
  `,
};
const errorMessageModifiers = {
  dark: (theme) => css`
    color: ${theme.darkTheme.colors.text};
  `,
  light: (theme) => css`
    color: ${theme.lightTheme.colors.text};
  `,
};

// Also applies to PasswordInput component
export const InputTextFieldContainer = styled.div`
  ${({ theme, invalid, variant }) => css`
    input {
      border-radius: 5px;
      border: 1px solid transparent;
      border-color: ${invalid ? theme.darkTheme.colors.danger : "transparent"};
      padding: 1.5rem 3rem 0.3rem 0.5rem;
      width: 100%;
      height: 50px;
      display: block;
      font-size: 16px;

      :disabled {
        opacity: 0.6;
      }
    }

    label {
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
      line-height: 130%;
      display: flex;
      align-items: center;
      position: absolute;
      top: 16px;
      left: 8px;
      height: 0px;
      width: 100%;
    }

    ${variant && inputModifiers[variant](theme)}
  `}
`;

// Also applies to PasswordInput component
export const InputContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
  `}
`;

// Also applies to PasswordInput component
export const ErrorMessage = styled.p`
  ${({ theme, variant }) => css`
    font-size: 14px;
    font-weight: 100;
    margin-top: 5px;
    margin-bottom: 0; //reset p style

    ${variant && errorMessageModifiers[variant](theme)}
  `}
`;
