import React from "react";
import { useWindowWidthCalculate } from "../../hooks";
import { ReactComponent as LogoCasa } from "../../assets/images/logocdsmais.svg";

import * as S from "./styles.js";

const LoginContainer = (props) => {
  const { isDesktop } = useWindowWidthCalculate();

  return (
    <S.Wrapper id={"page-content"}>
      {isDesktop && (
        <div className="logo">
          <LogoCasa alt="Logotipo Casa do Saber +" />
        </div>
      )}
      {props.children}
    </S.Wrapper>
  );
};

export default LoginContainer;
