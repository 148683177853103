import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { store } from "react-notifications-component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { notifications } from "../../util/settings";

import { useGiftSubscriptionStatus, useSendGiftEmail } from "../../hooks";
import {
  CheckoutFooter,
  CheckoutPageTitle,
  Input,
  Button,
  LoadingSpinner,
  Textarea,
  Alert,
} from "../../components";

import { ReactComponent as LogoCasa } from "../../assets/images/simbolo-cds.svg";
import statue from "../../assets/images/giftcard-image.png";
import * as S from "./styles";

const schema = yup.object({
  recipientName: yup
    .string()
    .required("Por favor, preencha o nome da pessoa presenteada")
    // eslint-disable-next-line no-template-curly-in-string
    .min(2, "O nome da pessoa presenteada deve ter, no mínimo, 2 caracteres"),
  recipientEmail: yup
    .string()
    .required("Preencha um email válido")
    .email("Preencha um email válido")
    .lowercase()
    .trim(),
});

const GiftCheckoutSendEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ resolver: yupResolver(schema) });

  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { giftCode } = Object.fromEntries([...searchParams]);

  const [statusMessage, setStatusMessage] = useState({});

  const {
    data: giftData,
    isLoading,
    isFetching,
    error,
    isError,
  } = useGiftSubscriptionStatus(giftCode);

  const { mutate: sendGiftEmail, isLoading: sendingForm } = useSendGiftEmail();

  function setNotification(message, type = "danger") {
    store.addNotification({
      ...notifications,
      message: message,
      type: type,
    });
  }

  useEffect(() => {
    if (!giftCode) {
      return navigate(`/presente`, { replace: true });
    }

    if (error?.response?.data?.status === 409) {
      setNotification(error.response.data.message);
      return navigate("/entrar", { replace: true });
    }

    if (error?.response?.data?.status === 400) {
      setNotification(error.response.data.message);
      return navigate("/presente");
    }

    if (isError) {
      setNotification(
        "Houve um erro inesperado. Tente novamente ou entre em contato com o suporte"
      );
      return navigate(`/presente/vale-presente?giftCode=${giftCode}`);
    }
  }, [giftCode, error, isError]);

  const onSubmit = async (data) => {
    sendGiftEmail(
      { giftCode, ...data },
      {
        onSuccess: (data) => {
          setStatusMessage({ message: data.message, type: "success" });

          setTimeout(() => {
            setStatusMessage({});
            navigate(`/presente/vale-presente?giftCode=${giftCode}`, {
              state: state,
            });
          }, 2000);
        },
        onError: (error) => {
          if (error.response.data.message) {
            const { message } = error.response.data;
            return setStatusMessage({ message, type: "error" });
          }

          setStatusMessage({
            message:
              "Houve um erro ao enviar o email. Por favor, tente novamente ou entre em contato com o suporte.",
            type: "error",
          });

          setTimeout(() => {
            setStatusMessage({});
            navigate(`/presente/vale-presente?giftCode=${giftCode}`, {
              state: state,
            });
          }, 2000);
        },
      }
    );
  };

  if (isLoading || isFetching)
    return <LoadingSpinner color="dark" background="light" />;

  return (
    <S.Wrapper className="wrapper">
      <S.EmptyHeader />

      <S.GiftCheckoutContainer>
        <S.GiftCard bgImage={statue}>
          <div className="title-container">
            <div>
              <p className="title">Vale Presente</p>
              <p className="subtitle">Casa do Saber</p>
            </div>
            <LogoCasa
              alt="Casa do Saber +"
              id={"btn-header-logo"}
              name={"btn-header-logo"}
            />
          </div>
          <div>
            <p className="code-label">Código:</p>
            <p className="code-value">{giftCode}</p>
          </div>
        </S.GiftCard>

        <CheckoutPageTitle title={"Enviar vale presente por e-mail"} />

        <S.Description>
          Verifique se as informações estão corretas e escreva uma mensagem
          personalizada se desejar.
        </S.Description>

        {!!statusMessage.message && (
          <Alert variant={statusMessage.type} text={statusMessage.message} />
        )}

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            variant="light"
            label={"Nome"}
            inputName={"recipientName"}
            registerFunction={register}
            control={control}
            errors={errors}
            defaultValue={giftData?.recipientName || ""}
          />
          <Input
            variant="light"
            label={"E-mail"}
            inputName={"recipientEmail"}
            registerFunction={register}
            control={control}
            errors={errors}
            style={{ textTransform: "lowercase" }}
            defaultValue={giftData?.recipientEmail || ""}
          />
          <Textarea
            variant="light"
            label={"Mensagem personalizada (opcional)"}
            inputName={"giftMessage"}
            rows="4"
            registerFunction={register}
            errors={errors}
            defaultValue={""}
          />

          <Button
            variant={"green"}
            size={"full"}
            type="submit"
            id="FinalizarIdentificacao"
            name="FinalizarIdentificacao"
            disabled={sendingForm}
            loading={sendingForm}
          >
            Continuar
          </Button>
        </S.Form>
      </S.GiftCheckoutContainer>
      <CheckoutFooter />
    </S.Wrapper>
  );
};

export default GiftCheckoutSendEmail;
