import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, OnboardingHeader } from "../../components";
import { useActiveCampaignTag, useUser } from "../../hooks";
import activeCampaignTags from "../../constants/active-campaign-tags.json";
import * as S from "./styles";

const HOURS_PER_WEEK = [
  { tag: "3horasporsemana", title: "Até 3 horas por semana" },
  { tag: "9horasporsemana", title: "Até 9 horas por semana" },
  { tag: "14horasporsemana", title: "Até 14 horas por semana" },
  {
    tag: "mais14horasporsemana",
    title: "Mais de 14 horas por semana",
  },
  { tag: "horasnaosei", title: "Eu ainda não sei" },
];

const OnboardingStudyTime = () => {
  const navigate = useNavigate();
  const {
    data: { userData },
  } = useUser();
  const { mutate: sendToActiveCampaign } = useActiveCampaignTag();

  const [disabled, setDisabled] = useState(true);

  /**
   *
   * @param {React.FormEvent} e
   */
  function onSubmit(e) {
    e.preventDefault();
    const {
      target: {
        time: { value },
      },
    } = e;

    if (value !== "") {
      sendToActiveCampaign({
        email: userData.email,
        tagId: activeCampaignTags[value],
      });
    }

    navigate("/onboarding/como-conheceu-a-casa-do-saber", { replace: true });
  }

  return (
    <S.Wrapper>
      <OnboardingHeader />
      <S.Container onSubmit={onSubmit}>
        <h1>Quanto tempo você pretende dedicar aos nossos cursos?</h1>

        <p>Escolha uma opção:</p>

        <S.ChannelsList>
          {HOURS_PER_WEEK.map(({ tag, title }) => (
            <label htmlFor={`time-${tag}`} key={`time-${tag}`}>
              <S.RadioButton
                type="radio"
                name="time"
                id={`time-${tag}`}
                value={tag}
                onChange={() => setDisabled(false)}
              />
              <S.RadioButtonMark />
              {title}
            </label>
          ))}
        </S.ChannelsList>
        <Button variant={"white"} disabled={disabled}>
          RESPONDER
        </Button>
        <S.LinkStyled to="/">PULAR E COMEÇAR</S.LinkStyled>
      </S.Container>
    </S.Wrapper>
  );
};

export default OnboardingStudyTime;
