import styled from "styled-components";

export const Wrapper = styled.footer`
  font-family: "MaisonNeueBook", sans-serif;
  color: ${(props) => props.theme.darkTheme.colors.text};
  font-size: 12px;
  text-align: center;
  padding: 16px;
  margin: 0 -0.7rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  max-height: 115px;

  a {
    color: ${(props) => props.theme.darkTheme.colors.text};
    text-decoration: underline;
    text-underline-offset: 1px;

    :hover {
      font-weight: 800;
      color: ${(props) => props.theme.darkTheme.colors.text};
    }
  }
`;
