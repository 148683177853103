import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const MainWrapper = styled.main`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacings.medium};
    background-color: ${theme.lightTheme.colors.background};
    height: 100%;

    @media (${device.tablet}) {
      margin: auto;
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 414px;
    margin: auto;
    width: 100%;
    padding: 50px 16px 0px;
    min-height: calc(100vh - 64px); // header height: 62px
    margin: auto;
    position: relative;

    .alert-box span {
      color: ${theme.lightTheme.colors.text};
    }
    .alert-box a {
      color: ${theme.lightTheme.colors.text};
    }
  `}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PrivacyPolicy = styled.div`
  ${({ theme }) => css`
    padding: 15px;
    background: ${theme.lightTheme.colors.background};
    border: 1px solid ${theme.lightTheme.colors.gray};
    border-radius: 5px;
    p {
      color: ${theme.lightTheme.colors.text};
      display: block;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 130%;
      justify-content: center;
      margin-bottom: 5px;
      margin-top: 2px;
    }
    a {
      color: ${theme.lightTheme.colors.text};
      text-decoration: underline;
    }

    button:hover:enabled,
    button:focus:enabled {
      border-width: 2px;
      border-style: solid;
      border-color: ${theme.lightTheme.colors.green};
    }
  `}
`;

export const GiftLink = styled(Link)`
  ${({ theme }) => css`
    cursor: pointer;
    margin: auto;
    text-align: center;
    color: ${theme.lightTheme.colors.darkBlack};
    span {
      text-decoration: underline;
    }
    :hover {
      color: ${theme.lightTheme.colors.darkBlack};
      text-decoration: none;
    }
  `}
`;

export const GiftSection = styled.div`
  margin-top: 10px;
  text-align: center;
`;
