import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    font-size: 12px;
    transition: all 0.1s ease-in;
    cursor: pointer;

    .stroke-solid {
      stroke: ${theme.darkTheme.colors.white};
      stroke-dashoffset: 0;
      stroke-dasharray: 300;
      stroke-width: 6px;
      fill: transparent;
      transition: fill 1s ease-out, stroke-dashoffset 1s ease, opacity 1s ease;
    }

    .icon {
      fill: ${theme.darkTheme.colors.white};
      transform: scale(0.8);
      transform-origin: 50% 50%;
      transition: transform 200ms ease-out, fill 200ms ease-out;
    }

    // Hover
    #play:hover {
      .stroke-solid {
        fill: ${theme.darkTheme.colors.green};
        opacity: 1;
        stroke-dashoffset: 300;
      }
      .icon {
        fill: ${theme.darkTheme.colors.darkBlack};
        transform: scale(0.9);
      }
    }
  `}
`;
