import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAuthContext } from "../../context/AuthContext";
import { useWindowWidthCalculate } from "../../hooks";
import Datalayer from "../../services/datalayer";
import { translateAmplifyErrorMessage } from "../../util/messages";

import {
  CodeInput,
  GoBackButton,
  PasswordInput,
  Button,
  LoginPageTitle,
  LoginContainer,
  Alert,
} from "../../components";

import * as S from "./styles";

const schema = yup.object({
  newPassword: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(6, "A senha deve conter, ao menos, ${min} caracteres")
    .required("A senha deve conter, ao menos, 8 caracteres")
    .trim(),
  newPasswordConfirm: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(6, "A senha deve conter, ao menos, ${min} caracteres")
    .oneOf([yup.ref("newPassword"), null], "As senhas devem ser iguais")
    .required("As senhas devem ser iguais")
    .trim(),
});

const RecoverPassword = () => {
  const { isDesktop } = useWindowWidthCalculate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { recoverPassword } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state?.email;

  const [alert, setAlert] = useState({});

  const formatVerificationCode = (data) => {
    const key = 0;
    const value = 1;
    const codeArray = [];

    Object.entries(data).forEach((input) => {
      if (input[key].includes("verificationCode")) {
        return codeArray.push(input[value]);
      }
    });

    const verificationCode = codeArray.join("");

    return { verificationCode };
  };

  const onSubmit = async (data) => {
    const { verificationCode } = formatVerificationCode(data);

    try {
      await recoverPassword(userEmail, verificationCode, data.newPassword);
      return navigate("/entrar", {
        state: {
          passwordUpdated: true,
          message:
            "Senha redefinida com sucesso. Faça login para acessar a plataforma.",
        },
      });
    } catch (error) {
      const { message } = translateAmplifyErrorMessage(error?.message);

      setAlert({
        message,
        type: "error",
      });
    }
  };

  return (
    <LoginContainer>
      {!isDesktop && <GoBackButton />}

      <S.LoginWrapper>
        {isDesktop && <GoBackButton />}

        {!!alert.message && <Alert variant={alert.type} text={alert.message} />}

        <LoginPageTitle
          title={"Recuperação de conta"}
          subtitle={`Enviamos um código de confirmação de 6 dígitos no e-mail ${userEmail}. Informe esses números juntamente com a sua nova senha.`}
        />

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <CodeInput
            quantity={6}
            inputName={"verificationCode"}
            registerFunction={register}
          />

          <PasswordInput
            label={"Nova Senha"}
            inputName={"newPassword"}
            registerFunction={register}
            errors={errors}
          />

          <PasswordInput
            label={"Confirmar Nova Senha"}
            inputName={"newPasswordConfirm"}
            registerFunction={register}
            errors={errors}
          />

          <Button
            variant={"white"}
            size={"full"}
            type="submit"
            onClick={() =>
              Datalayer.onClick(
                "forgot-password",
                "botao:recuperacao-de-senha",
                "save-new-password"
              )
            }
          >
            Verificar
          </Button>
        </S.Form>
      </S.LoginWrapper>
    </LoginContainer>
  );
};

export default RecoverPassword;
