import styled from "styled-components";

export const Wrapper = styled.div`
  h1 {
    color: ${(props) => props.theme.darkTheme.colors.text};
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    position: relative;
    margin-bottom: 0px;
    text-align: left;
  }
  p {
    color: ${(props) => props.theme.darkTheme.colors.gray};
    font-weight: 100;
    margin-bottom: 0px;
    .text-white {
      color: #fff;
    }
  }
`;
