import React, { useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useCourse,
  useCoursesByAuthor,
  useQuery,
  useUser,
  useUpdateAutoplay,
  useNextVideo,
  useCertificateModal,
  useSentry,
} from "../../hooks";

import { formatCourseTime } from "../../util/course";
import { getProgress } from "../../util/course";
import { PageWrapper } from "../../containers";
import {
  Container,
  CourseBanner,
  Player,
  CertificateModal,
  LoadingSpinner,
  MyListButton,
  ProgressBar,
  Tabs,
  CourseVideos,
  CourseAbout,
  CourseButton,
  ShareButton,
  PlayerTrailer,
  TrailerButton,
  CourseRating,
  Rating,
} from "../../components";

import playIcon from "../../assets/icons/play-black-icon.svg";
import { ReactComponent as ClassesIcon } from "../../assets/icons/classes-icon.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/total-class-hours-icon.svg";

import strings from "../../config/strings.json";
import * as S from "./styles";

const Course = () => {
  const { id } = useParams();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const query = useQuery();
  const navigate = useNavigate();
  const { logException } = useSentry();

  const {
    data: { userData },
  } = useUser();

  const {
    data: courseData,
    isLoading: isCourseLoading,
    isIdle: isCourseIdle,
    refetch: refetchCourseData,
  } = useCourse(userData?.id, id, {
    onError: (error) => {
      const exceptionParams = {
        error,
        transactionName: "Course",
        origin: "useCourse",
        tags: [
          { label: "errorMessage", value: error.message },
          { label: "errorCode", value: error.code },
        ],
        extras: [
          { label: "data", value: error },
          { label: "courseId", value: id },
        ],
      };
      logException(exceptionParams);
      navigate("/error");
    },
  });

  const { data: authorData, isLoading: isAuthorLoading } = useCoursesByAuthor(
    courseData?.authorId
  );

  const { mutate: updateAutoplay } = useUpdateAutoplay();

  const {
    showModal,
    modalType,
    certificate,
    setShowModal,
    setModalType,
    openCertificateModal,
  } = useCertificateModal(courseData?.userCourseId);

  const [hasAccess, setHasAccess] = useState(false);
  const [videoSlug, setVideoSlug] = useState(null);
  const [showTrailer, setShowTrailer] = useState(false);
  const [ignoreProgressOnAutoplay, setIgnoreProgressOnAutoplay] =
    useState(false);
  const [showRatingForm, setShowRatingForm] = useState(false);

  const removeQueryParams = () => {
    const param = searchParams.get("play_video");

    if (param) {
      window.history.replaceState(null, "remove search", location.pathname);
      // delete query param
      searchParams.delete("play_video");

      // update state after
      setSearchParams(searchParams);
    }
  };
  // verifica se pelos parâmetros da URL se deve abrir o próximo vídeo
  useEffect(() => {
    const courseAvailable = courseData?.hasAccess && courseData?.published;

    if (courseAvailable && query.get("play_video") === "true") {
      setVideoSlug(courseData.activeContent?.slug);
    }
    setHasAccess(courseAvailable);
  }, [courseData]);

  // busca o id do curso para passar pro player
  const currentClass = useMemo(() => {
    return courseData?.contents?.find((content) => content.slug === videoSlug);
  }, [videoSlug]);

  const ratingType = useMemo(() => {
    const currentClassIndex = courseData?.contents
      ?.map((c) => c.id)
      .indexOf(currentClass?.id);

    if (currentClassIndex === courseData?.contents?.length - 1) {
      return courseData?.ratingAnswered.course ? null : "lastClass";
    }
    if (currentClassIndex === 0) {
      return courseData?.ratingAnswered.firstClass ? null : "firstClass";
    }
    return null;
  }, [courseData, currentClass]);

  // Próximo vídeo e texto do botão
  const { nextVideo, playButtonText } = useNextVideo(
    courseData?.id,
    courseData?.contents,
    courseData?.isFinished,
    courseData?.classesFinished
  );

  const playVideo = (slug) => {
    setIgnoreProgressOnAutoplay(false);
    if (slug) {
      return setVideoSlug(slug);
    }
    const { slug: nextVideoSlug } = nextVideo();

    setVideoSlug(nextVideoSlug);
  };

  const closePlayerAndRefetch = () => {
    removeQueryParams();
    refetchCourseData();
    return setVideoSlug(null);
  };

  const autoplayNextVideo = () => {
    try {
      const index = courseData.contents?.findIndex(
        (video) => video.slug === videoSlug
      );
      const isLast = index === courseData.contents.length - 1;
      if (isLast) {
        return closePlayerAndRefetch();
      }
      setIgnoreProgressOnAutoplay(true);
      const nextVideoOnContentsList = courseData.contents[index + 1];

      if (nextVideoOnContentsList.status === "published") {
        return setVideoSlug(courseData.contents[index + 1]?.slug);
      }
      return closePlayerAndRefetch();
    } catch (error) {
      const exceptionParams = {
        error,
        transactionName: "Player",
        origin: "autoplayNextVideo",
        tags: [
          { label: "errorMessage", value: error.message },
          { label: "errorCode", value: error.code },
        ],
        extras: [
          { label: "data", value: error },
          { label: "courseData", value: courseData },
          { label: "videoSlug", value: videoSlug },
        ],
      };
      logException(exceptionParams);

      return setVideoSlug(null);
    }
  };

  if (isCourseIdle || isCourseLoading || isAuthorLoading) {
    return <LoadingSpinner color={"light"} />;
  }

  const suffix = Number(courseData.classes) > 1 ? "s" : "";
  const hasTrailer = !!courseData.trailer;
  const hasCertificateButton = !!courseData.isFinished;

  const { status, percentage } = getProgress(
    courseData.classesFinished,
    courseData.classes
  );
  const renderProgressContainer = {
    inProgress: (
      <S.ProgressContainer>
        <ProgressBar progress={percentage} />
        Aula{" "}
        <strong>
          {courseData.classesFinished}/{courseData.classes}
        </strong>
      </S.ProgressContainer>
    ),
    concluded: (
      <S.ProgressContainer>
        <ProgressBar progress={percentage} />
        <span>
          <strong>100% concluído</strong>
        </span>
        <span>
          Aula{" "}
          <strong>
            {courseData.classesFinished}/{courseData.classes}
          </strong>
        </span>
      </S.ProgressContainer>
    ),
    notStarted: <></>,
    default: <></>,
  };

  const onFinishVideo = () => {
    if (!!ratingType) {
      setShowRatingForm(true);
    } else {
      setShowRatingForm(false);
      autoplayNextVideo();
    }
  };

  const onCloseRatingForm = () => {
    setShowRatingForm(false);
    autoplayNextVideo();
  };
  return (
    <PageWrapper>
      <Container type={"transparent"}>
        <CourseBanner
          coverDesktop={courseData.cover}
          coverMobile={courseData.coverMobile || courseData.cover}
          isCourseLoading={isCourseLoading}
        />
        <S.CourseContent>
          <S.CourseHeader>
            <div>
              <S.CourseTitle>{courseData.title}</S.CourseTitle>
              <S.CourseSubtitle>por {courseData.author}</S.CourseSubtitle>
            </div>

            <Rating
              value={courseData.rating}
              totalVotes={courseData.totalRatings}
            />

            <S.CourseDuration>
              <div>
                <ClockIcon /> {formatCourseTime(courseData.courseDuration)}
              </div>
              <div>
                <ClassesIcon /> {courseData.classes} aula{suffix}
              </div>
            </S.CourseDuration>

            <S.ButtonsContainer>
              {!courseData.published && courseData.publishedAt && (
                <CourseButton
                  disabled
                  type="coursePageWatch"
                  variant={"white"}
                  text={`Disponível em ${courseData.publishedAt.toLocaleString(
                    "pt-br",
                    {
                      timeZone: "utc",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )}`}
                />
              )}
              {hasAccess && (
                <CourseButton
                  text={playButtonText()}
                  type={"coursePageWatch"}
                  variant={"white"}
                  icon={playIcon}
                  onClick={() => playVideo()}
                  id={"btn-course-assistir"}
                  name={"btn-course-assistir"}
                />
              )}
              {hasCertificateButton && (
                <CourseButton
                  text={"Ver certificado"}
                  type={"coursePageCertificate"}
                  variant={"transparent"}
                  icon={null}
                  onClick={openCertificateModal}
                  id={"btn-course-ver_certificado"}
                  name={"btn-course-ver_certificado"}
                />
              )}

              {/* if has certificate button show on desktop (> 767px)
               ** over progress bar and hide on mobile (<= 767px)
               */}
              <S.ContainerOverProgressBar hideOnMobile={hasCertificateButton}>
                <MyListButton courseId={courseData.id} />
                <ShareButton />
                {hasTrailer && (
                  <TrailerButton onClick={() => setShowTrailer(!showTrailer)} />
                )}
              </S.ContainerOverProgressBar>
            </S.ButtonsContainer>

            {renderProgressContainer[status]}

            {/* if has certificate button show only on mobile (<= 767px)
             ** below progress bar
             */}
            {hasCertificateButton && (
              <S.ContainerBelowProgressBar>
                <MyListButton courseId={courseData.id} />
                <ShareButton />

                {hasTrailer && (
                  <TrailerButton onClick={() => setShowTrailer(!showTrailer)} />
                )}
              </S.ContainerBelowProgressBar>
            )}

            {!!courseData.complementaryFile && (
              <S.AnchorDownload
                download={`${courseData.title}.pdf`}
                href={courseData.complementaryFile}
                target={"_blank"}
                rel="noreferrer noopener"
              >
                {strings.course.downloadComplementaryFile}{" "}
              </S.AnchorDownload>
            )}
          </S.CourseHeader>

          <Tabs
            titles={["Aulas", "Sobre"]}
            contents={[
              <CourseVideos
                content={courseData.contents}
                playVideo={playVideo}
                hasAccess={hasAccess}
                toggleAutoplay={() =>
                  updateAutoplay({
                    ...userData,
                    autoplay: !userData.autoplay,
                  })
                }
                isAutoPlay={userData.autoplay}
              />,
              <CourseAbout
                description={courseData.description}
                author={courseData.author}
                authorId={authorData.id}
                authorDescription={authorData.description}
                authorPhoto={authorData.photo}
              />,
            ]}
          />
        </S.CourseContent>
        {hasTrailer && showTrailer && (
          <PlayerTrailer
            trailer={courseData.trailer}
            videoTitle={courseData.title}
            onClose={() => setShowTrailer(false)}
          />
        )}
        {/* Só vai executar se houver videoSlug */}
        <Player
          video={currentClass}
          ignoreProgress={ignoreProgressOnAutoplay}
          courseId={courseData.id}
          isAutoPlay={userData.autoplay}
          onClose={closePlayerAndRefetch}
          onFinishVideo={onFinishVideo}
        />
        {showModal && courseData.isFinished && (
          <CertificateModal
            type={modalType}
            courseName={courseData.title}
            authorName={courseData.author}
            certificateUrl={certificate}
            setShowModal={setShowModal}
            changeType={setModalType}
            tryAgain={openCertificateModal}
          ></CertificateModal>
        )}{" "}
        {showRatingForm && (
          <CourseRating
            ratingType={ratingType}
            onClose={onCloseRatingForm}
            classId={currentClass?.id}
            courseId={id}
          />
        )}
      </Container>
    </PageWrapper>
  );
};

export default Course;
