import styled, { css } from "styled-components";
import { device } from "../../styles/mediaQueries";

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 25px;
  `}
`;

export const SignUpLink = styled.a`
  ${({ theme }) => css`
    font-size: 16px;
    color: ${theme.darkTheme.colors.gray};
    text-decoration: none;
    font-weight: 100;
    span {
      color: ${theme.darkTheme.colors.white};
    }
    :hover {
      color: ${theme.darkTheme.colors.gray};
      text-decoration: none;
    }
  `}
`;

// Applies to ForgotPassword and RecoverPassword components
export const LoginWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 414px;
    margin: 0 auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    row-gap: 25px;

    @media (${device.laptop}) {
      margin: 10vh auto 0;
      justify-content: start;
    }
  `}
`;
