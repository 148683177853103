import React, { useState } from "react";
import * as S from "./styles";

const Tabs = ({ titles = [], contents = [] }) => {
  const [active, setActive] = useState(0);

  return (
    <S.Wrapper>
      <S.TabsContainer>
        {titles.map((item, index) => (
          <S.Tab
            key={item}
            onClick={() => setActive(index)}
            active={active === index}
          >
            {item}
          </S.Tab>
        ))}
      </S.TabsContainer>

      <S.ContentContainer>{contents[active]}</S.ContentContainer>
    </S.Wrapper>
  );
};

export default Tabs;
