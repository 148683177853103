import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    font-size: 12px;
    white-space: nowrap;
    transition: all 0.1s ease-in;
    cursor: pointer;

    i {
      font-size: 28px;
    }

    i.checked {
      color: ${theme.darkTheme.colors.green};
      border-color: ${theme.darkTheme.colors.green};
    }

    :hover {
      i {
        color: ${theme.darkTheme.colors.green};
        border-color: ${theme.darkTheme.colors.green};
      }
    }

    //loading must be the same icon font-size
    .spinner-border {
      max-width: 28px;
      max-height: 28px;
    }
  `}
`;
