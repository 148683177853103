import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCheckoutContext } from "../../context/CheckoutContext";
import { useSubscriptionStatus, useUser } from "../../hooks";
import Datalayer from "../../services/datalayer";
import {
  CheckoutHeader,
  CheckoutPageTitle,
  CheckoutFooter,
  CTAButton,
  StepperCustom,
  LoadingSpinner,
} from "../../components";

import * as S from "./styles";

export const CheckoutPlansPreview = () => {
  const navigate = useNavigate();
  const { checkoutSteps, currentStep, changeStep } = useCheckoutContext();
  const { isLoading } = useSubscriptionStatus();

  const {
    data: { userData },
  } = useUser();

  useEffect(() => {
    changeStep("entenda");
  }, []);

  const pageTitle = "Conta criada";
  const pageSubtitle = "Você usará este e-mail para entrar em sua conta:";

  if (isLoading)
    return (
      <S.Wrapper>
        <CheckoutHeader />
        <LoadingSpinner />
      </S.Wrapper>
    );

  return (
    <S.Wrapper>
      <CheckoutHeader />

      <StepperCustom steps={checkoutSteps} currentStep={currentStep} />

      <S.CheckoutContainer>
        <CheckoutPageTitle title={pageTitle} subtitle={pageSubtitle}>
          <S.Email>{userData?.email}</S.Email>
        </CheckoutPageTitle>

        <CTAButton
          id={"btn-conta-criada"}
          name={"btn-conta-criada"}
          onClick={() => {
            Datalayer.onClick("checkout", "botao:conta-criada", "proximo");
            navigate(`/checkout/entenda-a-assinatura`);
          }}
          text="PRÓXIMO"
        />
      </S.CheckoutContainer>
      <CheckoutFooter />
    </S.Wrapper>
  );
};

export default CheckoutPlansPreview;
