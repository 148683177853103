import styled from "styled-components";

export const Wrapper = styled.footer`
  position: absolute;
  bottom: 0;
  max-height: 180px;
  font-family: "MaisonNeueBook", sans-serif;
  color: ${(props) => props.theme.darkTheme.colors.text};
  font-size: 12px;
  text-align: center;
  padding: 16px;
  margin: 0 -0.7rem;
  width: 100%;
`;
