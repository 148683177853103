import React from "react";
import { support } from "../../data/support";
import * as S from "./styles";

const phone = (
  <a
    href={support.whatsapp.href}
    target={support.whatsapp.target}
    rel={support.whatsapp.rel}
    id={"btn-account_footer-phone"}
    name={"btn-account_footer-phone"}
  >
    {support.whatsapp.text}
  </a>
);

const mail = (
  <a
    href={support.mail.href}
    target={support.mail.target}
    rel={support.mail.rel}
    id={"btn-account_footer-mail"}
    name={"btn-account_footer-mail"}
  >
    {support.mail.text}
  </a>
);

const AccountFooter = () => {
  return (
    <S.Wrapper>
      Para tirar dúvidas entre em contato com {mail} ou por WhatsApp no número{" "}
      {phone}, de segunda à sexta-feira das 10h às 19h.
    </S.Wrapper>
  );
};

export default AccountFooter;
