import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, LoadingSpinner, OnboardingHeader } from "../../components";
import { useActiveCampaignTag, useChannels, useUser } from "../../hooks";
import * as S from "./styles";

const OnboardingOriginChannel = () => {
  const navigate = useNavigate();
  const {
    data: { userData },
  } = useUser();
  const { mutate: sendToActiveCampaign } = useActiveCampaignTag();
  const { data: channels, isFetching: channelsLoading } = useChannels({
    showQuestion: true,
  });

  const [disabled, setDisabled] = useState(true);

  /**
   *
   * @param {React.FormEvent} e
   */
  function onSubmit(e) {
    e.preventDefault();
    const {
      target: {
        channel: { value },
      },
    } = e;

    if (value !== "") {
      sendToActiveCampaign({
        email: userData.email,
        tagId: e.target.channel.value,
      });
    }

    navigate("/", { replace: true });
  }

  if (channelsLoading) return <LoadingSpinner color="light" />;

  return (
    <S.Wrapper>
      <OnboardingHeader />
      <S.Container onSubmit={onSubmit}>
        <h1>Como você conheceu a Casa do Saber?</h1>

        <p>Escolha uma opção:</p>

        <S.ChannelsList>
          {channels.map(({ id, title, acTagId }) => (
            <label htmlFor={`channel-${id}`} key={`channel-${id}`}>
              <S.RadioButton
                type="radio"
                name="channel"
                id={`channel-${id}`}
                value={acTagId}
                onChange={() => setDisabled(false)}
              />
              <S.RadioButtonMark />
              {title}
            </label>
          ))}
        </S.ChannelsList>
        <Button variant={"white"} disabled={disabled}>
          RESPONDER E COMEÇAR
        </Button>
        <S.LinkStyled to="/">PULAR E COMEÇAR</S.LinkStyled>
      </S.Container>
    </S.Wrapper>
  );
};

export default OnboardingOriginChannel;
