import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    background: ${theme.darkTheme.colors.background};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  `}
`;

export const Content = styled.div`
  max-width: 384px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: #fff;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin: 0;
    @media (max-width: ${theme.breakPoint.tablet}) {
      margin: 10px;
    }
  `}
`;

export const StarSection = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 21px;
    margin-bottom: 25px;
    @media (max-width: ${theme.breakPoint.tablet}) {
      gap: 10px;
    }
  `}
`;

export const StarButton = styled.button`
  background: transparent;
  outline: none;
  border: 0;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
`;

export const Top = styled.div`
  display: flex;
  justify-content: end;
  max-width: 1152px;
  margin: 0 auto;
  margin-top: 23px;
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.darkTheme.colors.gray};
    font-weight: 400;
    margin: 0;
  `}
`;

export const TextArea = styled.textarea`
  ${({ theme }) => css`
    background-color: ${theme.darkTheme.colors.inputBackground};
    border: none;
    border-radius: 5px;
    width: 100%;
    padding: 8px;
    color: white;
    outline: none;
    font-size: 14px;
  `}
`;
