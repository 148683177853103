import React from "react";
import { useState } from "react";
import clipIcon from "../../assets/icons/clip.png";
import { copyLink } from "../../util/Util";
import * as S from "./styles";

const CopyButton = ({ round, size, textToCopy, className, id, name }) => {
  const [success, setSuccess] = useState(false);

  return (
    <S.Wrapper
      round={round}
      size={size}
      onClick={async () =>
        copyLink(textToCopy).then(() => {
          setSuccess(true);
        })
      }
      onMouseOver={() => success && setSuccess(false)}
      id={id}
      name={name}
      className={className}
    >
      <img src={clipIcon} alt="copiar" />
      <span className="tooltiptext">{success ? "Copiado!" : "Copiar"}</span>
    </S.Wrapper>
  );
};

export default CopyButton;
