import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MaskedInput from "react-input-mask";
import _ from "lodash";
import { Spinner } from "reactstrap";
import { FiAlertTriangle } from "react-icons/fi";

import { useCheckoutContext } from "../../context/CheckoutContext";
import Datalayer from "../../services/datalayer";
import { applyGiftCouponCode, verifyCouponCode } from "../../services/checkout";
import { secureStorage } from "../../util/SecureStorage";
import { formatDiscountLabel } from "../../util/Util";
import { CTAButton, CheckoutPageTitle, LoadingSpinner } from "..";

import strings from "../../config/strings.json";
import * as S from "./styles";
import ArrowDown from "../../assets/icons/arrow-down.svg";

export const GiftPlans = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { giverEmail, recipientEmail } = state ?? {};

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [blockCouponInput, setBlockCouponInput] = useState(false);

  const {
    giftPlans,
    setGiftPlans,
    resetGiftPlan,
    getPromotionalGiftPlans,
    getGiftPlans,
    selectedPlan,
    setSelectedPlan,
    couponFromParam,
    setDisableInCashDiscount,
  } = useCheckoutContext();

  const updateGiftPlansWithCoupon = async () => {
    if (couponCode.length > 1) {
      setButtonDisabled(true);

      try {
        let giftPlansArray = [];

        if (couponCode === process.env.REACT_APP_PROMOTIONAL_GIFT_COUPON_CODE) {
          giftPlansArray = getPromotionalGiftPlans(); // deixar só plano de 1 ano
          setBlockCouponInput(true);
          setDisableInCashDiscount(true);
        } else {
          giftPlansArray = getGiftPlans();
          setDisableInCashDiscount(false);
        }

        const arrayOfPlansUpdated = await Promise.all(
          giftPlansArray.map(async (plan) => {
            const { success, response } = await applyGiftCouponCode(
              couponCode,
              plan
            );
            if (!success) throw response;
            return { success, response };
          })
        );

        const updatedPlans = arrayOfPlansUpdated.map((plan, index) => {
          if (!plan.success) {
            return { ...giftPlansArray[index] };
          } else {
            return { ...giftPlansArray[index], ...plan.response };
          }
        });

        setErrors(false);

        setGiftPlans(updatedPlans);
      } catch (error) {
        // Previne exibir erro quando aplicado via variável de ambiente e volta a mostrar o plano de 2 anos quando inválido o cupom promocional
        if (couponCode === process.env.REACT_APP_PROMOTIONAL_GIFT_COUPON_CODE) {
          setCouponCode("");
          setIsOpen(false);
          setBlockCouponInput(false);
          return setLoading(false);
        }

        setMessage(error);
        setButtonDisabled(false);
        setDisableInCashDiscount(false);
        resetGiftPlan();
        return setErrors(true);
      } finally {
        setButtonDisabled(false);
        if (loading) {
          return setLoading(false);
        }
      }
    }
  };
  const delayedQuery = useCallback(
    _.debounce(() => updateGiftPlansWithCoupon(couponCode), 800),
    [couponCode]
  );

  useEffect(() => {
    if (!giverEmail || !recipientEmail)
      navigate("/presente", { replace: true });
    if (!secureStorage.getItem(giverEmail + recipientEmail))
      navigate("/presente", { replace: true });
  }, [giverEmail, recipientEmail]);

  useEffect(() => {
    if (!!couponFromParam) {
      setCouponCode(couponFromParam);
      return setIsOpen(true);
    }

    if (!!selectedPlan.coupon) {
      setCouponCode(selectedPlan.coupon);
      return setIsOpen(true);
    }

    if (process.env.REACT_APP_PROMOTIONAL_GIFT_COUPON_CODE) {
      setCouponCode(process.env.REACT_APP_PROMOTIONAL_GIFT_COUPON_CODE);
      return setIsOpen(true);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    delayedQuery();
    return delayedQuery.cancel;
  }, [couponCode, delayedQuery]);

  const applyCouponAndRedirect = async () => {
    setErrors(false);
    setButtonDisabled(true);

    const verifyCoupon = await verifyCouponCode(
      couponCode,
      selectedPlan.price,
      selectedPlan.id
    );

    if (!verifyCoupon.success) {
      Datalayer.onCouponCallback("erro");
      Datalayer.addToCart(
        selectedPlan.name,
        selectedPlan.id,
        selectedPlan.price,
        selectedPlan.coupon
      );
    } else {
      Datalayer.onCouponCallback("sucesso");
      setSelectedPlan({ ...selectedPlan, ...verifyCoupon.response });

      Datalayer.addToCart(
        verifyCoupon.response.name,
        verifyCoupon.response.id,
        verifyCoupon.response.price,
        couponCode
      );
    }

    return navigate("/presente/pagamento", {
      state: {
        ...state,
        plan: selectedPlan.id,
      },
    });
  };

  const goToPayment = async (e) => {
    e.preventDefault();
    if (buttonDisabled) return;
    if (!selectedPlan) {
      setMessage("Por favor, selecione um plano para prosseguir");
      setButtonDisabled(false);
      return setErrors(true);
    }

    if (!couponCode) {
      Datalayer.addToCart(
        selectedPlan.name,
        selectedPlan.id,
        selectedPlan.price,
        selectedPlan.coupon
      );
      return navigate("/presente/pagamento", {
        state: {
          ...state,
          plan: selectedPlan.id,
        },
      });
    } else {
      applyCouponAndRedirect();
    }
  };

  if (loading) return <LoadingSpinner />;

  return (
    <S.CheckoutContainer>
      <CheckoutPageTitle
        title={"Escolha um plano para presente"}
        subtitle={null}
      />

      <S.PlansContainer>
        {giftPlans.map((plan) => (
          <S.PlanItem
            key={plan.name}
            onClick={() => {
              setErrors(false);
              Datalayer.onSelectProduct(plan.name, plan.id, plan.price);
              return setSelectedPlan(plan);
            }}
            selected={plan.id === selectedPlan.id}
          >
            <span className="plan-name">{plan.name}</span>
            <span className="plan-value">
              {plan.installmentsInfo}
              <strong> R$ {plan.installmentPrice}</strong>
              {!!plan.discount && (
                <S.DiscountLabel>
                  ↓{formatDiscountLabel(plan.discountLabel)}
                </S.DiscountLabel>
              )}
            </span>
          </S.PlanItem>
        ))}
      </S.PlansContainer>

      {errors && (
        <S.Errors>
          {" "}
          <FiAlertTriangle /> <span>{message}</span>{" "}
        </S.Errors>
      )}

      <S.Coupon
        className={"coupon-container"}
        open={isOpen}
        data-testid={"checkout-coupon-accordion"}
      >
        <S.TitleContainer
          className="coupon-title"
          onClick={() => setIsOpen(!isOpen)}
          id={"btn-gift_plans-coupon"}
          name={"btn-gift_plans-coupon"}
        >
          Cupom de desconto
          <img src={ArrowDown} alt="Seta para cima" />
        </S.TitleContainer>

        <S.InputContainer className="coupon-code">
          <label htmlFor={"couponCode"}>Digite o código</label>
          <MaskedInput
            id="couponCode"
            key="couponCode"
            name="couponCode"
            type="couponCode"
            value={couponCode}
            onChange={(e) => !blockCouponInput && setCouponCode(e.target.value)}
            autoComplete="off"
          />
        </S.InputContainer>
      </S.Coupon>

      <CTAButton
        id={"btn-gift_plans-proximo"}
        name={"btn-gift_plans-proximo"}
        type="submit"
        disabled={buttonDisabled}
        onClick={(e) => {
          Datalayer.onClick("checkout", "botao:presente:2", "proximo");
          !buttonDisabled && goToPayment(e);
        }}
        text={buttonDisabled ? <Spinner color="dark" /> : "PRÓXIMO"}
      />

      <S.GiftDisclaimer>{strings.giftCheckout.disclaimer}</S.GiftDisclaimer>
    </S.CheckoutContainer>
  );
};

export default GiftPlans;
