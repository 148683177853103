import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "./AuthContext";
import { usePromotionalCampaign, useUser } from "../hooks";
import useLocalStorage from "../hooks/useLocalStorage";
import checkIconWhite from "../assets/icons/check-white.svg";
import { getURLParamValue } from "../util/Util";

export const CheckoutContext = createContext();
CheckoutContext.displayName = "Planos";

const INITIAL_PLAN_DATA = {
  name: "Plano anual",
  id: process.env.REACT_APP_DB_PLAN_VINDI,
  installmentsInfo: "Até 12x de",
  installmentPrice: "38,33",
  price: "459,90",
  originalPrice: "459,90",
  discount: "",
  coupon: "",
  couponId: "",
};

// Planos de presente
const INITIAL_GIFT_PLAN_DATA = [
  {
    name: "1 ano",
    id: process.env.REACT_APP_DB_PLAN_VINDI_GIFT_1YEAR,
    installmentsInfo: "Até 12x de",
    installmentPrice: "39,90",
    price: "478,80",
    originalPrice: "478,80",
    discount: "",
    coupon: "",
    couponId: "",
  },
  {
    name: "2 anos",
    id: process.env.REACT_APP_DB_PLAN_VINDI_GIFT_2YEARS,
    installmentsInfo: "Até 12x de",
    installmentPrice: "65,90",
    price: "790,80",
    originalPrice: "790,80",
    discount: "",
    coupon: "",
    couponId: "",
  },
];
// CAMPANHAS - esconder o plano de 2 anos
const INITIAL_PROMOTIONAL_GIFT_PLAN_DATA = [
  {
    name: "1 ano",
    id: process.env.REACT_APP_DB_PLAN_VINDI_GIFT_1YEAR,
    installmentsInfo: "Até 12x de",
    installmentPrice: "39,90",
    price: "478,80",
    originalPrice: "478,80",
    discount: "",
    coupon: "",
    couponId: "",
  },
];

export const CheckoutProvider = ({ children }) => {
  const { isLoggedIn } = useAuthContext();
  const {
    data: { userData },
  } = useUser({
    enabled: isLoggedIn,
  });

  const planFromParams = getURLParamValue("plan");

  const [couponFromParam, setCouponFromParam] = useState("");
  const [showWhatsapp, setShowWhatsapp] = useState(false);
  const [plan, setPlan] = useState(INITIAL_PLAN_DATA);
  const [data, setData] = useState({
    email: "",
    plan: planFromParams,
    price: plan.price.replace(",", ""),
    cardName: "",
    cardNumber: "",
    cardExpiration: "",
    cvv: "",
    issuer: "",
    installments: "1",
    cpf: "",
  });
  const [currentStep, setCurrentStep] = useLocalStorage("checkout-step", 1);

  const checkoutSteps = [
    {
      title: "Escolha uma senha",
      icon: currentStep > 0 ? checkIconWhite : null,
    },
    {
      title: "Entenda a assinatura",
      icon: currentStep > 1 ? checkIconWhite : null,
    },
    {
      title: "Efetue o pagamento",
      icon: currentStep > 2 ? checkIconWhite : null,
    },
  ];

  // contexto de presente - campaign: /best-week endpoint
  const { data: campaign } = usePromotionalCampaign({
    enabled: !!userData,
  });

  const initialGiftPlan = !!campaign
    ? INITIAL_PROMOTIONAL_GIFT_PLAN_DATA
    : INITIAL_GIFT_PLAN_DATA;

  const [giftPlans, setGiftPlans] = useState(initialGiftPlan);
  const [disableInCashDiscount, setDisableInCashDiscount] = useState(false);

  const [selectedPlan, setSelectedPlan] = useState(giftPlans[0]);

  const giftSteps = [
    {
      title: "Cadastro",
      icon: currentStep > 0 ? checkIconWhite : null,
    },
    {
      title: "Entenda a assinatura",
      icon: currentStep > 1 ? checkIconWhite : null,
    },
    {
      title: "Efetue o pagamento",
      icon: currentStep > 2 ? checkIconWhite : null,
    },
  ];
  /**** fim contexto presente ****/

  useEffect(() => {
    if (userData) {
      setData({ ...data, email: userData.email });
    }
  }, [userData]);

  useEffect(() => {
    setTimeout(() => {
      setShowWhatsapp(true);
    }, 120000);
  }, []);

  useEffect(() => {
    const cupom = getURLParamValue("cupom");
    if (cupom) {
      setCouponFromParam(cupom);
    }
  }, []);

  return (
    <CheckoutContext.Provider
      value={{
        plan,
        setPlan,
        giftPlans,
        setGiftPlans,
        selectedPlan,
        setSelectedPlan,
        data,
        setData,
        currentStep,
        setCurrentStep,
        checkoutSteps,
        giftSteps,
        showWhatsapp,
        couponFromParam,
        disableInCashDiscount,
        setDisableInCashDiscount,
        initialGiftPlan,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutContext = () => {
  const {
    plan,
    setPlan,
    giftPlans,
    setGiftPlans,
    selectedPlan,
    setSelectedPlan,
    data,
    setData,
    currentStep,
    setCurrentStep,
    checkoutSteps,
    giftSteps,
    showWhatsapp,
    couponFromParam,
    disableInCashDiscount,
    setDisableInCashDiscount,
  } = useContext(CheckoutContext);

  const handleInput = (event, name) => {
    setData({ ...data, [name]: event.target.value });
  };

  const handleCreditCardIssuer = (brand) => {
    setData({ ...data, issuer: brand });
  };

  const changeStep = (stepName) => {
    switch (stepName) {
      case "senha":
      case "cadastro":
        setCurrentStep(0);
        break;
      case "entenda":
        setCurrentStep(1);
        break;
      case "pagamento":
        setCurrentStep(2);
        break;
      case "sucesso":
        setCurrentStep(3);
        break;
      default:
        break;
    }
  };
  const getPromotionalGiftPlans = () => INITIAL_PROMOTIONAL_GIFT_PLAN_DATA;
  const getGiftPlans = () => INITIAL_GIFT_PLAN_DATA;

  const resetPlan = () => setPlan(INITIAL_PLAN_DATA);
  const resetGiftPlan = () => setGiftPlans(INITIAL_GIFT_PLAN_DATA);

  return {
    plan,
    setPlan,
    giftPlans,
    setGiftPlans,
    selectedPlan,
    setSelectedPlan,
    data,
    handleInput,
    handleCreditCardIssuer,
    currentStep,
    changeStep,
    checkoutSteps,
    giftSteps,
    showWhatsapp,
    resetPlan,
    resetGiftPlan,
    getPromotionalGiftPlans,
    getGiftPlans,
    couponFromParam,
    disableInCashDiscount,
    setDisableInCashDiscount,
  };
};
