import { useMutation, useQuery } from "react-query";
import { requests } from "../services/requests";
import { setFreshTime } from "../constants/reactQueryDefaultOption";
import { useAuthContext } from "../context/AuthContext";

const queryKeys = {
  promotionalCampaign: "promotional-campaign",
};

export const usePromotionalCampaign = (options = {}) => {
  const { isLoggedIn, testingDate } = useAuthContext();
  const context = isLoggedIn ? "app" : "public";

  return useQuery(
    [queryKeys.promotionalCampaign, context],
    () => requests.getPromotionalCampaign(context, testingDate),
    {
      ...setFreshTime(5),
      refetchOnWindowFocus: false,
      retry: 1,
      ...options,
    }
  );
};

export const useExtendsSubscription = (options = {}) => {
  return useMutation((params) => requests.extendsSubscription(params), {
    ...options,
  });
};
