import styled, { css } from "styled-components";

export const Wrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: ${({ theme }) => theme.darkTheme.colors.background};
`;

export const Container = styled.div`
  margin: 15px auto 0 auto;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  row-gap: 25px;

  ${({ theme }) => css`
    max-width: ${theme.breakPoint.regularPhone};
    @media (max-width: ${theme.breakPoint.regularPhone}) {
      margin: 15px;
    }
  `}

  h1 {
    font-weight: 700;
    font-size: 24px;
  }
`;
