import React from "react";
import { useBannerInfoContent } from "../../hooks";

import * as S from "./styles";

const BannerInformative = () => {
  const { showBannerInfo, bannerContent } = useBannerInfoContent();

  const hascontent = Object.keys(bannerContent).length > 0;

  if (!showBannerInfo || !hascontent) {
    return null;
  }

  return (
    <S.Wrapper color={bannerContent.backgroundColor}>
      <S.Content>{bannerContent.text}</S.Content>
      <S.BannerActions>
        <S.Button
          opacity="true"
          onClick={() => bannerContent.dismissButton.action()}
          id={bannerContent.dismissButton.id}
          name={bannerContent.dismissButton.name}
        >
          {bannerContent.dismissButton.text}
        </S.Button>
        <S.Button
          onClick={() => bannerContent.actionButton.action()}
          id={bannerContent.actionButton.id}
          name={bannerContent.actionButton.name}
        >
          {bannerContent.actionButton.text}
        </S.Button>
      </S.BannerActions>
    </S.Wrapper>
  );
};

export default BannerInformative;
