import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium};
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium};
    font-family: ${theme.font.family.maisonNeue};
    font-size: ${theme.font.size.default};
    font-weight: ${theme.font.weight.bold};
    line-height: ${theme.font.lineHeight.medium};
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: ${theme.breakPoint.tablet}) {
      font-size: ${theme.font.size.large};
    }

    @media (min-width: ${theme.breakPoint.tv}) {
      font-size: ${theme.font.size.larger};
    }
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};
    font-family: ${theme.font.family.maisonNeue};
    font-size: ${theme.font.size.default};
    font-weight: ${theme.font.weight.light};
    line-height: ${theme.font.lineHeight.medium};
    letter-spacing: 0em;
    text-align: center;

    @media (min-width: ${theme.breakPoint.tv}) {
      font-size: ${theme.font.size.larger};
    }
  `}
`;
